import React, {useState} from 'react';

import {STRINGS} from '../../atoms';
import {ADDITIONAL_NOTES} from '../QuoteSummaryItem/constants';
import {
  Wrapper,
  Header,
  InfoTitle,
  InfoTextBold,
  InfoText,
  MoreInfoToggle,
  ToggleText,
  Body,
  LightText,
} from './moreinfo.styles';

const MoreInfo = ({type, info, border, additionalNotes}) => {
  const [open, setOpen] = useState(false);
  const clickMoreInfo = () => {
    setOpen(!open);
  };

  const getTitle = () => {
    switch (type) {
      case 'dispatch':
        return {
          title: 'Dispatch: ',
          text: `${info.number} ${info.street}`,
        };
      case 'provider':
        return {
          title: 'Provider: ',
          text: info,
        };
      case 'quote-provider':
        return {
          title: 'Provider: ',
          text: info.providerName,
        };
      case 'details':
        return {
          title: 'Details: ',
          text: 'Property inspection service',
        };
      case 'additinal-notes':
        return {
          title: ADDITIONAL_NOTES,
          text: additionalNotes,
        };
      default:
        return;
    }
  };

  const getBody = () => {
    switch (type) {
      case 'details':
        return (
          <>
            <LightText>Estimated time - 1 hour</LightText>
            <LightText>{`${info.numberOfBedrooms} bedroom property`}</LightText>
            <LightText>
              {info.buyOrOwn === 'own'
                ? 'Property privately owned'
                : 'Property rented'}
            </LightText>
            <LightText>{`Municipality plan ${
              info.municipalityPlan ? 'included' : 'excluded'
            }`}</LightText>
          </>
        );
      case 'dispatch':
        return (
          <>
            <LightText>{info.suburb}</LightText>
            <LightText>{info.city}</LightText>
            <LightText>{info.postalCode}</LightText>
          </>
        );
      case 'provider':
        return <LightText>{info}</LightText>;
      default:
        return;
    }
  };

  return (
    <Wrapper border={border}>
      <Header>
        <InfoTitle>
          <InfoTextBold>{getTitle().title}</InfoTextBold>
          <InfoText>{getTitle().text}</InfoText>
        </InfoTitle>
        <MoreInfoToggle onClick={() => clickMoreInfo()}>
          <ToggleText>
            {open ? STRINGS.LESS_INFO : STRINGS.MORE_INFO}
          </ToggleText>
        </MoreInfoToggle>
      </Header>
      {open && <Body>{getBody()}</Body>}
    </Wrapper>
  );
};

export default MoreInfo;
