import React from 'react';

import {Box, Card} from '@material-ui/core';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import {useMountEffect} from '@react-hookz/web';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {useTheme} from 'styled-components';

import {MIN_PAGE_HEIGHT} from '../../navigation/styles';
import {
  dataLayerVariables,
  sendComponentAnalytics,
} from '../../utils/Analytics';
import {RoundedButton} from '../Button';
import {BodyReg, BodyXsm, Header4, Subtitle2} from '../newTypography';

export default function YouDoNotQualifySolarLoan({
  pageName,
  buttonText = 'Home',
}) {
  const theme = useTheme();
  const history = useHistory();
  const financeOptions = ['A Personal loan', 'Your home loan', 'Pay in cash'];

  const buttonAction = async () => {
    return history.push('/');
  };

  useMountEffect(() => {
    sendComponentAnalytics(
      {
        ...dataLayerVariables({
          pageCategory: 'Property Hub',
          pageName: `${pageName}`,
          pageSubSection1: `${pageName}`,
        }),
      },
      'globalVirtualPageView',
    );
  });

  return (
    <Box
      minHeight={MIN_PAGE_HEIGHT}
      bgcolor={theme.colors.browserBackground}
      display="flex"
      justifyContent="center">
      <Box>
        <Box
          py={{xs: '20%', sm: '5%'}}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center">
          <Box
            component={Card}
            sx={{
              paddingX: '5%',
              width: '60%',
              paddingY: {xs: '10%', sm: '4%'},
            }}>
            <Box px={{xs: '4%'}}>
              <Box display="flex" justifyContent="center">
                <Box
                  component="img"
                  alt="clip-board"
                  src={theme.images.clipboardIcon}
                />
              </Box>
              <Box width="100%" mt={{xs: '10%', sm: '5%'}} textAlign="center">
                <Box>
                  <Header4 color={theme.colors.darkGray} inline>
                    You do not qualify for a Solar Loan at this time
                  </Header4>
                </Box>
                <Box mt={{xs: '10%', sm: '6%'}}>
                  <Subtitle2 color={theme.colors.brightRed} inline>
                    Unfortunately you don`t meet the minimum criteria for this
                    loan
                  </Subtitle2>
                </Box>
                <Box
                  component="hr"
                  color={theme.colors.hrColor}
                  width={{xs: '80%', sm: '60%'}}
                  mt={{xs: '10%', sm: '6%'}}
                />
              </Box>
              <Box mt={{xs: '8%', sm: '4%'}} textAlign="left">
                <Box>
                  <BodyReg
                    color={theme.colors.darkGray}
                    inline
                    fontWeight={700}>
                    Here are a few possible alternative finance options to
                    explore:
                  </BodyReg>
                </Box>
                <Box
                  mt={{xs: '10%', sm: '6%'}}
                  display="flex"
                  flexDirection="column">
                  {financeOptions.map(financeOption => (
                    <Box
                      display="flex"
                      alignItems="center"
                      mb="10px"
                      key={financeOption}>
                      <Box
                        fontSize={24}
                        component={CheckCircleOutlineRoundedIcon}
                        color={theme.colors.primaryBlue}
                        mr="10px"
                      />
                      <Box>
                        <BodyReg inline>{financeOption}</BodyReg>
                      </Box>
                    </Box>
                  ))}
                </Box>
                <Box mt={{xs: '6%', sm: '10%'}}>
                  <BodyXsm inline italic color={theme.colors.darkGray}>
                    Contact{' '}
                    <Box
                      component="a"
                      href="mailto:solarloan@standardbank.co.za"
                      color={theme.colors.darkGray}>
                      solarloan@standardbank.co.za
                    </Box>{' '}
                    for any queries.
                  </BodyXsm>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            mt="5%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center">
            <RoundedButton
              width="300px"
              data-id="link_content"
              data-text={`You do not qualify for a Solar Loan at this time |  ${buttonText} button click`}
              data-scope="button"
              data-intent="navigational"
              backgroundColor={theme.colors.blueMobileNav}
              textColor={theme.colors.white}
              text={buttonText}
              onClick={() => buttonAction()}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

YouDoNotQualifySolarLoan.propTypes = {
  pageName: PropTypes.string,
  buttonText: PropTypes.string,
};
