import styled from 'styled-components';

export const Wrapper = styled.div`
  color: ${({theme}) => theme.colors.black1200};
  font-weight: 600;
  background: ${({theme}) => theme.colors.yellow400};
  padding: ${({theme}) => `${theme.spacing.small} ${theme.spacing.large}`};
  display: flex;
  align-items: baseline;
  font: 600 14px Inter;
`;

export const ActionRequired = styled.a`
  cursor: pointer;
  text-decoration: underline;
`;
