import React from 'react';

import {useHistory} from 'react-router-dom';
import {useTheme} from 'styled-components';

import {STRINGS} from '../../atoms';
import {manageCookiesUrl} from '../../navigation/routes';
import {useFeaturesContext} from '../../provider/features';
import {
  Headline,
  LinkText,
  TermsText,
  FooterWrap,
  StyledIcon,
  Icons,
  Legal,
  Help,
  Terms,
  Grid,
} from './styles';

const TERMS_COPY =
  'Standard Bank is a licensed financial services provider in terms of the Financial Advisory and Intermediary Services Act and a registered credit provider in terms of the National Credit Act. Registration number NCRCP15';

function Footer() {
  const history = useHistory();
  const theme = useTheme();
  const features = useFeaturesContext();

  const handlePrivacy = () => {
    history.push('/privacy-policy');
  };

  const handleContact = () => {
    history.push('/contact-us');
  };

  const handleTerms = () => {
    history.push('/terms-and-conditions');
  };

  const handleCookies = () => {
    history.push(manageCookiesUrl);
  };

  const handleConduct = () => {
    history.push('/code-of-conduct');
  };

  return (
    <FooterWrap id="footer">
      <Grid>
        <Legal className="legal">
          <>
            <Headline>{STRINGS.LEGAL}</Headline>
            <LinkText
              onClick={() => handleTerms()}
              data-intent="informational"
              data-scope="footer"
              data-id="link_content"
              data-text="Legal | Terms and Conditions link click">
              Platform Terms and Conditions
            </LinkText>
            <LinkText
              onClick={() => handleConduct()}
              data-intent="informational"
              data-scope="footer"
              data-id="link_content"
              data-text="Legal | Code of Conduct link click">
              Code of Conduct
            </LinkText>
            <LinkText
              onClick={() => handlePrivacy()}
              data-intent="informational"
              data-scope="footer"
              data-id="link_content"
              data-text="Legal | Privacy Policy link click">
              {STRINGS.PRIVACY_POLICY}
            </LinkText>
            <LinkText
              onClick={() => handleCookies()}
              data-intent="informational"
              data-scope="footer"
              data-id="link_content"
              data-text={`Legal | ${STRINGS.MANAGE_COOKIES} link click`}>
              {STRINGS.MANAGE_COOKIES}
            </LinkText>
          </>
        </Legal>
        <Help className="help">
          <Headline>{STRINGS.HELP}</Headline>
          <LinkText
            onClick={() => handleContact()}
            data-intent="informational"
            data-scope="footer"
            data-id="link_content"
            data-text="Help | Contact link click">
            {STRINGS.CONTACT_US}
          </LinkText>
        </Help>
        <Terms className="terms">
          <TermsText>{TERMS_COPY}</TermsText>
        </Terms>
        {features.appAdvertisement && (
          <Icons className="icons">
            <>
              <a
                href="https://apps.apple.com/us/app/looksee-by-standard-bank/id1565804624"
                target="_blank"
                rel="noreferrer">
                <StyledIcon src={theme.images.appStore} alt="app-store" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.lookseemobileapp"
                target="_blank"
                rel="noreferrer">
                <StyledIcon src={theme.images.playStore} alt="play-store" />
              </a>
            </>
          </Icons>
        )}
      </Grid>
    </FooterWrap>
  );
}

export default Footer;
