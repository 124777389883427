import React, {useState} from 'react';

import {STRINGS} from '../../atoms';
import {ADDITIONAL_NOTES, DETAILS, PROVIDER} from './constants';
import {
  Wrapper,
  Header,
  InfoTitle,
  InfoTextBold,
  InfoText,
  MoreInfoToggle,
  ToggleText,
  Body,
  LightText,
} from './moreinfo.styles';

const MoreInfo = ({type, info, border, additionalNotes}) => {
  const [open, setOpen] = useState(false);
  const clickMoreInfo = () => {
    setOpen(!open);
  };
  const getTitle = () => {
    switch (type) {
      case 'details':
        return {
          title: DETAILS,
          text: 'Property inspection service',
        };
      case 'provider':
        return {
          title: PROVIDER,
          text: info.providerName,
        };
      case 'additinal-notes':
        return {
          title: ADDITIONAL_NOTES,
          text: additionalNotes,
        };
      default:
        return;
    }
  };

  const getBody = () => {
    if (type === 'details') {
      return (
        <>
          <LightText>Estimated time - 1 hour</LightText>
          <LightText>{`${info.numberOfBedrooms} bedroom property`}</LightText>
          <LightText>
            {info.buyOrOwn === 'own'
              ? 'Property privately owned'
              : 'Buying property'}
          </LightText>
          <LightText>{`Municipality plan ${
            info.municipalityPlan ? 'included' : 'excluded'
          }`}</LightText>
        </>
      );
    }
  };

  return (
    <Wrapper border={border}>
      <Header>
        <InfoTitle>
          <InfoTextBold>{getTitle().title}</InfoTextBold>
          <InfoText>{getTitle().text}</InfoText>
        </InfoTitle>
        <MoreInfoToggle onClick={() => clickMoreInfo()}>
          <ToggleText>
            {open ? STRINGS.LESS_INFO : STRINGS.MORE_INFO}
          </ToggleText>
        </MoreInfoToggle>
      </Header>
      {open && <Body>{getBody()}</Body>}
    </Wrapper>
  );
};

export default MoreInfo;
