import styled from 'styled-components';

export const TextFieldWrapper = styled.div`
  display: grid;
  margin-top: 15px;

  @media ${({theme}) => theme.device.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
`;

export const Wrapper = styled.div`
  padding: 40px 50px;
`;
