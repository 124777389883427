import React from 'react';

import PropTypes from 'prop-types';
import Carousel from 'react-material-ui-carousel';

import useDetectMobile from '../../hooks/useDetectMobile';

const Div = props => <div {...props} />;

function CollapseToCarousel({className, children, ...props}) {
  const isMobile = useDetectMobile();
  const Component = isMobile ? Carousel : Div;

  return (
    <Component className={className} {...props} interval={10000}>
      {children}
    </Component>
  );
}

CollapseToCarousel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

export default CollapseToCarousel;
