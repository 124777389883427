import React, {useEffect, useState} from 'react';

import {useConditionalEffect} from '@react-hookz/web';
import {useParams} from 'react-router-dom';
import {useRecoilValue} from 'recoil';

import atoms, {STRINGS} from '../../atoms';
import {CarouselRow} from '../../components';
import {logger} from '../../services/Logger';
import LookSeeServices from '../../services/LookSeeServices';
import {dataLayerVariables, sendPageAnalytics} from '../../utils/Analytics';
import {Container, Wrapper} from './styles';

const getSubcategory = async subcategoryId => {
  try {
    return await LookSeeServices.getSubcategory(subcategoryId);
  } catch (error) {
    logger.error(error);
  }
};

function Products() {
  const {subcategoryCode, categoryCode} = useParams();
  const decodedCategoryCode = decodeURIComponent(categoryCode);
  const decodedSubcategoryCode = decodeURIComponent(subcategoryCode);
  const categories = useRecoilValue(atoms.marketplaceCategories);
  const [productItems, setProductItems] = useState([]);
  const [subCategory, setSubCategory] = useState(null);
  const category = categories.find(
    item => item.categoryCode === decodedCategoryCode,
  );
  const subcategory = category?.subcategories.find(
    item => item.categoryCode === decodedSubcategoryCode,
  );

  useEffect(() => {
    const fetchSubcategory = async () => {
      if (subcategory) {
        const fetchedSubCategory = await getSubcategory(subcategory.categoryId);
        setSubCategory(fetchedSubCategory);
      }
    };

    fetchSubcategory();
  }, [subcategory]);

  useConditionalEffect(
    () => {
      setProductItems([...subCategory.items]);
      sendPageAnalytics(
        dataLayerVariables({
          pageCategory: 'Products and services',
          pageName: `Products and services:${
            category.name
          }:${subCategory.name.toLowerCase()}`,
          pageSubSection1: 'Products and services',
          pageSubSection2: `Products and services:${category.name}`,
          pageSubSection3: `Products and services:${
            category.name
          }:${subCategory.name.toLowerCase()}`,
        }),
      );
    },
    [category, subCategory],
    [subCategory?.items],
  );

  return subCategory ? (
    <Container>
      <CarouselRow
        title={subCategory.name}
        description={subCategory.description}
        data={productItems}
        showGrid
        productRow
      />
    </Container>
  ) : (
    <Wrapper>{STRINGS.LOADING}</Wrapper>
  );
}

export default Products;
