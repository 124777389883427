import React from 'react';

import PropTypes from 'prop-types';
import {useTheme} from 'styled-components';

import {Button} from '..';
import {STRINGS} from '../../atoms';
import {
  AbandonWrapper,
  AbandonTitle,
  AbandonDescription,
  Buttons,
  ButtonWrapper,
} from './styles';

const Abandon = function ({
  onDiscard,
  onKeep,
  title,
  description,
  onDiscardTitle,
  onKeepTitle,
  pageSection,
}) {
  const theme = useTheme();

  return (
    <AbandonWrapper>
      <AbandonTitle>{title}</AbandonTitle>
      <AbandonDescription>{description}</AbandonDescription>
      <Buttons>
        <ButtonWrapper
          data-intent="confirmational"
          data-scope={pageSection}
          data-id="link_content"
          data-text={`${title} | ${description} | ${onKeepTitle} button click`}>
          <Button
            onClick={() => onKeep(false)}
            text={onKeepTitle}
            backgroundColor={theme.colors.white}
            textColor={theme.colors.primary}
            styles={{
              fontSize: 16,
              border: `solid 1px ${theme.colors.primary}`,
              borderRadius: 4,
              margin: '4px 10px',
              width: '100%',
              fontWeight: 500,
            }}
            hoverColor="none"
            activeColor="none"
          />
        </ButtonWrapper>
        <ButtonWrapper
          data-intent="confirmational"
          data-scope={pageSection}
          data-id="link_content"
          data-text={`${title} | ${description} | ${onDiscardTitle} button click`}>
          <Button
            onClick={onDiscard}
            text={onDiscardTitle}
            backgroundColor={theme.colors.white}
            textColor={theme.colors.error}
            styles={{
              fontSize: 16,
              border: `solid 1px ${theme.colors.error}`,
              borderRadius: 4,
              margin: '4px 10px',
              width: '100%',
            }}
            hoverColor="none"
            activeColor="none"
          />
        </ButtonWrapper>
      </Buttons>
    </AbandonWrapper>
  );
};

Abandon.defaultProps = {
  onDiscard: () => null,
  onKeep: () => null,
  title: STRINGS.ARE_YOU_SURE_ABANDON,
  description: STRINGS.ANY_TEXT_OR_ATTACHMENTS,
  onDiscardTitle: STRINGS.DISCARD_POST,
  onKeepTitle: STRINGS.KEEP_EDITING,
};

Abandon.propTypes = {
  onDiscard: PropTypes.func,
  onKeep: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  onDiscardTitle: PropTypes.string,
  onKeepTitle: PropTypes.string,
  pageSection: PropTypes.string,
};

export default Abandon;
