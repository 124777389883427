import styled from 'styled-components';

export const Headline = styled.h4`
  color: ${({theme}) => theme.colors.white};
  font-weight: 600;
  font-size: ${({theme}) => theme.spacing.normal};
  margin-top: 0;
  margin-bottom: ${({theme}) => theme.spacing.small};
`;

export const LinkText = styled.p`
  color: ${({theme}) => theme.colors.white};
  font-weight: 400;
  font-size: 15px;
  line-height: 14px;
  margin-top: 0;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    opacity: 0.8;
  }
`;

export const TermsText = styled.p`
  color: ${({theme}) => theme.colors.white};
  font-weight: 400;
  font-size: 15px;
  line-height: ${({theme}) => theme.spacing.large};
  margin-top: 0;
  display: block;
`;

export const StyledIcon = styled.img`
  margin-left: ${({theme}) => theme.spacing.small};
`;

export const FooterWrap = styled.div`
  padding: 15px 15px;
  background-color: ${({theme}) => theme.colors.tertiary};
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 3fr;
  grid-template-areas: 'legal help terms icons';
  grid-gap: 20px;

  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      'legal help icons'
      'terms terms terms';
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-template-areas: 'legal' 'help' 'terms' 'icons';
    align-items: center;
    text-align: center;
  }
`;

export const Legal = styled.div`
  grid-area: legal;
`;
export const Help = styled.div`
  grid-area: help;
`;

export const Icons = styled.div`
  grid-area: icons;
  display: flex;
  align-self: flex-start;
  justify-content: flex-end;
  @media (max-width: 500px) {
    justify-content: center;
  }
`;
export const Terms = styled.div`
  grid-area: terms;
`;
