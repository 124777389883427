import React from 'react';

import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import {useTheme} from 'styled-components';

import atoms, {STRINGS} from '../../atoms';
import {blogsUrl, productsServicesUrl} from '../../navigation/routes';
import {useFeaturesContext} from '../../provider/features';
import {Icon, IconName, IconWrapper, Divider} from './styles';

function MobileMainMenu({setSelectedMenu}) {
  const theme = useTheme();
  const history = useHistory();
  const features = useFeaturesContext();
  const user = useRecoilValue(atoms.user);
  const loggedIn = !!user;

  const featureItems = [
    features.propertyHub && {
      icon: theme.images.homeIconSelected,
      iconName: 'Products and services',
      route: 'property',
      handleClick: () => {
        history.push(productsServicesUrl);
      },
    },
    {
      icon: theme.images.knowledgeHub,
      iconName: 'Knowledge Hub',
      handleClick: () => {
        window.location.href = blogsUrl;
      },
    },
  ].filter(i => i);

  const onClickAccountOption = () => {
    setSelectedMenu('accountMenu');
  };

  const onMarketPlaceClickOption = () => {
    setSelectedMenu('marketPlaceMenu');
  };

  return (
    <div>
      {featureItems.map(({icon, iconName, handleClick}) => (
        <IconWrapper onClick={handleClick} key={iconName}>
          <Icon src={icon} alt={iconName} />
          <IconName>{iconName}</IconName>
        </IconWrapper>
      ))}
      <Divider />
      {loggedIn && (
        <IconWrapper onClick={onMarketPlaceClickOption}>
          <Icon
            src={theme.images.shoppingIconCartMobile}
            alt="Products and Services Account"
          />
          <IconName>Products and Services Account </IconName>
        </IconWrapper>
      )}
      <IconWrapper onClick={onClickAccountOption}>
        <Icon
          src={theme.images.mobileNavBarAccountIcon}
          alt={STRINGS.PROFILE}
        />
        <IconName>{STRINGS.PROFILE}</IconName>
      </IconWrapper>
    </div>
  );
}

MobileMainMenu.propTypes = {
  setSelectedMenu: PropTypes.func,
};

export default MobileMainMenu;
