import React from 'react';

import {Route, Switch, useRouteMatch} from 'react-router-dom';

import SolarScoreAnalysisPage from './SolarScoreAnalysisPage';
import SolarScoreLandingPage from './SolarScoreLandingPage';

export default function SolarScore() {
  const {path} = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:propertyKey`}>
        <SolarScoreAnalysisPage />
      </Route>
      <Route path={path}>
        <SolarScoreLandingPage />
      </Route>
    </Switch>
  );
}
