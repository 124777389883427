import React from 'react';

import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {useSetRecoilState, useRecoilState} from 'recoil';
import {useTheme} from 'styled-components';

import atoms, {STRINGS} from '../../atoms';
import {useFeaturesContext} from '../../provider/features';
import PingIdentityService from '../../services/PingIdentityServices';
import ManageAccount from '../ManageAccount';
import MenuBadge from './MenuBadge';

const AccountsMenu = ({onReset}) => {
  const history = useHistory();
  const theme = useTheme();
  const features = useFeaturesContext();
  const [user, setUser] = useRecoilState(atoms.user);
  const setOnboard = useSetRecoilState(atoms.onboard);
  const setSliderModal = useSetRecoilState(atoms.sliderModal);
  const setLogout = useSetRecoilState(atoms.logout);
  const setLoginOrSignup = useSetRecoilState(atoms.loginOrSignup);

  const loggedIn = !!user;

  const accountLinks = [
    features.manageAccount && {
      icon: theme.images.mobileNavBarAccountIcon,
      alt: STRINGS.PROFILE,
      hide: !loggedIn,
      header: STRINGS.PROFILE,
      notifications: null,
      listItems: [
        !loggedIn && {
          icon: theme.images.managePropertiesIcon,
          title: STRINGS.CREATE_ACCOUNT,
          handleClick: () => {
            setLoginOrSignup(STRINGS.REGISTER);
          },
        },
        !loggedIn && {
          icon: theme.images.mobileNavBarAccountIcon,
          title: STRINGS.SIGN_IN,
          handleClick: () => {
            setLoginOrSignup(STRINGS.LOGIN);
          },
        },
        !!loggedIn && {
          icon: theme.images.mobileNavBarAccountIcon,
          title: STRINGS.MANAGE_ACCOUNT,
          handleClick: () => {
            setSliderModal(<ManageAccount mobile />);
          },
        },
        {
          icon: theme.images.settingsTermsAndConditionsIcon,
          title: STRINGS.TERMS_AND_CONDITIONS,
          handleClick: () => {
            history.push('/terms-and-conditions');
          },
        },
        {
          icon: theme.images.settingsFeedbackIcon,
          title: STRINGS.FEEDBACK,
          handleClick: () => {
            history.push('/contact-us');
          },
        },
        !!loggedIn && {
          icon: theme.images.logOutIcon,
          title: STRINGS.SIGN_OUT,
          handleClick: async () => {
            setLogout(true);
            await PingIdentityService.logout();
            setOnboard(0);
            setUser(null);
          },
        },
      ].filter(x => x),
    },
  ].filter(x => x);
  return <MenuBadge menuLinks={accountLinks} onReset={onReset} />;
};

AccountsMenu.propTypes = {
  onReset: PropTypes.func,
};

export default AccountsMenu;
