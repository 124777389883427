import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  background: white;
  top: 60px;
  left: 0;
  box-sizing: border-box;
  height: 100%;

  width: 85%;
  transform: ${({open}) => (open ? 'translateX(0%)' : 'translateX(-102%)')};
  transition: transform 0.3s ease-in-out;
  background: ${({theme}) => theme.colors.white};
`;

export const InnerWrapper = styled.div`
  padding: ${({theme}) =>
    `${theme.spacing.xSmall} ${theme.spacing.small} 0 ${theme.spacing.small}`};
`;

export const Row = styled.div`
  width: 100%;
  height: auto;
  flex-direction: 'row';
  ${({grow}) => (grow ? 'flex-grow: 3;' : '')};
`;

export const SignupBanner = styled.div`
  padding: ${({theme}) => `0 ${theme.spacing.small} 0 ${theme.spacing.small}`};
  display: flex;
  height: 50px;
  background: ${({theme}) => theme.colors.primary};
`;

export const BannerText = styled.a`
  align-self: center;
  font: ${({theme}) => theme.typography.description};
  color: ${({theme}) => theme.colors.white};
`;

export const Icon = styled.img`
  justify-self: center;
  display: grid;
  place-content: center;
  ${({theme, normal}) => (normal ? `width: ${theme.spacing.normal}` : '')};
  ${({theme, small}) => (small ? `width: ${theme.spacing.small}` : '')};
`;
