import React from 'react';

import Lottie from 'react-lottie';
import {useRecoilValue} from 'recoil';

import atoms from '../../atoms';
import searchIconLottie from '../../images/search-icon-lottie.json';
import {Overlay, Box, Wrapper} from './styles';

const LoadingModal = () => {
  const loadingModal = useRecoilValue(atoms.loadingModal);

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: searchIconLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return loadingModal ? (
    <Overlay>
      <Box>
        <Wrapper>
          <Lottie options={lottieOptions} width={80} height={80} />
        </Wrapper>
      </Box>
    </Overlay>
  ) : null;
};

export default LoadingModal;
