import styled from 'styled-components';

import {Body} from '../typography';

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 48px;
  width: 300px;
  cursor: pointer;
  padding: 0px ${({theme}) => theme.spacing.medium};

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

export const ListText = styled(Body)`
  margin: 0 ${({theme}) => theme.spacing.medium};
  text-transform: capitalize;
  font-weight: 500;
`;
