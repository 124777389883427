import {phone} from 'phone';
import * as Yup from 'yup';

export const phoneValidator = Yup.string()
  .required('Required')
  .test('is-valid', 'Invalid cell number', value => validate(value));

const validate = cell => {
  const {isValid} = phone(cell, {country: 'ZAF'});
  return isValid;
};
