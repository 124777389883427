import React from 'react';

import {Box, CardContent, Grid} from '@material-ui/core';
import {useTheme} from 'styled-components';

import {
  BodyLg,
  BodyMed,
  BodyReg,
  calcPreferredValue,
} from '../../components/newTypography';
import useDetectMobile from '../../hooks/useDetectMobile';
import {BannerImage, RoundedCard} from './styles';

export default function StepsSection() {
  const theme = useTheme();
  const isMobile = useDetectMobile();
  const steps = [
    {
      number: (
        <img src={theme.images.oneEllipse} alt="one circled with ellipses" />
      ),
      title: 'Solar Quote',
      description:
        'Get a solar system quotation from LookSee or your own installer.',
    },
    {
      number: (
        <img src={theme.images.twoEllipse} alt="two circled with ellipses" />
      ),
      title: 'Solar Loan',
      description:
        'Let the LookSee Solar Customer Care team help you with your application or apply online or at your nearest branch.',
    },
    {
      number: (
        <img
          src={theme.images.threeEllipse}
          alt="three circled with ellipses"
        />
      ),
      title: 'Pay your installer',
      description:
        'Your loan will be paid into your account so you can pay your solar deposit and schedule your installation.',
    },
  ];

  return (
    <BannerImage imageUrl={theme.images.StepsBanner} py="2%">
      <Box
        component={RoundedCard}
        bgcolor={theme.colors.whiteTransparent}
        width="40%"
        ml="5%"
        my="5%"
        p="2%"
        height="20%"
        sx={{textAlign: {xs: 'center', sm: 'start'}}}
        minWidth={isMobile && '85%'}>
        <CardContent>
          <BodyLg
            color={theme.colors.tertiary}
            fontWeight="700"
            lineHeight={0}
            inline
            fontSize={`clamp(1.25rem, ${calcPreferredValue(40, 20)}, 2.5rem)`}>
            How it works
          </BodyLg>
          <BodyMed lineHeight={0} color={theme.colors.blendedBlue}>
            In 3 simple steps.
          </BodyMed>
          <Box component={Grid} container spacing={1}>
            {steps.map(({number, title, description}) => (
              <Grid item xs={12} sm={4} key={title}>
                {number}
                <BodyMed
                  color={theme.colors.tertiary}
                  fontWeight={700}
                  lineHeight={0}>
                  {title}
                </BodyMed>
                <BodyReg color={theme.colors.tertiary}>{description}</BodyReg>
              </Grid>
            ))}
          </Box>
        </CardContent>
      </Box>
    </BannerImage>
  );
}
