import React from 'react';

import {Box, Dialog, DialogContent} from '@material-ui/core';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {useTheme} from 'styled-components';

import {RoundedButton} from '../../components/Button';
import {BodyMed, Header4, Header5} from '../../components/newTypography';
import {solarUrl} from '../../navigation/routes';

function ErrorModal({open, onClose, hasAddress = false}) {
  const theme = useTheme();
  const history = useHistory();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Box textAlign="center">
          <img
            src={theme.images.wrongLocation}
            alt="solar score unavailable"
            height="60px"
            width="60px"
          />
        </Box>

        <Header4 style={{textAlign: 'center'}} color={theme.colors.tertiary}>
          Solar Score not available right now
        </Header4>
        {hasAddress && (
          <BodyMed color={theme.colors.grayOne}>
            Unfortunately a solar score is unavailable for this address right
            now. This could be because the address is linked to a sectional
            title, our data partners have not yet added this property to their
            database, or the metropolitan is not yet covered.
          </BodyMed>
        )}
        <BodyMed color={theme.colors.grayOne}>
          Even though we cannot give you a solar score at this time, please take
          a
          <Header5 color={theme.colors.grayOne} style={{display: 'inline'}}>
            {' '}
            free Home Power Analysis{' '}
          </Header5>
          to understand the alternate power solutions available to you.
        </BodyMed>
        <BodyMed color={theme.colors.grayOne}>
          Would you like to get your free home power analysis now?
        </BodyMed>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gridGap="10%"
          my="5%"
          sx={{
            flexDirection: {xs: 'column', sm: 'row'},
          }}>
          <Box>
            <RoundedButton
              text="Yes, I am interested"
              backgroundColor={theme.colors.tertiary}
              data-intent="navigational"
              data-scope="modal"
              data-id="link_content"
              data-text="solar score not available right now | yes, I am interested button click"
              onClick={() => history.push(solarUrl)}
            />
          </Box>
          <Box sx={{mt: {xs: '10%', sm: '0%'}}}>
            <RoundedButton
              text="Later"
              textColor={theme.colors.tertiary}
              backgroundColor={theme.colors.white}
              borderColor={theme.colors.tertiary}
              data-intent="navigational"
              data-scope="modal"
              data-id="link_content"
              data-text="solar score not available right now | later button click"
              onClick={onClose}
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

ErrorModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  hasAddress: PropTypes.bool,
};

export default ErrorModal;
