import React from 'react';

import {Box, Container, Grid} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {useTheme} from 'styled-components';

import {RoundedButton} from '../../components/Button';
import {BodyLg, BodySm} from '../../components/newTypography';
import useDetectMobile from '../../hooks/useDetectMobile';
import {solarUrl} from '../../navigation/routes';
import {CenterBox} from './styles';

export default function BenefitsSection() {
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useDetectMobile();
  const options = [
    {
      image: <img src={theme.images.provider} alt="provider illustration" />,
      title: 'Vetted Providers',
      description:
        'Get peace of mind knowing our Solar providers have been vetted by Standard Bank for their quality and reliable services.',
    },
    {
      image: <img src={theme.images.card} alt="bank card illustration" />,
      title: 'Financing Freedom',
      description:
        'We’ll guide you through your financing options and help you choose the best option for your needs and budget.',
    },
    {
      image: <img src={theme.images.concierge} alt="support illustration" />,
      title: 'Solar Support Team',
      description:
        'Our dedicated Solar Support Team will answer any questions you may have and walk you through your journey to Solar.',
    },
  ];

  return (
    <Box component={Container} my="4%">
      <Box component={Grid} container spacing={2} px="5%">
        {options.map(({image, title, description}) => (
          <CenterBox
            component={Grid}
            item
            xs={12}
            sm={4}
            key={title}
            textAlign="center">
            {image}
            <BodyLg
              fontWeight={700}
              lineHeight={0}
              color={theme.colors.tertiary}
              inline>
              {title}
            </BodyLg>
            <BodySm color={theme.colors.tertiary}>{description}</BodySm>
          </CenterBox>
        ))}
        <CenterBox component={Grid} item xs={12}>
          <Box mt={2} width={isMobile ? '100%' : '30%'}>
            <RoundedButton
              text="Get a financed solar system"
              textColor={theme.colors.tertiary}
              backgroundColor={theme.colors.secondary}
              onClick={() => history.push(`${solarUrl}/apply`)}
              width="100%"
            />
          </Box>
        </CenterBox>
      </Box>
    </Box>
  );
}
