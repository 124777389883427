import React from 'react';

import {Switch, useRouteMatch, Route} from 'react-router-dom';

import SolarApplication from '../SolarApplication';
import DefaultSolarScreen from './DefaultSolarScreen';

export default function Solar() {
  const {path} = useRouteMatch();

  return (
    <Switch>
      <Route path={path} exact>
        <DefaultSolarScreen />
      </Route>
      <Route path={`${path}/apply`}>
        <SolarApplication />
      </Route>
    </Switch>
  );
}
