import styled from 'styled-components';

export const ModalContainer = styled.div`
  display: flex;
  align-self: center;
  ${({centered}) => (centered ? 'flex-direction: column;' : null)};
`;

export const BodyContainer = styled.div`
  padding: 15px;
  @media ${props => props.theme.device.mobileL} {
    padding: 0;
  }
`;

export const ModalWrapper = styled.div(
  ({theme}) => `
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.small};
  width: 100%;

  @media ${theme.device.laptop} {
    padding: ${theme.spacing.xLarge} ${theme.spacing.xGiant};
  }
`,
);

export const CenterText = styled.p`
  color: ${({theme}) => theme.colors.black};
  padding: 0;
  margin: 0;
  text-align: center;

  @media ${({theme}) => theme.device.laptop} {
    padding: ${({theme, padding}) =>
      padding ? padding : theme.spacing.xSmall};
    margin: ${({theme}) => theme.spacing.xSmall};
  }
`;
