import React from 'react';

import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports
import styled, {useTheme} from 'styled-components';

import {Wrapper} from './styles';

const Button = ({
  width,
  styles, // Deprecated, Buttons should be styled with styled-components.
  text,
  hoverColor,
  hoverTextColor,
  backgroundColor,
  textColor,
  activeColor,
  icon = null,
  disabled,
  disabledColor,
  disabledBGColor,
  borderColor,
  borderWidth,
  endIcon = false,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Wrapper
      width={width}
      text={text}
      type="button"
      styles={styles}
      hoverColor={hoverColor}
      hoverTextColor={hoverTextColor}
      backgroundColor={backgroundColor || theme.colors.primary}
      textColor={textColor}
      activeColor={activeColor}
      disabled={disabled}
      disabledColor={disabledColor}
      disabledBGColor={disabledBGColor}
      borderColor={borderColor}
      borderWidth={borderWidth}
      endIcon={endIcon}
      {...props}>
      {endIcon || icon}
      {text}
      {endIcon && icon}
    </Wrapper>
  );
};

Button.propTypes = {
  styles: PropTypes.object,
  text: PropTypes.string,
  hoverColor: PropTypes.string,
  hoverTextColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  activeColor: PropTypes.string,
  borderColor: PropTypes.string,
};

const RoundedButton = styled(Button)`
  border-radius: 30px;
  padding: 15px 55px;
`;

export default Button;
export {RoundedButton};
