import moment from 'moment-timezone';

export const capitalizeString = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeFirstLetter = str => {
  return str
    .split(' ')
    .map(e => e[0].toUpperCase() + e.substring(1, e.length))
    .join(' ');
};

export const checkCurrency = currency => {
  const lowerCaseIt = currency.toLowerCase();
  switch (lowerCaseIt) {
    case 'eur':
      return '€';
    case 'rand':
    case 'zar':
    default:
      return 'R';
  }
};

export const formatPrice = price => {
  const {amount, currency} = price;

  return `${checkCurrency(currency)} ${amount}`;
};

export const setLocalZone = (date, timezone) => {
  const dateWithoutZone = moment
    .tz(date, timezone)
    .format('YYYY-MM-DDTHH:mm:ss.SSS');
  const localZone = moment(dateWithoutZone).format('Z');
  const dateWithLocalZone = [dateWithoutZone, localZone].join('');

  return new Date(dateWithLocalZone);
};

export const getImage = data => {
  const {attributes} = data;
  return attributes?.logoUrl;
};
