import React from 'react';

import {bannerMap} from './config';
import {
  ACTION_HEADING,
  PENDING_CLIENT_COMPLETION_CONFIRMATION,
  PENDING_PROVIDER_CANCELLATION_CONFIRMATION,
} from './constants';
import {Wrapper, ActionRequired} from './styles';

function OrderBanner({state}) {
  if (
    state === PENDING_PROVIDER_CANCELLATION_CONFIRMATION ||
    state === PENDING_CLIENT_COMPLETION_CONFIRMATION
  ) {
    const backgroundColor = bannerMap[state].background;
    const message = bannerMap[state].message;
    return (
      <Wrapper background={backgroundColor}>
        <div>{ACTION_HEADING}</div>
        <ActionRequired>{message}</ActionRequired>
      </Wrapper>
    );
  }

  return null;
}

export default OrderBanner;
