import React from 'react';

import PropTypes from 'prop-types';
import {useTheme} from 'styled-components';

import {STRINGS} from '../../../atoms';
import ListItemBadge from '../../ListItemBadge';
import {Divider} from '../styles';
import {MenuTitle, PaddingItem} from './styles';

const MenuBadge = ({menuLinks, onReset}) => {
  const theme = useTheme();
  return (
    <>
      {menuLinks.map(item => (
        <div key={item.header}>
          <MenuTitle>{item.header}</MenuTitle>
          {item.listItems?.map(i => (
            <ListItemBadge
              key={i.title}
              notificationTotal={i.notificationTotal}
              title={i.title}
              handleClick={i.handleClick}
              icon={i.icon}
              data-intent="navigational"
              data-scope="header"
              data-id="link_content"
              data-text={`${item.header.toLowerCase()} | ${i.title.toLowerCase()} link click`}
            />
          ))}
          <Divider />
          <PaddingItem>
            <ListItemBadge
              title={STRINGS.BACK}
              handleClick={() => {
                onReset();
              }}
              icon={theme.images.blackChevronLeft}
              data-intent="navigational"
              data-scope="header"
              data-id="link_content"
              data-text={`${STRINGS.PROFILE.toLowerCase()} | ${STRINGS.BACK.toLowerCase()} link click`}
            />
          </PaddingItem>
        </div>
      ))}
    </>
  );
};

MenuBadge.propTypes = {
  menuLinks: PropTypes.array,
  onReset: PropTypes.func,
};

export default MenuBadge;
