import {Tab, Tabs} from '@material-ui/core';
import styled from 'styled-components';

export const StyledTabs = styled(Tabs)`
  &.MuiTabs-root {
    height: 60px;
    align-items: center;
    width: 100%;
  }

  .MuiTabs-scrollButtons {
    color: ${({theme}) => theme.colors.white};
    opacity: 1;
  }

  .MuiTabs-indicator {
    background-color: ${({theme}) => theme.colors.secondary};
  }
`;

export const StyledTab = styled(Tab)`
  &.MuiTab-root {
    text-transform: none;
    opacity: 1;
    min-width: fit-content;
    padding: 0 25px;
  }
`;

export const MarketPlaceMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({theme}) => theme.colors.blueMobileNav};
`;
