import React, {useEffect} from 'react';

import {Box} from '@material-ui/core';
import {Form, useFormikContext} from 'formik';
import {useTheme} from 'styled-components';

import {STRINGS} from '../../atoms/app/constants';
import {RoundedButton} from '../Button';

export default function FormSkeleton({
  children,
  onBackClick,
  formName,
  submitButtonText = STRINGS.NEXT,
  finalStep = false,
  buttonPartialDataText,
}) {
  const theme = useTheme();
  const {
    isValid,
    isSubmitting,
    dirty,
    setTouched,
    validateForm,
    validationSchema,
  } = useFormikContext();

  useEffect(() => {
    // This just exists so that the Next button works correctly with its disabled state
    setTouched({});
    validateForm();
  }, [validationSchema]);

  return (
    <Form data-frmcontainer={formName} id={formName} name={formName}>
      {children}
      <Box
        display="flex"
        sx={{flexDirection: {xs: 'column', sm: 'row'}}}
        gridGap="15px"
        my="30px">
        <Box sx={{order: {xs: 2, sm: 1}}}>
          <RoundedButton
            data-intent="navigational"
            data-scope="button"
            data-text={`${buttonPartialDataText} | ${STRINGS.BACK} button click`}
            data-id="link_content"
            backgroundColor={theme.colors.white}
            borderColor={theme.colors.blueMobileNav}
            text={STRINGS.BACK}
            textColor={theme.colors.blueMobileNav}
            onClick={onBackClick}
            tabIndex={-1}
            width="100%"
          />
        </Box>
        <Box sx={{order: {xs: 1, sm: 2}}}>
          <RoundedButton
            data-intent={finalStep ? 'confirmational' : 'navigational'}
            data-scope="button"
            data-text={`${buttonPartialDataText} | ${submitButtonText} button click`}
            data-id="link_content"
            backgroundColor={theme.colors.blueMobileNav}
            text={submitButtonText}
            textColor={theme.colors.white}
            type="submit"
            disabled={!isValid || !dirty || isSubmitting}
            disabledColor={theme.colors.white}
            disabledBGColor={theme.colors.grayFour}
            width="100%"
          />
        </Box>
      </Box>
    </Form>
  );
}
