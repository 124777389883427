import React from 'react';

import moment from 'moment';
import {useSetRecoilState} from 'recoil';
import {useTheme} from 'styled-components';

import atoms, {STRINGS} from '../../atoms';
import {getRfQ} from '../../utils/marketplace';
import {
  PENDING_AMOUNT,
  AWAITING_QUOTE_PRICE,
  PENDING,
  PROVIDER,
  DETAILS,
} from './constants';
import {InfoTitle, InfoTextBold, InfoText, LightText} from './moreinfo.styles';
import {
  Wrapper,
  MobileInfoWrapper,
  MobileBody,
  MobileHeader,
  MobileBackButton,
  MobileHeadWrapper,
} from './quotedetailmobile.styles';
import {
  HeaderWrap,
  Heading,
  FlexColumn,
  TextBold,
  Text,
  OrderInfo,
  HeaderTotal,
  OrderNumber,
  ItemName,
  MobileItemInfo,
  MobileItemDetails,
  MobileImageWrap,
  ProductImage,
} from './styles';

const QuoteDetailMobile = ({quote, getPriceWithCurrency, serviceName}) => {
  const setSliderModal = useSetRecoilState(atoms.sliderModal);
  const theme = useTheme();

  return (
    <Wrapper>
      <MobileHeader>
        <MobileBackButton
          src={theme.images.chevronLeft}
          onClick={() => setSliderModal(null)}
        />
        {STRINGS.QUOTE_DETAILS}
      </MobileHeader>
      <div>
        <HeaderWrap>
          <Heading>{quote.stateDescription}</Heading>
          <OrderInfo>
            <HeaderTotal>
              Total:{' '}
              {quote.quoteData?.price?.amount
                ? getPriceWithCurrency(quote)
                : AWAITING_QUOTE_PRICE}
            </HeaderTotal>
            <FlexColumn>
              <TextBold>
                Order placed:
                <Text>{moment(quote.createdAt).format('LL')}</Text>
              </TextBold>
            </FlexColumn>
            <OrderNumber>
              Quote order no:<Text>{quote.quoteId || PENDING}</Text>
            </OrderNumber>
          </OrderInfo>
        </HeaderWrap>
        <MobileBody>
          <MobileHeadWrapper>
            <MobileImageWrap>
              <ProductImage src={theme.images.PropertyInspection} />
            </MobileImageWrap>
            <FlexColumn>
              <ItemName>{getRfQ(serviceName).name}</ItemName>
              <MobileItemInfo>
                <MobileItemDetails>
                  {quote.quoteData?.price?.amount || PENDING_AMOUNT}
                </MobileItemDetails>
              </MobileItemInfo>
            </FlexColumn>
          </MobileHeadWrapper>
          <MobileInfoWrapper>
            <InfoTitle>
              <InfoTextBold>{PROVIDER}</InfoTextBold>
              <InfoText>{quote.rfqItems[0].providerName}</InfoText>
            </InfoTitle>
          </MobileInfoWrapper>
          <MobileInfoWrapper>
            <InfoTitle>
              <InfoTextBold>{DETAILS}</InfoTextBold>
            </InfoTitle>
            <LightText>Estimated time - 1 hour</LightText>
            <LightText>{`${quote.rfqData.numberOfBedrooms} bedroom property`}</LightText>
            <LightText>
              {quote.rfqData.buyOrOwn === 'own'
                ? 'Property privately owned'
                : 'Buying property'}
            </LightText>
            <LightText>{`Municipality plan ${
              quote.rfqData.municipalityPlan ? 'included' : 'excluded'
            }`}</LightText>
          </MobileInfoWrapper>
        </MobileBody>
      </div>
    </Wrapper>
  );
};

export default QuoteDetailMobile;
