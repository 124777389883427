import styled from 'styled-components';

export const ToolTipHead = styled.h1(
  ({theme}) => `
  margin-top: 0px;
  margin-bottom: ${theme.spacing.xSmall};
  font-size: 12px;
  font-weight: 600;
`,
);

export const ToolTipBody = styled.div(
  ({theme}) => `
  font: ${theme.typography.description};
`,
);

export const InfoIcon = styled.img`
  display: block;
`;

export const InfoIconWrapper = styled.div(
  ({theme}) => `
  display: inline-block;
  margin: 0 ${theme.spacing.tiny};
`,
);
