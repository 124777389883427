import React from 'react';

import {Box, Divider, Grid} from '@material-ui/core';
import {useToggle} from '@react-hookz/web';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import {useTheme} from 'styled-components';

import {STRINGS} from '../../atoms';
import {capitalizeString} from '../../utils/formatting';
import {RoundedButton} from '../Button';
import Currency from '../Currency';
import MarketplaceContactModal from '../MarketplaceContactModal';
import {Title2} from '../typography';
import {
  addMarginBottom,
  productTitle,
  PriceContainer,
  containerStyle,
  dividerStyling,
  rightSpacing,
} from './styles';
function ProductDetailsHeader({productInformation}) {
  const theme = useTheme();
  const [userJourneyModalVisible, setUserJourneyModalVisible] =
    useToggle(false);
  const price = productInformation?.amount;
  const monthlyPrice = productInformation?.monthlyAmount;

  return (
    <>
      <div style={containerStyle}>
        <Title2>{capitalizeString(productInformation.name)}</Title2>
        <div style={addMarginBottom}>
          <PriceContainer>
            <Title2 style={rightSpacing}>
              {monthlyPrice ? (
                <div>
                  {price && (
                    <>
                      Cash Price: <Currency value={price} />
                      <br />
                    </>
                  )}
                  Monthly Price: <Currency value={monthlyPrice} />
                </div>
              ) : (
                <Currency value={price} />
              )}
            </Title2>
          </PriceContainer>
        </div>
        <Divider style={dividerStyling} />
        <Grid container>
          <Grid item xs={12} style={productTitle}>
            <Title2>{STRINGS.PRODUCT_OVERVIEW}</Title2>
          </Grid>
          <Grid item xs={12}>
            <ReactMarkdown>{productInformation.description}</ReactMarkdown>
          </Grid>
        </Grid>
        <Box mt={2}>
          <RoundedButton
            data-intent="transactional"
            data-scope="product details |"
            data-id="link_content"
            text="Call me back"
            width="100%"
            textColor={theme.colors.tertiary}
            backgroundColor={theme.colors.secondary}
            onClick={() => setUserJourneyModalVisible(true)}
          />
        </Box>
      </div>
      <MarketplaceContactModal
        visible={userJourneyModalVisible}
        handleClose={() => setUserJourneyModalVisible()}
        productInformation={productInformation}
        type="call"
      />
    </>
  );
}
ProductDetailsHeader.propTypes = {
  productDetail: PropTypes.shape({
    externalId: PropTypes.string,
    attributes: PropTypes.shape({
      name: PropTypes.string,
      amount: PropTypes.number,
      currency: PropTypes.string,
    }),
  }),
  serviceIncluded: PropTypes.object,
  clickSaveHeart: PropTypes.func,
  saved: PropTypes.bool,
  propertyInspection: PropTypes.bool,
  productInformation: PropTypes.object,
};
export default ProductDetailsHeader;
