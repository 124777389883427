import styled from 'styled-components';

import styleTheme from '../../Style';

export const CarouselContainer = styled.div`
  margin-bottom: 20px;
  justify-content: center;
`;

export const AccordianContainer = styled.div`
  margin: 4% 3%;

  @media ${props => props.theme.device.laptop} {
    margin-left: 15%;
    margin-right: 15%;
    margin-bottom: 20px;
  }
  border-bottom: ${({theme}) => `1px solid ${theme.colors.grayFour}`};
`;

export const ProductInfo = styled.div`
  justify-self: center;
  overflow-y: auto;
`;

export const PageStart = styled.div``;

export const BreadCrumbWrapper = styled.div`
  height: 60px;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  background: ${({theme}) => theme.colors.black100};

  @media ${props => props.theme.device.laptop} {
    background: ${({theme}) => theme.colors.white};
    padding: 0 24px;
  }
`;

export const gridStyle = {
  backgroundColor: styleTheme.colors.white,
  overflowX: 'hidden',
};

export const carouselRowStyle = {
  padding: '20px 15%',
  backgroundColor: styleTheme.colors.white,
};

export const CarouselImage = styled.img`
  max-height: 300px;
  max-width: 100%;
  min-width: 30vw;
  object-fit: contain;
  width: 100%;
`;

export const gridItem = {
  paddingLeft: '10%',
  paddingRight: '10%',
  marginBottom: '10px',
};
