import React from 'react';

import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {useTheme} from 'styled-components';

import {STRINGS} from '../../atoms';
import {productsServicesUrl} from '../../navigation/routes';
import {useFeaturesContext} from '../../provider/features';
import MenuBadge from './MenuBadge';

const MarketPlaceMenu = ({onReset}) => {
  const history = useHistory();
  const theme = useTheme();
  const features = useFeaturesContext();
  const marketPlaceLinks = [
    {
      icon: theme.images.shoppingIconCart,
      alt: `Products and Services Account`,
      header: `Products and Services Account`,
      listItems: [
        features.marketplace && {
          id: 'ordersNavItem',
          icon: theme.images.purchasesIcon,
          title: STRINGS.ORDERS,
          handleClick: () => {
            history.push(`${productsServicesUrl}/purchases`);
          },
        },
        features.marketplace && {
          id: 'quotesNavItem',
          icon: theme.images.quotesIcon,
          title: STRINGS.MY_QUOTES,
          handleClick: () => {
            history.push(`${productsServicesUrl}/quotes`);
          },
        },
      ].filter(x => x),
    },
  ].filter(x => x);
  return <MenuBadge menuLinks={marketPlaceLinks} onReset={onReset} />;
};

MarketPlaceMenu.propTypes = {
  onReset: PropTypes.func,
};

export default MarketPlaceMenu;
