import React from 'react';

import {Box, Dialog, DialogContent} from '@material-ui/core';
import PropTypes from 'prop-types';
import {useTheme} from 'styled-components';

import {Header3} from '../newTypography';
import {ProgressCircle} from './styles';

export default function CircularLoader({open}) {
  const theme = useTheme();
  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: 5,
          width: {xs: '70%', sm: '30%'},
          height: {xs: '70%', sm: '40%'},
        },
      }}
      open={open}
      aria-labelledby="loading-modal"
      aria-describedby="loading-modal">
      <Box
        component={DialogContent}
        textAlign="center"
        style={{overflow: 'hidden'}}>
        <Box pt={1}>
          <Header3 inline color={theme.colors.blueMobileNav}>
            Sending...
          </Header3>
        </Box>
        {open && <ProgressCircle disableShrink thickness={5} size={70} />}
      </Box>
    </Dialog>
  );
}

CircularLoader.propTypes = {
  open: PropTypes.bool.isRequired,
};
