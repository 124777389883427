export const getModifiers = productDetail => {
  const result = {};
  const modifiersFeature = productDetail?.attributes.productFeatures?.find(
    item => item.heading === 'Modifiers',
  );
  (modifiersFeature?.keyValuePairs || []).forEach(item => {
    result[item.key] = item.value;
  });
  return Object.freeze(result);
};

export const getRfQ = type => {
  const rfq = {
    homeCareServices: {
      pathName: '/marketplace/basket/checkout/home-care-service',
      name: 'Home care service',
      image: theme => theme.images.service,
      alt: 'home-care',
      stepFourHeading1: 'When would you like the service to be?',
      partialConsentFormText:
        'and return it via email or to the service provider in person.',
      formName: 'home_care_service_form',
    },
    propertyInspectionServices: {
      pathName: '/marketplace/basket/checkout/property-inspection',
      name: 'Property inspection service',
      image: theme => theme.images.PropertyInspection,
      alt: 'property-inspection',
      stepFourHeading1: 'When would you like your inspection to be?',
      partialConsentFormText:
        'and return it via email or to the property inspector in person.',
      formName: 'property_inspection_form',
    },
  };

  return type ? rfq[type] : rfq['propertyInspectionServices'];
};
