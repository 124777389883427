import React, {useCallback} from 'react';

import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {useRecoilValue} from 'recoil';
import {useTheme} from 'styled-components';

import atoms from '../../atoms';
import {productsServicesUrl} from '../../navigation/routes';
import Link from '../Link';
import {Header3} from '../newTypography';

const SubcategoryCard = ({data}) => {
  const category = data;
  const {name, imageUrl} = category;
  const categories = useRecoilValue(atoms.marketplaceCategories);
  const theme = useTheme();
  // TODO: Change/Delete the images
  const getImage = () => {
    if (imageUrl) {
      return imageUrl;
    }
    return theme.images.Service;
  };

  function findCategory(categoryId) {
    return categories.find(c => c.categoryId === categoryId);
  }
  const getSubcategoryUrl = useCallback(
    subcategory => {
      const parentCategory = findCategory(subcategory.parentCategoryId);
      const parentCategoryUrl = `${productsServicesUrl}/${encodeURIComponent(
        parentCategory?.categoryCode,
      )}`;

      return `${parentCategoryUrl}/${encodeURIComponent(
        subcategory.categoryCode,
      )}`;
    },
    [categories],
  );

  return (
    <Link
      to={getSubcategoryUrl(category)}
      data-intent="navigational"
      data-scope="link"
      data-id="link_content"
      data-text={`products and services | ${name}`}>
      <Box component={Card} maxWidth="245px" variant="outlined">
        <CardActionArea>
          <Box
            sx={{
              height: {xs: '145px', sm: '190px', md: '245px'},
            }}
            component={CardMedia}
            image={getImage()}
            alt={name}
          />
          <Box
            component={CardContent}
            height={100}
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center">
            <Header3 fontSize="16px">{name}</Header3>
          </Box>
        </CardActionArea>
      </Box>
    </Link>
  );
};

SubcategoryCard.propTypes = {
  data: PropTypes.shape({
    category: PropTypes.shape({
      name: PropTypes.string,
      imageUrl: PropTypes.string,
    }),
  }),
};

SubcategoryCard.propTypes = {
  data: PropTypes.shape({}),
};

export default SubcategoryCard;
