import React, {useState, useEffect, useMemo} from 'react';

import {Box, Grid} from '@material-ui/core';
import moment from 'moment';
import {useHistory} from 'react-router-dom';
import {useSetRecoilState, useRecoilValue} from 'recoil';
import {useTheme} from 'styled-components';

import atoms, {STRINGS} from '../../atoms';
import {productsServicesUrl} from '../../navigation/routes';
import {useMarketplaceContext} from '../../provider/marketplace';
import {formatPrice, getImage} from '../../utils/formatting';
import {getRfQ} from '../../utils/marketplace';
import useWidth from '../../utils/useWidth';
import Button from '../Button';
import Currency from '../Currency';
import InformationalModal from '../InformationalModal';
import OrderBanner from '../OrderBanner';
import MoreInfo from './MoreInfo';
import OrderDetailMobile from './OrderDetailMobile';
import OrderHeader from './OrderHeader';
import QuoteOrderBanner from './QuoteOrderBanner';
import {InfoTitle, InfoTextBold, InfoText} from './moreinfo.styles';
import {MobileHeader, MobileMainBackButton} from './orderdetailmobile.styles';
import {
  Wrapper,
  ProductImage,
  BorderSummaryItemWrap,
  Title,
  CardBody,
  ImageWrap,
  ItemName,
  FlexRow,
  FlexColumn,
  ItemDetails,
  QuantWrapper,
  DetailsWrapper,
  Attributes,
  ItemPrice,
  MobileImageWrap,
  MobileDelivDate,
  MobileItemInfo,
  MobileItemDetails,
  EmptyWrapper,
  EmptyText,
  TitleWrapper,
} from './styles';

const OrderSummary = () => {
  const width = useWidth();
  const history = useHistory();
  const theme = useTheme();
  const setSliderModal = useSetRecoilState(atoms.sliderModal);
  const messages = useRecoilValue(atoms.messages);
  const [loading, setLoading] = useState(true);
  const isTablet = useMemo(() => width >= theme.sizes.tabletWidth, [width]);
  const [creditCardModalValue, setCreditCardModalValue] = useState(undefined);

  const {
    services: {getOrders},
    selectors: {orders},
  } = useMarketplaceContext();

  useEffect(() => {
    const getData = async () => {
      await getOrders();
      setLoading(false);
    };

    getData();
  }, []);

  const getAppointment = slot =>
    slot['after']
      ? `After ${moment(slot['after']).utcOffset(2).format('DD/MM/YYYY HH:mm')}`
      : `Before ${moment(slot['before'])
          .utcOffset(2)
          .format('DD/MM/YYYY HH:mm')}`;

  const clickOrderMobile = order => {
    if (!isTablet) {
      setSliderModal(<OrderDetailMobile order={order} />);
    }
  };

  if (loading) {
    return <Wrapper>{STRINGS.LOADING}</Wrapper>;
  }

  if (!loading && !orders.length) {
    return (
      <EmptyWrapper>
        <div>
          <img src={theme.images.EmptyBasket} alt="empty orders" />
          <EmptyText>{STRINGS.YOU_HAVE_NOT_ORDERED}</EmptyText>
        </div>
      </EmptyWrapper>
    );
  }

  const renderQuoteOrder = order => {
    const familyCode = order.quote?.rfqItems[0].familyCode;
    const rfqAttributes = getRfQ(familyCode);

    const message = messages.find(
      ({referenceId, direction}) =>
        referenceId === order.orderId && direction === 'INBOUND',
    );

    return (
      <BorderSummaryItemWrap
        key={order.orderId}
        onClick={() => clickOrderMobile(order)}>
        <OrderHeader order={order} isTablet={isTablet} />

        <QuoteOrderBanner
          ribbon={!!message}
          state={order.state}
          getOrder={order}
        />

        <CardBody>
          <div>
            <DetailsWrapper item={order.quote?.rfqItems}>
              <ImageWrap>
                <ProductImage src={rfqAttributes.image(theme)} />
              </ImageWrap>
              <Attributes>
                <FlexRow>
                  <MobileImageWrap>
                    <ProductImage src={rfqAttributes.image(theme)} />
                  </MobileImageWrap>
                  <TitleWrapper>
                    <ItemName>{rfqAttributes.name}</ItemName>
                    {isTablet && (
                      <ItemPrice>
                        {formatPrice(order.quote.quoteData?.price)}
                      </ItemPrice>
                    )}
                    {!isTablet && (
                      <MobileItemInfo>
                        <MobileItemDetails>
                          {formatPrice(order.quote.quoteData?.price)}
                        </MobileItemDetails>
                      </MobileItemInfo>
                    )}
                  </TitleWrapper>
                </FlexRow>
                <div
                  data-intent="informational"
                  data-scope="My orders"
                  data-id="link_content"
                  data-text="Quote | Provider | More info link click">
                  <MoreInfo
                    border
                    type="quote-provider"
                    info={order.quote.rfqItems[0]}
                  />
                </div>
                <MoreInfo
                  border
                  type="dispatch"
                  info={
                    order.quote.rfqData.serviceAddress
                      ? order.quote.rfqData.serviceAddress
                      : order.quote.rfqData.address
                  }
                />
                {familyCode === 'homeCareServices' ? (
                  <div
                    data-intent="informational"
                    data-scope="My orders"
                    data-id="link_content"
                    data-text="Quote | Additional | More info link click">
                    <MoreInfo
                      type="additinal-notes"
                      additionalNotes={
                        order.quote.quoteData.additionalInformation
                      }
                    />
                  </div>
                ) : (
                  <div
                    data-intent="informational"
                    data-scope="My orders"
                    data-id="link_content"
                    data-text="Quote | Details | More info link click">
                    <MoreInfo type="details" info={order.quote.rfqData} />
                  </div>
                )}
              </Attributes>
            </DetailsWrapper>
          </div>
        </CardBody>
      </BorderSummaryItemWrap>
    );
  };

  const renderBasketOrder = order => (
    <BorderSummaryItemWrap
      key={order.orderId}
      onClick={() => clickOrderMobile(order)}>
      <OrderHeader order={order} isTablet={isTablet} />
      <OrderBanner state={order.state} />
      <CardBody>
        <div>
          {order.orderLineItems &&
            order.orderLineItems.map((item, index) => {
              const {deliveryDetails} = item;
              const {address} = deliveryDetails;
              return (
                <DetailsWrapper item={order.orderLineItems} key={index}>
                  <ImageWrap>
                    <ProductImage src={getImage(item.item)} />
                  </ImageWrap>
                  <Attributes>
                    <FlexRow>
                      <MobileImageWrap>
                        <ProductImage src={getImage(item.item)} />
                      </MobileImageWrap>
                      <FlexColumn>
                        <ItemName>{item.item.attributes?.name}</ItemName>
                        {isTablet && order.orderLineItems.length > 1 && (
                          <ItemPrice>
                            <Currency value={order.paymentAmount} />
                          </ItemPrice>
                        )}
                        {!isTablet && (
                          <MobileItemInfo>
                            <MobileItemDetails>
                              {order.stateDescription}
                            </MobileItemDetails>
                            {order.orderLineItems.length > 1 && (
                              <MobileItemDetails>
                                <Currency value={order.paymentAmount} />
                              </MobileItemDetails>
                            )}
                            <MobileDelivDate>
                              {getAppointment(item.deliveryDetails.slot)}
                            </MobileDelivDate>
                          </MobileItemInfo>
                        )}
                      </FlexColumn>
                    </FlexRow>
                    <ItemDetails>
                      {getAppointment(item.deliveryDetails.slot)}
                    </ItemDetails>
                    {item.item.attributes?.brand && (
                      <MoreInfo
                        border
                        type="provider"
                        info={item.item.attributes?.brand}
                      />
                    )}
                    <MoreInfo type="dispatch" info={address} />
                    <QuantWrapper>
                      <InfoTitle>
                        <InfoTextBold>{`${STRINGS.QUANTITY}:`}</InfoTextBold>
                        <InfoText>{item.quantity}</InfoText>
                      </InfoTitle>
                    </QuantWrapper>
                  </Attributes>
                </DetailsWrapper>
              );
            })}
        </div>
      </CardBody>
    </BorderSummaryItemWrap>
  );

  const renderSubscriptionOrder = order => (
    <BorderSummaryItemWrap
      key={order.orderId}
      onClick={() => clickOrderMobile(order)}>
      <OrderHeader order={order} isTablet={isTablet} />
      <OrderBanner state={order.state} />
      <CardBody>
        <DetailsWrapper item={order.orderLineItems}>
          <ImageWrap>
            <ProductImage src={getImage(order.subscription)} />
          </ImageWrap>
          <Attributes>
            <FlexRow>
              <MobileImageWrap>
                <ProductImage src={getImage(order.subscription)} />
              </MobileImageWrap>
              <TitleWrapper>
                <ItemName>{order.subscription.data.itemName}</ItemName>
                {!isTablet && (
                  <MobileItemInfo>
                    <MobileItemDetails>
                      {order.stateDescription}
                    </MobileItemDetails>
                  </MobileItemInfo>
                )}
              </TitleWrapper>
            </FlexRow>
            <MoreInfo
              border
              type="dispatch"
              info={order.subscription.data.deliveryDetails.address}
            />
          </Attributes>
        </DetailsWrapper>
      </CardBody>
    </BorderSummaryItemWrap>
  );

  const renderOrder = order => {
    switch (order.type) {
      case 'QUOTE':
        return renderQuoteOrder(order);
      case 'SUBSCRIPTION_ITEM':
        return renderSubscriptionOrder(order);
      case 'BASKET':
      default:
        return renderBasketOrder(order);
    }
  };

  return (
    <Wrapper>
      {isTablet && <Title>{STRINGS.MY_ORDERS}</Title>}
      {!isTablet && (
        <MobileHeader>
          <MobileMainBackButton
            src={theme.images.chevronLeft}
            onClick={() => history.push(productsServicesUrl)}
          />
          {STRINGS.ORDERS}
        </MobileHeader>
      )}
      {orders?.map(renderOrder)}
      <InformationalModal
        visible={creditCardModalValue}
        handleClose={() => setCreditCardModalValue(null)}
        image={theme.images.trustShield}
        body={STRINGS.CREDIT_CARD_INFO}
        textCenter>
        <Box mt={4}>
          <Grid container spacing={4}>
            <Grid item container justifyContent="center" md={6} sm={12}>
              <Button
                onClick={() => setCreditCardModalValue(null)}
                width="90%"
                text={STRINGS.GO_BACK}
                border
                borderColor={theme.colors.border}
                backgroundColor={theme.colors.white}
                textColor={theme.colors.grayFive}
                hoverColor={theme.colors.border}
                activeColor={theme.colors.grayFour}
              />
            </Grid>
          </Grid>
        </Box>
      </InformationalModal>
    </Wrapper>
  );
};

export default OrderSummary;
