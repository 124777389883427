import React from 'react';

import {Box, Card, Container, Divider} from '@material-ui/core';
import {useMountEffect} from '@react-hookz/web';
import {useHistory} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import {useTheme} from 'styled-components';

import atoms, {STRINGS} from '../../atoms';
import {solarLoan} from '../../navigation/routes';
import {MIN_PAGE_HEIGHT} from '../../navigation/styles';
import LookSeeServices from '../../services/LookSeeServices';
import {
  dataLayerVariables,
  sendComponentAnalytics,
} from '../../utils/Analytics';
import {RoundedButton} from '../Button';
import Currency from '../Currency';
import {BodyXsm, Header3} from '../newTypography';
import BulletedItem from './bulletedItem';

export default function CongratulationsYouQualify({
  pageName,
  close = true,
  buttonText = 'Submit your application',
}) {
  const theme = useTheme();
  const history = useHistory();
  const creditInfo = useRecoilValue(atoms.creditInformation);

  const submitSolarLoanLead = async () => {
    await LookSeeServices.submitSolarLoanLead(creditInfo.solarLoanLeadId);
    history.push(`${solarLoan}/next-steps`);
  };

  const buttonAction = () => {
    return submitSolarLoanLead();
  };

  useMountEffect(() => {
    sendComponentAnalytics(
      {
        ...dataLayerVariables({
          pageCategory: 'Products and services',
          pageName: `${pageName}`,
          pageSubSection1: `${pageName}`,
        }),
      },
      'globalVirtualPageView',
    );
  });

  if (!creditInfo?.maxLoanAmount) {
    return <div>{STRINGS.LOADING}</div>;
  }

  const loanAmount = <Currency value={creditInfo.maxLoanAmount} />;
  const repaymentTerm = `x ${creditInfo.repaymentTerm} months`;
  const monthlyRepayment = (
    <Currency value={creditInfo.monthlyRepayment} suffix=" p/m" />
  );
  const interestRate = `${creditInfo.interestRate}%`;

  return (
    <Box minHeight={MIN_PAGE_HEIGHT} bgcolor={theme.colors.browserBackground}>
      <Container>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          paddingY="5%"
          paddingX="12%">
          <Card style={{padding: '5%', maxWidth: '450px', marginBottom: '3%'}}>
            <Box
              display="flex"
              flexDirection="column"
              flex={1}
              textAlign="center"
              justifyContent="center"
              alignItems="center">
              <img alt="send-cloud" src={theme.images.solarLoanQualify} />
              <Box paddingTop={2} paddingBottom="1%" marginBottom="3%">
                <Header3 color={theme.colors.darkGreyText} m="1%">
                  Congratulations, you qualify for a Solar Loan
                </Header3>
              </Box>
              <Box display="flex" justifyContent="center" marginBottom="5%">
                <Divider variant="middle" style={{width: '200px'}} />
              </Box>
              <Box px={1} textAlign="left">
                <BulletedItem
                  text="Maximum estimated loan amount"
                  value={loanAmount}
                />
                <BulletedItem
                  text="Maximum repayment term"
                  value={repaymentTerm}
                />
                <BulletedItem
                  text="Monthly repayment"
                  value={monthlyRepayment}
                />
                <BulletedItem text="Interest rate" value={interestRate} />
                <Box display="inline-flex">
                  <BodyXsm>*</BodyXsm>
                  <Box position="relative" left="8px">
                    <BodyXsm>
                      Please note that this is an estimated quote and serves
                      only as an indication of the loan amount you may qualify
                      for.
                    </BodyXsm>
                    <BodyXsm>
                      <em>Terms and conditions apply.</em>
                    </BodyXsm>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Card>
          <RoundedButton
            width="295px"
            data-id="link_content"
            data-text={`Congratulations, you qualify for a Solar Loan | ${buttonText} button click`}
            data-scope="button"
            data-intent="navigational"
            backgroundColor={theme.colors.blueMobileNav}
            textColor={theme.colors.white}
            text={buttonText}
            onClick={() => buttonAction()}
            style={{marginBottom: '2%'}}
          />
          {close && (
            <RoundedButton
              width="295px"
              data-id="link_content"
              data-text="Qualify for a Solar Loan | Close button click"
              data-scope="button"
              data-intent="navigational"
              backgroundColor={theme.colors.white}
              borderColor={theme.colors.blueMobileNav}
              textColor={theme.colors.blueMobileNav}
              text="Close"
              onClick={() => history.push('/')}
              style={{marginBottom: '2%'}}
            />
          )}
        </Box>
      </Container>
    </Box>
  );
}
