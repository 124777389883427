import React, {useRef, useEffect, useState, useMemo} from 'react';

import {Box} from '@material-ui/core';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import {useSetRecoilState, useRecoilState, useRecoilValue} from 'recoil';
import {useTheme} from 'styled-components';

import atoms, {STRINGS} from '../../atoms';
import {
  blogsUrl,
  productsServicesUrl,
  solarApply,
  solarLoan,
} from '../../navigation/routes';
import {useFeaturesContext} from '../../provider/features';
import PingIdentityService from '../../services/PingIdentityServices';
import {getCurrentTabValue} from '../../utils';
import {capitalizeFirstLetter} from '../../utils/formatting';
import useWidth from '../../utils/useWidth';
import BurgerIcon from '../BurgerIcon';
import Button from '../Button';
import Dropdown from '../Dropdown';
import Link from '../Link';
import ListItemBadge from '../ListItemBadge';
import ManageAccount from '../ManageAccount';
import MobileMenuContainer from '../MobileMenuContainer';
import {
  Container,
  IconRow,
  Icon,
  IconWrapper,
  NotificationBubble,
  HomeIcon,
  StyledTab,
  StyledTabs,
} from './styles.js';

const joyrideClass = alt => {
  switch (alt) {
    case `Products and services account`:
      return 'shoppingCart';
    case STRINGS.PROFILE:
      return 'profile';
    default:
      return '';
  }
};

const NavBar = () => {
  const width = useWidth();
  const theme = useTheme();

  const setLoginOrSignup = useSetRecoilState(atoms.loginOrSignup);

  const [showAccount, setShowAccount] = useState(false);
  const [homeMenu, setHomeMenu] = useRecoilState(atoms.homeMenu);
  const [open, setOpen] = useRecoilState(atoms.menu);
  const [burgerOpen, setBurgerOpen] = useRecoilState(atoms.burgerMenu);
  const [user, setUser] = useRecoilState(atoms.user);
  const setOnboard = useSetRecoilState(atoms.onboard);
  const unreadMessages = useRecoilValue(atoms.unreadMessages);
  const setLogout = useSetRecoilState(atoms.logout);
  const features = useFeaturesContext();
  const location = useLocation();

  const clickRef = useRef();
  const prefRef = useRef();

  const loggedIn = useMemo(() => !!user, [user]);

  const activeApplication = location.pathname === solarApply;
  const congratulations = location.pathname === `${solarLoan}/congratulations`;
  const youDoNotQualifySolarLoan =
    location.pathname === `${solarLoan}/you-do-not-qualify`;
  const nextSteps = location.pathname === `${solarLoan}/next-steps`;

  const notShowNav =
    !activeApplication &&
    !congratulations &&
    !nextSteps &&
    !youDoNotQualifySolarLoan;

  const useOnOutsideClick = (ref, handler) => {
    useEffect(() => {
      const listener = event => {
        const composed = event.composedPath();
        if (
          !ref.current ||
          (event.path
            ? event.path.includes(clickRef.current)
            : composed.includes(clickRef.current)) ||
          (event.path
            ? event.path.includes(prefRef.current)
            : composed.includes(prefRef.current))
        ) {
          return;
        }
        handler(event);
      };
      document.addEventListener('mousedown', listener);
      return () => {
        document.removeEventListener('mousedown', listener);
      };
    }, [ref, handler]);
  };

  const iconLinks = [
    features.manageAccount && {
      id: '39525085-2aca-4533-a159-cd2c638db1dd',
      icon: theme.images.navBarAccountIcon,
      alt: STRINGS.PROFILE,
      hide: !loggedIn,
      header: STRINGS.PROFILE,
      notifications: null,
      listItems: [
        features.homeManagement && {
          id: 'homeManagementNavItem',
          icon: theme.images.navHomeManagementIconSelected,
          title: STRINGS.HOME_MANAGEMENT,
          path: '/home-management',
        },
        {
          id: '889a6c1a-b39d-4270-bccd-e710c4a7ab9f',
          icon: theme.images.mobileNavBarAccountIcon,
          title: STRINGS.MANAGE_ACCOUNT,
          handleClick: () => {
            setShowAccount(true);
          },
        },
        {
          icon: theme.images.settingsTermsAndConditionsIcon,
          title: STRINGS.TERMS_AND_CONDITIONS,
          path: '/terms-and-conditions',
        },
        {
          icon: theme.images.settingsFeedbackIcon,
          title: STRINGS.FEEDBACK,
          path: '/contact-us',
        },
        {
          id: '09150514-346e-4bbf-b6cd-da3c0e56eb3f',
          icon: theme.images.logOutIcon,
          title: STRINGS.SIGN_OUT,
          handleClick: async () => {
            setLogout(true);
            await PingIdentityService.logout();
            setUser(null);
            setOnboard(0);
          },
        },
      ].filter(x => x),
      render: data => (
        <Box component={data.path ? Link : 'div'} to={data.path}>
          <ListItemBadge
            icon={data.icon}
            notificationTotal={data.notificationTotal}
            title={data.title}
            handleClick={data.handleClick}
            header="account"
          />
        </Box>
      ),
    },
  ].filter(x => x);

  const marketPlaceLinks = [
    {
      id: '39525085-2aca-4533-a159-cd2c638db1e',
      icon: theme.images.shoppingIconCart,
      alt: `Products and Services Account`,
      hide: !loggedIn,
      header: `Products and Services Account`,
      listItems: [
        features.marketplace && {
          id: 'ordersNavItem',
          icon: theme.images.purchasesIcon,
          title: STRINGS.ORDERS,
          path: `${productsServicesUrl}/purchases`,
        },
        features.marketplace && {
          id: 'quotesNavItem',
          icon: theme.images.quotesIcon,
          title: STRINGS.MY_QUOTES,
          path: `${productsServicesUrl}/quotes`,
        },
      ].filter(x => x),
      render: data => (
        <Box component={data.path ? Link : 'div'} to={data.path}>
          <ListItemBadge
            icon={data.icon}
            notificationTotal={data.notificationTotal}
            title={data.title}
            handleClick={data.handleClick}
            header="products and services account"
          />
        </Box>
      ),
    },
  ].filter(x => x);

  useOnOutsideClick(clickRef, () => {
    setHomeMenu(false);
    setBurgerOpen(false);
  });

  useOnOutsideClick(prefRef, () => {
    setOpen(false);
  });

  const validTabs = [
    features.marketplace && {
      value: 'products-services',
      label: 'Products and Services',
      to: '/products-services',
    },
    features.eventfeed && {value: 'feed', label: 'My Feed', to: '/feed'},
    {
      value: blogsUrl,
      label: 'Knowledge Hub',
      href: blogsUrl,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    features.contactUs && {
      value: 'contact-us',
      label: 'Contact',
      to: '/contact-us',
    },
  ].filter(x => x);

  const isMobile = width < theme.sizes.laptopWidth;
  const tabValue = getCurrentTabValue(location.pathname, 1);

  return (
    <Container id="header">
      {isMobile && !nextSteps && (
        <div ref={clickRef}>
          <BurgerIcon
            open={burgerOpen}
            setOpen={setBurgerOpen}
            hide={!loggedIn}
            onClick={() => setHomeMenu(o => !o)}
          />
          <MobileMenuContainer unreadMessages={unreadMessages} />
        </div>
      )}
      {!homeMenu && (
        <Link
          data-intent="navigational"
          data-scope="header"
          data-id="link_content"
          data-text="looksee logo click"
          to="/">
          <HomeIcon
            src={theme.images.lookSeeLogo}
            open={open}
            alt="Home"
            style={{width: width > 600 && 150}}
            screenWidth={width}
          />
        </Link>
      )}
      {!isMobile && notShowNav && (
        <StyledTabs variant="scrollable" value={tabValue} textColor="primary">
          {validTabs.map(({value, to, ...tabProps}) => (
            <StyledTab
              component={to ? Link : 'a'}
              data-intent="navigational"
              data-scope="header"
              data-id="link_content"
              data-text={`${tabProps.label} tab click`}
              key={value}
              value={value}
              to={to}
              {...tabProps}
            />
          ))}
        </StyledTabs>
      )}
      {notShowNav && (
        <IconRow>
          {features.marketplace &&
            loggedIn &&
            !homeMenu &&
            !isMobile &&
            marketPlaceLinks.map(
              ({
                id,
                icon,
                alt,
                onClick,
                hide,
                header,
                listItems,
                notifications: numNotifications,
                render,
                slot,
                height,
              }) => (
                <Dropdown
                  key={id}
                  header={header}
                  listItems={listItems}
                  button={
                    <>
                      <IconWrapper
                        data-intent="informational"
                        data-scope="header"
                        data-id="link_content"
                        data-text={`${capitalizeFirstLetter(
                          header,
                        )} icon click`}
                        onClick={onClick}
                        hidden={hide}
                        key={alt}
                        data-for="navTT"
                        data-tip={alt}
                        className={joyrideClass(alt)}>
                        <Icon src={icon} alt={alt} />
                        {numNotifications && (
                          <NotificationBubble>
                            {numNotifications}
                          </NotificationBubble>
                        )}
                      </IconWrapper>
                      <ReactTooltip
                        id="navTT"
                        effect="solid"
                        place="bottom"
                        className="navToolTip"
                        arrowColor={theme.colors.black100}
                      />
                    </>
                  }
                  renderItem={render}
                  slot={slot}
                  height={height}
                />
              ),
            )}
          {!homeMenu &&
            !isMobile &&
            iconLinks.map(
              ({
                id,
                icon,
                alt,
                onClick,
                hide,
                header,
                listItems,
                render,
                slot,
                height,
              }) => (
                <Dropdown
                  key={id}
                  header={header}
                  listItems={listItems}
                  button={
                    <>
                      <IconWrapper
                        data-intent="informational"
                        data-scope="header"
                        data-id="link_content"
                        data-text={`${capitalizeFirstLetter(
                          header,
                        )} icon click`}
                        onClick={onClick}
                        hidden={hide}
                        key={alt}
                        data-for="navTT"
                        data-tip={alt}
                        className={joyrideClass(alt)}>
                        <Icon src={icon} alt={alt} />
                      </IconWrapper>
                      <ReactTooltip
                        id="navTT"
                        effect="solid"
                        place="bottom"
                        className="navToolTip"
                        arrowColor={theme.colors.black100}
                      />
                    </>
                  }
                  renderItem={render}
                  slot={slot}
                  height={height}
                />
              ),
            )}
          {showAccount && <ManageAccount onOutsideClick={setShowAccount} />}
          {!loggedIn && !isMobile && (
            <>
              <Button
                data-intent="transactional"
                data-scope="header nav"
                data-id="link_content"
                data-text="sign up link click"
                text="Sign up"
                backgroundColor="transparent"
                onClick={() => setLoginOrSignup(STRINGS.REGISTER)}
                activeColor={theme.colors.secondary}
                hoverColor="transparent"
                styles={{
                  padding: `0 ${theme.spacing.normal}`,
                  width: 100,
                  height: 33,
                  marginRight: theme.spacing.small,
                }}
              />
              <Button
                data-intent="transactional"
                data-scope="header nav"
                data-id="link_content"
                data-text="log in link click"
                text="Log in"
                textColor={theme.colors.tertiary}
                backgroundColor={theme.colors.secondary}
                onClick={() => setLoginOrSignup(STRINGS.LOGIN)}
                hoverColor={theme.colors.secondary}
                styles={{
                  padding: `0 ${theme.spacing.normal}`,
                  width: 120,
                  height: 33,
                  borderRadius: 30,
                }}
              />
            </>
          )}
        </IconRow>
      )}
    </Container>
  );
};

NavBar.defaultProps = {};

NavBar.propTypes = {
  styles: PropTypes.shape({}),
};

export default NavBar;
