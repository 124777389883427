import React from 'react';

import {Box} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {useTheme} from 'styled-components';

import {RoundedButton} from '../../components/Button';
import {BodyLg, calcPreferredValue} from '../../components/newTypography';
import {solarLoanApply} from '../../navigation/routes';
import {BannerImage} from './styles';

export default function MiddleBanner() {
  const theme = useTheme();
  const history = useHistory();

  return (
    <BannerImage
      gradient={theme.gradient.lightBlueGradient}
      imageUrl={theme.images.MiddleBannerImage}
      py="4%"
      mt="5%">
      <Box px="5%">
        <BodyLg
          color={theme.colors.white}
          fontWeight="700"
          fontSize={`clamp(1.25rem, ${calcPreferredValue(40, 20)}, 2.5rem)`}>
          Have your own installation quote or invoice?
        </BodyLg>
        <RoundedButton
          data-intent="navigational"
          data-scope="button"
          data-id="link_content"
          data-text="Have your own installation quote or invoice? | Only apply for a Solar Loan button click"
          text="Only apply for a Solar Loan"
          textColor={theme.colors.white}
          backgroundColor={theme.colors.tertiary}
          onClick={() => history.push(solarLoanApply)}
        />
      </Box>
    </BannerImage>
  );
}
