import {lazy} from 'react';

import ProductsAndServices from '../pages/ProductsAndServices';

const VerifyEmail = lazy(() => import('../pages/VerifyEmail'));
const ContactUs = lazy(() => import('../pages/ContactUs'));

const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('../pages/TermsAndConditions'));
const CodeOfConduct = lazy(() => import('../pages/CodeOfConduct'));
const ManageCookies = lazy(() => import('../pages/ManageCookies'));

export const productsServicesUrl = '/products-services';
export const blogsUrl = 'https://contenthub.looksee.co.za/looksee/content-hub';
export const signupUrl = '/signup';
const contactUrl = '/contact-us';
const privacyUrl = '/privacy-policy';
export const manageCookiesUrl = '/manage-cookies';
export const termsUrl = '/terms-and-conditions';
const codeOfConductUrl = '/code-of-conduct';
export const solarUrl = `${productsServicesUrl}/solar`;
export const solarApply = `${solarUrl}/apply`;
export const solarLoan = `${productsServicesUrl}/solarloan`;
export const solarLoanApply = `${solarLoan}/apply`;
const verifyEmailUrl = '/email/verify';

export const allRoutes = [
  {path: `${verifyEmailUrl}/:token`, Page: VerifyEmail, isPrivate: true},
  {path: privacyUrl, Page: PrivacyPolicy, exact: true},
  {path: termsUrl, Page: TermsAndConditions, exact: true},
  {path: manageCookiesUrl, Page: ManageCookies, exact: true},
  {path: codeOfConductUrl, Page: CodeOfConduct, exact: true},
  {path: contactUrl, Page: ContactUs},
  {
    path: productsServicesUrl,
    Page: ProductsAndServices,
    feature: 'marketplace',
  },
];
