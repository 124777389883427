import styled from 'styled-components';

export const MIN_PAGE_HEIGHT = 'calc(100vh - 80px - 149px)'; // fullscreen - navbar - footer

export const PageContent = styled.div`
  margin-top: 60px;
  min-height: ${MIN_PAGE_HEIGHT};
  @media ${props => props.theme.device.laptop} {
    margin-top: 80px;
  }
`;
