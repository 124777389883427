import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${props => props.containerStyle};
`;
export const Wrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 1px;
  border-radius: 4px;

  border: ${props => {
    if (props.focused && !props.error) {
      return `1px solid ${props.theme.colors.primary}`;
    } else if (!props.focused && props.error) {
      return `1px solid ${props.theme.colors.error}`;
    } else if (props.borderColor) {
      return `1px solid ${props.borderColor}`;
    } else {
      return `1px solid ${props.theme.colors.grayFour}`;
    }
  }};
  background: ${props =>
    props.focused ? props.theme.colors.brandBlue1 : props.theme.colors.white};
  ${({wrapperStyles}) => wrapperStyles}
`;
export const Label = styled.label`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 10px;

  ${props => props.labelStyle};
`;
export const Input = styled.textarea`
  height: 48px;
  width: 100%;
  outline: none;
  border: none;
  font: ${({theme}) => theme.typography.body};
  background: ${props => props.theme.colors.white};
  padding: 10px 0 0 10px;
  font-size: ${({fontSize}) => fontSize || '16px'};
  resize: ${({resize}) => resize || 'none'};

  ::placeholder {
    color: ${props => props.theme.colors.grayNine};
  }

  &:focus {
    background: ${props => props.theme.colors.brandBlue1};
  }

  :disabled {
    color: ${props => props.theme.colors.grayFour};
  }

  ${props => props.inputStyle};
`;
export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const ErrorText = styled.span`
  font-size: 12px;
  color: ${props => props.theme.colors.error};

  &::first-letter {
    text-transform: uppercase;
  }
`;
export const VisibilityWrapper = styled.label`
  display: flex;
  justify-content: center;
  padding-right: 8px;

  & .MuiSvgIcon-root {
    fill: ${props =>
      props.focused ? props.theme.colors.primary : props.theme.colors.grayFour};
    height: 20px;
  }
`;
export const IconShowWrapper = styled.img`
  ${props => props.customIconStyle};
`;
