import {selector} from 'recoil';

import {logger} from '../services/Logger';
import LookSeeServices from '../services/LookSeeServices';

export default {
  contentHubArticles: selector({
    key: 'contentHubArticles',
    get: async () => {
      try {
        return await LookSeeServices.getContentHubArticles();
      } catch (e) {
        logger.error('Error fetching contentHubArticles', e);
        return [];
      }
    },
  }),
};
