import styled from 'styled-components';

export const Overlay = styled.div`
  display: flex;
  position: ${({contained}) => (contained ? 'fixed' : 'absolute')};
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  top: 0;
  bottom: 0;
  width: 100%;
  background: rgba(33, 33, 33, 0.2);
  z-index: ${({zIndex}) => zIndex ?? 9999};
  overflow-y: auto;
  ${({styles}) => styles}
`;

export const Box = styled.div`
  box-sizing: border-box;
  background: ${props => props.theme.colors.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  width: 100%;
  min-height: ${({collapse, height}) => (collapse ? 0 : height ?? '100%')};
  margin-top: 120px;
  position: relative;
  max-height: 90vh;
  overflow-y: auto;

  @media ${({theme}) => theme.device.tablet} {
    min-height: ${({collapse, height}) => (collapse ? 0 : height ?? '380px')};
    margin-top: 0;
    width: ${({width}) => (width ? width : '70%')};
    max-width: 643px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
`;

export const CenterItem = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ModalHeaderText = styled.h3`
  font-size: 24px;
  font-weight: 600;
  color: ${props => props.theme.colors.black};
  margin: 0;
  text-align: center;
`;

export const ModalImage = styled.img(
  ({theme}) => `
  max-height: 200px;
  object-fit: contain;

  @media ${theme.device.laptop} {
    max-height: ${({contained}) => (contained ? '250px' : '320px')};
    max-width: ${({contained}) => (contained ? '250px' : '320px')};
  }
`,
);

export const CloseIcon = styled.img(
  ({theme}) => `
  cursor: pointer;
  padding: ${theme.spacing.small};
  position: absolute;
  right: ${theme.spacing.large};
  top: ${theme.spacing.large};
`,
);

export const ButtonWrapper = styled.div(props => [
  {
    display: 'flex',
    flexDirection: 'column',
    gap: props.theme.spacing.normal,
  },
  props.style,
]);
