export function pickRandom(arr) {
  return arr[Math.floor(Math.random() * arr.length)];
}

export const pickRandomItems = (array, totalItemsToReturn = 1) => {
  let result = [];
  let i = 0;
  const arrayCopy = [...array];
  const maxItems = Math.min(arrayCopy.length, totalItemsToReturn);
  while (i < maxItems) {
    const item = pickRandom(arrayCopy);
    result.push(item);
    const index = arrayCopy.findIndex(c => c === item);
    arrayCopy.splice(index, 1);
    i += 1;
  }
  return result;
};

export const getCurrentTabValue = (pathname, index) => {
  const tabValue = pathname.split('/');
  return tabValue[index]?.length ? tabValue[index] : false;
};

export function calculateMonthlyPayment(
  monthlyInterestRate,
  numMonths,
  presentValue,
  futureValue = 0,
  paymentsDueAtStart = false,
) {
  /*
  Implementation from https://gist.github.com/ahmadjoya/daa6b6f5deca44a0d628349b8b90147f
  and refactored for readability.
  */
  const monthlyInterestRateRatio = monthlyInterestRate / 100;

  if (monthlyInterestRateRatio === 0) {
    return -(presentValue + futureValue) / numMonths;
  }

  let payment;
  const presentValueInFutureMultiplier = Math.pow(
    1 + monthlyInterestRateRatio,
    numMonths,
  );
  payment =
    (-monthlyInterestRateRatio *
      (presentValue * presentValueInFutureMultiplier + futureValue)) /
    (presentValueInFutureMultiplier - 1);

  if (paymentsDueAtStart) {
    payment /= 1 + monthlyInterestRateRatio;
  }
  return payment;
}
