import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  position: fixed;
  bottom: 0px;
  border-top-left-radius: ${({theme}) => theme.spacing.small};
  border-top-right-radius: ${({theme}) => theme.spacing.small};
  height: 99%;
  width: 100%;
  text-align: left;
  transition: transform 0.2s ease-in-out;
  background: ${props => props.theme.colors.white};
  box-shadow: -6px 0px 20px -5px rgba(0, 0, 0, 0.5);
  transform: ${({state}) => {
    switch (state) {
      case 'entering':
        return 'translateY(175%)';
      case 'entered':
        return 'translateY(0%)';
      case 'exiting':
        return 'translateY(175%)';
      case 'exited':
        return 'translateY(175%)';
    }
  }};
  z-index: ${({theme}) => theme.sizes.modalZIndex};
  overflow: hidden;
`;
