import React, {useCallback, useMemo, useState} from 'react';

import {
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
} from '@material-ui/core';
import {ExpandMore} from '@material-ui/icons';
import {useConditionalEffect, useMountEffect} from '@react-hookz/web';
import {useFormikContext} from 'formik';
import PropTypes from 'prop-types';
import {useRecoilValue, useRecoilState, useSetRecoilState} from 'recoil';
import {useTheme} from 'styled-components';

import atoms from '../../atoms';
import {STRINGS, TAB_OPTIONS} from '../../atoms/app/constants';
import {RoundedButton} from '../../components/Button';
import {
  BodyReg,
  BodySm,
  Header4,
  Header5,
} from '../../components/newTypography';
import useDetectMobile from '../../hooks/useDetectMobile';
import {useFeaturesContext} from '../../provider/features';
import {
  dataLayerVariables,
  sendComponentAnalytics,
  updateApplicationData,
} from '../../utils/Analytics';
import PackageWidget from './PackageWidget';
import {FlatAccordion, TabToggle, ToggleOption} from './styles';

function getRecommendedPackage(monthlyAverageBill, sortedPackages) {
  if (monthlyAverageBill < sortedPackages[0].minimumBillRecommendation) {
    return sortedPackages[0];
  }
  if (
    monthlyAverageBill >
    sortedPackages[sortedPackages.length - 1].maximumBillRecommendation
  ) {
    return sortedPackages[sortedPackages.length - 1];
  }
  return sortedPackages.find(
    p =>
      monthlyAverageBill >= p.minimumBillRecommendation &&
      monthlyAverageBill <= p.maximumBillRecommendation,
  );
}

export default function Packages({onBackClick}) {
  const theme = useTheme();
  const features = useFeaturesContext();
  const isMobile = useDetectMobile({detectTablet: false});
  const [expanded, setExpanded] = useState(false);
  const packages = useRecoilValue(atoms.solarPackages);
  const [selectedTab, setSelectedTab] = useRecoilState(atoms.solarPackagesTab);
  const formik = useFormikContext();
  const {monthlyAverageBill, averageTariff} = formik.values;
  const [recommendedPackage, setRecommendedPackage] = useState({});
  const [otherPackages, setOtherPackages] = useState([]);
  const setWantSolarLoan = useSetRecoilState(atoms.solarLoanFinancing);
  const calculationsConfig = useMemo(() => {
    return {
      monthlyAverageBill,
      tariffRate:
        Number(averageTariff) || features.solarHPAConfig.defaultKiloWattRate,
      interestRate: features.solarHPAConfig.interestRate,
      loanTermMonths: features.solarHPAConfig.loanTermMonths,
      interestRateBattery: features.solarHPAConfig.interestRateBattery,
      loanTermMonthsBattery: features.solarHPAConfig.loanTermMonthsBattery,
    };
  }, [
    averageTariff,
    features.solarHPAConfig.defaultKiloWattRate,
    features.solarHPAConfig.interestRate,
    features.solarHPAConfig.loanTermMonths,
    features.solarHPAConfig.interestRateBattery,
    features.solarHPAConfig.loanTermMonthsBattery,
    monthlyAverageBill,
  ]);

  const [disabled, setDisabled] = useState(false);
  const onPackageSelect = useCallback(
    selectedPackage => {
      setWantSolarLoan(false);

      setDisabled(true);
      formik.setValues({
        ...formik.values,
        solution: selectedPackage.numPanels
          ? STRINGS.BATTERY_SOLAR
          : STRINGS.BATTERY_BACKUP,
        packageId: selectedPackage.itemId,
        recommendedPackageId: recommendedPackage.itemId,
        proposalUri: selectedPackage.proposalUri,
        package: selectedPackage.name,
        recommendedPackage: recommendedPackage.name,
        systemPowerOffset: selectedPackage.systemPowerOffset,
        monthlySavingsAmount: selectedPackage.monthlySavingsAmount,
        estMonthlyPrice: selectedPackage.estMonthlyPrice,
        estCashPrice: selectedPackage.estCashPrice,
        systemSize: selectedPackage.inverterCapacity,
      });
      setTimeout(() => {
        setDisabled(false);
        formik.submitForm();
      }, 1000);
    },
    [formik, recommendedPackage.itemId, recommendedPackage.name],
  );

  useConditionalEffect(
    () => {
      const sortedPackages = packages
        .filter(p =>
          selectedTab.value === STRINGS.BATTERY_SOLAR
            ? p.numPanels
            : !p.numPanels,
        )
        .sort(
          (a, b) => a.minimumBillRecommendation - b.minimumBillRecommendation,
        );

      const recommended = getRecommendedPackage(
        monthlyAverageBill,
        sortedPackages,
      );

      setRecommendedPackage(recommended);
      setOtherPackages(sortedPackages.filter(p => p !== recommendedPackage));
    },
    [monthlyAverageBill, packages, recommendedPackage, selectedTab.value],
    [packages.length > 1],
  );

  useMountEffect(() => {
    updateApplicationData({
      applicationStep: 'Step 2',
    });
    sendComponentAnalytics(
      {
        ...dataLayerVariables({
          pageCategory: 'Property Hub',
          pageName: 'Property Hub:Solar:Apply:Your Solution',
          pageSubSection1: 'Property Hub:Solar:Apply:Your Solution',
        }),
      },
      'globalVirtualPageView',
    );
  });

  const backButton = (
    <RoundedButton
      data-intent="navigational"
      data-scope="button"
      data-text="View range of solutions | Your solution | Back button click"
      data-id="link_content"
      backgroundColor={theme.colors.white}
      borderColor={theme.colors.blueMobileNav}
      text="Back"
      textColor={theme.colors.blueMobileNav}
      onClick={onBackClick}
      tabIndex={-1}
    />
  );

  const renderedOtherPackages = otherPackages
    .filter(x => x)
    .map(p => (
      <Box marginTop="10px" key={p.name}>
        <PackageWidget
          onContinueClick={onPackageSelect}
          calculationsConfig={calculationsConfig}
          solarPackage={p}
          buttonDisabled={disabled}
          packageType={selectedTab.value}
        />
      </Box>
    ));

  const footer = isMobile ? (
    <>
      <Box
        width="100%"
        borderColor={theme.colors.grayFour}
        borderTop="1px solid"
      />
      <Box alignSelf="flex-start" marginTop="20px">
        <Header5 inline>View other solutions</Header5>
      </Box>
      {otherPackages.length > 1 && renderedOtherPackages}
      <Box alignSelf="flex-start" paddingY="20px">
        {backButton}
      </Box>
    </>
  ) : (
    <FlatAccordion
      data-intent="informational"
      data-scope="accordion"
      data-text="View range of solutions | Your Solution | View other solutions accordion click"
      data-id="link_content"
      expanded={expanded}
      onChange={(event, newValue) => setExpanded(newValue)}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Box
          width="100%"
          display="flex"
          justifyContent={expanded ? 'flex-end' : 'space-between'}>
          {!expanded && backButton}
          <Box justifySelf="flex-end">
            <BodySm fontWeight="300">View other solutions</BodySm>
          </Box>
        </Box>
      </AccordionSummary>
      <Box component={AccordionDetails} flexDirection="column" gridGap="30px">
        {renderedOtherPackages}
      </Box>
      <Box component={AccordionActions} justifyContent="flex-start">
        {backButton}
      </Box>
    </FlatAccordion>
  );

  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <TabToggle value={selectedTab.value}>
        {TAB_OPTIONS.map(tab => (
          <ToggleOption
            key={tab.value}
            data-intent="navigational"
            data-scope="tab"
            data-text={`View range of solutions | Your Solution | ${tab.label} tab click`}
            data-id="link_content"
            onClick={() => setSelectedTab(tab)}
            value={tab.value}
            label={tab.label}
          />
        ))}
      </TabToggle>
      <Box alignSelf="flex-start" paddingY="20px">
        <Header4 inline>Recommended solutions for you</Header4>
        <BodyReg>
          Homes with similar electricity consumption to yours choose this
          solution
        </BodyReg>
      </Box>
      <Box paddingBottom="20px">
        {packages.length > 1 && (
          <PackageWidget
            selected
            calculationsConfig={calculationsConfig}
            solarPackage={recommendedPackage}
            onContinueClick={onPackageSelect}
            buttonDisabled={disabled}
            packageType={selectedTab.value}
          />
        )}
      </Box>
      {footer}
    </Box>
  );
}

Packages.propTypes = {
  onBackClick: PropTypes.func.isRequired,
  setCalculationsConfig: PropTypes.func.isRequired,
  selectedTab: PropTypes.func.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
};
