import styled from 'styled-components';

export const Checkbox = styled.input`
  min-width: 12px;
  min-height: 12px;
  border: 1px solid
    ${({theme, error}) => (error ? theme.colors.error : theme.colors.grayFour)};
  border-radius: 3px;

  &:checked {
    background-image: url(${props => props.theme.images.CheckboxOn});
    border: ${({theme}) => `1px solid ${theme.colors.primary}`};
    min-width: 12px;
    min-height: 12px;
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const TermsWrapper = styled.label`
  width: auto;
  display: flex;
  margin-bottom: ${({theme}) => theme.spacing.small};
`;

export const TermsText = styled.span`
  font: ${({theme}) => theme.typography.description};
  color: ${({theme}) => theme.colors.grayFive};
  line-height: 24px;
  padding-left: 6px;
`;

export const TermsLink = styled.a`
  color: ${({theme}) => theme.colors.primary};
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
`;
