import React from 'react';

import {useTheme} from 'styled-components';

import {Wrapper, ActionRequired} from './styles';

const Order = ({onClick, backgroundColor, text, innerText}) => (
  <Wrapper bgColor={backgroundColor}>
    {text}
    <ActionRequired onClick={onClick}>{innerText}</ActionRequired>
  </Wrapper>
);

function QuoteBanner({onClick, ribbon, getOrder}) {
  const theme = useTheme();
  if (getOrder?.stateDescription === 'Order placed') {
    if (!ribbon) {
      return null;
    }
    return (
      <Order
        onClick={onClick}
        backgroundColor={ribbon ? theme.colors.pink200 : ''}
        text={ribbon ? 'Action required: ' : ''}
        innerText={ribbon ? 'Respond to seller' : ''}
      />
    );
  }
  if (getOrder?.stateDescription === 'Quote received') {
    return <Order onClick={onClick} />;
  }
  if (getOrder?.stateDescription === 'Order pending completion') {
    return (
      <div
        data-intent="informational"
        data-scope="My orders"
        data-id="link_content"
        data-text="Property inspection service | Download report link click">
        <Order
          onClick={onClick}
          backgroundColor={theme.colors.yellow400}
          text="Action required: "
          innerText="Download report"
        />
      </div>
    );
  }
  return null;
}

export default QuoteBanner;
