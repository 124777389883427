import {Box, Card} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import styled from 'styled-components';

import {calcPreferredValue} from '../../components/newTypography';
export const CenterBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CheckedCircle = styled(CheckCircleOutlinedIcon)`
  &.MuiSvgIcon-root {
    color: ${({theme}) => theme.colors.primary};
  }
`;

export const Arrow = styled(ArrowForwardIcon)`
  &.MuiSvgIcon-root {
    color: ${({theme}) => theme.colors.newPrimary};
    vertical-align: top;
  }
`;

export const BannerImage = styled(Box)`
  background-image: ${({imageUrl}) => `url(${imageUrl})`};
  background-size: cover;
  background-position: center;
`;

export const RoundedCard = styled(Card)`
  &.MuiCard-root {
    border-radius: 14px;
  }
`;

export const LearnMoreLink = styled.a`
  color: ${({theme, color}) => (color ? color : theme.colors.newPrimary)};
  text-decoration: none;
  display: inline-flex;
  &:hover {
    cursor: pointer;
  }
`;

export const subHeadingFontSize = `clamp(1.25rem, ${calcPreferredValue(
  40,
  20,
)}, 2.5rem)`;

export const StyledImg = styled.img`
  object-fit: cover;
`;

export const StyledArticleContainer = styled(Box)`
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  &:hover,
  &:focus,
  &:active {
    overflow-x: auto;
    scrollbar-color: #ccc #f0f0f0;
  }
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 30px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
  &::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    border-radius: 4px;
    width: 8px;
  }
`;
