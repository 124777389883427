import styled from 'styled-components';

export const Wrapper = styled.div`
  color: ${({theme}) => theme.colors.black1200};
  font-weight: 600;
  background: ${({background}) => background};
  padding: ${({theme}) => `${theme.spacing.small} ${theme.spacing.large}`};
  display: flex;
  align-items: baseline;
  font: 600 14px Inter;
`;

export const ActionRequired = styled.div`
  border-bottom: ${({theme}) => `2px solid ${theme.colors.black1200}`};
  padding-bottom: 2px;
  margin-left: 5px;
`;
