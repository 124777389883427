import HttpServices, {APPLICATION_ID} from './HttpServices';

const LookSeeServices = {
  async getUser() {
    return HttpServices.get('/user/me');
  },
  async login(email, password) {
    const result = await HttpServices.postWithTimeout('/user/login', {
      email,
      password,
    });

    HttpServices.setToken(result.token);

    return result;
  },
  async propertySearch(query, provider = 'lightstone') {
    return HttpServices.getWithTimeout(
      `/property/find?s=${encodeURIComponent(query)}&provider_id=${provider}`,
    );
  },
  async propertyValuation(query) {
    return HttpServices.get(
      `/property/valuation?property_id=${encodeURIComponent(query)}`,
    );
  },
  async verifyEmail(token) {
    try {
      return await HttpServices.post('/user/email/confirm', {token});
    } catch (e) {
      return e;
    }
  },
  async resendVerificationEmail(email) {
    try {
      return await HttpServices.post('/user/email/verify', {email});
    } catch (e) {
      return e;
    }
  },
  async deactivateAccount() {
    return HttpServices.put('/user/deactivate');
  },
  async getTopProducts() {
    return HttpServices.get(`/products-services/products/top-picks`);
  },
  async getTermsAndConditions(type) {
    const result = await HttpServices.getWithTimeout(
      `/applications/${APPLICATION_ID}/terms/${type}`,
    );

    return {
      type: result.type,
      termId: result.term_id,
      content: result.content,
    };
  },
  async updateTermsAndConditions(type, accepted) {
    return HttpServices.put(
      `/applications/${APPLICATION_ID}/terms/${type}/user`,
      {accepted},
    );
  },

  async postGeneralFeedback(feedback) {
    return HttpServices.postWithTimeout(
      `/applications/${APPLICATION_ID}/contact-us`,
      {data: feedback},
    );
  },

  async getMarketplaceSavedItems() {
    return HttpServices.get(`/marketplace/saved`);
  },
  async getOrder(orderId) {
    return HttpServices.get(`/marketplace/orders/${orderId}`);
  },

  async getOrders() {
    return HttpServices.get(`/marketplace/orders`);
  },
  async getItem(marketId, categoryId, itemId) {
    return HttpServices.get(
      `/marketplace/markets/${marketId}/categories/${categoryId}/items?itemId=${encodeURIComponent(
        itemId,
      )}`,
    );
  },
  async getQuotes() {
    return HttpServices.get(`/marketplace/quotes`);
  },
  async declineQuote(quoteId) {
    return HttpServices.postWithTimeout(`/marketplace/quotes/reject`, {
      quoteId,
    });
  },
  getMarketplaceRules(type) {
    return HttpServices.get(`/marketplace/rules/${type}`);
  },
  callMeBack(body) {
    return HttpServices.post(`/marketplace/callback`, body);
  },
  addToWaitingList(body) {
    return HttpServices.post(`/waitinglist/contact/add`, body);
  },
  getContentHubArticles() {
    return HttpServices.get('/content-hub/articles');
  },
  submitSolarApplication(body) {
    return HttpServices.post(`/solar/application`, body);
  },
  getSolarLoanCreditCheck(body) {
    return HttpServices.post(`/solar/qualify`, body);
  },
  submitSolarLoanLead(leadId) {
    return HttpServices.post(`/solar/leads/${leadId}`);
  },
  getPluginToken(context) {
    return HttpServices.get(`/property/plugin/token?context=${context}`);
  },
  getPropertyDetails(addressId, provider, unit_number) {
    return HttpServices.get(
      `/property/details?address_id=${addressId}&provider_id=${provider}&unit_number=${unit_number}`,
    );
  },
  getSolarScore(propertyKey, subplaceId) {
    return HttpServices.get(
      `/solar/score?property_key=${propertyKey}&subplace_id=${subplaceId}`,
    );
  },
  getSolarPackages() {
    return HttpServices.get(`/solar/packages`);
  },
  async getCategories() {
    return HttpServices.getWithTimeout('/products-services/categories');
  },
  getProduct(subcategoryName, itemCode) {
    return HttpServices.get(
      `/products-services/subcategory/${encodeURIComponent(
        subcategoryName,
      )}/product/${encodeURIComponent(itemCode)}`,
    );
  },
  async getSubcategory(subcategoryId) {
    return HttpServices.getWithTimeout(
      `/products-services/category/${subcategoryId}`,
    );
  },
};

export default LookSeeServices;
