import {Tab, Tabs} from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  height: 60px;
  z-index: 100;
  width: 100%;
  flex-direction: row;
  background-color: ${({theme}) => theme.colors.tertiary};
  justify-content: space-between;
  align-items: center;
  box-shadow: ${({noBorder}) =>
    noBorder ? 'none' : '0px 4px 4px rgba(0, 0, 0, 0.05)'};
  padding: ${({theme}) => `0 ${theme.spacing.normal}`};
  box-sizing: border-box;

  @media ${props => props.theme.device.mobileL} {
    padding-left: ${({theme}) => theme.spacing.xLarge};
    padding-right: ${({theme}) => theme.spacing.xLarge};
  }

  @media ${props => props.theme.device.laptop} {
    height: 80px;
  }
`;

export const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    background-color: ${({theme}) => theme.colors.secondary};
  }

  .MuiTabs-scrollButtons {
    color: ${({theme}) => theme.colors.white};
    opacity: 1;
  }
`;

export const StyledTab = styled(Tab)`
  &.MuiTab-root {
    text-transform: none;
    font: ${({theme}) => theme.typography.headline2};
    min-width: fit-content;
    padding: 0 25px;
  }

  &.MuiTab-textColorPrimary,
  &.Mui-selected.MuiTab-textColorPrimary {
    color: ${({theme}) => theme.colors.white};
  }
`;

export const IconRow = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const Icon = styled.img`
  cursor: pointer;
`;

export const HomeIcon = styled.img`
  margin-left: -10px;
  padding: 0 25px 0 0;
`;

export const IconWrapper = styled.div`
  display: ${({hidden}) => (hidden ? 'none' : 'grid')};
  place-content: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  border-radius: 50%;
  height: ${({theme: {spacing}}) => spacing.giant};
  width: ${({theme: {spacing}}) => spacing.giant};

  &:hover {
    transition: 150ms;
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const NotificationBubble = styled.div`
  position: absolute;
  display: grid;
  place-content: center;
  font-size: 10px;
  font-weight: 700;
  top: ${({theme: {spacing}}) => spacing.xSmall};
  left: 50%;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background: ${({theme}) => theme.colors.secondary};
  color: ${({theme}) => theme.colors.white};
  z-index: 1000;
`;
