import React from 'react';

import {useRecoilValue} from 'recoil';

import atoms from '../../atoms';
import {Overlay, Box, Wrapper} from './styles';

const InteractiveModal = () => {
  const interactiveModal = useRecoilValue(atoms.interactiveModal);

  return interactiveModal ? (
    <Overlay>
      <Box>
        <Wrapper>{interactiveModal}</Wrapper>
      </Box>
    </Overlay>
  ) : null;
};

export default InteractiveModal;
