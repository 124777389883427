import React, {useState, useEffect, useRef} from 'react';

import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {useTheme} from 'styled-components';

import atoms, {STRINGS} from '../../atoms';
import LookSeeServices from '../../services/LookSeeServices';
import PingIdentityService from '../../services/PingIdentityServices';
import Abandon from '../Abandon';
import ChangeAccountDetails from '../ChangeAccountDetails';
import {
  AbandonWrapper,
  AvatarWrapper,
  Avatar,
  Fullname,
  ManageListItem,
  ListIcon,
  ListText,
  MobileHeader,
  MobileIcon,
  MobileTitle,
  MobileBody,
  UnorderedList,
  Header,
} from './styles';

const ManageAccount = ({onOutsideClick, mobile}) => {
  const node = useRef();
  const history = useHistory();
  const theme = useTheme();
  const [screen, setScreen] = useState('');
  const [user, setUser] = useRecoilState(atoms.user);
  const setLogout = useSetRecoilState(atoms.logout);
  const setInteractiveModal = useSetRecoilState(atoms.interactiveModal);
  const setSliderModal = useSetRecoilState(atoms.sliderModal);
  const setMenuOpen = useSetRecoilState(atoms.menu);

  const {first_name, last_name} = user;

  const handleClick = e => {
    if (!mobile && node && !node.current.contains(e.target)) {
      onOutsideClick(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const renderDeactivateModal = () => {
    setInteractiveModal(
      <AbandonWrapper>
        <Abandon
          title="Are you sure you want to delete this account?"
          description="You will lose all saved information."
          onDiscardTitle="Yes, delete account"
          onKeepTitle="Nevermind"
          onDiscard={async () => {
            LookSeeServices.deactivateAccount();
            await PingIdentityService.logout();
            setLogout(true);
            setUser(null);
            setInteractiveModal(null);
            history.push('/');
          }}
          onKeep={() => setInteractiveModal(null)}
          pageSection="manage account"
        />
      </AbandonWrapper>,
    );
  };

  const renderBody = (onChangeAccountDetails, onDeleteAccount) => {
    if (screen === 'change-account-details') {
      return (
        <ChangeAccountDetails mobile onSuccess={() => setSliderModal(null)} />
      );
    } else {
      return (
        <>
          <AvatarWrapper>
            <Avatar>{`${first_name[0]}${last_name[0]}`}</Avatar>
            <div>
              <Fullname>{`${first_name} `}</Fullname>
              <Fullname>{last_name}</Fullname>
            </div>
          </AvatarWrapper>
          <ManageListItem onClick={onChangeAccountDetails}>
            <ListIcon src={theme.images.pencilIcon} />
            <ListText>Change Account Details</ListText>
          </ManageListItem>
          <ManageListItem onClick={onDeleteAccount}>
            <ListIcon src={theme.images.trashBinIcon} />
            <ListText>Delete Account</ListText>
          </ManageListItem>
        </>
      );
    }
  };

  if (mobile) {
    return (
      <>
        <MobileHeader>
          <MobileIcon
            alt="close"
            src={
              screen === 'change-account-details'
                ? theme.images.chevronLeft
                : theme.images.closeIcon
            }
            onClick={() => {
              if (screen === 'change-account-details') {
                setScreen('');
              } else {
                setSliderModal(null);
                setMenuOpen(true);
              }
            }}
          />
          <MobileTitle>{STRINGS.MANAGE_ACCOUNT}</MobileTitle>
        </MobileHeader>
        <MobileBody>
          {renderBody(
            () => setScreen('change-account-details'),
            renderDeactivateModal,
          )}
        </MobileBody>
      </>
    );
  } else {
    return (
      <UnorderedList ref={node}>
        <Header>{STRINGS.MANAGE_ACCOUNT}</Header>
        {renderBody(
          () => setInteractiveModal(<ChangeAccountDetails />),
          renderDeactivateModal,
        )}
      </UnorderedList>
    );
  }
};

ManageAccount.prototype = {
  onOutsideClick: PropTypes.func,
  mobile: PropTypes.bool,
};

export default ManageAccount;
