import {Step, Stepper} from '@material-ui/core';
import styled from 'styled-components';

export const StyledStep = styled(Step)`
  .MuiStepIcon-root.MuiStepIcon-active {
    color: ${({theme}) => theme.colors.secondary};

    .MuiStepIcon-text {
      fill: ${({theme}) => theme.colors.black};
    }
  }

  .MuiStepIcon-root.MuiStepIcon-completed {
    color: ${({theme}) => theme.colors.success};
  }

  .MuiStepLabel-label {
    &.MuiStepLabel-alternativeLabel {
      color: ${({theme}) => theme.colors.grayFive};
    }
    &.MuiStepLabel-active {
      font-weight: 600;
      color: ${({theme}) => theme.colors.black};
    }
  }
`;

export const StyledStepper = styled(Stepper)`
  &.MuiStepper-root {
    background-color: transparent;
  }
`;
