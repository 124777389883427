import {atom} from 'recoil';

import Style from '../../Style';
import {DEFAULT_USER_STATE, TAB_OPTIONS} from './constants';

export default {
  theme: atom({
    key: 'theme',
    default: Style,
  }),
  user: atom({
    key: 'user',
    default: DEFAULT_USER_STATE,
  }),
  menu: atom({
    key: 'menu',
    default: false,
  }),
  termsAndConditions: atom({
    key: 'termsAndConditions',
    default: false,
  }),
  onboard: atom({
    key: 'onboard',
    default: 0,
  }),
  newsfeed: atom({
    key: 'newsfeed',
    default: [],
  }),
  notifications: atom({
    key: 'notifications',
    default: [],
  }),
  notificationsTotal: atom({
    key: 'notificationsTotal',
    default: 0,
  }),
  notificationsLoading: atom({
    key: 'notificationsLoading',
    default: true,
  }),
  messages: atom({
    key: 'messages',
    default: [],
  }),
  selectedMessage: atom({
    key: 'selectedMessage',
    default: null,
  }),
  selectedMessageOrder: atom({
    key: 'selectedMessageOrder',
    default: {},
  }),
  unreadMessages: atom({
    key: 'unreadMessages',
    default: 0,
  }),

  loginOrSignup: atom({
    key: 'loginOrSignup',
    default: null,
  }),

  // Global search Autosuggest state
  addressSuggestions: atom({
    key: 'addressSuggestions',
    default: [],
  }),

  // Modals
  showVerification: atom({
    key: 'showVerification',
    default: false,
  }),
  errorModal: atom({
    key: 'errorModal',
    default: {
      message: '',
      visible: false,
    },
  }),
  loadingModal: atom({
    key: 'loadingModal',
    default: false,
  }),
  interactiveModal: atom({
    key: 'interactiveModal',
    default: null,
  }),
  sliderModal: atom({
    key: 'sliderModal',
    default: null,
  }),
  burgerMenu: atom({
    key: 'burgerMenu',
    default: false,
  }),
  homeMenu: atom({
    key: 'homeMenu',
    default: false,
  }),
  solarPackagesTab: atom({
    key: 'solarPackagesTab',
    default: TAB_OPTIONS[0],
  }),
  logout: atom({
    key: 'logout',
    default: false,
  }),
};
