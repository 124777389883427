import React, {useRef, useState} from 'react';

import {Box, Grid} from '@material-ui/core';
import {useMountEffect} from '@react-hookz/web';
import sha256 from 'crypto-js/sha256';
import {Field, Form, Formik} from 'formik';
import {useHistory} from 'react-router-dom';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {useTheme} from 'styled-components';
import * as Yup from 'yup';

import atoms from '../../atoms';
import {FORM_REQUIREMENTS} from '../../atoms/app/constants';
import {RoundedButton} from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import CircularLoader from '../../components/CircularLoader';
import Currency from '../../components/Currency';
import EnsureDetailsAreCorrectAlert from '../../components/EnsureDetailsAreCorrectAlert';
import {BannerImage} from '../../components/FormHeading/styles';
import FormikTextField from '../../components/FormikTextField';
import GenericErrorModal from '../../components/GenericErrorModal';
import TermsAndConditionsAndMore from '../../components/TermsAndConditionsAndMore';
import {
  BodyMed,
  BodyReg,
  Header2,
  Header4,
} from '../../components/newTypography';
import useDetectMobile from '../../hooks/useDetectMobile';
import {solarLoan} from '../../navigation/routes';
import {APPLICATION_ID} from '../../services/HttpServices';
import {logger} from '../../services/Logger';
import LookSeeServices from '../../services/LookSeeServices';
import {
  sendComponentAnalytics,
  updateApplicationData,
} from '../../utils/Analytics';

const phoneRegex =
  /^0(\s*\d){9}$|^0\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d$/;
const validationSchema = Yup.object().shape({
  firstName: Yup.string().min(2).required(FORM_REQUIREMENTS.NAME_IS_REQUIRED),
  lastName: Yup.string().min(2).required(FORM_REQUIREMENTS.SURNAME_IS_REQUIRED),
  idNumber: Yup.string()
    .max(13, 'ID number must be at most 13 characters')
    .matches(/\d{13}/, 'ID number does not match the South African standard')
    .required('ID number is required'),
  email: Yup.string().email().required(FORM_REQUIREMENTS.EMAIL_IS_REQUIRED),
  phoneNumber: Yup.string()
    .required('Required')
    .matches(phoneRegex, 'Invalid phone number provided'),
  income: Yup.number()
    .typeError('You must specify a number')
    .moreThan(7999.99, 'Minimum qualifying gross monthly income is R8000')
    .required('Gross income is required'),
  totalExpenses: Yup.number()
    .typeError('You must specify a number')
    .positive('Total expenses must be positive')
    .required('Total expenses is required'),
  readConsent: Yup.boolean()
    .required(
      'You must consent that you have read and understood the content of the privacy statement.',
    )
    .isTrue(),
  debtReviewConsent: Yup.boolean()
    .required('You must consent you are not in insolvent or under debt review')
    .isTrue(),
});

export default function YourDetails() {
  const theme = useTheme();
  const isMobile = useDetectMobile();
  const user = useRecoilValue(atoms.user);
  const [genericErrorModal, setGenericErrorModal] = useState({});
  const history = useHistory();
  const formStartedRef = useRef(false);

  const formName = 'your details';
  const initialValues = {
    firstName: user?.first_name || '',
    lastName: user?.last_name || '',
    idNumber: '',
    email: user?.email || '',
    phoneNumber: '',
    income: '',
    readConsent: false,
    debtReviewConsent: false,
    totalExpenses: '',
  };
  const formFields = [
    <Field
      component={FormikTextField}
      label="First name"
      name="firstName"
      placeholder="First name"
      key="first_name"
    />,
    <Field
      component={FormikTextField}
      label="Last name"
      name="lastName"
      placeholder="Last name"
      key="last_name"
    />,
    <Field
      component={FormikTextField}
      label="ID number"
      name="idNumber"
      placeholder="ID number"
      key="id_number"
    />,
    <Field
      component={FormikTextField}
      label="Email address"
      name="email"
      placeholder="Email address"
      key="email_address"
    />,
    <Field
      component={FormikTextField}
      label="Mobile number"
      name="phoneNumber"
      placeholder="Mobile number"
      key="mobile_number"
    />,
    <Field
      component={FormikTextField}
      name="income"
      label="Gross monthly income"
      placeholder="Gross monthly income (before deductions)"
      key="gross_monthly_income"
      InputProps={{
        inputComponent: Currency,
      }}
    />,
    <Field
      component={FormikTextField}
      name="totalExpenses"
      label="Total expenses"
      placeholder="Total expenses"
      key="total_expenses"
      InputProps={{
        inputComponent: Currency,
      }}
    />,
  ];

  const analyticsData = {
    applicationProduct: 'Solar Loan',
    applicationMethod: 'online',
    applicationName: 'application: pbb | solar loan',
    applicationID: `${APPLICATION_ID}:${
      user?.id ? user.id : new Date().getTime()
    }`,
  };
  useMountEffect(() => {
    updateApplicationData({
      ...analyticsData,
      applicationStart: true,
      applicationComplete: false,
    });
    sendComponentAnalytics(
      {
        formStatus: '',
        formName,
      },
      'globalApplicationStart',
    );
  });

  const setCreditInfo = useSetRecoilState(atoms.creditInformation);

  const performCreditCheck = async values => {
    try {
      const emailHash = sha256(values.email).toString();
      const {
        data: {
          finDecisionCod,
          finOffMaxLimAmnt,
          finOffInstlmtMaxPer,
          finOffTermMaxMonths,
          repaymentAmount,
          solarLoanLeadId,
        },
      } = await LookSeeServices.getSolarLoanCreditCheck({
        ...values,
        analyticsUserId: emailHash,
        channelIndicator: 'LookSee',
      });
      setCreditInfo({
        decisionCode: finDecisionCod,
        maxLoanAmount: finOffMaxLimAmnt,
        interestRate: finOffInstlmtMaxPer,
        repaymentTerm: finOffTermMaxMonths,
        monthlyRepayment: repaymentAmount,
        solarLoanLeadId: solarLoanLeadId,
      });
      sendComponentAnalytics(
        {
          formStatus: 'complete',
          formName,
          formisSubmitted: true,
          hashed_msisdn: sha256(values.phoneNumber).toString(),
          email_sha256: sha256(values.email).toString(),
        },
        'globalFormComplete',
      );
      sendComponentAnalytics(
        {
          application: {
            ...analyticsData,
            applicationStart: false,
            applicationComplete: true,
          },
        },
        'globalApplicationComplete',
      );

      if (finDecisionCod === 'D') {
        history.push(`${solarLoan}/you-do-not-qualify`);
      } else if (finDecisionCod === 'A') {
        history.push(`${solarLoan}/congratulations`);
      }
    } catch (error) {
      logger.error(error);
      setGenericErrorModal({message: error.message, visible: true});
    }
  };

  const onValueChange = () => {
    if (!formStartedRef.current) {
      formStartedRef.current = true;
      sendComponentAnalytics({formStatus: '', formName}, 'globalFormStart');
    }
  };

  function getBannerImageBig() {
    return (
      <BannerImage
        display="flex"
        flexDirection="column"
        justifyContent="center"
        gradient={theme.gradient.lightBlueGradient}
        imageUrl={theme.images.solarLoanYourDetails}
        py="5vh"
        px="5vw">
        <Box>
          <Header2 inline color={theme.colors.white} justifyContent="center">
            Provide your details to see if you qualify for a Solar Loan
          </Header2>
        </Box>
      </BannerImage>
    );
  }

  function getBannerImageSmall() {
    return (
      <BannerImage
        display="flex"
        flexDirection="column"
        justifyContent="center"
        py="3vh"
        px="5vw">
        <Box>
          <Header2 inline color={theme.colors.black} justifyContent="center">
            Provide your details to see if you qualify for a Solar Loan
          </Header2>
        </Box>
      </BannerImage>
    );
  }

  function getWhichBannerImage() {
    return isMobile ? getBannerImageSmall() : getBannerImageBig();
  }

  return (
    <Box>
      {getWhichBannerImage()}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={performCreditCheck}
        data-frmcontainer={formName}
        id={formName}
        name={formName}>
        {({isSubmitting, isValid, dirty}) => (
          <Box
            onChange={onValueChange}
            component={Form}
            my="2%"
            display="flex"
            flexDirection="column"
            alignItems="center">
            {formFields.map((item, index) => (
              <Box
                key={index}
                my="1%"
                width={{xs: '90%', sm: '70%', md: '50%'}}>
                {item}
              </Box>
            ))}
            <Box width={{xs: '90%', sm: '70%', md: '50%'}} mb="2%" mt="2%">
              <EnsureDetailsAreCorrectAlert />
            </Box>
            <Box width={{xs: '90%', sm: '70%', md: '50%'}} mb="2%">
              <TermsAndConditionsAndMore />
            </Box>
            <Box width={{xs: '90%', sm: '70%', md: '50%'}} mb="2%">
              <BodyMed
                fontWeight="600"
                lineHeight="0"
                color={theme.colors.tertiary}>
                I confirm that:
              </BodyMed>
              <Field
                as={Checkbox}
                name="readConsent"
                key="readConsent"
                text={
                  <BodyMed color={theme.colors.tertiary}>
                    I have read, marked, and understood the contents of the
                    Privacy Statement.
                  </BodyMed>
                }
              />
              <Field
                as={Checkbox}
                name="debtReviewConsent"
                key="debtReviewConsent"
                text={
                  <BodyMed color={theme.colors.tertiary}>
                    I am not insolvent, under debt review (current or applied
                    for), administration or sequestration.
                  </BodyMed>
                }
              />
            </Box>
            <Box
              component={Grid}
              container
              display="flex"
              justifyContent="center">
              <Grid item xs={5} sm={3}>
                <RoundedButton
                  data-intent="navigational"
                  data-scope="button"
                  data-text="provide your details for a solar loan | back button click"
                  data-id="link_content"
                  width="90%"
                  text="Back"
                  backgroundColor={theme.colors.white}
                  textColor={theme.colors.tertiary}
                  borderColor={theme.colors.tertiary}
                  onClick={() => history.goBack()}
                />
              </Grid>
              <Grid item xs={5} sm={3}>
                <RoundedButton
                  data-intent="confirmational"
                  data-scope="button"
                  data-text="provide your details for a solar loan | submit button click"
                  data-id="link_content"
                  width="90%"
                  text="Submit"
                  type="submit"
                  disabled={isSubmitting || !isValid || !dirty}
                  textColor={theme.colors.white}
                  backgroundColor={theme.colors.tertiary}
                />
              </Grid>
            </Box>
            {isSubmitting && !genericErrorModal.visible && (
              <CircularLoader open={isSubmitting} />
            )}
          </Box>
        )}
      </Formik>
      <GenericErrorModal
        open={genericErrorModal.visible}
        onClose={setGenericErrorModal}
        onContinue={setGenericErrorModal}
        messageBody={
          <Box>
            <Header4 fontWeight={600}>Something Went Wrong.</Header4>
            <BodyReg>{genericErrorModal.message}</BodyReg>
          </Box>
        }
      />
    </Box>
  );
}
