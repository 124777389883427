import theme from '../../Style';
import {
  RED_MESSAGE,
  YELLOW_MESSAGE,
  PENDING_PROVIDER_CANCELLATION_CONFIRMATION,
  PENDING_CLIENT_COMPLETION_CONFIRMATION,
} from './constants';

export const bannerMap = {
  [PENDING_PROVIDER_CANCELLATION_CONFIRMATION]: {
    background: theme.colors.red100,
    message: RED_MESSAGE,
  },
  [PENDING_CLIENT_COMPLETION_CONFIRMATION]: {
    background: theme.colors.yellow400,
    message: YELLOW_MESSAGE,
  },
};
