import React, {useCallback, useState} from 'react';

import {Box, Dialog, DialogTitle} from '@material-ui/core';
import {useConditionalEffect, useLocalStorageValue} from '@react-hookz/web';
import {useRecoilValue} from 'recoil';
import {useTheme} from 'styled-components';

import atoms, {STRINGS} from '../../atoms';
import {useFeaturesContext} from '../../provider/features';
import PingIdentityService from '../../services/PingIdentityServices';
import {RoundedButton} from '../Button';
import {BodySm, Header4} from '../newTypography';

export default function PingRedirectNotificationModal() {
  const theme = useTheme();
  const features = useFeaturesContext();
  const shouldNotifyOfPing = useLocalStorageValue('looksee-notify-ping', {
    defaultValue: {register: true, login: true},
  });

  const [pingModalMode, setPingModalMode] = useState(null);

  const loginOrSignup = useRecoilValue(atoms.loginOrSignup);

  const redirectToPing = useCallback(shouldRegister => {
    PingIdentityService.redirectToLogin(shouldRegister);
  }, []);

  const showPingNotification = useCallback(
    shouldRegister => {
      const localStorageKey = shouldRegister ? STRINGS.REGISTER : STRINGS.LOGIN;
      const featureKey = shouldRegister
        ? 'pingRegistrationModal'
        : 'pingLoginModal';

      if (features[featureKey] && shouldNotifyOfPing.value[localStorageKey]) {
        const newValue = {
          ...shouldNotifyOfPing.value,
          [localStorageKey]: false,
        };
        shouldNotifyOfPing.set(newValue);
        setPingModalMode(shouldRegister ? STRINGS.REGISTER : STRINGS.LOGIN);
      } else {
        redirectToPing(shouldRegister);
      }
    },
    [features, shouldNotifyOfPing, redirectToPing],
  );

  useConditionalEffect(
    () => {
      showPingNotification(loginOrSignup !== STRINGS.LOGIN);
    },
    [loginOrSignup],
    [loginOrSignup !== null],
  );

  const pingModalBody =
    pingModalMode === STRINGS.LOGIN ? (
      <>
        <BodySm color={theme.colors.darkPurple} inline>
          We&apos;ve changed our login system to bring LookSee up to world class
          security standards.
        </BodySm>
        <BodySm color={theme.colors.darkPurple}>
          On the sign in screen, please use your existing email address and
          password to sign in.
        </BodySm>
      </>
    ) : (
      <Box paddingBottom="10px">
        <BodySm color={theme.colors.darkPurple} inline>
          Anyone can sign up for a LookSee account, however if you are already a
          Standard Bank online banking or Mobile App customer, please use those
          details to sign in.
        </BodySm>
      </Box>
    );

  return (
    <Dialog
      open={!!pingModalMode}
      aria-labelledby="ping-dialog-title"
      maxWidth="xs">
      <DialogTitle id="ping-dialog-title">
        <Header4 color={theme.colors.darkPurple} inline>
          Please note
        </Header4>
      </DialogTitle>
      <Box paddingX="24px" paddingBottom="16px">
        {pingModalBody}
        <Box display="flex" justifyContent="center">
          <RoundedButton
            backgroundColor={theme.colors.darkPurple}
            onClick={() => redirectToPing(pingModalMode === STRINGS.REGISTER)}
            text="Continue"
          />
        </Box>
      </Box>
    </Dialog>
  );
}
