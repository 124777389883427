import React, {useState} from 'react';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import {useTheme} from 'styled-components';

import {
  Container,
  Label,
  ToolTipHead,
  ToolTipBody,
  Wrapper,
  Input,
  IconWrapper,
  VisibilityWrapper,
  IconShowWrapper,
  ErrorText,
} from './styles';

const TextField = ({
  id,
  disabled,
  label,
  type,
  value,
  onChange,
  containerStyle,
  labelStyle,
  inputStyle,
  placeholder,
  fontSize,
  inputName,
  error,
  displayErrorText,
  icon,
  passwordVisibility,
  onVisibilityClick,
  wrapperStyles,
  onFocus,
  onBlur,
  autocomplete,
  borderColor,
  autoFocus,
  customIconStyle,
  customIconClick,
  infoToolTip,
  pollBorder,
}) => {
  const theme = useTheme();
  const [focused, setFocused] = useState(false);

  const changeFocus = e => {
    onBlur?.(e);
    setFocused(false);
  };

  const getContent = dataTip => {
    return (
      <>
        {dataTip?.head && <ToolTipHead>{dataTip.head}</ToolTipHead>}
        <ToolTipBody>{dataTip?.body}</ToolTipBody>
      </>
    );
  };
  return (
    <Container style={containerStyle}>
      <Label htmlFor={id} style={labelStyle}>
        {label}
        {(infoToolTip?.head || infoToolTip?.body) && (
          <>
            <img
              src={theme.images.info}
              data-for="textField"
              data-tip={infoToolTip}
              style={{
                marginLeft: '8px',
                height: '10px',
                width: '10px',
                ...infoToolTip.styles,
              }}
            />
            <ReactTooltip
              id="textField"
              effect="solid"
              arrowColor={theme.colors.secondary}
              className="toolTip"
              place="bottom"
              getContent={() => getContent(infoToolTip)}
            />
          </>
        )}
      </Label>
      <Wrapper
        focused={focused}
        error={error}
        borderColor={borderColor}
        wrapperStyles={wrapperStyles}
        pollBorder={pollBorder}>
        <Input
          id={id}
          disabled={disabled}
          autoFocus={autoFocus}
          autocomplete={autocomplete}
          name={inputName}
          value={value}
          type={type || 'text'}
          style={inputStyle}
          onChange={onChange}
          placeholder={placeholder}
          fontSize={fontSize}
          onFocus={() => {
            setFocused(true);
            onFocus?.();
          }}
          onBlur={changeFocus}
        />
        <IconWrapper>
          {onVisibilityClick ? (
            <VisibilityWrapper
              htmlFor={id}
              onClick={onVisibilityClick}
              focused={focused}>
              {passwordVisibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </VisibilityWrapper>
          ) : (
            <IconShowWrapper
              src={icon}
              onClick={customIconClick}
              style={customIconStyle}
            />
          )}
        </IconWrapper>
      </Wrapper>
      {displayErrorText && <ErrorText>{error}</ErrorText>}
    </Container>
  );
};

TextField.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  inputName: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  containerStyle: PropTypes.shape({}),
  labelStyle: PropTypes.shape({}),
  inputStyle: PropTypes.shape({}),
  displayErrorText: PropTypes.bool,
  icon: PropTypes.node,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

export default TextField;
