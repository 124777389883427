import React from 'react';

import {Wrapper, ActionRequired} from './styles';

function QuoteBanner({state, onAccept, onDecline}) {
  if (state === 'Quote received') {
    return (
      <Wrapper>
        <div>
          {'Action required: '}
          <ActionRequired onClick={onAccept}>Accept</ActionRequired>
          {' or '}
          <ActionRequired onClick={onDecline}>Decline</ActionRequired>
          {' quote'}
        </div>
      </Wrapper>
    );
  }

  return null;
}

export default QuoteBanner;
