import React from 'react';

import {Box, Card, CardContent} from '@material-ui/core';

import {Header5} from '../../components/newTypography';

export default function SolarScoreCard({title, bodyContent, image}) {
  return (
    <Box
      height="100%"
      width="100%"
      component={Card}
      display="flex"
      flexDirection="column"
      justifyContent="space-between">
      <Box component={CardContent} flex={1}>
        <Box mb="8px">
          <Header5 inline>{title}</Header5>
        </Box>
        {bodyContent}
      </Box>
      <Box component="img" width="100%" src={image} />
    </Box>
  );
}
