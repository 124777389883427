import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 32px;
  font-weight: 600;
  text-align: center;
`;

export const Container = styled.div`
  padding-bottom: 50px;
`;
