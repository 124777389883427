import React, {useEffect, useState} from 'react';

import {useConditionalEffect, useMountEffect} from '@react-hookz/web';
import sha256 from 'crypto-js/sha256';
import {Helmet} from 'react-helmet';
import {useHistory, useLocation} from 'react-router-dom';
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import {ThemeProvider} from 'styled-components';

import atoms, {DEFAULT_USER_STATE} from './atoms';
import Navigation from './navigation';
import {productsServicesUrl} from './navigation/routes';
import {FeaturesContext} from './provider/features';
import HttpServices, {APPLICATION_ID} from './services/HttpServices';
import {logger} from './services/Logger';
import LookSeeServices from './services/LookSeeServices';
import PingIdentityService from './services/PingIdentityServices';
import {
  satelliteTrackCode,
  sendComponentAnalytics,
  sendPageAnalyticsByPath,
} from './utils/Analytics';
import {fetchConfig, initialiseFirebase} from './utils/Firebase';

function App() {
  const location = useLocation();
  const history = useHistory();
  const setErrorModal = useSetRecoilState(atoms.errorModal);

  const [loaded, setLoaded] = useState(false);
  const [features, setFeatures] = useState({});
  const [initialSnapshot, setInitialSnapshot] = useState();
  const [logout, setLogout] = useRecoilState(atoms.logout);

  const theme = useRecoilValue(atoms.theme);
  const [user, setUser] = useRecoilState(atoms.user);

  const captureInitialState = useRecoilCallback(
    ({snapshot}) =>
      () => {
        setInitialSnapshot(snapshot);
      },
    [],
  );

  const resetInitialState = useRecoilCallback(
    ({gotoSnapshot}) =>
      () => {
        gotoSnapshot(initialSnapshot);
      },
    [initialSnapshot],
  );

  useMountEffect(() => {
    logger.info('Application started');
    captureInitialState();
  });

  useConditionalEffect(
    async () => {
      resetInitialState();
    },
    [user?.id],
    [initialSnapshot, !user],
  );

  useConditionalEffect(
    () => {
      sendPageAnalyticsByPath(location.pathname);
    },
    [location, window._satellite?.track],
    [window._satellite?.track],
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const handleUserLoginSuccess = () => {
    satelliteTrackCode('userLogin');
    sendComponentAnalytics({formName: 'login form'}, 'globalFormComplete');
    window.dataLayer.userLoginSuccess = 'true';
    const item = localStorage.getItem('redirect-url-after-login');
    if (item) {
      localStorage.removeItem('redirect-url-after-login');
      try {
        const propertyInspection = JSON.parse(item);
        if (propertyInspection?.pathname) {
          history.push(propertyInspection);
        }
      } catch (e) {
        history.push(item);
      }
    } else if (
      history.location &&
      history.location.state &&
      history.location.state.from
    ) {
      const state = {...history.location.state};
      delete state.from;
      history.replace({...history.location, state});
    } else {
      history.push(productsServicesUrl);
    }
  };

  const loginUser = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');
    const state = queryParams.get('state');

    if (code && state) {
      try {
        const result = await PingIdentityService.login({code, state});
        setUser(result);
      } catch (e) {
        setErrorModal({
          message: 'Login Error',
          visible: true,
          onPrimaryClick: () => {
            history.push(productsServicesUrl);
          },
        });
      }

      handleUserLoginSuccess();
    }
  };

  const loadUser = async () => {
    try {
      if (!HttpServices.getToken()) {
        throw new Error('No token');
      }
      const userData = await LookSeeServices.getUser();
      setUser(userData);
      setLogout(false);
    } catch (e) {
      if (!logout) {
        setUser(DEFAULT_USER_STATE);
      }
      window.dataLayer.loginStatus = 'guest';
    } finally {
      setLoaded(true);
    }
  };

  const getFeatures = async config => {
    const featuresFlags = await fetchConfig(config);
    setFeatures(featuresFlags);
  };

  useEffect(() => {
    const config = initialiseFirebase();
    if (config) {
      getFeatures(config);
    }
  }, []);

  useEffect(() => {
    if (user) {
      window.dataLayer.loginStatus = 'member';
      window.dataLayer.userID = user.id;
      window.dataLayer.applicationStart = true;
      window.dataLayer.applicationComplete = false;
      window.dataLayer.application.applicationID = `${APPLICATION_ID}:${user.id}`;
      window.dataLayer.email_sha256 = sha256(user.email).toString();
    }
  }, [user]);

  useEffect(() => {
    const path = (/#!(\/.*)$/.exec(history.location.hash) || [])[1];
    if (path) {
      history.replace(path);
    }
  }, []);

  useMountEffect(() => {
    loadUser();
    loginUser();
  });

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <link rel="canonical" href={window.location.href.split('?')[0]} />
      </Helmet>
      <FeaturesContext.Provider value={features}>
        {loaded ? <Navigation /> : null}
      </FeaturesContext.Provider>
    </ThemeProvider>
  );
}

export default App;
