import React from 'react';

import {Box, Card, Container, Divider} from '@material-ui/core';
import {useMountEffect} from '@react-hookz/web';
import {useHistory} from 'react-router-dom';
import {useTheme} from 'styled-components';

import {MIN_PAGE_HEIGHT} from '../../navigation/styles';
import {
  dataLayerVariables,
  sendComponentAnalytics,
} from '../../utils/Analytics';
import {RoundedButton} from '../Button';
import {BodyMed, Header3, Subtitle2} from '../newTypography';
import BulletedItem from './bulletedItem';

export default function NextStepsQualify({
  pageName,
  nextSteps,
  heading = 'Your application has been submitted',
  subHeading,
}) {
  const theme = useTheme();
  const history = useHistory();

  useMountEffect(() => {
    sendComponentAnalytics(
      {
        ...dataLayerVariables({
          pageCategory: 'Products and services',
          pageName: `${pageName}`,
          pageSubSection1: `${pageName}`,
        }),
      },
      'globalVirtualPageView',
    );
  });

  return (
    <Box minHeight={MIN_PAGE_HEIGHT} bgcolor={theme.colors.browserBackground}>
      <Container>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          paddingY="5%"
          paddingX="12%">
          <Card
            style={{padding: '5%', maxWidth: '450px', marginBottom: '2rem'}}>
            <Box
              display="flex"
              flexDirection="column"
              flex={1}
              textAlign="center"
              justifyContent="center"
              alignItems="center">
              <img alt="send-cloud" src={theme.images.nextSteps} />
              <Box paddingTop={2} paddingBottom="1%" marginBottom="5%">
                <Header3
                  color={theme.colors.darkGreyText}
                  m="2%"
                  fontSize="24px"
                  lineHeight="32px">
                  {heading}
                </Header3>
                {subHeading && (
                  <Subtitle2
                    m="2%"
                    fontSize="16px"
                    lineHeight="20px"
                    style={{marginTop: '7%'}}>
                    {subHeading}
                  </Subtitle2>
                )}
              </Box>
              <Box display="flex" justifyContent="center" marginBottom="5%">
                <Divider variant="middle" style={{width: '200px'}} />
              </Box>
              <BodyMed
                fontWeight={700}
                fontSize="18px"
                lineHeight="24px"
                color={theme.colors.darkGreyText}
                m="1%">
                What happens next?
              </BodyMed>
              <Box px={1} textAlign="left" marginTop="3%">
                {nextSteps.map((step, index) => (
                  <BulletedItem
                    key={index}
                    title={step.title}
                    body={step.body}
                    number={index + 1}
                  />
                ))}
              </Box>
            </Box>
          </Card>
          <RoundedButton
            width="295px"
            data-id="link_content"
            data-text="Qualify for a Solar Loan Next Steps | Home button click"
            data-scope="button"
            data-intent="navigational"
            backgroundColor={theme.colors.blueMobileNav}
            textColor={theme.colors.white}
            text="Home"
            onClick={() => history.push('/')}
            style={{marginBottom: '2%'}}
          />
        </Box>
      </Container>
    </Box>
  );
}
