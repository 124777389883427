import React, {useEffect} from 'react';

import {Box, StepButton, StepLabel} from '@material-ui/core';
import {useSet} from '@react-hookz/web';
import PropTypes from 'prop-types';

import useDetectMobile from '../../hooks/useDetectMobile';
import {BodySm} from '../newTypography';
import {StyledStep, StyledStepper} from './styles';

export default function Stepper({
  steps,
  activeStepIndex,
  getStepCompleted,
  onStepClick,
}) {
  const isMobile = useDetectMobile();
  const touchedSteps = useSet();

  useEffect(() => {
    touchedSteps.add(activeStepIndex);
  }, [activeStepIndex]);

  if (isMobile) {
    return (
      <Box py="10px">
        <BodySm>
          Step {activeStepIndex + 1} of {steps.length}
        </BodySm>
      </Box>
    );
  }

  return (
    <StyledStepper alternativeLabel activeStep={activeStepIndex} nonLinear>
      {steps.map((step, index) => (
        <StyledStep key={step.title}>
          <StepButton
            disabled={!touchedSteps.has(index)}
            completed={getStepCompleted(step)}
            onClick={() => onStepClick(index)}>
            <StepLabel>{step.title}</StepLabel>
          </StepButton>
        </StyledStep>
      ))}
    </StyledStepper>
  );
}

Stepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node.isRequired,
    }),
  ).isRequired,
  activeStepIndex: PropTypes.number.isRequired,
  getStepCompleted: PropTypes.func.isRequired,
  onStepClick: PropTypes.func.isRequired,
};
