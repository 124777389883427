import React from 'react';

import {CheckboxWrapper, TermText} from './styles';

function Checkbox({error, value, onChange, onClick, text = '', ...props}) {
  return (
    <TermText>
      <CheckboxWrapper
        type="checkbox"
        error={error}
        checked={!!value}
        value={value}
        onChange={onChange}
        onClick={onClick?.()}
        {...props}
      />
      {text}
    </TermText>
  );
}

export default Checkbox;
