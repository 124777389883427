import {useCallback} from 'react';

import {useHistory} from 'react-router-dom';
import {useRecoilValue} from 'recoil';

import atoms from '../atoms';
import {productsServicesUrl} from '../navigation/routes';

function getCategoryUrl(categoryCode) {
  return `${productsServicesUrl}/${encodeURIComponent(categoryCode)}`;
}

function findCategory(categoryId, categories) {
  return categories.find(c => c.categoryId === categoryId);
}

export default function useProductsServicesNavigation() {
  const history = useHistory();
  const categories = useRecoilValue(atoms.marketplaceCategories);

  const getSubcategoryUrl = useCallback(
    subcategory => {
      const {categoryCode, parentCategoryId} = subcategory;
      const category = findCategory(parentCategoryId, categories);
      return `${getCategoryUrl(category)}/${encodeURIComponent(categoryCode)}`;
    },
    [categories],
  );

  const getProductUrl = useCallback(
    product => {
      const {categoryId, itemCode} = product;
      const subcategory = findCategory(
        categoryId,
        categories.map(s => s.subcategories).flat(),
      );
      return `${getSubcategoryUrl(subcategory)}/${encodeURIComponent(
        itemCode,
      )}`;
    },
    [getSubcategoryUrl, categories],
  );

  const goToCategory = useCallback(
    category => {
      history.push(getCategoryUrl(category));
    },
    [history],
  );

  const goToSubcategory = useCallback(
    (subcategoryCode, categoryCode) => {
      history.push(
        `${getCategoryUrl(categoryCode)}/${encodeURIComponent(
          subcategoryCode,
        )}`,
      );
    },
    [getCategoryUrl, history],
  );

  const goToProduct = useCallback(
    product => {
      history.push(getProductUrl(product));
    },
    [getProductUrl, history],
  );

  return {
    goToCategory,
    goToSubcategory,
    goToProduct,
    getCategoryUrl,
    getSubcategoryUrl,
    getProductUrl,
  };
}
