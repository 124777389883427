import * as Yup from 'yup';

import {FORM_REQUIREMENTS, STRINGS} from '../../atoms/app/constants';

const phoneRegex =
  /^0(\s*\d){9}$|^0\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d$/;
const NONE_OF_THE_ABOVE = 'none_of_the_above';
const PRIVATE = 'private';
const PRESTIGE = 'prestige';

const positiveNumber = Yup.number().positive(FORM_REQUIREMENTS.POSITIVE_NUMBER);
const requiredString = Yup.string().required(FORM_REQUIREMENTS.REQUIRED);

const basicContactInformationValidation = {
  firstName: Yup.string().min(2).required(FORM_REQUIREMENTS.NAME_IS_REQUIRED),
  lastName: Yup.string().min(2).required(FORM_REQUIREMENTS.SURNAME_IS_REQUIRED),
  email: Yup.string().email().required(FORM_REQUIREMENTS.EMAIL_IS_REQUIRED),
  phone: Yup.string()
    .required('Required')
    .matches(phoneRegex, 'Invalid phone number provided'),
  customerType: Yup.string()
    .oneOf([PRESTIGE, PRIVATE, NONE_OF_THE_ABOVE])
    .required('Please indicate your account type from the options provided.'),
  dataConsent: Yup.boolean()
    .required(FORM_REQUIREMENTS.CONSENT_IS_REQUIRED)
    .isTrue(),
};

const validationSchemas = {
  contactInformation: Yup.object().shape({
    ...basicContactInformationValidation,
  }),

  yourPower: Yup.object().shape({
    monthlyAverageBill: positiveNumber.required(FORM_REQUIREMENTS.REQUIRED),
    installationAddress: requiredString,
  }),

  packages: Yup.object().shape({
    packageId: requiredString,
    recommendedPackageId: requiredString,
    proposalUri: requiredString,
    recommendedPackage: requiredString,
    package: requiredString,
    systemSize: requiredString,
  }),
};

const validationSchemaFinancing = {
  ...validationSchemas,
  contactInformation: Yup.object().shape({
    ...basicContactInformationValidation,
    idNumber: Yup.string()
      .max(13, 'ID number must be at most 13 characters')
      .matches(/\d{13}/, 'ID number does not match the South African standard')
      .required('ID number is required'),
    income: Yup.number()
      .typeError('You must specify a number')
      .moreThan(7999.99, 'Minimum qualifying gross monthly income is R8000')
      .required('Gross income is required'),
    totalExpenses: Yup.number()
      .typeError('You must specify a number')
      .positive('Total expenses must be positive')
      .required('Total expenses is required'),
    readConsent: Yup.boolean()
      .required(
        'You must consent that you have read and understood the content of the privacy statement.',
      )
      .isTrue(),
    debtReviewConsent: Yup.boolean()
      .required(
        'You must consent you are not in insolvent or under debt review',
      )
      .isTrue(),
  }),
};

const validationSchemaNoFinancing = {
  ...validationSchemas,
  contactInformation: Yup.object().shape({
    ...basicContactInformationValidation,
  }),
};

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  monthlyAverageBill: '',
  installationAddress: '',
  solution: STRINGS.BATTERY_SOLAR,
  systemSize: '',
  backupHours: 0,
  systemPowerOffset: 0,
  monthlySavingsAmount: 0,
  estCashPrice: 0,
  estMonthlyPrice: 0,
  interestRate: 0,
  loanTerm: 0,
  customerType: '',
};

const initialValuesFinancing = {
  ...initialValues,
  idNumber: '',
  income: '',
  readConsent: false,
  debtReviewConsent: false,
  totalExpenses: '',
};
const initialValuesNoFinancing = {...initialValues, dataConsent: false};

export {
  PRESTIGE,
  PRIVATE,
  NONE_OF_THE_ABOVE,
  validationSchemaFinancing,
  validationSchemaNoFinancing,
  initialValuesFinancing,
  initialValuesNoFinancing,
};
