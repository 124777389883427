import React, {useEffect} from 'react';

import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useToggle} from '@react-hookz/web';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';
import {useRecoilValue} from 'recoil';

import atoms from '../../atoms';
import {Title2} from '../typography';
import {StyledAccordion} from './styles';

function ProductAccordion({title, children}) {
  const productDetail = useRecoilValue(atoms.productDetail);
  const {attributes} = productDetail || {};

  const [expanded, setExpanded] = useToggle();

  const location = useLocation();

  useEffect(() => {
    if (decodeURI(location.hash).replace('#', '') === title) {
      setExpanded(true);
    }
  }, [location]);

  return (
    <StyledAccordion
      id={title}
      data-intent="informational"
      data-scope="product details"
      data-id="link_content"
      data-text={`${attributes?.name} | ${title} accordion button click`}
      expanded={expanded}
      onChange={() => {
        setExpanded(!expanded);
      }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Title2>{title}</Title2>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </StyledAccordion>
  );
}

ProductAccordion.prototype = {
  title: PropTypes.string,
  heading: PropTypes.string,
  children: PropTypes.shape({}),
};

export default ProductAccordion;
