import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  overflow: scroll;
`;

export const MobileInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: ${({theme}) => theme.spacing.tiny};
`;

export const QuantWrapper = styled.div`
  width: 100%;
  margin-bottom: ${({theme}) => theme.spacing.small};
`;

export const MobileBody = styled.div`
  display: flex;
  max-width: 100%;
  flex-direction: column;
  margin-bottom: ${({theme}) => theme.spacing.large};
  margin-left: ${({theme}) => theme.spacing.normal};
`;

export const MobileHeader = styled.div`
  font: 600 14px Inter;
  width: 100%;
  box-shadow: 0px 4px 4px black;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
`;

export const MobileBackButton = styled.img`
  width: 10px;
  height: 11px;
  position: relative;
  right: 100px;
  @media ${props => props.theme.device.mobileM} {
    right: 120px;
  }
`;

export const MobileMainBackButton = styled.img`
  width: 10px;
  height: 11px;
  position: relative;
  right: 120px;
  @media ${props => props.theme.device.mobileM} {
    right: 140px;
  }
`;

export const MobileHeadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: ${({theme}) => `1px solid ${theme.colors.grayFour}`};
  padding-bottom: ${({theme}) => theme.spacing.tiny};
  margin-left: -16px;
`;
