import {atom, selector} from 'recoil';

import LookSeeServices from '../../services/LookSeeServices';

export default {
  claimedPropertySelect: atom({
    key: 'claimedPropertySelect',
    default: {},
  }),
  claimedPropertyList: atom({
    key: 'claimedPropertyList',
    default: [],
  }),
  claimedPropertySelectValuation: atom({
    key: 'claimedPropertySelectValuation',
    default: {},
  }),
  searchedPropertySelect: atom({
    key: 'searchedPropertySelect',
    default: {propertyDetails: {}, propertyValuation: {}},
  }),
  searchedPropertyList: atom({
    key: 'searchedPropertyList',
    default: [],
  }),
  selectedUserSuburb: atom({
    key: 'selectedUserSuburb',
    default: '',
  }),
  mobileToggleProperty: atom({
    key: 'mobileToggleProperty',
    default: false,
  }),
  mapToken: selector({
    key: 'mapToken',
    get: async () => {
      const response = await LookSeeServices.getPluginToken('map');
      return response.token;
    },
  }),
};
