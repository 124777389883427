import {Accordion} from '@material-ui/core';
import styled from 'styled-components';

export const StyledAccordion = styled(Accordion)`
  &.MuiAccordion-root {
    border-top: 1px solid ${({theme}) => theme.colors.grayFour};

    &.Mui-expanded {
      margin: 0;
    }

    &.MuiAccordion-rounded {
      box-shadow: none;
    }
  }
`;
