import React, {useState} from 'react';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import PropTypes from 'prop-types';

import {
  Container,
  Label,
  Wrapper,
  Input,
  IconWrapper,
  VisibilityWrapper,
  IconShowWrapper,
  ErrorText,
} from './styles';

const TextArea = ({
  id,
  disabled,
  label,
  type,
  value,
  onChange,
  containerStyle,
  labelStyle,
  inputStyle,
  placeholder,
  fontSize,
  inputName,
  error,
  displayErrorText,
  icon,
  passwordVisibility,
  onVisibilityClick,
  wrapperStyles,
  onBlur,
  autocomplete,
  borderColor,
  autoFocus,
  customIconStyle,
  customIconClick,
}) => {
  const [focused, setFocused] = useState(false);

  const changeFocus = e => {
    onBlur?.(e);
    setFocused(false);
  };

  return (
    <Container style={containerStyle}>
      <Label htmlFor={id} style={labelStyle}>
        {label}
      </Label>
      <Wrapper
        focused={focused}
        error={error}
        borderColor={borderColor}
        wrapperStyles={wrapperStyles}>
        <Input
          id={id}
          disabled={disabled}
          autoFocus={autoFocus}
          autocomplete={autocomplete}
          name={inputName}
          value={value}
          type={type || 'text'}
          style={inputStyle}
          onChange={onChange}
          placeholder={placeholder}
          fontSize={fontSize}
          onFocus={() => {
            setFocused(true);
          }}
          onBlur={changeFocus}
          // onKeyDown={( evt ) => onKeyDown( evt )}
        />
        <IconWrapper>
          {onVisibilityClick ? (
            <VisibilityWrapper
              htmlFor={id}
              onClick={onVisibilityClick}
              focused={focused}>
              {passwordVisibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </VisibilityWrapper>
          ) : (
            <IconShowWrapper
              src={icon}
              onClick={customIconClick}
              style={customIconStyle}
            />
          )}
        </IconWrapper>
      </Wrapper>
      {displayErrorText && <ErrorText>{error}</ErrorText>}
    </Container>
  );
};

TextArea.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  inputName: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  containerStyle: PropTypes.shape({}),
  labelStyle: PropTypes.shape({}),
  inputStyle: PropTypes.shape({}),
  displayErrorText: PropTypes.bool,
  icon: PropTypes.node,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

export default TextArea;
