import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Title = styled.div(
  ({theme}) => `
    font: ${theme.typography.title2};
    margin-left: ${theme.spacing.small};

    @media ${theme.device.tablet} {
      margin: 100px 0px ${theme.spacing.medium} ${theme.spacing.xSmall};
    }

    @media ${theme.device.laptop} {
      margin-top: 0px;
    }
  `,
);

export const ProductImage = styled.img`
  width: 60px;
  margin-right: 18px;
  margin-left: -8px;
  @media ${props => props.theme.device.tablet} {
    width: 100px;
    margin-right: ${({theme}) => theme.spacing.small};
    object-fit: contain;
    margin-left: ${({theme}) => theme.spacing.small};
  }
  @media ${props => props.theme.device.laptop} {
    margin-right: 0px;
  }
`;

export const Text = styled.span`
  font: ${({theme}) => theme.typography.description};
  margin-left: 5px;
`;

export const TextBold = styled.span`
  font: ${({theme}) => theme.typography.headline3};
  margin: 2px 0px;
  color: ${({theme}) => theme.colors.grayFive};
`;

export const Heading = styled.h4`
  padding-top: ${({theme}) => theme.spacing.tiny};
  margin: 0;
  color: ${({theme, alert}) =>
    alert ? theme.colors.red800 : theme.colors.black};
  font-size: 16px;
  font-weight: 600;
  margin-bottom: ${({theme}) => theme.spacing.xSmall};
`;

export const OrderNumber = styled.h4`
  font: ${({theme}) => theme.typography.headline3};
  padding: 0px;
  margin: 0px;
  margin-top: ${({theme}) => theme.spacing.xSmall};
  color: ${({theme}) => theme.colors.grayFive};
  @media ${props => props.theme.device.tablet} {
    text-align: end;
    margin-top: 0px;
  }
`;

export const BorderSummaryItemWrap = styled.div`
  width: 100%;
  background-color: ${({theme}) => theme.colors.white};
  border-bottom: ${({theme}) => `1px solid ${theme.colors.border}`};
  @media ${props => props.theme.device.tablet} {
    border-bottom: none;
    margin: 16px 0px;
    border-radius: ${({theme}) => theme.spacing.xSmall};
  }
`;

export const HeaderWrap = styled.div`
  padding: 14px 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: ${({theme}) => theme.colors.black50};

  @media ${props => props.theme.device.tablet} {
    flex-direction: row;
    justify-content: space-between;
    padding: 14px 24px;
    background: white;
  }
`;

export const OrderInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({theme}) => theme.spacing.tiny};
`;

export const CardBody = styled.div`
  display: grid;
  @media ${props => props.theme.device.laptop} {
    grid-template-columns: 65% 35%;
  }
`;

export const HeaderTotal = styled.div`
  color: ${({theme}) => theme.colors.grayEight};
  font: ${({theme}) => theme.typography.headline2};
  display: flex;
  justify-content: flex-start;
  margin-bottom: ${({theme}) => theme.spacing.xSmall};
  @media ${props => props.theme.device.tablet} {
    justify-content: flex-end;
    font: ${({theme}) => theme.typography.title2};
  }
`;

export const ImageWrap = styled.div`
  display: none;
  justify-content: center;
  align-items: flex-start;
  @media ${props => props.theme.device.tablet} {
    display: flex;
  }
  @media ${props => props.theme.device.laptop} {
    margin-right: ${({theme}) => theme.spacing.normal};
  }
`;

export const MobileImageWrap = styled.div`
  margin: 0px;
  margin-left: ${({theme}) => theme.spacing.normal};
  @media ${props => props.theme.device.tablet} {
    display: none;
  }
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: ${props =>
    props.item.length > 1 ? `1px solid ${props.theme.colors.border}` : 'none'};
  margin-bottom: ${({theme}) => theme.spacing.xSmall};
  padding-top: ${({theme}) => theme.spacing.small};
  background: ${({theme}) => theme.colors.white};
  &:last-child {
    border-bottom: none;
  }
  @media ${props => props.theme.device.tablet} {
    flex-direction: row;
    padding-top: none;
  }
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media ${props => props.theme.device.mobileL} {
    flex-direction: row;
    padding: 8px 20px 8px 0;
    border-bottom: ${({theme}) => `1px solid ${theme.colors.border}`};
  }
`;

export const ItemName = styled.div`
  font: ${({theme}) => theme.typography.footerBold};
  color: ${({theme}) => theme.colors.primary};
  margin-bottom: 8px;
  @media ${props => props.theme.device.tablet} {
    font: 500 18px Inter;
    margin-bottom: 0px;
  }
`;

export const ItemPrice = styled.div`
  font: ${({theme}) => theme.typography.footerBold};
  margin-bottom: ${({theme}) => theme.spacing.xSmall};
  color: ${({theme}) => theme.colors.grayTwo};

  @media ${props => props.theme.device.tablet} {
    font: 500 18px Inter;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Attributes = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 18px;
  @media ${props => props.theme.device.tablet} {
    margin: 0px;
    width: 100%;
  }
`;

export const MobileItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  @media ${props => props.theme.device.tablet} {
    display: none;
  }
`;

export const MobileItemDetails = styled.div`
  color: ${({theme}) => theme.colors.black};
  font: ${({theme}) => theme.typography.footerBold};
  margin-bottom: ${({theme}) => theme.spacing.xSmall};
`;

export const EmptyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 60px);
  place-content: center;
`;

export const EmptyText = styled.div`
  font: ${({theme}) => theme.typography.headline1};
  color: ${({theme}) => theme.colors.grayFive};
  text-align: center;
  @media ${({theme}) => theme.device.mobileL} {
    font: ${({theme}) => theme.typography.title1};
  }
`;
