import React from 'react';

import {Box, Container, Grid} from '@material-ui/core';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {useTheme} from 'styled-components';

import {RoundedButton} from '../../components/Button';
import {BodyMed, Header3, Header5} from '../../components/newTypography';
import {solarUrl} from '../../navigation/routes';
import {BannerImageNextStepsFooter} from './styles';

export default function NextStepsFooter({title}) {
  const theme = useTheme();
  const history = useHistory();

  return (
    <Container>
      <Header3>{title}</Header3>
      <BannerImageNextStepsFooter>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} sm={8}>
            <Box p="18px">
              <Header5 inline>Get your solar quote</Header5>
            </Box>
            <Box p="12px">
              <BodyMed>
                Request a free solar quote to find the right-sized solar system
                for your home and pocket.
              </BodyMed>
              <BodyMed>
                We will ask you for some basic information about your household
                electricity consumption to:
              </BodyMed>
              <ul>
                <li>
                  <BodyMed fontWeight={700}>
                    help us determine your energy needs and
                  </BodyMed>
                </li>

                <li>
                  <BodyMed fontWeight={700}>
                    provide you with a quote for a custom fit solar or battery
                    backup solution.
                  </BodyMed>
                </li>
              </ul>
              <BodyMed>
                We also provide access to experienced solar solution providers
                and a range of financing options.
              </BodyMed>
              <Box
                display="flex"
                justifyContent={{xs: 'center', sm: 'flex-start'}}>
                <RoundedButton
                  data-id="link_content"
                  data-text={`${title} | Get quote button click`}
                  data-scope="button"
                  data-intent="navigational"
                  backgroundColor={theme.colors.blueMobileNav}
                  textColor={theme.colors.white}
                  text="Get quote"
                  onClick={() => history.push(solarUrl)}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </BannerImageNextStepsFooter>
    </Container>
  );
}

NextStepsFooter.propTypes = {
  title: PropTypes.string.isRequired,
};
