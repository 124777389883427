import React, {useCallback, useRef} from 'react';

import {
  useConditionalEffect,
  useEventListener,
  useToggle,
} from '@react-hookz/web';
import PropTypes from 'prop-types';
import {useRecoilValue} from 'recoil';

import atoms from '../atoms';
import config from '../config';
import {useFeaturesContext} from '../provider/features';

export default function MapPlugin({propertyKey}) {
  const token = useRecoilValue(atoms.mapToken);
  const features = useFeaturesContext();
  const [loaded, toggleLoaded] = useToggle(false);
  const iframeRef = useRef();

  const mapPluginUrl = config.services.mapPluginUrl;
  const pluginBaseUrl = new URL(mapPluginUrl).origin;
  const params = Object.entries({
    token,
    ...features.mapPluginArguments,
  })
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    )
    .join('&');
  const url = `${mapPluginUrl}?${params}`;

  useEventListener(window, 'message', message => {
    if (message.origin !== pluginBaseUrl) {
      return;
    }

    if (message.data.type === 'MapEvent') {
      handleMapEvent(message.data.event);
    }
  });

  const handleMapEvent = useCallback(event => {
    if (event?.name === 'MapReady') {
      toggleLoaded();
    }
  }, []);

  const callMapFunction = useCallback(
    (name, args) => {
      const message = {
        func: name,
        args: args,
      };

      iframeRef.current.contentWindow.postMessage(message, pluginBaseUrl);
    },
    [pluginBaseUrl],
  );

  useConditionalEffect(
    () => {
      callMapFunction('setStyle', 'hybrid');
      callMapFunction('selectFeature', {
        id: propertyKey,
        type: 'property',
      });
    },
    [loaded, propertyKey],
    [loaded, propertyKey],
  );

  return (
    <iframe
      ref={iframeRef}
      src={url}
      frameBorder="0"
      width="100%"
      height="100%"
      title="Map"
    />
  );
}

MapPlugin.propTypes = {
  propertyKey: PropTypes.string.isRequired,
};
