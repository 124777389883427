import styled from 'styled-components';

export const MobileHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
  height: 60px;
`;
export const MobileIcon = styled.img`
  justify-self: center;
  align-self: center;
`;
export const MobileTitle = styled.span`
  text-align: center;
  align-self: center;
  font: ${({theme}) => theme.typography.headline2};
`;
export const MobileBody = styled.div`
  max-height: 80%;
  padding: ${({theme}) => theme.spacing.normal};
`;
export const ListText = styled.span`
  margin: 0 ${({theme}) => theme.spacing.medium};
  text-transform: capitalize;
`;
export const ListIcon = styled.img``;
export const ManageListItem = styled.li`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
  padding: 0px ${({theme}) => theme.spacing.medium};
  height: 48px;
`;
export const Header = styled.p`
  font-weight: 600;
  font-size: 18px;
  margin: 16px 20px;
  text-transform: capitalize;
`;
export const UnorderedList = styled.ul`
  position: absolute;
  box-sizing: border-box;
  list-style: none;
  top: calc(100%);
  right: 20px;
  margin: 0;
  padding: 10px 0px;
  background: ${props => props.theme.colors.white};
  box-shadow: 4px 4px 16px 1px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
`;
export const AbandonWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${({theme}) => theme.spacing.large};

  @media ${({theme}) => theme.device.tablet} {
    padding: ${({theme}) => `${theme.spacing.giant} ${theme.spacing.xGiant}`};
    width: 680px;
    height: 400px;
  }
`;
export const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid ${({theme}) => theme.colors.border};
  padding: ${({theme}) => theme.spacing.medium};

  @media ${({theme}) => theme.device.tablet} {
    border-top: 1px solid ${({theme}) => theme.colors.border};
    margin: ${({theme}) => theme.spacing.medium};
  }
`;
export const Fullname = styled.span`
  font: ${({theme}) => theme.typography.body};
  text-transform: capitalize;
`;
export const Avatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font: ${({theme}) => theme.typography.headline2};
  font-size: 50px;
  border-radius: 50px;
  height: 100px;
  width: 100px;
  background: aliceblue;
  text-transform: uppercase;
  margin-bottom: 20px;
`;
