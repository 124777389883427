import React from 'react';

import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import {useTheme} from 'styled-components';

import {ToolTipHead, ToolTipBody, InfoIcon, InfoIconWrapper} from './styles';

const Tooltip = props => {
  const theme = useTheme();
  const {
    id,
    title,
    body,
    icon = theme.images.infoLG,
    arrowColor = theme.colors.secondary,
    dataIntent,
    dataScope,
    dataId,
    dataText,
  } = props;
  return (
    <InfoIconWrapper>
      <InfoIcon
        className="insightIcon"
        src={icon}
        data-for={id}
        data-tip={body}
        data-intent={dataIntent}
        data-scope={dataScope}
        data-id={dataId}
        data-text={dataText}
        data-event="mouseenter click focusin"
        data-event-off="mouseleave clickout mouseout scroll mousewheel blur"
      />
      <ReactTooltip
        id={id}
        effect="solid"
        arrowColor={arrowColor}
        className="toolTip"
        place="bottom"
        getContent={dataTip => (
          <>
            <ToolTipHead>{title}</ToolTipHead>
            <ToolTipBody>{dataTip}</ToolTipBody>
          </>
        )}
      />
    </InfoIconWrapper>
  );
};

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  body: PropTypes.string.isRequired,
  icon: PropTypes.string,
  arrowColor: PropTypes.string,
};

export default Tooltip;
