import React from 'react';

import {Grid} from '@material-ui/core';
import {useMountEffect} from '@react-hookz/web';
import {Field, useFormikContext} from 'formik';
import {useRecoilValue} from 'recoil';

import atoms from '../../atoms';
import {SOLAR_YOUR_POWER} from '../../atoms/app/constants';
import Currency from '../../components/Currency';
import FormikTextField from '../../components/FormikTextField';
import {useFeaturesContext} from '../../provider/features';
import {APPLICATION_ID} from '../../services/HttpServices';
import {
  sendComponentAnalytics,
  updateApplicationData,
} from '../../utils/Analytics';

export default function YourPower() {
  const formik = useFormikContext();
  const {solarHPAConfig} = useFeaturesContext();
  const user = useRecoilValue(atoms.user);

  const applicationData = {
    applicationStep: 'Step 1',
    applicationProduct: 'Solar',
    applicationMethod: 'online',
    applicationID: `${APPLICATION_ID}:${
      user?.id ? user.id : new Date().getTime()
    }`,
    applicationName: `application: pbb | solar | ${solarHPAConfig.solarHeaderBannerTitle}`,
    applicationStart: true,
    applicationComplete: false,
  };

  useMountEffect(() => {
    updateApplicationData(applicationData);
    sendComponentAnalytics(
      {
        formStatus: '',
        formName: 'Your Usage Details',
      },
      'globalApplicationStart',
    );
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Field
          component={FormikTextField}
          name="monthlyAverageBill"
          InputProps={{
            inputComponent: Currency,
          }}
          InputLabelProps={{
            shrink: !!formik.values.monthlyAverageBill,
          }}
          label={SOLAR_YOUR_POWER.MONTHLY_BILL}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          component={FormikTextField}
          name="installationAddress"
          label="Installation address"
        />
      </Grid>
    </Grid>
  );
}
