import styled from 'styled-components';

export const IconName = styled.p`
  font: ${({theme, small}) =>
    small ? theme.typography.mobileSmall : theme.typography.headline2};
  padding-left: ${({theme, small}) => (small ? theme.spacing.tiny : '')};
  white-space: nowrap;
  color: ${({white, theme}) =>
    white ? theme.colors.white : theme.colors.black1200};
`;

export const Icon = styled.img`
  ${({theme, medium}) => (medium ? `width: ${theme.spacing.large}` : '')};
`;

export const IconWrapper = styled.div`
  position: relative;
  display: grid;
  height: 50px;
  align-items: center;
  justify-content: start;
  grid-template-columns: 24px 1fr;
  grid-gap: ${({theme}) => theme.spacing.large};
  cursor: pointer;
  background: 'none';
  border-radius: '50%';
  max-width: '60px';
  margin-bottom: ${({theme}) => theme.spacing.tiny};
`;

export const Divider = styled.div`
  border: ${({theme}) => `1px solid ${theme.colors.black}`};
`;
