import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${props => props.containerStyle};
`;
export const Wrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 1px;
  border-radius: 4px;

  border: ${props => {
    if (props.focused && !props.error) {
      return `1px solid ${props.theme.colors.primary}`;
    } else if (!props.focused && props.error) {
      return `1px solid ${props.theme.colors.error}`;
    } else if (props.borderColor) {
      return `1px solid ${props.borderColor}`;
    } else {
      return `1px solid ${props.theme.colors.grayFour}`;
    }
  }};
  background: ${props =>
    props.focused ? props.theme.colors.brandBlue1 : props.theme.colors.white};
  ${({wrapperStyles}) => wrapperStyles}
`;
export const Label = styled.label`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  ${props => props.labelStyle};
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const Input = styled.input`
  height: 48px;
  width: 100%;
  outline: none;
  border: none;
  font: ${({theme}) => theme.typography.body};
  background: ${props => props.theme.colors.white};
  padding-left: 10px;
  font-size: ${({fontSize}) => fontSize || null};

  ::placeholder {
    color: ${props => props.theme.colors.grayFour};
  }

  &:focus {
    background: ${props => props.theme.colors.brandBlue1};
  }

  :disabled {
    color: ${props => props.theme.colors.grayFour};
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }

  ${props => props.inputStyle};
`;
export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const ErrorText = styled.span`
  font-size: 12px;
  color: ${props => props.theme.colors.error};

  &::first-letter {
    text-transform: uppercase;
  }
`;
export const VisibilityWrapper = styled.label`
  display: flex;
  justify-content: center;
  padding-right: 8px;

  & .MuiSvgIcon-root {
    fill: ${props => props.theme.colors.grayFour};
    height: 20px;
  }
`;
export const IconShowWrapper = styled.img`
  ${props => props.customIconStyle};
`;

export const ToolTipHead = styled.h1`
  margin-top: 0px;
  margin-bottom: ${({theme}) => theme.spacing.xSmall};
  font-size: 12px;
  font-weight: 600;
`;

export const ToolTipBody = styled.div`
  font: ${({theme}) => theme.typography.description};
`;
