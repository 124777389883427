import styled from 'styled-components';

export const addMarginBottom = {
  marginBottom: '2%',
};

export const productTitle = {
  marginTop: '2%',
};

export const PriceContainer = styled.div`
  @media ${props => props.theme.device.tablet} {
    display: flex;
    align-content: center;
  }
`;

export const dividerStyling = {margin: '10px 0'};

export const rightSpacing = {marginRight: '10px'};

export const containerStyle = {
  paddingLeft: '10%',
  paddingRight: '10%',
  marginBottom: '10px',
};

export const InfoIcon = styled.img`
  height: 18px;
  margin-bottom: -3px;
`;

export const InfoIconWrapper = styled.span`
  min-width: 100%;
  padding-left: 10px;
  margin-top: 4px;
`;
