import {
  Accordion,
  AccordionSummary,
  Box,
  Card,
  Slider,
  Chip,
} from '@material-ui/core';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {HashLink} from 'react-router-hash-link';
import styled from 'styled-components';

export const HeroImage = styled(Box)`
  background-image: url(${({theme}) =>
    theme.images.SolarScoreHeroBannerMobile});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 340px;

  @media ${({theme}) => theme.device.tablet} {
    background-image: url(${({theme}) =>
      theme.images.SolarScoreHeroBannerTablet});
    min-height: 360px;
  }

  @media ${({theme}) => theme.device.laptop} {
    background-image: url(${({theme}) => theme.images.SolarScoreHeroBanner});
    min-height: 600px;
  }
`;

export const CapitalizedLink = styled(HashLink)`
  text-decoration: underline;
  text-transform: uppercase;
  text-align: center;
  font-size: 10px;
  line-height: 16px;
`;

export const StyledAccordion = styled(Accordion)`
  &.MuiAccordion-root.Mui-expanded {
    margin: 0;
  }
`;

export const StyledSummary = styled(AccordionSummary)`
  &.MuiAccordionSummary-root {
    background: ${({theme}) => theme.gradient.lightBlue};
    flex-direction: row-reverse;
  }
  .MuiAccordionSummary-content {
    margin: 0;
  }
  .MuiAccordionSummary-expandIcon {
    transform: rotate(270deg);
    margin-right: 2%;
    color: ${({theme}) => theme.colors.tertiary};
  }
  .MuiAccordionSummary-expandIcon.Mui-expanded {
    transform: rotate(0deg);
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
`;

export const Table = styled.table`
  border: ${({theme}) => `1px solid ${theme.colors.black}`};
  border-collapse: collapse;
`;

export const TableData = styled.td`
  padding: 10px;
  border: ${({theme}) => `1px solid ${theme.colors.black}`};
  border-collapse: collapse;
`;

export const AnalysisImage = styled(Box)`
  background-image: ${({image}) => `url(${image})`};
  background-repeat: no-repeat;
  background-position: center;
  ${({size}) => (size ? `background-size: ${size}` : '')};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 200px;
`;

export const StyledChip = styled(Chip)`
  &.MuiChip-root {
    color: ${({chipColor}) => `${chipColor}`};
    background-color: ${({chipBackgroundColor}) => `${chipBackgroundColor}`};
    border-radius: 4px;
    width: 60%;
  }
`;

export const ListItem = styled.li`
  color: ${({color}) => `${color}`};
  text-align: left;
  &::marker {
    font-size: 1.5rem;
    vertical-align: bottom;
  }
`;

export const AnnualSavingsSlider = styled(Slider)`
  &.MuiSlider-root {
    color: ${({theme}) => `${theme.colors.sliderRail}`};
    height: 30px;
    padding: 18px 0;
    box-shadow: none;
    margin-top: 30px;
  }

  .MuiSlider-track {
    height: 6px;
  },

  .MuiSlider-thumb {
    height: 30px;
    width: 30px;
    background-color: ${({theme}) => `${theme.colors.white}`};
    box-shadow: ${({theme}) => `${theme.gradient.sliderThumbBoxShadow}`};
    margin-top: -14px;

    &.Mui-focusVisible, .MuiSlider-thumb:hover {
      box-shadow: ${({theme}) => `${theme.gradient.sliderThumbFocusBoxShadow}`};
      @media (hover: none) {
        box-shadow: ${({theme}) => `${theme.gradient.sliderThumbBoxShadow}`};
      },
    },
  }

  .MuiSlider-rail {
    height: 3px;
    opacity: 0.4;
  },

  .MuiSlider-mark {
    height: 8px;
    width: 1px;
    margin-top: -3px;
  },
`;

export const GradientCard = styled(Card)`
  background-image: url(${({theme}) => theme.images.cardBackgroundOrange});
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
`;

export const BannerImageNextStepsFooter = styled(Box)`
  background: ${({theme}) => theme.gradient.lightPurpleBackgroundGradient},
    url(${({theme}) => theme.images.nextStepFooter});
  background-size: contain;
  background-position: bottom right;
  background-repeat: no-repeat;
  padding-bottom: 200px;

  @media ${({theme}) => theme.device.tablet} {
    background-size: auto, 50%;
    padding-bottom: 0;
  }
  @media ${({theme}) => theme.device.laptopL} {
    background-size: contain;
  }
`;
