import React from 'react';

import PropTypes from 'prop-types';

import {productsServicesUrl} from '../../navigation/routes';
import Link from '../Link';
import {Wrapper, Title, BodyText, TitleImage} from './styles';

const CategoryCard = ({scope, category, showDescription = true, ...props}) => {
  const {name, description, imageUrl} = category;

  function createCategoryURl() {
    return `${productsServicesUrl}/${encodeURI(category.categoryCode)}`;
  }

  return (
    <Wrapper {...props}>
      <Link
        to={createCategoryURl()}
        data-intent="navigational"
        data-scope={scope}
        data-id="link_content"
        data-text={`${name} card click`}>
        <TitleImage src={imageUrl} />
        <Title>{name}</Title>
        {showDescription && description && <BodyText>{description}</BodyText>}
      </Link>
    </Wrapper>
  );
};

CategoryCard.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    imageUrl: PropTypes.string.isRequired,
  }).isRequired,
  showDescription: PropTypes.bool,
  scope: PropTypes.string.isRequired,
};

export default CategoryCard;
