import React, {useCallback} from 'react';

import {TextField} from '@material-ui/core';
import PropTypes from 'prop-types';

function FormikTextField({
  field: {name, value, onChange: formikOnChange, onBlur: formikOnBlur},
  form,
  onChange,
  onBlur,
  ...props
}) {
  const {getFieldMeta} = form;
  const {error, touched} = getFieldMeta(name);

  const newOnChange = useCallback(
    (...args) => {
      formikOnChange(...args);
      return onChange?.(...args);
    },
    [formikOnChange, onChange],
  );

  const newOnBlur = useCallback(
    (...args) => {
      formikOnBlur(...args);
      return onBlur?.(...args);
    },
    [formikOnBlur, onBlur],
  );

  return (
    <TextField
      // Overridable defaults
      helperText={touched && error}
      error={touched && !!error}
      id={name}
      variant="outlined"
      fullWidth
      // The rest of the props for underlying component
      {...props}
      // Non-overridable props
      onChange={newOnChange}
      onBlur={newOnBlur}
      value={value ?? ''}
      name={name}
    />
  );
}

FormikTextField.propTypes = {
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }).isRequired,
  form: PropTypes.shape({
    getFieldMeta: PropTypes.func.isRequired,
  }).isRequired,
};

export default FormikTextField;
