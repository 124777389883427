import styled from 'styled-components';

import CategoryCard from '../CategoryCard';

export const Wrapper = styled.div`
  margin-top: 0px;
  border-bottom: 1px solid ${({theme}) => theme.colors.graySix};
  padding: ${({theme}) => theme.spacing.normal};
  ${({wrapperStyling}) =>
    wrapperStyling
      ? `background-color: ${wrapperStyling?.backgroundColor}`
      : ''};

  @media ${props => props.theme.device.tablet} {
    border-bottom: none;
  }

  @media ${({theme}) => theme.device.mobileL} {
    padding: ${({theme}) =>
      `${theme.spacing.large} ${theme.spacing.large} 0 ${theme.spacing.large}`};
    ${({wrapperStyling}) => wrapperStyling};
  }
`;

export const UpperRow = styled.div`
  font-size: 18px;
  font-weight: 600;
  padding-top: ${props => (props.title ? props.theme.spacing.large : '0px')};
  padding-bottom: ${props => (props.title ? props.theme.spacing.large : '0px')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: ${props => (props.title ? '34px' : '0px')};
  @media ${props => props.theme.device.tablet} {
    width: 97%;
    margin-right: 3%;
  }
  @media ${props => props.theme.device.laptop} {
    font-size: 20px;
    padding-top: ${props => (props.title ? props.theme.spacing.medium : '0px')};
    margin-bottom: 0px;
    width: 97.5%;
    margin-right: 2.5%;
  }
  @media ${props => props.theme.device.laptopL} {
    width: 98%;
    margin-right: 2%;
  }
`;

export const LowerRow = styled.div`
  display: flex;
  overflow: auto;
  flex-wrap: ${({showAll}) => (showAll ? 'wrap' : 'nowrap')};
  transition: 150ms;
  gap: 15px;

  /* Hide scrollbar on all browsers */
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ShowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
`;

export const StyledCategoryCard = styled(CategoryCard)`
  width: 45%;
  flex: 0 0 auto;
  @media ${props => props.theme.device.tablet} {
    width: 23%;
  }
`;
