import React from 'react';

import {Box} from '@material-ui/core';
import {useTheme} from 'styled-components';

import {BodyReg} from '../newTypography';

export default function BulletedItem({text, value}) {
  const {images, colors} = useTheme();

  return (
    <Box>
      <Box display="inline-flex" alignItems="start" paddingY="3%">
        <img src={images.checkedCircle} alt="or-dot" />
        <Box
          paddingLeft="5px"
          display="flex"
          flexDirection="column"
          sx={{minWidth: {xs: '60%', sm: '100%'}}}>
          <BodyReg color={colors.darkGreyText} m="1%">
            {text}
          </BodyReg>
          <BodyReg fontWeight={700} color={colors.darkGreyText} m="1%">
            {value}
          </BodyReg>
        </Box>
      </Box>
    </Box>
  );
}
