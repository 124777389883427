import React from 'react';

import BasicModal from '../BasicModal';
import {
  ButtonWrapper,
  CenterItem,
  ModalHeaderText,
  ModalImage,
} from '../BasicModal/styles';
import {
  BodyContainer,
  CenterText,
  ModalContainer,
  ModalWrapper,
} from './styles';

export function OptionsModal({header, bodyList, children, image, ...props}) {
  return (
    <BasicModal width="50%" {...props}>
      <ModalWrapper>
        <ModalContainer centered>
          <CenterItem>
            <ModalImage src={image} />
          </CenterItem>
          <ModalHeaderText>{header}</ModalHeaderText>
          <BodyContainer>
            {bodyList.map((x, index) => (
              <CenterText key={index}>{x}</CenterText>
            ))}
          </BodyContainer>
        </ModalContainer>
        <ButtonWrapper>{children}</ButtonWrapper>
      </ModalWrapper>
    </BasicModal>
  );
}
