import {Accordion, Card, Tab, Tabs} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import styled, {css} from 'styled-components';

export const WarrantyBanner = styled(Card)`
  display: flex;
  align-items: center;

  &.MuiCard-root {
    background-color: ${({theme}) => theme.colors.green200};
    border: none;
    box-shadow: none;
  }
`;

const CardStyling = css`
  background-color: ${({theme}) => theme.colors.white};
  border-radius: 4px;
  border: 4px solid transparent;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.17);
`;

const SelectedStyling = css`
  box-shadow: 0px 1px 12px 4px rgba(76, 128, 213, 0.46);
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to bottom, #1967d2, #f4ad44) border-box;
`;

export const PackageCard = styled(Card)`
  &.MuiCard-root {
    ${CardStyling}
    ${({gradient}) => gradient && SelectedStyling};
  }
`;

export const MobilePackageCard = styled(Accordion)`
  &.MuiAccordion-root {
    ${CardStyling}
    ${({gradient}) => gradient && SelectedStyling};
  }
`;

export const TabToggle = styled(Tabs)`
  &.MuiTabs-root {
    background-color: ${({theme}) => theme.colors.white};
    border-radius: 50px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.17);
  }
  .MuiTabs-indicator {
    display: none;
  }
`;

export const ToggleOption = styled(Tab)`
  &.MuiTab-root {
    opacity: 1;
    border-radius: 50px;
    padding: 0 20px;
  }
  &.Mui-selected {
    background-color: ${({theme}) => theme.colors.secondary};
    font-weight: 700;
  }
`;

export const FlatAccordion = styled(Accordion).attrs({
  variant: 'outlined',
})`
  &.MuiAccordion-root:before {
    display: none;
  }
  &.MuiAccordion-root {
    box-shadow: none;
    border: none;
    background-color: transparent;
`;

export const StyledRadio = styled(Radio)`
  &.Mui-checked.MuiRadio-colorSecondary {
    color: ${({theme}) => theme.colors.secondary};
  }
`;
