import React from 'react';

import {useSetRecoilState, useRecoilValue} from 'recoil';

import {Button} from '..';
import atoms, {STRINGS} from '../../atoms';
import LookSeeServices from '../../services/LookSeeServices';
import BasicModal from '../BasicModal';
import {Image, TextWrapper, Text1, Text2, ButtonWrapper} from './styles';

const VerificationModal = () => {
  const theme = useRecoilValue(atoms.theme);
  const user = useRecoilValue(atoms.user);
  const setShowVerification = useSetRecoilState(atoms.showVerification);
  const setErrorModal = useSetRecoilState(atoms.errorModal);
  const showVerification = useRecoilValue(atoms.showVerification);

  const handleVerification = () => {
    setShowVerification(false);
  };

  const handleResend = async () => {
    // POST to resend link
    try {
      await LookSeeServices.resendVerificationEmail(user.email);
    } catch (error) {
      setErrorModal({message: error.message, visible: true});
    }
    setShowVerification(false);
  };

  return (
    <BasicModal contained visible={showVerification}>
      <Image src={theme.images.WeveSentYouAnEmail} alt="Onboard image" />
      <TextWrapper>
        <Text1>{STRINGS.CHECK_YOUR_INBOX}</Text1>
        <Text2>{STRINGS.VERIFICATION_LINK}</Text2>
      </TextWrapper>

      <Button
        data-intent="transactional"
        data-scope={`${STRINGS.CHECK_YOUR_INBOX} | ${STRINGS.VERIFICATION_LINK}`}
        data-id="link_content"
        data-text={`${STRINGS.CONTINUE} button click`}
        text={STRINGS.CONTINUE}
        onClick={handleVerification}
        width="80%"
      />
      <ButtonWrapper
        data-intent="transactional"
        data-scope={`${STRINGS.CHECK_YOUR_INBOX} | ${STRINGS.VERIFICATION_LINK}`}
        data-id="link_content"
        data-text={`${STRINGS.RESEND_LINK} button click`}
        onClick={handleResend}
        text={STRINGS.RESEND_LINK}
      />
    </BasicModal>
  );
};

export default VerificationModal;
