import React from 'react';

import {Box, Grid} from '@material-ui/core';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components';

import BasicModal from '../BasicModal';
import {LargeBody, Title2} from '../typography';

const InformationalModal = ({
  visible,
  handleClose,
  title,
  body,
  children,
  image,
  textCenter = false,
  showCloseButton = true,
}) => {
  return (
    <BasicModal
      contained
      visible={visible}
      showCloseButton={showCloseButton}
      onCloseClick={handleClose}
      overlayStyles={{left: 0}}
      width="40%"
      height="15%">
      <Wrapper>
        <TitleWrapper>{title && <Title2>{title}</Title2>}</TitleWrapper>
        {image && (
          <Grid justifyContent="center" container item>
            <Box
              component="img"
              height="150"
              py={3}
              alt="Credit card limitation image"
              src={image}
            />
          </Grid>
        )}

        <BodyWrapper center={textCenter}>
          <LargeBody>{body}</LargeBody>
        </BodyWrapper>
        {children}
      </Wrapper>
    </BasicModal>
  );
};

const Wrapper = styled.div`
  padding: 20px 30px;
`;

const TitleWrapper = styled.div`
  margin: 10px 0;
`;

const BodyWrapper = styled.div`
  ${({center}) => (center ? 'text-align: center ' : null)};
  margin: 10px 0;
`;

InformationalModal.propTypes = {
  visible: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  body: PropTypes.string,
  children: PropTypes.element,
  textCenter: PropTypes.bool,
  image: PropTypes.string,
  showCloseButton: PropTypes.bool,
};

export default InformationalModal;
