import React from 'react';

import {useSetRecoilState} from 'recoil';
import {useTheme} from 'styled-components';

import atoms from '../../atoms';
import PingIdentityService from '../../services/PingIdentityServices';
import {RoundedButton} from '../Button';
import {
  Wrapper,
  FormContainer,
  CloseIconWrapper,
  CloseIcon,
  Header,
} from './styles';

const ChangeAccountDetails = ({mobile, onSuccess}) => {
  const theme = useTheme();
  const setInteractiveModal = useSetRecoilState(atoms.interactiveModal);
  const handleClose = () => {
    if (mobile) {
      onSuccess();
    } else {
      setInteractiveModal(null);
    }
  };

  return (
    <Wrapper>
      <FormContainer>
        <CloseIconWrapper>
          <CloseIcon
            alt="close"
            src={theme.images.closeIcon}
            onClick={() => handleClose()}
          />
        </CloseIconWrapper>
        <Header>Change Account Details</Header>
        <iframe
          height="525px"
          width="400px"
          style={{border: 'none'}}
          src={PingIdentityService.getManageAccountPath()}
        />
      </FormContainer>
      <RoundedButton
        data-intent="navigational"
        data-scope="button"
        data-text="Manage account details done button click"
        data-id="link_content"
        backgroundColor={theme.colors.blueMobileNav}
        borderColor={theme.colors.blueMobileNav}
        text="Done"
        textColor={theme.colors.white}
        onClick={() => handleClose()}
        width="100%"
      />
    </Wrapper>
  );
};

export default ChangeAccountDetails;
