import styled from 'styled-components';

export const Wrapper = styled.div`
  display: none;
  margin-bottom: ${({theme}) => theme.spacing.small};
  border-bottom: ${props =>
    props.border ? `1px solid ${props.theme.colors.border}` : 'none'};
  padding-bottom: ${({theme}) => theme.spacing.small};
  padding-top: ${props => (props.border ? `12px` : '0px')};
  @media ${props => props.theme.device.tablet} {
    display: block;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${props => props.theme.device.tablet} {
    margin-right: ${({theme}) => theme.spacing.large};
  }
`;

export const InfoTitle = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({theme}) => theme.spacing.tiny};
  color: ${({theme}) => theme.colors.grayEight};
  font: ${({theme}) => theme.typography.body};
`;

export const InfoTextBold = styled.div`
  width: 70px;
  margin-right: ${({theme}) => theme.spacing.xSmall};
  font: 600 14px Inter;
`;

export const InfoText = styled.div``;

export const MoreInfoToggle = styled.div`
  cursor: pointer;
`;

export const ToggleText = styled.div`
  font: 400 10px Inter;
  color: ${({theme}) => theme.typography.grayEight};
`;

export const Body = styled.div``;

export const LightText = styled.div`
  margin-bottom: ${({theme}) => theme.spacing.tiny};
  color: ${({theme}) => theme.colors.palladium};
  font: ${({theme}) => theme.typography.body};
  margin-left: 78px;
  @media ${props => props.theme.device.tablet} {
  }
`;
