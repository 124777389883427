import React from 'react';

import {Container} from '@material-ui/core';
import {useTheme} from 'styled-components';

import {
  BodyMed,
  Header2,
  Header5,
  LinkReg,
} from '../../components/newTypography';
import {useFeaturesContext} from '../../provider/features';
import FaqsAccordion from './FaqsAccordion';
import {Table, TableData} from './styles';

const tableInfo = [
  {
    scoring: 'Unfeasible',
    scoreRange: ' 0-40',
    description:
      'Your property will not benefit from a solar installation because the amount of ' +
      'electricity that can be harnessed from the sun is low. This could be due to a lack of ' +
      'sunlight, your roof being at a poor angle to the sun, or the shape and slope of your roof not being optimal.',
  },
  {
    scoring: ' Low',
    scoreRange: '40-50',
    description:
      'Your property could benefit from an entry-level solar ' +
      'installation, but the output from the system could potentially be ' +
      'limited.',
  },
  {
    scoring: 'Average',
    scoreRange: '50-60',
    description:
      'Your property, like most residences in South Africa, could benefit ' +
      'from a solar installation due to a combination of roof area and ' +
      'its northern facing cardinal direction.',
  },
  {
    scoring: 'Good',
    scoreRange: '60-70',
    description:
      'Your property offers above-average feasibility for a Solar ' +
      'installation and might be able to support an ‘off-the-grid’ ' +
      'system.',
  },
  {
    scoring: 'Excellent',
    scoreRange: '70-100',
    description:
      'Your property has the best Solar installation potential and could ' +
      'allow you to go ‘off-the-grid’ as well as sell-back to the grid ' +
      'opportunities, if local authorities permit.',
  },
];

export function FaqSection() {
  const theme = useTheme();
  const {solarScoreFaqs} = useFeaturesContext();

  return (
    <Container style={{marginBottom: '5%'}}>
      <Header2 color={theme.colors.tertiary}>FAQ</Header2>
      <FaqsAccordion title="What is a Solar Score?">
        <BodyMed color={theme.colors.grayOne}>
          Many South Africans are considering solar power for their home as they
          look to take control of their electricity supply and bring down rising
          monthly costs. At the same time, the case for solar is becoming more
          attractive with system prices dropping and electricity savings
          increasing.
        </BodyMed>
        <BodyMed color={theme.colors.grayOne}>
          Determining whether a solar investment is right for your home and your
          pocket can be difficult which is why we have developed a Solar Score
          that will make your decision that much easier.
        </BodyMed>
        <BodyMed color={theme.colors.grayOne}>
          This Solar Score indicates how effective your solar system could be at
          generating power and will be a good indicator of the feasibility for
          you and your home. It also provides you with the estimated usable
          hours of sunlight as well as the estimated electricity output that
          could be produced by your roof per day, month and year based on your
          roof area. Using this information, we can calculate the potential
          savings you could expect over time.
        </BodyMed>
        <Header5>What does your scoring mean?</Header5>
        <Table>
          <tbody>
            <tr>
              <TableData>
                <Header5>Scoring</Header5>
              </TableData>
              <TableData>
                <Header5>Description</Header5>
              </TableData>
            </tr>
            {tableInfo.map(({scoring, scoreRange, description}) => (
              <tr key={scoring}>
                <TableData>
                  <BodyMed>
                    {scoring}
                    <br />
                    {scoreRange}
                  </BodyMed>
                </TableData>
                <TableData>
                  <BodyMed>{description}</BodyMed>
                </TableData>
              </tr>
            ))}
          </tbody>
        </Table>
      </FaqsAccordion>
      {solarScoreFaqs?.length &&
        solarScoreFaqs.map(({title, description, linkText, linkUrl}) => (
          <FaqsAccordion title={title} key={title}>
            <BodyMed color={theme.colors.grayOne}>{description}</BodyMed>
            <LinkReg
              color={theme.colors.primary}
              href={linkUrl}
              data-intent="navigational"
              data-scope="accordion"
              data-id="link_content"
              data-text={`${title} | ${linkText} link click`}>
              {linkText}
            </LinkReg>
          </FaqsAccordion>
        ))}
    </Container>
  );
}
