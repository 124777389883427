import React from 'react';

import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
} from '@material-ui/core';
import {useToggle} from '@react-hookz/web';
import {Helmet} from 'react-helmet';
import {useHistory} from 'react-router-dom';
import {useTheme} from 'styled-components';

import {RoundedButton} from '../../components/Button';
import {Header1, Subtitle1} from '../../components/newTypography';
import {LargeBody, LargestBody} from '../../components/typography';
import useDetectMobile from '../../hooks/useDetectMobile';
import {solarApply, solarLoan} from '../../navigation/routes';
import {useFeaturesContext} from '../../provider/features';
import SolarEnquiryModal from './SolarEnquiryModal';
import SolarProducts from './SolarProducts';
import {
  BATTERY_PACKAGE,
  BATTERY_PACKAGE_PARAGRAPH,
  CHOOSE_PAYMENT_METHOD,
  FOOTER_BANNER_TITLE,
  GET_FINANCING,
  HOW_DOES_IT_WORK,
  HOW_DOES_IT_WORK_HEADLINE,
  HOW_DOES_IT_WORK_OPTIONS,
  OUR_OFFERS,
  PAY_CASH,
  SOLAR_AND_BATTERY_PACKAGE,
  SOLAR_AND_BATTERY_PACKAGE_PARAGRAPH,
  USE_ACCESS_BOND,
  CUSTOMER_TESTIMONIAL,
  WHAT_CUSTOMERS_SAY,
  CUSTOMER_NAME,
} from './constants';
import {
  InfoBoxHeadline,
  InfoBoxParagraph,
  InfoBoxSubTitle,
  InfoBoxTitle,
  HeaderBannerImage,
  RoundedIcon,
  HeaderBannerMainTitle,
  StyledSubTitle,
  PaymentMethodBoxTitle,
  OurOffersTitle,
  BatteryPackageTitle,
  BatteryPackageParagraph,
  FooterBannerTitle,
  StyledCheckCircleIcon,
  TestimonialHeading,
  SolarLoanBannerImage,
  bannerContainerHeight,
} from './styles';

function DefaultSolarScreen() {
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useDetectMobile();
  const [open, toggleOpen] = useToggle();
  const {hpaTestimonialVideoUrl} = useFeaturesContext();
  const features = useFeaturesContext();

  const GET_QUOTE = 'Get quote';

  return (
    <Box>
      <Helmet>
        <title>LookSee Solar</title>
        <meta
          name="description"
          content="Get the best backup power or home solar system backed by vetted installers, flexible financing options and customer support."
        />
      </Helmet>
      <HeaderBannerImage
        height="100vh"
        width="100%"
        minHeight="600px"
        display="flex"
        flexDirection="column">
        <Container>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            gridRowGap="32px"
            marginTop="15vh">
            <Box width={isMobile ? '100%' : '60%'}>
              <HeaderBannerMainTitle color={theme.colors.white}>
                {features.solarHPAConfig.solarHeaderBannerTitle}
              </HeaderBannerMainTitle>
            </Box>
            <Box width={isMobile ? '100%' : '60%'}>
              <StyledSubTitle color={theme.colors.white}>
                {features.solarHPAConfig.solarHeaderBannerSubTitle}
              </StyledSubTitle>
            </Box>
            <Box display="flex" flexWrap="wrap" gridGap="16px">
              <Box sx={{width: {xs: '100%', sm: '210px'}}}>
                <RoundedButton
                  data-text={`${features.solarHPAConfig.solarHeaderBannerTitle} | ${GET_QUOTE} button click`}
                  data-id="link_content"
                  data-scope="button"
                  data-intent="navigational"
                  width="100%"
                  text={GET_QUOTE}
                  textColor={theme.colors.tertiary}
                  backgroundColor={theme.colors.secondary}
                  onClick={() => history.push(solarApply)}
                />
              </Box>
            </Box>
          </Box>
        </Container>
      </HeaderBannerImage>
      <Container>
        <Box marginTop={{xs: '-15vh', sm: '-20vh'}}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  padding: {xs: '22px'},
                  textAlign: {xs: 'center', sm: 'left'},
                }}>
                <Box>
                  <InfoBoxTitle color={theme.colors.black}>
                    {HOW_DOES_IT_WORK}
                  </InfoBoxTitle>
                </Box>
                <Box marginTop="12px">
                  <InfoBoxSubTitle color={theme.colors.grayOne}>
                    {HOW_DOES_IT_WORK_HEADLINE}
                  </InfoBoxSubTitle>
                </Box>
              </Box>
              <Box
                textAlign={{xs: 'center', sm: 'left'}}
                sx={{
                  padding: {sm: '22px'},
                }}>
                <Grid container justifyContent="center" spacing={3}>
                  <Grid item xs={12} sm={6} md={3}>
                    <RoundedIcon
                      src={theme.images.settingsSuggestIcon}
                      alt="icon"
                    />
                    <InfoBoxHeadline>
                      {HOW_DOES_IT_WORK_OPTIONS.SOLUTION_HEADING}
                    </InfoBoxHeadline>
                    <InfoBoxParagraph color={theme.colors.grayOne}>
                      {HOW_DOES_IT_WORK_OPTIONS.SOLUTION_PARAGRAPH}
                    </InfoBoxParagraph>
                    <Box display={{sm: 'none'}}>
                      <Divider />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <RoundedIcon src={theme.images.handShakeIcon} alt="icon" />
                    <InfoBoxHeadline>
                      {HOW_DOES_IT_WORK_OPTIONS.PROVIDER_HEADING}
                    </InfoBoxHeadline>
                    <InfoBoxParagraph color={theme.colors.grayOne}>
                      {HOW_DOES_IT_WORK_OPTIONS.PROVIDER_PARAGRAPH}
                    </InfoBoxParagraph>

                    <Box display={{sm: 'none'}}>
                      <Divider />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <RoundedIcon
                      src={theme.images.accountBalanceIcon}
                      alt="icon"
                    />
                    <InfoBoxHeadline>
                      {HOW_DOES_IT_WORK_OPTIONS.FINANCING_HEADING}
                    </InfoBoxHeadline>
                    <InfoBoxParagraph color={theme.colors.grayOne}>
                      {HOW_DOES_IT_WORK_OPTIONS.FINANCING_PARAGRAPH}
                    </InfoBoxParagraph>

                    <Box display={{sm: 'none'}}>
                      <Divider />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <RoundedIcon
                      src={theme.images.supportAgentIcon}
                      alt="icon"
                    />
                    <InfoBoxHeadline>
                      {HOW_DOES_IT_WORK_OPTIONS.SUPPORT_HEADING}
                    </InfoBoxHeadline>
                    <InfoBoxParagraph color={theme.colors.grayOne}>
                      Offering quality, end-to-end Customer Care support to
                      assist you every step of the way
                    </InfoBoxParagraph>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Container>
      <SolarProducts />
      <Container>
        <Box marginTop="10%">
          <Grid container alignItems="center" spacing={4}>
            <Grid item xs={12} sm={6} md={8}>
              <Box display="flex" gridGap="20px">
                <Box
                  component="img"
                  display={{xs: 'none', md: 'inline-block'}}
                  borderRadius="4px"
                  minHeight="440px"
                  src={theme.images.solarBrochureTileImageOne}
                />
                <Box
                  component="img"
                  display={{xs: 'none', md: 'inline-block'}}
                  borderRadius="4px"
                  minHeight="440px"
                  src={theme.images.solarBrochureTileImageTwo}
                />
                <Box
                  flexGrow={1}
                  flexShrink={1}
                  minHeight="440px"
                  borderRadius="4px"
                  display="inline-block"
                  sx={{
                    backgroundImage: `url(${theme.images.solarBrochureTileImageThree})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box display="flex" flexDirection="column" gridGap="24px">
                <Box>
                  <PaymentMethodBoxTitle>
                    {CHOOSE_PAYMENT_METHOD}
                  </PaymentMethodBoxTitle>
                </Box>
                <Box display="flex" alignItems="center" gridGap="24px">
                  <StyledCheckCircleIcon fontSize="large" />
                  <LargestBody>{PAY_CASH}</LargestBody>
                </Box>
                <Box display="flex" alignItems="center" gridGap="24px">
                  <Box>
                    <StyledCheckCircleIcon fontSize="large" />
                  </Box>
                  <Box>
                    <LargestBody>{USE_ACCESS_BOND}</LargestBody>
                  </Box>
                </Box>
                <Box display="flex" alignItems="center" gridGap="24px">
                  <Box>
                    <StyledCheckCircleIcon fontSize="large" />
                  </Box>
                  <Box>
                    <LargestBody>{GET_FINANCING}</LargestBody>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      {features?.solarLoan && (
        <SolarLoanBannerImage
          color={theme.colors.white}
          sx={{marginTop: {xs: '10%'}, height: bannerContainerHeight}}>
          <Container>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-around"
              sx={{
                height: bannerContainerHeight,
                width: {xs: '100%', sm: '75%'},
              }}>
              <Box>
                <Header1 color={theme.colors.white} inline>
                  Already have a quote from a solar provider?
                </Header1>
              </Box>
              <Box>
                <Subtitle1 color={theme.colors.white} inline>
                  In that case, apply for a Solar Loan with us and let’s help
                  you begin your journey to Solar.
                </Subtitle1>
              </Box>
              <Box>
                <Box
                  component={RoundedButton}
                  data-text={`${features.solarHPAConfig.solarHeaderBannerTitle} | Already have a quote from a solar provider?  | GET A SOLAR LOAN button click`}
                  data-id="link_content"
                  data-scope="button"
                  data-intent="navigational"
                  sx={{width: {xs: '100%', sm: '65%', md: '40%'}}}
                  text="GET A SOLAR LOAN"
                  textColor={theme.colors.tertiary}
                  backgroundColor={theme.colors.secondary}
                  onClick={() => history.push(solarLoan)}
                />
              </Box>
            </Box>
          </Container>
        </SolarLoanBannerImage>
      )}
      <Container>
        <Box sx={{marginTop: {xs: '10%'}}}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={4}>
            <Grid item xs={12}>
              <Box
                display="flex"
                sx={{
                  flexDirection: {xs: 'column', md: 'row'},
                  gridGap: {md: '7%'},
                }}
                justifyContent="center"
                alignItems="center">
                <Box flexBasis="90%">
                  <OurOffersTitle color={theme.colors.tertiary}>
                    {OUR_OFFERS}
                  </OurOffersTitle>
                  <BatteryPackageTitle color={theme.colors.tertiary}>
                    {BATTERY_PACKAGE}
                  </BatteryPackageTitle>
                  <BatteryPackageParagraph color={theme.colors.grayOne}>
                    {BATTERY_PACKAGE_PARAGRAPH}
                  </BatteryPackageParagraph>
                </Box>
                <Box
                  borderRadius="4px"
                  width="100%"
                  height="370px"
                  sx={{
                    backgroundImage: `url(${theme.images.solarBrochureContentImageTwo})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                sx={{
                  flexDirection: {xs: 'column', md: 'row'},
                  gridGap: {md: '7%'},
                  marginTop: {md: '30px'},
                }}
                justifyContent="center"
                alignItems="center">
                <Box flexBasis="90%" order={{md: 2}}>
                  <BatteryPackageTitle color={theme.colors.tertiary}>
                    {SOLAR_AND_BATTERY_PACKAGE}
                  </BatteryPackageTitle>
                  <BatteryPackageParagraph color={theme.colors.grayOne}>
                    {SOLAR_AND_BATTERY_PACKAGE_PARAGRAPH}
                  </BatteryPackageParagraph>
                </Box>
                <Box
                  borderRadius="4px"
                  width="100%"
                  height="370px"
                  sx={{
                    backgroundImage: `url(${theme.images.solarBrochureContentImageOne})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    order: {md: 1},
                  }}
                />
              </Box>
            </Grid>
            <Box my="5%" px={{xs: '3%', md: '1%'}}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TestimonialHeading color={theme.colors.tertiary}>
                    {WHAT_CUSTOMERS_SAY}
                  </TestimonialHeading>
                  <Box component={Card} p={4} mt={2}>
                    <CardContent>
                      <LargeBody color={theme.colors.grayOne}>
                        <i>{CUSTOMER_TESTIMONIAL}</i>
                      </LargeBody>
                      <Box mt={{xs: 3, md: 5}}>
                        <LargeBody color={theme.colors.grayOne}>
                          {CUSTOMER_NAME}
                        </LargeBody>
                      </Box>
                    </CardContent>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <video
                    height="100%"
                    width="100%"
                    poster={theme.images.HpaTestimonial}
                    controls>
                    <source src={hpaTestimonialVideoUrl} type="video/mp4" />
                  </video>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Container>
      <Container>
        <Box
          marginBottom="5%"
          width="100%"
          borderRadius="4px"
          display="flex"
          sx={{
            flexDirection: {xs: 'column', sm: 'row'},
            alignItems: {md: 'center'},
            marginTop: {xs: '5%', sm: '10%'},
            background: theme.gradient.blueTwo,
          }}>
          <Box marginY="32px" marginLeft="32px" order={{xs: 1, sm: 2}}>
            <Box marginBottom="16px" marginRight="50px">
              <FooterBannerTitle color={theme.colors.white}>
                {FOOTER_BANNER_TITLE}
              </FooterBannerTitle>
            </Box>
            <Box>
              <RoundedButton
                data-text={`${FOOTER_BANNER_TITLE} | ${GET_QUOTE} button click`}
                data-id="link_content"
                data-scope="button"
                data-intent="transactional"
                width="60%"
                text={GET_QUOTE}
                textColor={theme.colors.tertiary}
                backgroundColor={theme.colors.secondary}
                onClick={() => history.push(solarApply)}
              />
            </Box>
          </Box>
          <Box
            component="img"
            width="100%"
            alignSelf="flex-end"
            sx={{
              marginTop: {sm: '-5%'},
              maxWidth: {sm: '45%'},
              height: {sm: '105%'},
            }}
            borderRadius="8px"
            src={theme.images.solarBrochureFooterBannerImage}
            order={{xs: 2, sm: 1}}
          />
        </Box>
      </Container>
      <SolarEnquiryModal open={open} onClose={toggleOpen} />
    </Box>
  );
}
export default DefaultSolarScreen;
