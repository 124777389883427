import React, {useRef} from 'react';

import {Box} from '@material-ui/core';
import {useToggle} from '@react-hookz/web';
// eslint-disable-next-line no-restricted-imports
import {useFormik} from 'formik';
import PropTypes from 'prop-types';
import {useRecoilValue} from 'recoil';
import {useTheme} from 'styled-components';
import * as Yup from 'yup';

import atoms, {STRINGS} from '../../atoms';
import {CALL_ME_BACK_TYPE, FORM_REQUIREMENTS} from '../../atoms/app/constants';
import LookSeeServices from '../../services/LookSeeServices';
import {
  satelliteTrackCode,
  sendComponentAnalytics,
} from '../../utils/Analytics';
import {phoneValidator} from '../../validations/phone';
import BasicModal from '../BasicModal';
import {RoundedButton} from '../Button';
import Checkbox from '../Checkbox';
import {TextArea, TextField} from '../index';
import {Title2} from '../typography';
import ThankYou from './ThankYou';
import {TextFieldWrapper, Wrapper} from './styles';

const callMeBackValidationSchema = Yup.object().shape({
  firstName: Yup.string().required(FORM_REQUIREMENTS.NAME_IS_REQUIRED),
  lastName: Yup.string().required(FORM_REQUIREMENTS.SURNAME_IS_REQUIRED),
  emailAddress: Yup.string()
    .email()
    .required(FORM_REQUIREMENTS.EMAIL_IS_REQUIRED),
  phoneNumber: phoneValidator.required('Phone number is required'),
  product: Yup.string(),
  query: Yup.string(),
  financeConsent: Yup.bool().oneOf([true, false]),
  consent: Yup.bool().oneOf([true], FORM_REQUIREMENTS.CONSENT_IS_REQUIRED),
});

const waitingListValidationSchema = Yup.object().shape({
  firstName: Yup.string().required(FORM_REQUIREMENTS.NAME_IS_REQUIRED),
  lastName: Yup.string().required(FORM_REQUIREMENTS.SURNAME_IS_REQUIRED),
  emailAddress: Yup.string()
    .email()
    .required(FORM_REQUIREMENTS.EMAIL_IS_REQUIRED),
  phoneNumber: phoneValidator,
  query: Yup.string().required(FORM_REQUIREMENTS.QUERY_IS_REQUIRED),
  consent: Yup.bool().oneOf([true], FORM_REQUIREMENTS.CONSENT_IS_REQUIRED),
});

const callToBuyValidationSchema = Yup.object().shape({
  firstName: Yup.string().required(FORM_REQUIREMENTS.NAME_IS_REQUIRED),
  lastName: Yup.string().required(FORM_REQUIREMENTS.SURNAME_IS_REQUIRED),
  emailAddress: Yup.string()
    .email()
    .required(FORM_REQUIREMENTS.EMAIL_IS_REQUIRED),
  phoneNumber: phoneValidator,
  consent: Yup.bool().oneOf([true], FORM_REQUIREMENTS.CONSENT_IS_REQUIRED),
});
const names = [
  {
    name: 'firstName',
    label: FORM_REQUIREMENTS.FIRST_NAME,
  },
  {
    name: 'lastName',
    label: FORM_REQUIREMENTS.LAST_NAME,
  },
];

const phoneNumber = {
  name: 'phoneNumber',
  label: FORM_REQUIREMENTS.PHONE_NUMBER,
  containerStyle: {marginBottom: 16},
};

const emailAddress = {
  name: 'emailAddress',
  placeholder: FORM_REQUIREMENTS.EMAIL_ADDRESS,
  label: FORM_REQUIREMENTS.EMAIL_ADDRESS,
  containerStyle: {marginBottom: 16},
};

const query = {
  name: 'query',
  label: FORM_REQUIREMENTS.WHAT_KNOW,
  placeholder: 'Enter your query here',
  inputStyle: {height: '90px'},
  containerStyle: {marginBottom: 16},
};

const product = {
  name: 'product',
  label: 'Product',
  containerStyle: {marginBottom: '16px'},
};

const ContactModal = ({
  formikValues,
  handleClose,
  visible,
  type,
  productName,
}) => {
  const waitingList = type === 'wait';
  const callToBuy = type === 'call';
  const isTracked = useRef(false);
  const theme = useTheme();
  const onHandleChange = () => {
    if (!isTracked.current) {
      sendComponentAnalytics(
        {formName: 'call me back form'},
        'globalFormStart',
      );
      isTracked.current = true;
    }
  };

  return (
    <BasicModal
      contained
      visible={visible}
      showCloseButton
      onCloseClick={handleClose}
      overlayStyles={{left: 0}}>
      <Wrapper
        data-frmcontainer="call_me_back_form"
        id="call_me_back_form"
        name="call_me_back_form">
        {waitingList && <Title2>{STRINGS.PLEASE_FILL_WAITING_LIST}</Title2>}
        {callToBuy && <Title2>{STRINGS.PLEASE_FILL_CALL_TO_BUY}</Title2>}
        <TextFieldWrapper>
          {names.map((item, index) => (
            <TextField
              key={index}
              id={item.name}
              displayErrorText
              name={item.name}
              type="text"
              inputName={item.name}
              label={item.label}
              placeholder={item.label}
              error={
                formikValues?.touched[item.name] &&
                formikValues?.errors[item.name]
              }
              value={formikValues?.values[item.name]}
              onChange={e => {
                onHandleChange();
                formikValues?.handleChange(e);
              }}
              containerStyle={{marginBottom: 16}}
              onBlur={formikValues?.handleBlur}
            />
          ))}
        </TextFieldWrapper>

        <TextField
          displayErrorText
          {...phoneNumber}
          type="text"
          inputName={phoneNumber.name}
          placeholder={phoneNumber.label}
          error={
            formikValues?.touched.phoneNumber &&
            formikValues?.errors.phoneNumber
          }
          value={formikValues?.values.phoneNumber}
          onChange={e => {
            onHandleChange();
            formikValues?.handleChange(e);
          }}
          onBlur={formikValues?.handleBlur}
        />

        <TextField
          displayErrorText
          {...emailAddress}
          type="text"
          inputName={emailAddress.name}
          error={
            formikValues?.touched.emailAddress &&
            formikValues?.errors.emailAddress
          }
          value={formikValues?.values.emailAddress}
          onChange={e => {
            onHandleChange();
            formikValues?.handleChange(e);
          }}
          onBlur={formikValues?.handleBlur}
        />

        {!type && (
          <TextField
            {...product}
            inputName={product.name}
            placeholder={product.label}
            value={formikValues?.values.product}
            readonly
          />
        )}

        {!type && (
          <TextArea
            {...query}
            id={query.name}
            onChange={e => {
              onHandleChange();
              formikValues?.handleChange(e);
            }}
            value={formikValues?.values.query}
            onBlur={formikValues?.handleBlur}
          />
        )}
        {!type && (
          <Box my="5%">
            <Checkbox
              value={formikValues?.values.financeConsent}
              onChange={e =>
                formikValues?.setFieldValue('financeConsent', e.target.checked)
              }
              text="Would you like a lending consultant to call you regarding financing for this product?"
            />
          </Box>
        )}
        <Box mb="5%">
          <Checkbox
            value={formikValues?.values.consent}
            onChange={e =>
              formikValues?.setFieldValue('consent', e.target.checked)
            }
            text={FORM_REQUIREMENTS.CONSENT}
          />
        </Box>

        <RoundedButton
          data-id="link_content"
          data-text={`products and services | ${productName} | call me back button click`}
          data-scope="button"
          data-intent="navigational"
          disabled={
            !formikValues?.isValid ||
            !formikValues.dirty ||
            formikValues.isSubmitting
          }
          text={STRINGS.CALL_ME_BACK}
          backgroundColor={theme.colors.blueMobileNav}
          textColor={theme.colors.white}
          onClick={formikValues.handleSubmit}
          styles={{borderRadius: 4}}
          width="100%"
        />
      </Wrapper>
    </BasicModal>
  );
};

const MarketplaceContactModal = ({
  handleClose,
  productInformation,
  type,
  visible,
}) => {
  const user = useRecoilValue(atoms.user);
  const waitingList = type === 'wait';
  const [open, toggleOpen] = useToggle(false);

  const callMeBackFormik = useFormik({
    initialValues: {
      firstName: user?.first_name || '',
      lastName: user?.last_name || '',
      emailAddress: user?.email || '',
      phoneNumber: user?.phone || '',
      product: productInformation.name,
      query: '',
      financeConsent: false,
      consent: false,
    },
    validationSchema: callMeBackValidationSchema,
    onSubmit: async values => {
      const callMeBackData = {
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        email: values.emailAddress,
        query: values.query,
        itemName: productInformation.name,
        itemCode: productInformation.itemCode,
        entityId: productInformation.itemId,
        type: CALL_ME_BACK_TYPE.GENERAL,
        financeConsent: values.financeConsent ? 'Yes' : 'No',
        consent: 'Yes',
      };
      await LookSeeServices.callMeBack(callMeBackData);
      handleClose();
      satelliteTrackCode('globalFormComplete');
    },
  });

  const callToBuyFormik = useFormik({
    initialValues: {
      firstName: user?.first_name || '',
      lastName: user?.last_name || '',
      phoneNumber: user?.phone || '',
      emailAddress: user?.email || '',
      consent: false,
    },
    validationSchema: callToBuyValidationSchema,
    onSubmit: async values => {
      const callToBuyData = {
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        itemName: productInformation.name,
        itemCode: productInformation.itemCode,
        entityId: productInformation.itemId,
        email: values.emailAddress,
        type: CALL_ME_BACK_TYPE.GENERAL,
        price: productInformation?.amount || productInformation?.monthlyAmount,
        consent: values.consent,
      };
      await LookSeeServices.callMeBack(callToBuyData);
      handleClose();
      toggleOpen();
      satelliteTrackCode('globalFormComplete');
    },
  });

  const waitingListFormik = useFormik({
    initialValues: {
      firstName: user?.first_name || '',
      lastName: user?.last_name || '',
      phoneNumber: user?.phone || '',
      emailAddress: user?.email || '',
      consent: false,
    },
    validationSchema: waitingListValidationSchema,
    onSubmit: async values => {
      const waitingListData = {
        email: values.emailAddress,
        firstName: `${values.firstName} ${values.lastName}`,
        campaignId: productInformation.name,
        phoneNumber: values.phoneNumber,
      };
      await LookSeeServices.addToWaitingList(waitingListData);
      handleClose();
      satelliteTrackCode('globalFormComplete');
    },
  });

  let formikValues = callMeBackFormik;
  if (type) {
    formikValues = waitingList ? waitingListFormik : callToBuyFormik;
  }

  return (
    <>
      <ContactModal
        formikValues={formikValues}
        type={type}
        visible={visible}
        handleClose={handleClose}
        productName={productInformation.name}
      />
      <ThankYou open={open} />
    </>
  );
};

MarketplaceContactModal.propTypes = {
  handleClose: PropTypes.func,
  productInformation: PropTypes.object,
  visible: PropTypes.bool,
  type: PropTypes.string,
};

export default MarketplaceContactModal;
