import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: grid;
  z-index: ${props => (props.lowIndex ? 0 : 100)};
  box-sizing: border-box;
`;
export const ButtonWrapper = styled.div`
  display: grid;
`;
export const UnorderedList = styled.ul`
  position: absolute;
  box-sizing: border-box;
  list-style: none;
  top: calc(100% + ${({offset}) => offset});
  right: 0;
  margin: 0;
  padding: 0px 0px;
  background: ${props => props.theme.colors.white};
  box-shadow: 4px 4px 16px 1px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  max-height: ${({height}) => (height ? height : '400px')};
  min-width: 64px;
  overflow: auto;
  ${({fullWidth}) => (fullWidth ? 'width: 100%;' : null)};
`;
export const Header = styled.p`
  font-weight: 600;
  font-size: 18px;
  margin: 16px 20px;
  text-transform: capitalize;
`;
