import React from 'react';

import PropTypes from 'prop-types';
import {useTheme} from 'styled-components';

import {Overlay, Box, Wrapper, CloseIcon} from './styles';

const BasicModal = ({
  visible,
  children,
  collapse,
  width,
  onOverlayClick,
  onCloseClick,
  showCloseButton,
  contained,
  height,
  zIndex,
  overlayStyles,
}) => {
  const theme = useTheme();

  const handleOverlayClick = e => {
    onOverlayClick?.(e);
  };

  const handleCloseClick = e => {
    onCloseClick?.(e);
  };

  if (!visible) {
    return null;
  }

  return (
    <Overlay
      styles={overlayStyles}
      zIndex={zIndex}
      contained={contained}
      onClick={handleOverlayClick}>
      <Box
        onClick={e => e.stopPropagation()} // prevents passing the click event to overlay
        collapse={collapse}
        width={width}
        height={height}>
        {showCloseButton && (
          <CloseIcon src={theme.images.closeIcon} onClick={handleCloseClick} />
        )}
        <Wrapper>{children}</Wrapper>
      </Box>
    </Overlay>
  );
};

BasicModal.propTypes = {
  visible: PropTypes.bool,
  collapse: PropTypes.bool,
  children: PropTypes.node,
  width: PropTypes.string,
  onOverlayClick: PropTypes.func,
  onCloseClick: PropTypes.func,
  showCloseButton: PropTypes.bool,
  contained: PropTypes.bool,
  height: PropTypes.string,
  zIndex: PropTypes.number,
  left: PropTypes.string,
  overlayStyles: PropTypes.shape({}),
};

BasicModal.defaultProps = {
  visible: false,
  collapse: false,
  showCloseButton: false,
  contained: false,
};

export default BasicModal;
