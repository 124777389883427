import React, {useEffect, useState} from 'react';

import {Box, Card, CardActions, Container} from '@material-ui/core';
import {useRecoilValue} from 'recoil';
import {useTheme} from 'styled-components';

import atoms from '../../atoms';
import Link from '../../components/Link';
import {BodyMed, Header2} from '../../components/newTypography';
import {Headline3, LargeBody} from '../../components/typography';
import {productsServicesUrl} from '../../navigation/routes';
import {logger} from '../../services/Logger';
import LookSeeServices from '../../services/LookSeeServices';
import {
  Carousel,
  ProductCardContainer,
  ProductImage,
  StyledCard,
} from './styles';

const getSubcategory = async subcategoryId => {
  try {
    return await LookSeeServices.getSubcategory(subcategoryId);
  } catch (error) {
    logger.error(error);
  }
};

export default function SolarProducts() {
  const homeSolarCategory = useRecoilValue(atoms.marketplaceCategories)?.find(
    c => c.name.toLowerCase() === 'home solar',
  );
  const subcategory = homeSolarCategory?.subcategories[0];
  const [items, setItems] = useState([]);
  const theme = useTheme();
  const categoryCode = homeSolarCategory?.categoryCode;
  const subcategoryCode = subcategory?.categoryCode;

  useEffect(() => {
    const fetchSubcategory = async () => {
      if (subcategory) {
        const fetchedSubCategory = await getSubcategory(subcategory.categoryId);
        setItems(fetchedSubCategory?.items);
      }
    };

    fetchSubcategory();
  }, [subcategory]);

  return (
    items.length && (
      <Box component={Container} mt="5%">
        <Box
          component={StyledCard}
          elevation={3}
          sx={{
            pl: {xs: '5%', sm: '10%'},
            textAlign: {xs: 'center', sm: 'left'},
          }}>
          <Box>
            <Header2 color={theme.colors.white}>
              View our home solar solutions
            </Header2>
            <BodyMed color={theme.colors.white}>
              Our home solar solutions have been vetted by Standard Bank and are
              covered by extensive manufacturer and workmanship warranties.
            </BodyMed>
          </Box>
        </Box>
        <Box
          mt={{xs: '-18vh', sm: '-15vh', md: '-12vh'}}
          display="flex"
          justifyContent="center">
          <Carousel columns={items?.length}>
            {items?.map(item => (
              <Card key={item.itemId} elevation={1}>
                <Link
                  data-id="link_content"
                  data-scope="card"
                  data-intent="navigational"
                  data-text={`products and services | home solar | view our home solar solutions | ${item.name} card click`}
                  to={encodeURI(
                    `${productsServicesUrl}/${categoryCode}/${subcategoryCode}/${item.itemCode}`,
                  )}>
                  <ProductCardContainer>
                    <ProductImage alt={item.name} src={item.imageUrl[0].name} />
                  </ProductCardContainer>
                </Link>
                <Box px="3%" mb="2%">
                  <LargeBody>{item.name}</LargeBody>
                </Box>
                <CardActions>
                  <Link
                    data-id="link_content"
                    data-scope="link"
                    data-intent="navigational"
                    data-text={`products and services | home solar | view our home solar solutions| ${item.name} | shop now link click`}
                    to={encodeURI(
                      `${productsServicesUrl}/${homeSolarCategory.categoryCode}/${subcategory.categoryCode}/${item.itemCode}`,
                    )}>
                    <Headline3 color={theme.colors.newPrimary}>
                      SHOP NOW
                    </Headline3>
                  </Link>
                </CardActions>
              </Card>
            ))}
          </Carousel>
        </Box>
      </Box>
    )
  );
}
