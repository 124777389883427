import React from 'react';

import {Box, Card, CardContent, Container, Grid} from '@material-ui/core';
import {useTheme} from 'styled-components';

import {
  Header2,
  Subtitle1,
  BodyMed,
  BodySm,
} from '../../components/newTypography';
import SolarScoreCard from './SolarScoreCard';

export default function SolarScoreLandingBody() {
  const theme = useTheme();

  const cardInformation = [
    {
      title: '1. Search for your address',
      bodyContent: (
        <Box>
          <BodySm inline color={theme.colors.grayOne}>
            Using lidar technology we analyse your roof direction, roof area,
            roof slope and horizontal irradiation to{' '}
            <strong>assess the solar feasibility of your home.</strong>
          </BodySm>
        </Box>
      ),
      image: theme.images.solarScoreHouse1,
    },
    {
      title: '2. Receive your solar feasibility score',
      bodyContent: (
        <Box>
          <BodySm inline color={theme.colors.grayOne}>
            Your <strong>Solar Score</strong> is a classification between{' '}
            <strong>0 and 100</strong>, ranging from unfeasible to excellent.
          </BodySm>
        </Box>
      ),
      image: theme.images.solarScoreHouse2,
    },
    {
      title: '3. Get a quote for a solar solution',
      bodyContent: (
        <Box>
          <BodySm inline color={theme.colors.grayOne}>
            Request a free <strong>home power analysis</strong> to find the{' '}
            <strong>right-sized solar system</strong> for your home and pocket.
          </BodySm>
        </Box>
      ),
      image: theme.images.solarScoreHouse3,
    },
  ];
  return (
    <Box component={Container} mt="5%">
      <Box mb="1%">
        <Header2 inline>How does LookSee&apos;s Solar Score work?</Header2>
      </Box>
      <Box sx={{marginBottom: {xs: '5%', md: '30px'}}}>
        <Subtitle1 inline color={theme.colors.grayOne}>
          Make an informed decision about your solar installation by following
          these{' '}
          <Box
            component="span"
            sx={{
              textDecoration: 'underline',
              textDecorationColor: theme.colors.secondary,
            }}>
            three easy steps:
          </Box>
        </Subtitle1>
      </Box>

      <Grid container spacing={5}>
        {cardInformation.map(cardInfo => (
          <Grid item xs={12} md={4} key={cardInfo.title}>
            <SolarScoreCard
              title={cardInfo.title}
              bodyContent={cardInfo.bodyContent}
              image={cardInfo.image}
            />
          </Grid>
        ))}
      </Grid>
      <Box
        display="flex"
        flexDirection={{xs: 'column', md: 'row'}}
        alignItems="center"
        gridColumnGap="5%"
        gridRowGap="25px"
        mt="5%">
        <Box order={{md: 2}}>
          <Box mb={{xs: '8px', md: '16px'}}>
            <Header2 inline color={theme.colors.tertiary}>
              Available areas
            </Header2>
          </Box>
          <Box>
            <BodyMed inline color={theme.colors.grayOne}>
              LookSee’s Solar Score is currently available in three major
              metro’s. We are working to expand our reach across the country, so
              come back soon if your area is not supported.
            </BodyMed>
          </Box>
        </Box>
        <Box order={{md: 1}}>
          <Card>
            <CardContent>
              <Box
                id="coverage"
                component="img"
                maxWidth="640px"
                width={{xs: '100%', md: 'auto'}}
                src={theme.images.solarScoreMapTargetAreas}
              />
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Box>
  );
}
