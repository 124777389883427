import {atom, selector} from 'recoil';

import {logger} from '../../services/Logger';
import LookSeeServices from '../../services/LookSeeServices';

const marketplaceCategories = selector({
  key: 'marketplaceCategories',
  get: async () => {
    try {
      return await LookSeeServices.getCategories();
    } catch (e) {
      logger.error('Error fetching the categories', e);
      return [];
    }
  },
});

export default {
  productDetail: atom({
    key: 'productDetail',
    default: {},
  }),
  basket: atom({
    key: 'basket',
    default: {},
  }),
  marketplaceSavedItems: atom({
    key: 'marketplaceSavedItems',
    default: [],
  }),
  mostRecentProducts: atom({
    key: 'mostRecentProducts',
    default: [],
  }),
  beInTouchModalState: atom({
    key: 'beInTouchModalState',
    default: false,
  }),
  raiseIssueModalState: atom({
    key: 'raiseIssueModalState',
    default: {
      visible: false,
      orderId: null,
    },
  }),
  marketTriggers: atom({
    key: 'marketTriggers',
    default: [],
  }),
  quoteSuccessModal: atom({
    key: 'quoteSuccessModal',
    default: false,
  }),
  subscriptions: atom({
    key: 'subscriptions',
    default: [],
  }),
  appointments: atom({
    key: 'appointments',
    default: [],
  }),
  loginCallback: atom({
    key: 'loginCallback',
    default: null,
  }),
  marketplaceCategories,
};
