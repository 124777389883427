import React from 'react';

import {Grid} from '@material-ui/core';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import {useTheme} from 'styled-components';

import ProductAccordion from '../ProductAccordion';
import {LargeBody} from '../typography';
import {Container, FeatureItem} from './styles';

function ProductFeatures({features}) {
  const theme = useTheme();

  return (
    <>
      {features.map((item, index) => (
        <ProductAccordion key={index} title={item.heading}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={(item.keyValuePairs || []).length ? 6 : false}>
              <ReactMarkdown>{item.description}</ReactMarkdown>
            </Grid>
            <Grid item xs={12} sm={6}>
              {(item.keyValuePairs || []).map(({key, value}, i) => (
                <Container key={i}>
                  <LargeBody>{key}</LargeBody>
                  {value !== 'true' && (
                    <LargeBody color={theme.colors.grayEight}>
                      <FeatureItem>{value}</FeatureItem>
                    </LargeBody>
                  )}
                </Container>
              ))}
            </Grid>
          </Grid>
        </ProductAccordion>
      ))}
    </>
  );
}

ProductFeatures.prototype = {
  features: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    keyValuePairs: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
  }),
};

export default ProductFeatures;
