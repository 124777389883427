import React from 'react';

import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import {useTheme} from 'styled-components';

import {parseOrderState} from '../../hooks/useParsedOrders';
import {ToolTipBody, ToolTipHead} from '../Tooltip/styles';
import {
  Text,
  Heading,
  HeaderWrap,
  OrderNumber,
  TextBold,
  OrderInfo,
  HeaderTotal,
  FlexColumn,
} from './styles';

const OrderHeader = ({order, isTablet}) => {
  const theme = useTheme();

  return (
    <HeaderWrap>
      <div>
        <Heading
          id={`header:${order.orderId}`}
          data-tip
          data-for={`tooltipId:${order.orderId}`}
          alert={order.alertText}>
          {parseOrderState(order.state)}
        </Heading>
        <ReactTooltip
          id={`tooltipId:${order.orderId}`}
          effect="solid"
          className="toolTip"
          place="bottom"
          arrowColor={theme.colors.secondary}
          getContent={() => (
            <>
              <ToolTipHead>{parseOrderState(order.state)}</ToolTipHead>
              <ToolTipBody>{order.stateDescription}</ToolTipBody>
            </>
          )}
        />
        {isTablet && (
          <FlexColumn>
            <TextBold>
              Order placed:
              <Text>
                {moment(order.createdAt)
                  .utcOffset(2)
                  .format('DD/MM/YYYY HH:mm')}
              </Text>
            </TextBold>
          </FlexColumn>
        )}
      </div>
      <OrderInfo>
        <HeaderTotal>Total: R {order.paymentAmount}</HeaderTotal>
        {isTablet && (
          <OrderNumber>
            Order no:<Text>{order.orderId}</Text>
          </OrderNumber>
        )}
      </OrderInfo>
    </HeaderWrap>
  );
};

export default OrderHeader;
