import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({theme}) => theme.colors.white};
  width: 100%;
  border-radius: 8px;
  padding-bottom: ${({theme}) => theme.spacing.medium};

  @media ${props => props.theme.device.tablet} {
    max-width: 33%;
    padding: ${({theme}) => theme.spacing.xLarge};
  }
`;
export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Header = styled.div`
  font: ${({theme}) => theme.typography.headline1};
  text-align: center;
  text-transform: capitalize;
`;
export const CloseIconWrapper = styled.div`
  display: none;
  @media ${({theme}) => theme.device.tablet} {
    display: flex;
    justify-content: flex-end;
    margin-bottom: ${({theme}) => theme.spacing.large};
  }
`;
export const CloseIcon = styled.img``;
