import styled from 'styled-components';

export const Wrapper = styled.button`
  background: ${props =>
    props.hover || props.disabled
      ? props.theme.colors.border
      : props.theme.colors.white};
  border: 1px solid ${({theme}) => theme.colors.border};
  border-radius: ${({theme}) => theme.spacing.large};
  min-height: ${({theme}) => theme.spacing.xLarge};
  min-width: ${({theme}) => theme.spacing.xLarge};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${({theme}) => theme.spacing.xSmall};
`;
