import styled from 'styled-components';

export const AbandonWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 40%;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media ${({theme}) => theme.device.tablet} {
    flex-direction: row;
  }
`;
export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media ${({theme}) => theme.device.tablet} {
    flex-direction: row;
  }
`;
export const AbandonTitle = styled.div`
  font: ${({theme}) => theme.typography.title2};
  margin-bottom: ${({theme}) => theme.spacing.large};
  text-align: center;

  @media ${({theme}) => theme.device.tablet} {
    font: ${({theme}) => theme.typography.title1};
  }
`;
export const AbandonDescription = styled.div`
  font: ${({theme}) => theme.typography.body};
  margin-bottom: ${({theme}) => theme.spacing.xLarge};
  text-align: center;

  @media ${({theme}) => theme.device.tablet} {
    font: ${({theme}) => theme.typography.bodyLarge};
  }
`;
