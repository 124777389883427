import React from 'react';

import PropTypes from 'prop-types';

import {Container, Div} from './styles.js';

const BurgerIcon = ({open, setOpen, onClick, hide}) => {
  return (
    <div onClick={onClick}>
      <Container open={open} hide={hide} onClick={() => setOpen(!open)}>
        <Div open={open} hide={hide} />
        <Div open={open} hide={hide} />
        <Div open={open} hide={hide} />
      </Container>
    </div>
  );
};

BurgerIcon.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default BurgerIcon;
