import styled from 'styled-components';

import {Button} from '../index';

export const Text1 = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: ${props => props.theme.colors.black};
  margin: 0;
`;
export const Text2 = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;

  @media ${props => props.theme.device.tablet} {
    font-size: 17px;
  }
`;

export const Image = styled.img`
  height: 105px;
  width: 100px;

  @media ${props => props.theme.device.mobileM} {
    height: 155px;
    width: 150px;
  }
  @media ${props => props.theme.device.tablet} {
    height: 190px;
    width: 185px;
  }
  @media ${props => props.theme.device.laptop} {
    height: 290px;
    width: 315px;
  }
`;
export const TextWrapper = styled.div`
  box-sizing: border-box;
  text-align: center;
  padding: 10px;

  @media ${props => props.theme.device.tablet} {
    padding: 10px 0px;
    width: 520px;
  }
`;
export const ButtonWrapper = styled(Button)`
  width: 80%;
  margin: 5px 0;
  background-color: transparent;
  color: ${({theme}) => theme.colors.primary};

  &:hover {
    background-color: transparent;
    color: ${({theme}) => `${theme.colors.primary}50`};
  }
`;
