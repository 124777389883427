import React from 'react';

import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import {useConditionalEffect, useMountEffect} from '@react-hookz/web';
import {Field, useFormikContext} from 'formik';
import ReactTooltip from 'react-tooltip';
import {useRecoilState, useRecoilValue} from 'recoil';
import {useTheme} from 'styled-components';

import atoms, {STRINGS} from '../../atoms';
import {FORM_REQUIREMENTS} from '../../atoms/app/constants';
import Checkbox from '../../components/Checkbox';
import Currency from '../../components/Currency';
import EnsureDetailsAreCorrectAlert from '../../components/EnsureDetailsAreCorrectAlert';
import FormikTextField from '../../components/FormikTextField';
import TermsAndConditionsAndMore from '../../components/TermsAndConditionsAndMore';
import {ToolTipBody} from '../../components/TextField/styles';
import {InfoIcon, InfoIconWrapper} from '../../components/Tooltip/styles';
import {BodyMed, BodySm} from '../../components/newTypography';
import {Headline2} from '../../components/typography';
import {
  dataLayerVariables,
  sendComponentAnalytics,
  updateApplicationData,
} from '../../utils/Analytics';
import {NONE_OF_THE_ABOVE, PRESTIGE, PRIVATE} from './formModels';
import {StyledRadio} from './styles';

const CommunicationConsent =
  'I give consent to pass on my details to LookSee and 3rd parties which may use WhatsApp, phone call or email as a means of communication to deliver the service. You may contact us to opt out.';
const solarTextFields = [
  {
    name: 'firstName',
    label: FORM_REQUIREMENTS.FIRST_NAME,
  },
  {
    name: 'lastName',
    label: FORM_REQUIREMENTS.LAST_NAME,
  },
  {
    name: 'email',
    label: FORM_REQUIREMENTS.EMAIL_ADDRESS,
    type: 'email',
  },
  {
    name: 'phone',
    label: FORM_REQUIREMENTS.PHONE_NUMBER,
    type: 'tel',
  },
];

const solarLoanTextFields = [
  {
    label: 'ID number',
    name: 'idNumber',
    placeholder: 'ID number',
    key: 'id_number',
  },
  {
    name: 'income',
    label: 'Gross monthly income',
    placeholder: 'Gross monthly income (before deductions)',
    key: 'gross_monthly_income',
    inputProps: {
      inputComponent: Currency,
    },
  },
  {
    name: 'totalExpenses',
    label: 'Total expenses',
    placeholder: 'Total expenses',
    key: 'total_expenses',
    inputProps: {
      inputComponent: Currency,
    },
  },
];

function ContactInformation() {
  const theme = useTheme();

  const [wantSolarLoan, setWantSolarLoan] = useRecoilState(
    atoms.solarLoanFinancing,
  );
  const selectedTab = useRecoilValue(atoms.solarPackagesTab);
  const eligibleForLoan = selectedTab.value === STRINGS.BATTERY_SOLAR;

  useMountEffect(() => {
    updateApplicationData({
      applicationStep: 'Step 3',
    });
    sendComponentAnalytics(
      {
        ...dataLayerVariables({
          pageCategory: 'Property Hub',
          pageName: 'Property Hub:Solar:Apply:Contact Information',
          pageSubSection1: 'Property Hub:Solar:Apply:Contact Information',
        }),
      },
      'globalVirtualPageView',
    );
  });

  const {setFieldValue} = useFormikContext();

  useConditionalEffect(
    () => {
      setFieldValue('debtReviewConsent', false);
      setFieldValue('income', '');
      setFieldValue('totalExpenses', '');
    },
    [wantSolarLoan],
    [!wantSolarLoan],
  );

  const options = [
    {
      value: PRESTIGE,
      label: 'Prestige customer',
    },
    {
      value: PRIVATE,
      label: 'Private banking customer',
    },
    {value: NONE_OF_THE_ABOVE, label: 'Other'},
  ];

  return (
    <Grid container spacing={3}>
      {solarTextFields.map(
        ({name, label, type = 'text', placeholder, InputProps}) => (
          <Grid item key={name} xs={12}>
            <Field
              component={FormikTextField}
              name={name}
              placeholder={placeholder}
              label={label}
              type={type}
              InputProps={InputProps}
            />
          </Grid>
        ),
      )}
      <Grid item xs={12}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="customer-type">
            Choose the one that applies to you
          </InputLabel>
          <Field
            as={Select}
            labelId="customer-type"
            label="Choose the one that applies to you"
            name="customerType">
            {options.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        </FormControl>
      </Grid>
      {eligibleForLoan && (
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <BodyMed fontWeight="600" color={theme.colors.tertiary} inline>
                Do you require a Solar Loan to finance your solar installation?
              </BodyMed>
            </Box>
            <Box>
              <InfoIconWrapper>
                <InfoIcon
                  src={theme.images.info}
                  data-for="do-you-require-solar-loan"
                  data-tip="do-you-require-solar-loan"
                  data-event="mouseenter click focusin"
                  data-event-off="mouseleave clickout mouseout scroll mousewheel blur"
                />
                <ReactTooltip
                  id="do-you-require-solar-loan"
                  effect="solid"
                  arrowColor={theme.colors.darkPurple}
                  className="toolTip"
                  place="bottom"
                  getContent={() => (
                    <ToolTipBody>
                      The Solar Loan offers personalised interest rates capped
                      at a maximum of prime +2.5% and is based on government`s
                      Energy Bounce Back Loan Scheme.
                    </ToolTipBody>
                  )}
                />
              </InfoIconWrapper>
            </Box>
          </Box>
          <Box>
            <RadioGroup
              aria-label="do-you-require-solar-loan"
              name="do-you-require-solar-loan"
              value={wantSolarLoan}
              onChange={() => setWantSolarLoan(value => !value)}
              row>
              <FormControlLabel value control={<StyledRadio />} label="Yes" />
              <FormControlLabel
                value={false}
                control={<StyledRadio />}
                label="No"
              />
            </RadioGroup>
          </Box>
        </Grid>
      )}

      {wantSolarLoan && eligibleForLoan && (
        <Grid item xs={12} spacing={3}>
          <Box>
            <BodySm fontWeight="600" color={theme.colors.tertiary}>
              See if you qualify:
            </BodySm>
          </Box>
          <Grid container item xs={12} spacing={3}>
            {solarLoanTextFields.map(
              ({name, label, type = 'text', inputProps, placeholder}) => (
                <Grid item key={name} xs={12}>
                  <Field
                    component={FormikTextField}
                    name={name}
                    label={label}
                    placeholder={placeholder}
                    type={type}
                    InputProps={inputProps}
                  />
                </Grid>
              ),
            )}
            <Grid item xs={12}>
              <EnsureDetailsAreCorrectAlert />
            </Grid>
            <Grid item xs={12}>
              <TermsAndConditionsAndMore />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <BodyMed
              fontWeight="600"
              lineHeight="0"
              color={theme.colors.tertiary}>
              I confirm that:
            </BodyMed>
            <Field
              as={Checkbox}
              name="readConsent"
              key="readConsent"
              text={
                <BodyMed color={theme.colors.tertiary}>
                  I have read, marked, and understood the contents of the{' '}
                  <a
                    href="https://www.standardbank.co.za/southafrica/personal/about-us/legal/privacy-statement"
                    target="_blank"
                    rel="noreferrer">
                    Privacy Statement
                  </a>
                  .
                </BodyMed>
              }
            />
            <Field
              as={Checkbox}
              name="debtReviewConsent"
              key="debtReviewConsent"
              text={
                <BodyMed color={theme.colors.tertiary}>
                  I am not insolvent, under debt review (current or applied
                  for), administration or sequestration.
                </BodyMed>
              }
            />
            <Field
              as={Checkbox}
              name="dataConsent"
              key="dataConsent"
              text={
                <BodyMed color={theme.colors.tertiary}>
                  {CommunicationConsent}
                </BodyMed>
              }
            />
          </Grid>
        </Grid>
      )}

      {!wantSolarLoan && (
        <Grid item xs={12}>
          <Box marginBottom="10px">
            <Field
              as={Checkbox}
              name="dataConsent"
              text={<Headline2>{CommunicationConsent}</Headline2>}
            />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <img src={theme.images.LockClosedIcon} alt="lock-closed-icon" />
            </Box>
            <Box>
              <Headline2>
                Your personal information will remain confidential and will be
                processed in accordance with our{' '}
                <a
                  href="https://www.standardbank.co.za/southafrica/personal/about-us/legal/privacy-statement"
                  target="_blank"
                  rel="noreferrer">
                  Privacy Statement
                </a>
                .
              </Headline2>
            </Box>
          </Box>
        </Grid>
      )}
    </Grid>
  );
}

export default ContactInformation;
