import React, {useRef, useState, useEffect} from 'react';

import {UnorderedList, Header, ButtonWrapper, Container} from './styles';

const Dropdown = ({
  offset,
  slot,
  style,
  lowIndex,
  button,
  header,
  listItems,
  renderItem,
  fullWidth,
  height,
  state = false,
}) => {
  const node = useRef();
  const [open, setOpen] = useState(state);

  const handleClick = e => {
    if (node && !node.current?.contains?.(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const Slot = () => React.cloneElement(slot, {setOpen: () => setOpen(false)});

  const renderContent = () => {
    if (open) {
      if (slot) {
        return (
          <UnorderedList offset={offset} height={height}>
            <Slot />
          </UnorderedList>
        );
      }
      return (
        <UnorderedList offset={offset} fullWidth={fullWidth}>
          {!!header && <Header>{header}</Header>}
          {listItems.map((item, index) => (
            <div key={`${index}`} onClick={() => setOpen(false)}>
              {renderItem(item)}
            </div>
          ))}
        </UnorderedList>
      );
    }
  };

  return (
    <>
      <ButtonWrapper
        onClick={e => {
          e.stopPropagation();
          setOpen(!open);
        }}>
        {button}
      </ButtonWrapper>
      <Container ref={node} style={style} lowIndex={lowIndex}>
        {renderContent()}
      </Container>
    </>
  );
};

Dropdown.defaultProps = {
  offset: '4px',
};

export default Dropdown;
