import React from 'react';

import {Badge} from '@material-ui/core';
import PropTypes from 'prop-types';

import {ListItem, ListText} from './styles';

const ListItemBadge = ({
  notificationTotal = 0,
  title,
  handleClick,
  icon,
  ...props
}) => {
  return (
    <ListItem {...props} onClick={handleClick}>
      <Badge badgeContent={notificationTotal} color="primary">
        <img alt={title} src={icon} />
      </Badge>
      <ListText>{title}</ListText>
    </ListItem>
  );
};

ListItemBadge.propTypes = {
  notificationTotal: PropTypes.number,
  title: PropTypes.string,
  icon: PropTypes.string,
  handleClick: PropTypes.func,
  header: PropTypes.string,
};

export default ListItemBadge;
