import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  Suspense,
} from 'react';

import {Box, Container} from '@material-ui/core';
import {useConditionalEffect, useToggle} from '@react-hookz/web';
import sha256 from 'crypto-js/sha256';
import {Formik} from 'formik';
import {useHistory} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import {useTheme} from 'styled-components';
import * as Yup from 'yup';

import atoms from '../../atoms';
import CircularLoader from '../../components/CircularLoader';
import {FormHeading} from '../../components/FormHeading';
import FormSkeleton from '../../components/FormSkeleton';
import GenericErrorModal from '../../components/GenericErrorModal';
import NextStepsQualify from '../../components/NextStepsQualify';
import Stepper from '../../components/Stepper';
import {BodyReg, Header4} from '../../components/newTypography';
import ContactInformationImage from '../../images/hpa/your-details-banner.png';
import YourPackageImage from '../../images/hpa/your-package-banner.png';
import YourPowerImage from '../../images/hpa/your-power-banner.png';
import {solarUrl} from '../../navigation/routes';
import {useFeaturesContext} from '../../provider/features';
import {APPLICATION_ID} from '../../services/HttpServices';
import {logger} from '../../services/Logger';
import LookSeeServices from '../../services/LookSeeServices';
import {sendComponentAnalytics} from '../../utils/Analytics';
import ContactInformation from './ContactInformation';
import Packages from './Packages';
import YourPower from './YourPower';
import {
  validationSchemaFinancing,
  validationSchemaNoFinancing,
  initialValuesFinancing,
  initialValuesNoFinancing,
} from './formModels';

const nextStepsValues = [
  {
    title: 'Finalise system preference',
    body: 'Look at the proposal and make sure the system you’ve selected is suitable for your needs',
  },
  {
    title: 'Arrange a site visit',
    body: 'Email us with your available dates and times for when our installer can do a site visit.',
  },
];
const finaliseSolar = {
  title: 'Finalise Solar Loan application',
  body: "We'll guide you through the final steps for a quick and seamless solar loan application",
};

const creditAssessmentResult = code =>
  code === 'A' ? 'Solar loan approved' : 'Solar loan declined';

export default function SolarApplication() {
  const theme = useTheme();
  const history = useHistory();
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [genericErrorModal, setGenericErrorModal] = useState({});
  const user = useRecoilValue(atoms.user);
  const wantSolarLoan = useRecoilValue(atoms.solarLoanFinancing);
  const [response, setResponse] = useState();
  const [isSubmitting, toggleIsSubmitting] = useToggle(false);
  const features = useFeaturesContext();
  const [nextSteps, setNextSteps] = useState(nextStepsValues);

  const defaultValues = wantSolarLoan
    ? initialValuesFinancing
    : initialValuesNoFinancing;

  const initialValues = useMemo(
    () => ({
      ...defaultValues,
      firstName: user?.first_name || '',
      lastName: user?.last_name || '',
      email: user?.email || '',
    }),
    [user?.email, user?.first_name, user?.last_name, wantSolarLoan],
  );

  useConditionalEffect(() => {}, [wantSolarLoan], [wantSolarLoan]);

  const validationSchemas = wantSolarLoan
    ? validationSchemaFinancing
    : validationSchemaNoFinancing;

  const onBackClick = useCallback(() => {
    if (currentStepIndex === 0) {
      history.push(solarUrl);
    } else {
      setCurrentStepIndex(s => s - 1);
    }
  }, [currentStepIndex, history]);

  useEffect(() => {
    window.history.pushState(null, null, location.href);
    if (history.action === 'POP' && currentStepIndex !== 0) {
      onBackClick();
    }
  }, [history.location]);

  useEffect(() => {
    window.scrollTo({left: 0, top: 0, behavior: 'smooth'});
  }, [currentStepIndex]);

  useEffect(() => {
    if (response?.data?.finDecisionCod === 'A') {
      setNextSteps([...nextStepsValues, finaliseSolar]);
    } else {
      setNextSteps(nextStepsValues);
    }
  }, [response]);

  const steps = [
    {
      title: 'Your Usage Details',
      header: (
        <FormHeading
          heading="How much electricity do you use?"
          subtitle="Sharing your electricity consumption helps us determine a suitable solution."
          imageUrl={YourPowerImage}
          gradient={theme.gradient.yourDetailsBanner}
        />
      ),
      body: (
        <Container maxWidth="md">
          <FormSkeleton
            onBackClick={onBackClick}
            formName="Your Usage Details"
            submitButtonText="Continue"
            stackedButtons
            buttonPartialDataText="How much electricity do you use? | Your Usage Details">
            <YourPower />
          </FormSkeleton>
        </Container>
      ),
      validationSchema: validationSchemas.yourPower,
    },
    {
      title: 'Your Solution',
      header: (
        <FormHeading
          heading="View range of solutions"
          subtitle="Select a suitable solution to continue"
          imageUrl={YourPackageImage}
          gradient={theme.gradient.yourDetailsBanner}
        />
      ),
      body: (
        <Box paddingX="10%">
          <Suspense fallback={<div>Loading...</div>}>
            <Packages onBackClick={onBackClick} />
          </Suspense>
        </Box>
      ),
      validationSchema: validationSchemas.packages,
    },
    {
      title: 'Contact Information',
      header: (
        <FormHeading
          heading="Contact Information"
          subtitle="To receive the proposal to your inbox"
          imageUrl={ContactInformationImage}
          gradient={theme.gradient.yourDetailsBanner}
        />
      ),
      body: (
        <Container maxWidth="md">
          <FormSkeleton
            onBackClick={onBackClick}
            formName="Contact Information"
            submitButtonText="Submit"
            finalStep
            buttonPartialDataText="contact information">
            <ContactInformation />
          </FormSkeleton>
        </Container>
      ),
      validationSchema: validationSchemas.contactInformation,
    },
  ];

  const checkStepCompleted = useCallback(
    (step, values) => step.validationSchema.isValidSync(values),
    [],
  );

  const isOnLastStep = currentStepIndex === steps.length - 1;
  const currentStep = steps[currentStepIndex];

  const fullValidationSchema = steps.reduce(
    (acc, step) => acc.concat(step.validationSchema),
    Yup.object(),
  );
  const currentValidationSchema = isOnLastStep
    ? fullValidationSchema
    : currentStep.validationSchema;

  const submitApplication = async values => {
    try {
      const res = await LookSeeServices.submitSolarApplication(values);
      setResponse(res);
      sendComponentAnalytics(
        {
          application: {
            applicationProduct: 'Solar',
            applicationMethod: 'online',
            applicationID: `${APPLICATION_ID}:${
              user?.id ? user.id : new Date().getTime()
            }`,
            applicationName: `application: pbb | solar | ${features.solarHPAConfig.solarHeaderBannerTitle}`,
            applicationStart: false,
            applicationComplete: true,
          },
        },
        'globalApplicationComplete',
      );
    } catch (error) {
      toggleIsSubmitting();
      logger.error(error);
      setGenericErrorModal({message: error.message, visible: true});
    }
  };

  const onSubmitClick = useCallback(
    async (values, formikActions) => {
      const emailHash = sha256(values.email).toString();
      if (currentStep?.title && currentStep?.title !== 'Your Solution') {
        sendComponentAnalytics(
          {
            formName: currentStep.title.toLowerCase(),
            formStatus: 'complete',
            formisSubmitted: true,
            hashed_msisdn: sha256(values.phoneNumber).toString(),
            email_sha256: emailHash,
          },
          'globalFormComplete',
        );
      }
      if (isOnLastStep) {
        toggleIsSubmitting();
        await submitApplication({
          ...values,
          analyticsUserId: emailHash,
          channelIndicator: 'LookSee',
        });
      } else {
        setCurrentStepIndex(s => s + 1);
        formikActions.setSubmitting(false);
      }
    },
    [isOnLastStep, currentStep],
  );

  const pageName = wantSolarLoan
    ? `products and services:Solar:Apply:${creditAssessmentResult(
        response?.data.finDecisionCod,
      )}:Next Steps`
    : 'products and services:Solar:Apply:Next Steps';
  if (response) {
    return (
      <NextStepsQualify
        heading="Your proposal has been emailed to you"
        pageName={pageName}
        nextSteps={nextSteps}
      />
    );
  }

  return (
    <Box bgcolor={theme.colors.white}>
      <Formik
        validationSchema={currentValidationSchema}
        validateOnMount
        initialValues={initialValues}
        onSubmit={onSubmitClick}>
        {formikProps => (
          <>
            {currentStep.header}
            <Container maxWidth="md">
              <Stepper
                onStepClick={setCurrentStepIndex}
                activeStepIndex={currentStepIndex}
                steps={steps}
                getStepCompleted={step =>
                  checkStepCompleted(step, formikProps.values)
                }
              />
            </Container>
            <Box display="flex" justifyContent="center">
              {currentStep.body}
            </Box>
            {isSubmitting && <CircularLoader open={isSubmitting} />}
          </>
        )}
      </Formik>
      <GenericErrorModal
        open={genericErrorModal.visible}
        onClose={setGenericErrorModal}
        onContinue={setGenericErrorModal}
        messageBody={
          <Box>
            <Header4 fontWeight={600}>Something Went Wrong.</Header4>
            <BodyReg>{genericErrorModal.message}</BodyReg>
          </Box>
        }
      />
    </Box>
  );
}
