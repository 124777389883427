import React, {useState, useEffect} from 'react';

import moment from 'moment';
import {useHistory} from 'react-router-dom';
import {useSetRecoilState} from 'recoil';
import {useTheme} from 'styled-components';

import atoms, {STRINGS} from '../../atoms';
import {productsServicesUrl} from '../../navigation/routes';
import {useMarketplaceContext} from '../../provider/marketplace';
import {checkCurrency} from '../../utils/formatting';
import {getRfQ} from '../../utils/marketplace';
import useWidth from '../../utils/useWidth';
import MoreInfo from './MoreInfo';
import QuoteBanner from './QuoteBanner';
import QuoteDetailMobile from './QuoteDetailMobile';
import {PENDING_AMOUNT, AWAITING_QUOTE_PRICE, PENDING} from './constants';
import {MobileHeader, MobileMainBackButton} from './quotedetailmobile.styles';
import {
  Wrapper,
  ProductImage,
  Text,
  Heading,
  BorderSummaryItemWrap,
  HeaderWrap,
  OrderNumber,
  TextBold,
  OrderInfo,
  Title,
  CardBody,
  HeaderTotal,
  ImageWrap,
  ItemName,
  FlexRow,
  FlexColumn,
  DetailsWrapper,
  Attributes,
  ItemPrice,
  MobileImageWrap,
  MobileItemInfo,
  MobileItemDetails,
  EmptyWrapper,
  EmptyText,
  TitleWrapper,
} from './styles';

const QuoteSummaryItem = () => {
  const width = useWidth();
  const history = useHistory();
  const theme = useTheme();
  const setSliderModal = useSetRecoilState(atoms.sliderModal);
  const [loading, setLoading] = useState(true);

  const {
    services: {getQuotes},
    selectors: {quotes},
  } = useMarketplaceContext();

  useEffect(() => {
    const getData = async () => {
      await getQuotes();
      setLoading(false);
    };

    getData();
  }, []);

  const clickQuoteMobile = quote => {
    if (width < 768) {
      setSliderModal(
        <QuoteDetailMobile
          serviceName={quote.rfqItems[0].familyCode}
          quote={quote}
          getPriceWithCurrency={getPriceWithCurrency}
        />,
      );
    }
  };

  const getPriceWithCurrency = ({quoteData: {price}}) => {
    return `${checkCurrency(price.currency)}${price.amount}`;
  };

  if (loading) {
    return <Wrapper>{STRINGS.LOADING}</Wrapper>;
  }

  if (!loading && !quotes.length) {
    return (
      <EmptyWrapper>
        <div>
          <img src={theme.images.EmptyBasket} alt="empty orders" />
          <EmptyText>{STRINGS.YOU_HAVE_NOT_ORDERED}</EmptyText>
        </div>
      </EmptyWrapper>
    );
  }

  return (
    <Wrapper>
      {quotes.length && width >= 768 && <Title>{STRINGS.MY_QUOTES}</Title>}
      {quotes.length && width < 768 && (
        <MobileHeader>
          <MobileMainBackButton
            src={theme.images.chevronLeft}
            onClick={() => history.push(productsServicesUrl)}
          />
          <div>{STRINGS.MY_QUOTES}</div>
        </MobileHeader>
      )}
      <div>
        {quotes.length &&
          quotes.map(quote => (
            <BorderSummaryItemWrap key={quote.rfqId}>
              <HeaderWrap onClick={() => clickQuoteMobile(quote)}>
                <div>
                  <Heading>{quote.stateDescription}</Heading>
                  {width >= 768 && (
                    <FlexColumn>
                      <TextBold>
                        Quote requested:
                        <Text>{moment(quote.rfqCreatedAt).format('LL')}</Text>
                      </TextBold>
                    </FlexColumn>
                  )}
                </div>
                <OrderInfo>
                  <HeaderTotal>
                    Total:{' '}
                    {quote.quoteData?.price?.amount
                      ? getPriceWithCurrency(quote)
                      : AWAITING_QUOTE_PRICE}
                  </HeaderTotal>
                  {width >= 768 && (
                    <OrderNumber>
                      Quote ID no:<Text>{quote.quoteId || PENDING}</Text>
                    </OrderNumber>
                  )}
                </OrderInfo>
              </HeaderWrap>
              <QuoteBanner state={quote.stateDescription} />
              <CardBody onClick={() => clickQuoteMobile(quote)}>
                <div>
                  <DetailsWrapper item={quote.rfqItems}>
                    <ImageWrap>
                      <ProductImage
                        src={getRfQ(quote.rfqItems[0].familyCode).image(theme)}
                      />
                    </ImageWrap>
                    <Attributes>
                      <FlexRow>
                        <MobileImageWrap>
                          <ProductImage
                            src={getRfQ(quote.rfqItems[0].familyCode).image(
                              theme,
                            )}
                          />
                        </MobileImageWrap>
                        <TitleWrapper>
                          <ItemName>
                            {getRfQ(quote.rfqItems[0].familyCode).name}
                          </ItemName>
                          {width >= 768 && (
                            <ItemPrice>
                              {quote.quoteData?.price?.amount || PENDING_AMOUNT}
                            </ItemPrice>
                          )}
                          {width < 768 && (
                            <MobileItemInfo>
                              <MobileItemDetails>
                                {quote.quoteData?.price?.amount ||
                                  PENDING_AMOUNT}
                              </MobileItemDetails>
                            </MobileItemInfo>
                          )}
                        </TitleWrapper>
                      </FlexRow>
                      <div
                        data-intent="informational"
                        data-scope="link"
                        data-id="link_content"
                        data-text="Quote | Provider | More info link click">
                        <MoreInfo
                          border
                          type="provider"
                          info={quote.rfqItems[0]}
                        />
                      </div>

                      {quote.rfqItems[0].familyCode === 'homeCareServices' ? (
                        <div
                          data-intent="informational"
                          data-scope="link"
                          data-id="link_content"
                          data-text="Quote | Additional | More info link click">
                          <MoreInfo
                            type="additinal-notes"
                            additionalNotes={quote.rfqData.additionalNotes}
                          />
                        </div>
                      ) : (
                        <div
                          data-intent="informational"
                          data-scope="link"
                          data-id="link_content"
                          data-text="Quote | Details | More info link click">
                          <MoreInfo type="details" info={quote.rfqData} />
                        </div>
                      )}
                    </Attributes>
                  </DetailsWrapper>
                </div>
              </CardBody>
            </BorderSummaryItemWrap>
          ))}
      </div>
    </Wrapper>
  );
};
export default QuoteSummaryItem;
