import React, {useEffect, useState} from 'react';

import {Grid} from '@material-ui/core';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {useTheme} from 'styled-components';

import atoms, {STRINGS} from '../../atoms';
import LookSeeServices from '../../services/LookSeeServices';
import PingIdentityService from '../../services/PingIdentityServices';
import Button from '../Button';
import {OptionsModal} from '../OptionsModal';
import {Checkbox, TermsLink, TermsText, TermsWrapper} from './styles';

export default function TermsOfUseModal() {
  const theme = useTheme();

  const [showTCModal, setShowTCModal] = useRecoilState(
    atoms.termsAndConditions,
  );
  const [termsOfUse, setTermsOfUse] = useState(false);
  const [termsOfCom, setTermsOfCom] = useState(false);
  const [user, setUser] = useRecoilState(atoms.user);
  const setOnboard = useSetRecoilState(atoms.onboard);
  const setLogout = useSetRecoilState(atoms.logout);

  const TCModalOptions = {
    header: 'Looksee Terms of Use',
    bodyList: [''],
    image: theme.images.SignUp,
    visible: showTCModal,
    contained: true,
    zIndex: 10,
  };

  useEffect(() => {
    if (user && !user.accepted_terms) {
      setShowTCModal(true);
    } else {
      setShowTCModal(false);
    }
  }, [user]);

  const acceptUserTerms = async () => {
    const term = await LookSeeServices.getTermsAndConditions('site-terms');
    const result = await LookSeeServices.updateTermsAndConditions(
      term.type,
      true,
    );

    if (result.accepted) {
      setUser(currentUser => {
        return {...currentUser, accepted_terms: result.accepted};
      });
      setShowTCModal(false);
    }
  };

  return (
    <OptionsModal {...TCModalOptions}>
      <TermsWrapper>
        <Checkbox
          displayErrorText
          type="checkbox"
          id="terms"
          value={termsOfUse}
          onClick={() => setTermsOfUse(!termsOfUse)}
        />
        <TermsText>
          {STRINGS.BY_SIGNING_UP}
          <TermsLink href="/terms-and-conditions">
            {' '}
            {STRINGS.TERMS_AND_CONDITIONS}
          </TermsLink>
        </TermsText>
      </TermsWrapper>
      <TermsWrapper>
        <Checkbox
          displayErrorText
          type="checkbox"
          id="commsAcceptance"
          value={termsOfCom}
          onClick={() => setTermsOfCom(!termsOfCom)}
        />
        <TermsText>{STRINGS.COMMS_TERMS}</TermsText>
      </TermsWrapper>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}>
        <Grid item sm={6} xs={12}>
          <Button
            data-text="register user | decline button click"
            data-id="link_content"
            data-scope="button"
            data-intent="navigational"
            width="100%"
            text="Decline"
            onClick={async () => {
              setLogout(true);
              await PingIdentityService.logout();
              setUser(null);
              setOnboard(0);
            }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Button
            data-text="register user | agree button click"
            data-id="link_content"
            data-scope="button"
            data-intent="confirmational"
            width="100%"
            text="Agree"
            disabled={!termsOfUse || !termsOfCom}
            onClick={acceptUserTerms}
          />
        </Grid>
      </Grid>
    </OptionsModal>
  );
}
