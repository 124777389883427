import React from 'react';

import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {useTheme} from 'styled-components';

import useProductsServicesNavigation from '../../hooks/useProductsServicesNavigation';
import {productsServicesUrl} from '../../navigation/routes';
import {HOME} from './constants';
import {Wrapper, Item, Icon} from './styles';

function MarketplaceBreadcrumbs({
  productName,
  categoryName,
  subcategoryName,
  categoryCode,
  subcategoryCode,
}) {
  const theme = useTheme();
  const history = useHistory();
  const {goToCategory, goToSubcategory} = useProductsServicesNavigation();

  const createCrumbs = () => (
    <>
      <Item onClick={() => history.push(productsServicesUrl)}>{HOME}</Item>
      <Icon src={theme.images.chevronRightBreadcrumbs} />
      <Item onClick={() => goToCategory(categoryCode)}>{categoryName}</Item>
      <Icon src={theme.images.chevronRightBreadcrumbs} />
      <Item onClick={() => goToSubcategory(subcategoryCode, categoryCode)}>
        {subcategoryName}
      </Item>
      <Icon src={theme.images.chevronRightBreadcrumbs} />
      <Item noLink>{productName}</Item>
    </>
  );

  return <Wrapper>{createCrumbs()}</Wrapper>;
}

MarketplaceBreadcrumbs.propTypes = {
  productName: PropTypes.string,
  categoryName: PropTypes.string,
  subcategoryName: PropTypes.string,
  categoryCode: PropTypes.string,
  subcategoryCode: PropTypes.string,
};

export default MarketplaceBreadcrumbs;
