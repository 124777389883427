import React from 'react';

import {Box, Card, CardActionArea, CardContent} from '@material-ui/core';
import PropTypes from 'prop-types';

import {capitalizeString} from '../../utils/formatting';
import Currency from '../Currency';
import Link from '../Link';
import {BodySm} from '../newTypography';
import {Wrapper, TitleImage, ImageWrapper} from './styles';

const CarouselProductCard = ({title, data, showAll, similarProduct}) => {
  const {name, amount, imageUrl, itemCode, subcategoryCode} = data || {};

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <Wrapper showAll={showAll}>
      <Link
        data-intent="navigational"
        data-scope="card"
        data-id="link_content"
        data-text={`${title} | ${name}`}
        to={similarProduct ? `${itemCode}` : `${subcategoryCode}/${itemCode}`}>
        <Box component={Card} maxWidth="250px" variant="outlined">
          <CardActionArea>
            <ImageWrapper>
              <TitleImage src={imageUrl[0]?.name} />
            </ImageWrapper>
            <Box
              component={CardContent}
              height={145}
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="space-between">
              <BodySm>{capitalizeString(name)}</BodySm>
              <Box>
                <BodySm inline>
                  <Currency value={amount} />
                </BodySm>
              </Box>
            </Box>
          </CardActionArea>
        </Box>
      </Link>
    </Wrapper>
  );
};

CarouselProductCard.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    imageUrl: PropTypes.array,
    amount: PropTypes.number,
    itemCode: PropTypes.string,
  }),
  title: PropTypes.string,
  showAll: PropTypes.bool,
  similarProduct: PropTypes.bool,
};

export default CarouselProductCard;
