import React from 'react';

import {Box, CardActions, CardContent, Grid} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {useTheme} from 'styled-components';

import {RoundedButton} from '../../components/Button';
import {
  BodyExLg,
  BodyReg,
  BodySm,
  calcPreferredValue,
} from '../../components/newTypography';
import useDetectMobile from '../../hooks/useDetectMobile';
import {solarApply, solarLoanApply} from '../../navigation/routes';
import {useFeaturesContext} from '../../provider/features';
import {Arrow, BannerImage, CenterBox, LearnMoreLink} from './styles';

export default function HeroBannerSection() {
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useDetectMobile();
  const Component = isMobile ? CenterBox : Box;
  const {
    solarLoanConfig: {learnMoreUrl},
  } = useFeaturesContext();

  const details = [
    {
      title: 'Full system financing',
      image: (
        <img
          src={theme.images.systemFinancing}
          alt="system financing illustration"
        />
      ),
    },
    {
      title: 'Flexible payment terms',
      image: (
        <img src={theme.images.paymentTerms} alt="payment terms illustration" />
      ),
    },
    {
      title: 'Low interest rates',
      image: (
        <img src={theme.images.interestRate} alt="interest rate illustration" />
      ),
    },
  ];

  return (
    <BannerImage imageUrl={theme.images.TopBanner} py="2%">
      <Box mx="5%" sx={{my: {xs: '5%', md: '1%'}}}>
        <Box
          borderRadius="32px 32px 2px 2px"
          mx="5%"
          mb="1px"
          px="2%"
          bgcolor={theme.colors.whiteTransparent}
          display="flex"
          justifyContent="center"
          sx={{maxWidth: {xs: '90%', md: '50%'}, py: {xs: '2.5%', md: '1%'}}}>
          <Box
            component="a"
            color={theme.colors.newPrimary}
            textDecoration="none"
            display="inline-flex"
            alignItems="center"
            style={{cursor: 'pointer'}}
            onClick={() => history.push(solarLoanApply)}>
            <BodyReg
              color={theme.colors.newPrimary}
              fontSize="16px"
              lineHeight="1.5rem"
              m="0%">
              How much <strong>do I qualify for?</strong> <Arrow />
            </BodyReg>
          </Box>
        </Box>
        <Box
          borderRadius="2px 2px 14px 14px"
          mx="5%"
          p="2%"
          bgcolor={theme.colors.whiteTransparent}
          justifyContent="center"
          sx={{maxWidth: {xs: '90%', md: '50%'}}}>
          <Component component={CardContent} textAlign="center">
            {learnMoreUrl && (
              <Box display="flex" flexDirection="column">
                <Box>
                  <BodySm inline>
                    We’re proud to be participating in the Government Energy
                    Bounce Back Loan Scheme
                  </BodySm>
                </Box>
                <Box>
                  <LearnMoreLink
                    data-intent="navigational"
                    data-scope="link"
                    data-id="link_content"
                    data-text="We’re proud to be participating in the Government Energy Bounce Back Loan Scheme | learn more link click"
                    href={learnMoreUrl}
                    target="_blank"
                    rel="noopener noreferrer">
                    Learn more <Arrow />
                  </LearnMoreLink>
                </Box>
              </Box>
            )}
            <BodyExLg
              lineHeight={0}
              color={theme.colors.tertiary}
              fontWeight="700"
              m={0}
              fontSize={`clamp(1.25rem, ${calcPreferredValue(
                54,
                20,
              )}, 3.375rem)`}>
              Apply for a Solar Loan
            </BodyExLg>
            <BodySm lineHeight={0} m={0}>
              LookSee’s Solar Loan from Standard Bank helps households access
              the benefits of solar power, regardless of who they bank with.
            </BodySm>
            <Grid container spacing={2}>
              {details.map(({title, image}) => (
                <Grid item xs={12} sm={4} key={title}>
                  <BodyReg fontWeight={700} color={theme.colors.tertiary}>
                    {title}
                  </BodyReg>
                  {image}
                </Grid>
              ))}
            </Grid>
          </Component>
          <CardActions>
            <Box
              display="flex"
              justifyContent="center"
              width="100%"
              sx={{
                flexDirection: {xs: 'column', sm: 'row'},
                gridGap: {xs: '8px', sm: '40px'},
              }}>
              <Box>
                <Box
                  component={RoundedButton}
                  width="100%"
                  data-intent="navigational"
                  data-scope="button"
                  data-id="link_content"
                  data-text="Apply for a Solar Loan | Get a financed solar system button click"
                  text="Get a financed solar system"
                  backgroundColor={theme.colors.secondary}
                  textColor={theme.colors.tertiary}
                  onClick={() => history.push(solarApply)}
                />
              </Box>
              <Box>
                <Box
                  component={RoundedButton}
                  width="100%"
                  data-intent="navigational"
                  data-scope="button"
                  data-id="link_content"
                  data-text="Apply for a Solar Loan | Only apply for a Solar Loan button click"
                  text="Only apply for a Solar Loan"
                  border="1px solid"
                  backgroundColor={theme.colors.white}
                  borderColor={theme.colors.tertiary}
                  textColor={theme.colors.tertiary}
                  onClick={() => history.push(solarLoanApply)}
                />
              </Box>
            </Box>
          </CardActions>
        </Box>
      </Box>
    </BannerImage>
  );
}
