import React from 'react';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import {useTheme} from 'styled-components';

import {Header5} from '../../components/newTypography';
import {StyledAccordion, StyledAccordionDetails, StyledSummary} from './styles';

function FaqsAccordion({title, children}) {
  const theme = useTheme();

  return (
    <StyledAccordion
      data-intent="informational"
      data-scope="accordion"
      data-id="link_content"
      data-text={`FAQ | ${title} accordion click`}>
      <StyledSummary expandIcon={<ExpandMoreIcon />}>
        <Header5 color={theme.colors.blueMobileNav}>{title}</Header5>
      </StyledSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  );
}

FaqsAccordion.propType = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
};

export default FaqsAccordion;
