import styled from 'styled-components';

export const CheckboxWrapper = styled.input`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  margin-right: ${({theme}) => theme.spacing.normal};
`;

export const TermText = styled.label`
  display: flex;
  align-items: center;
  font-weight: 400;
  flex-shrink: 1;
`;
