export const DEFAULT_USER_STATE = undefined;
export const TIMEOUT_ERROR = 'The user aborted a request.';

export const STRINGS = {
  BATTERY_SOLAR: 'batterySolar',
  BATTERY_BACKUP: 'batteryBackup',
  SHARE_PRODUCT: 'Have a LookSee at this item I found:',
  SHOP_NOW: 'Shop now',
  FIND_OUT_MORE: 'Find out more',
  NO_THANKS: 'No thanks',
  CRIME: 'crime',
  SECURITY: 'security',
  WATER: 'water',
  BASKET_EMPTY: 'Basket empty',
  NO_IMAGE: 'No image',
  DELETE: 'Delete',
  SAVE_FOR_LATER: 'Save for later',
  BASKET: 'Basket',
  YOUR_BASKET: 'Your basket',
  SUBTOTAL: 'Subtotal',
  VIEW_BASKET: 'View basket',
  CHECKOUT: 'Checkout',
  ITEM_ADDED_TO_BASKET: 'Item added to basket',
  GO_TO_BASKET: 'Go to basket',
  CONTINUE_SHOPPING: 'Continue shopping',
  GOT_IT: 'Got it!',
  WELL_BE_IN_TOUCH: 'We’ll be in touch!',
  CALL_ME_BACK: 'Call me back',
  ONE_OF_OUR_TEAM:
    'One of our team will call you shortly to discuss your request.',
  CANCELLATION_REQUEST: 'Cancellation request',
  ARE_YOU_SURE:
    'If you cancel this order you will be refunded your payment minus fees charged by our escrow provider',
  CANCEL_MY_ORDER: 'Cancel my order',
  I_HAVE_READ_TERMS: 'I have read Terms and Conditions',
  INSTALLATION_INCLUDED: 'Cost includes installation',
  SERVICE_INCLUDED: 'Service included',
  FULL_INSTALLATION:
    'This purchase includes full installation service including consumables.',
  SHOW_ALL: 'Show all',
  CHANGE_PASSWORD: 'Change password',
  PICK_A_NEW_PASSWORD: 'Pick a new password for your account',
  CONTINUE: 'Continue',
  ENTER_YOUR_CURRENT_PASSWORD: 'Enter your current password',
  APPOINTMENT_SLOT: 'Appointment slot:',
  CONFIRM_YOUR_PROPERTY: 'Confirm your property',
  PLEASE_CONFIRM_WHICH_PROPERTY:
    'Please confirm which property belongs to you. We’ve supplied a list of your recent searches, or you can search for another property below.',
  SEARCH_FOR_PROPERTY: 'Search for a property to add it to your profile.',
  RECENT_SEARCHES: 'Recent Searches',
  SEARCH_FOR_AN_ADDRESS: 'Search for an address',
  SKIP: 'Skip',
  PROPERTY_ADDED: 'Property added',
  YOU_HAVE_SUCCESSFULLY_ADDED:
    'You have successfully added this property to your profile. You can now start managing your property from the property hub.',
  EDIT: 'Edit',
  CREATE: 'Create',
  ARE_YOU_SURE_ABANDON: 'Are you sure you want to abandon this post?',
  ANY_TEXT_OR_ATTACHMENTS: 'Any text or attachments will be lost.',
  DISCARD_POST: 'Discard post',
  KEEP_EDITING: 'Keep editing',
  LOADING: 'Loading...',
  THERE_ARE_NO_MORE_POSTS: 'There are no more posts',
  ARE_YOU_SURE_LEAVE: 'Are you sure you want to leave this community?',
  YOU_CAN_REJOIN: 'You can rejoin this community at any time.',
  NEVER_MIND: 'Never mind',
  YES_LEAVE_COMMUNITY: 'Yes, leave community.',
  INVITE_NEW_MEMBER: 'Invite new member',
  LEAVE_COMMUNITY: 'Leave community',
  ARE_YOU_SURE_DELETE: 'Are you sure you want to delete this post?',
  THIS_ACTION_CANNOT: 'This action cannot be undone.',
  YES_DELETE_POST: 'Yes, delete post.',
  START_TIME: 'Start time',
  FINISH_TIME: 'Finish time',
  FINISH_TIME_OPTIONAL: 'Finish time (optional)',
  ADD_EVENT_LOCATION: 'Add event location',
  ADD_PHOTO_OPTIONAL: 'Add photo (optional)',
  SAVE_CHANGES: 'Save changes',
  CREATE_EVENT: 'Create event',
  TITLE: 'title',
  DESCRIPTION: 'description',
  DESCRIPTION_OPTIONAL: 'description (optional)',
  POLL_NAME: 'Poll name',
  WHAT_IS_YOUR_POLL_ABOUT: 'What is your poll about?',
  TYPE_A_DESCRIPTION: 'Type a description',
  WRITE_SOMETHING: 'Write something',
  ADD_ANOTHER_CHOICE: 'Add another choice',
  CHOOSE_A_DATE: 'Choose a date',
  END_TIME: 'End time',
  CHOOSE_AN_END_TIME: 'Choose an end time',
  ENTER_POOL_MANUALLY: 'Enter Pool Manually',
  LINK_TO_MARKETPLACE: 'Link to Marketplace',
  POOL_NAME: 'Pool Name',
  WHAT_ARE_YOU_POOLING_FOR: 'What are you pooling for?',
  WRITE_A_DESCRIPTION: 'Write a description of your pool. (optional)',
  HOW_MUCH_ARE_YOU_POOLING: 'How much are you pooling for?',
  CONFIRM: 'Confirm',
  CANNOT_ADD_TO_EXISTING: 'Cannot add to existing pool',
  LINK_TO_MARKETPLACE_ITEM: 'Link to Marketplace item',
  STATUS: 'Status',
  EVENT: 'Event',
  POOL: 'Pool',
  POLL: 'Poll',
  CREATE_A_POST: 'Create a post',
  ADD_PHOTO: 'Add photo',
  ADD_VIDEO: 'Add video',
  ADD_LOCATION: 'Add location',
  EDIT_LOCATION: 'Edit location',
  POST: 'Post',
  CLOSE_DATE_OPTIONAL: 'Close date (optional)',
  YOUR_PROPERTIES: 'Your properties',
  ADD_NEW_ADDRESS: 'Add new address',
  TIME: 'Time',
  INVALID_TOKEN: 'Invalid token',
  SESSION_HAS_EXPIRED: 'Session has expired',
  UNAUTHORIZED: 'Unauthorized',
  EMAIL_DOES_NOT_EXIST: 'Email does not exist',
  TIMEOUT: 'Timeout',
  CONNECTION_TIMED_OUT: 'Connection timed out',
  FETCH_ERROR: 'Fetch error',
  COULD_NOT_RETRIEVE_RESULTS: 'Could not complete search',
  VALUATION_ERROR: 'Valuation error',
  FAILED_TO_RETRIEVE_PROPERTY_VALUATION:
    'Failed to retrieve property valuation',
  UNFORTUNATELY_WE_CANT_FIND:
    "Unfortunately, we can't find the information for this property. Please try again.",
  HUB_ACCESS_ERROR: 'Hub access error',
  ADD_YOUR_PROPERTY_TO_ACCESS: 'Add your property to access the hub',
  AS_SOON_AS_YOU_ADD_PROPERTY:
    'As soon as you add your property, you will gain access to all of the Looksee features, including insights, marketplace, and community.',
  CANCELLATION_ERROR: 'Cancellation error',
  SOMETHING_WENT_WRONG_CANCEL:
    'Something went wrong while trying to cancel your order. Please try again.',
  DISPUTE_ERROR: 'Dispute error',
  SOMETHING_WENT_WRONG_DISPUTE:
    'Something went wrong while trying to send your dispute. Please try again.',
  CONFIRM_ERROR: 'Confirm error',
  SOMETHING_WENT_WRONG_CONFIRM:
    'Something went wrong while trying to confirm your order. Please try again.',
  INVALID_FILETYPE: 'Invalid Filetype',
  FILETYPE_NOT_SUPPORTED:
    'The filetype that was uploaded is not supported. Please convert to a different format and try again.',
  LIMIT_ERROR: 'Limit error',
  UNABLE_TO_ADD_ITEM_LIMIT:
    'Unable to add the item to the basket because there is a limit on the amount you can spend in one transaction. Ensure the basket total is below R50,000.',
  SOMETHING_WENT_WRONG:
    'Sorry, it looks like something went wrong. Please try again.',
  USER_WITH_MATCHING_EMAIL: 'User with matching email not found',
  GOING: 'Going',
  MAYBE: 'Maybe',
  DECLINE: 'Decline',
  FEEDBACK: 'Feedback',
  SHOULD_YOU_FACE_ANY_ISSUE:
    'Should you face any issue, feel free to contact us. We will get back to you as soon as we can!',
  SEND: 'Send',
  CURRENCY: '£',
  BIG_ONE: 'Big One',
  LITTLE_ONE: 'Little One',
  LEGAL: 'Legal',
  TERMS_AND_CONDITIONS_PREAMBLE: 'I have read and agree to the',
  TERMS_AND_CONDITIONS: 'Terms and Conditions',
  TERMS_AND_CONDITIONS_REQUIRED: 'You must agree to the Terms and Conditions',
  PRIVACY_POLICY: 'Privacy Policy',
  MANAGE_COOKIES: 'Manage Cookies',
  CONTACT_US: 'Contact Us',
  HELP: 'Help',
  NOTIFY_ME: 'Notify Me',
  BACK: 'Back',
  MANAGE_ACCOUNT: 'Manage Account',
  CREATE_ACCOUNT: 'Create Account',
  HOME_MANAGEMENT: 'Home Management',
  WANT_TO_KNOW_MORE: 'Want to know more?',
  ADD_MY_PROPERTY: 'Add my property',
  SIGN_UP_WITH_LOOKSEE: 'Sign up with LookSee and verify your identity',
  SIGN_OUT: 'Sign out',
  SETTINGS: 'Settings',
  SOMETHING_WENT_WRONG_TITLE: 'Something went wrong',
  YOUR_PAYMENT_HAS_NOT_GONE_THROUGH:
    'Your payment has not gone through. Please try again inputting your details again, making sure that all information is filled in and correct.',
  INCLUDING_SERVICE: 'Including service',
  ADDRESS: 'Address',
  QUANTITY: 'Quantity',
  THANKS_FOR_YOUR_ORDER: 'Thanks for your order!',
  TRACK_MY_ORDERS: 'Track my orders',
  ORDER_SUMMARY: 'Order summary',
  TOTAL_PAID: 'Total paid',
  PAYMENT_LINK_ERROR: 'Payment link error',
  YOU_HAVE_NOT_ORDERED: 'You have not ordered anything.',
  MY_ORDERS: 'My Orders',
  ORDERS: 'Orders',
  CANCEL_ORDER: 'Cancel order',
  CANCEL: 'Cancel',
  RAISE_ISSUE: 'Raise issue',
  ORDER_COMPLETE: 'Order complete',
  CONTINUE_WITH_MY_ORDER: 'Continue with my order',
  CREDIT_CARD_INFO:
    'One last thing, please note that due to the size of your order, we will only be able to process your transaction via EFT, your transaction is still fully protected by our escrow provider',
  LESS_INFO: 'Less Info',
  MORE_INFO: 'More Info',
  ORDER_DETAILS: 'Order details',
  PROVIDER: 'Provider',
  DISPATCH: 'Dispatch',
  PASSWORD_CANNOT_CONTAIN:
    'Password cannot contain first name, last name, or email',
  PASSWORD_MUST_CONTAIN:
    "Password must be at least 8 characters long, contain at least 1 capital letter, 1 lower case letter, 1 special character, contain at least 2 numbers, and cannot contain user's email, first name or last name.",
  PREV_PAGE: 'Prev Page',
  NEXT_PAGE: 'Next Page',
  SUBMIT_VOTE: 'Submit Vote',
  SUBMIT_ANONYMOUSLY: 'Submit Anonymously',
  JUMP_TO_RESULTS: 'Jump to results',
  BACK_TO_VOTE: 'Back to Vote',
  PLEDGE_TO_POOL: 'Pledge to Pool',
  REGISTER_SIGN_IN: 'Register / Sign in',
  SIGN_IN: 'Sign in',
  NOTIFICATIONS: 'Notifications',
  ADD_TO_POOL: 'Add to pool',
  ADD_TO_BASKET: 'Add to basket',
  ADD_TO_COMMUNITY_POOL: 'Add to community pool',
  WERE_SORRY_TO_HEAR:
    "We're very sorry to hear things didn't go as planned. Please let us know the details of your issue below.",
  ENTER_DETAILS_OF_DISPUTE: 'Enter the details of your dispute here',
  ACCOUNT_DETAILS: 'Account details',
  PLEASE_ENTER_ACCOUNT_DETAILS:
    'Please enter your account details so that we can return your money',
  POST_SUCCESSFULLY_REPORTED: 'Post successfully reported',
  THANK_YOU_FOR_REPORT:
    'Thank you for report. We will review whether this post in in line with our community standards. If this post violates our community standards it we be removed. We will let this user know that their post has been removed, but not who reported it.',
  MINIMISE: 'Minimise',
  ADD_YOUR_PROPERTY_TO_ACCESS_INSIGHTS:
    'Add your property to access insights, actions and offers to help you manage your home. You must be the owner of the property you want to add.',
  EDIT_POST: 'Edit post',
  DELETE_POST: 'Delete post',
  REPORT: 'Report',
  CONGRATULATIONS: 'Congratulations',
  ACCOUNT_SUCCESSFULLY_CREATED:
    'Your account has been successfully created! Remember to verify your account to access all features - there is an email waiting for you in your inbox!',
  RESEND_LINK: 'Resend link',
  SUCCESSFULLY_RESET_PASSWORD: 'You have successfully reset your password!',
  CHECK_YOUR_INBOX: 'Check your inbox!',
  VERIFICATION_LINK:
    "We've sent you a verification link. As soon as you click on the link to confirm your identity, you'll have access to all of our features.",
  WHERE_WOULD_YOU_LIKE_ORDER: 'Where would you like to receive your order?',
  ADDITIONAL_INFORMATION: 'Additional information',
  CONFIRMATION_AND_PAYMENT: 'Confirmation and Payment',
  PLEASE_ADD_ITEMS: 'Please add items to your basket.',
  ERROR_LOADING_BASKET: 'There was an error loading your basket.',
  YES: 'Yes',
  NO: 'No',
  ORDERS_SAME_ADDRESS: 'Are all of your orders going to the same address?',
  PLEASE_SELECT_OR_ADD_ADDRESS:
    'Please select or add the address that you would like your item to be delivered to and select a delivery slot.',
  AGREE_AND_CONTINUE: 'Agree and Continue',
  CONTINUE_TO_PAYMENT: 'Continue to payment',
  TOTAL_TO_PAY: 'Total to pay',
  WEVE_SENT_A_LINK: "We've sent a link to your email.",
  FORGOT_YOUR_PASSWORD: 'Forgot your password?',
  NOT_TO_WORRY: "Not to worry! We'll send a verification link to your email.",
  COMMUNITY_DETAILS_INFORMATION: 'Details information here',
  NO_EVENTS_AVAILABLE: 'No events available',
  NO_PLANNED_EVENTS:
    'There are no planned or unplanned events occuring in your area.',
  PENDING_VALUE: 'Pending',
  PROPERTY_INFORMATION: 'Property information',
  SIZE_OF_LAND: 'Size of land',
  AGE_OF_PROPERTY: 'Age of property',
  MUNICIPALITY: 'Municipality',
  MORE_DETAILS: 'More Details',
  LAST_SOLD: 'Last sold',
  ESTIMATED_RANGE: 'Estimated value range',
  LAST_SALE_PRICE: 'Last sale price',
  DELETE_PROPERTY: 'Delete Property',
  ADD_ANOTHER_PROPERTY: 'Add another property',
  WELCOME_BACK: 'Welcome back!',
  DONT_HAVE_ACCOUNT: "Don't have an account?",
  CREATE_ONE_HERE: 'Create one here',
  FORGOT_PASSWORD: 'Forgot password?',
  SAVED_FOR_LATER: 'Saved for later',
  RECENTLY_VIEWED: 'Recently viewed',
  YOUR_SAVED_ITEMS: 'Your saved items',
  SORRY_THERE_WAS_A_PROBLEM:
    'Sorry, there was a problem with your order. Please try again.',
  UNABLE_RETRIEVE_PROPERTY_INFORMATION:
    'Unable to retrieve property information',
  TOKEN_EXPIRED: 'Token expired',
  REQUEST_NEW_LINK: 'Please request a new link and try again',
  GO_BACK: 'Go back',
  RESET_YOUR_PASSWORD: 'Reset your password',
  EMAIL_ALREADY_IN_USE: 'Email address is already in use',
  GET_STARTED: 'Get started',
  ALREADY_HAVE_ACCOUNT: 'Already have an account?',
  CLICK_HERE_TO_SIGN_IN: 'Click here to sign in',
  BY_SIGNING_UP: 'By signing up I accept the LookSee*',
  COMMS_TERMS:
    'I consent to receive communication from LookSee and our third party service providers on the solutions I choose, which may also include account verification, information on saving money, improving my home, managing my home and enhancing my home life.',
  CHOOSE_A_PASSWORD: 'Choose a password',
  PLEASE_CHOOSE_SECURE_PASSWORD:
    'Please choose a secure password for your LookSee account',
  GO_BACK_TO_LOGIN: 'Go back to login',
  NEXT: 'Next',
  SUBMIT: 'Submit',
  WHEN_WOULD_YOU_LIKE_YOUR_INSPECTION:
    'When would you like your inspection to be?',
  PLEASE_SELECT_APPOINTMENT_TIME:
    'Please select a preferred appointment time for your service at',
  SELECT_TIME_SLOT: 'Select time slot',
  ADDITIONAL_CONTACT_REQUIRED: 'Is there an additional contact required?',
  PLEASE_PROVIDE_THE_DETAILS_BELOW:
    'Please provide the details below of the person that will be receiving the service.',
  PROPERTY_INSPECTION_SERVICE: 'Property inspection service',
  CLICK_BELOW_FOR_FREE_QUOTE:
    'Click below for a free quote for your inspection.',
  WHAT_YOU_GET: 'What you get',
  SEE_TERMS_AND_CONDITIONS: 'See terms and conditions',
  SIGN_UP: 'Sign up',
  LOG_IN: 'Log in',
  ADD_YOUR_PROPERTY: 'Add your property',
  ALMOST_THERE: 'Almost there! Please add your property below.',
  CANT_FIND_ADDRESS: "Can't find your address?",
  ENTER_PROPERTY_MANUALLY:
    'Enter your property’s details manually, and a request will be sent to add your property to the system',
  CHOOSE_A_TIME: 'Choose a time',
  MY_QUOTES: 'Quotes',
  QUOTE_DETAILS: 'Quote details',
  ERROR_RETRIEVING_QUOTE: 'Error retrieving quote',
  DOWNLOAD_REPORT: 'Download report',
  MESSAGES: 'Messages',
  SUBSCRIPTION_PAYMENT_ERROR:
    'Your payment has not gone through. Please try again inputting your details again, making sure that all information is filled in and correct.',
  READ_THE_TERMS_AND_ACCEPT_CONDITIONS:
    'I have read and accept the Terms and Conditions',
  SUBURB_NAME_OR_POSTAL_CODE: 'Suburb name or postal code',
  SEARCH_SUBURB: 'Search for a suburb to add it to your profile.',
  SEARCH_SUBURB_LABEL: 'Search for a suburb',
  NO_INSIGHTS_FOUND_ON_POSTAL_CODE:
    'Apologies, we could not find insights for your area',
  DEFAULT_FEED_STATE:
    'Right now, events are only available for Johannesburg, our team is working hard on rolling it out to all cities in SA',
  ADD_PROPERTY: 'Add property',
  ADD_SUBURB: 'Add suburb',
  SEARCH_YOUR_SUBURB: 'Search for your suburb',
  SEARCH_YOUR_SUBURB_MESSAGE: 'Enter your suburb to see personalised insights',
  ADD_PROPERTY_BODY:
    'To provide you with the most up to date information on your property, we will need your address first.',
  ADD_SUBURB_BODY:
    'To provide you with the most relevant localised insights, we will need your suburb or postal code',
  JOBURG_POSTAL_CODE: '2198',
  VIEW_ONLY_MODAL_HEADER: 'View Only',
  VIEW_ONLY_MODAL_BODY:
    "This page is a 'view only' page, with sample data to give you a quick view into LookSee's features.",
  CONTINUE_AS_GUEST: 'Continue as Guest',
  VIEW_ONLY_PROPERTY:
    "If you'd like to see the value of your property, log in or create an account.",
  VIEW_ONLY_INSIGHTS:
    "If you'd like to see insights specific to your area, log in or create an account",
  VIEW_ONLY_COMMUNITY:
    "If you'd like to see posts from your community, log in or create an account",
  VIEW_ONLY_FEED:
    "If you'd like to see events happening in you neighbourhood, log in or create an account",
  TOO_MANY_PROPERTY_REQUESTS_ERROR:
    'Too many property requests from this IP address, please try again later',
  TOO_MANY_REQUESTS_ERROR: 'Too Many Requests',
  COST_BREAKDOWN: 'See cost breakdown',
  SEE_MORE: 'See more',
  SEARCH_MARKETPLACE: 'Search marketplace.',
  HERE_TO_HELP: "We're here to help",
  PRODUCT_OVERVIEW: 'Product Overview',
  TRUSTED_INSPECTOR: 'Trusted inspector',
  INSPECTION_PROVIDERS:
    'Inspection providers are those trusted by Standard Bank for property valuations.',
  REQUEST_QUOTE: 'Request a quote',
  JOIN_WAITING_LIST: "I'm interested in this product",
  CALL_TO_BUY: 'Buy now',
  I_WANT_MORE_INFORMATION: "I'd like more information about ",
  GO_TO_FAQS: 'Go to FAQs',
  GOT_QUESTION: 'Got a question?',
  CREDIT_INFO_TIP:
    'Our interest rates are personalised and range from a minimum of 11.25% to a maximum of 24.75% as per the NCA regulation',
  CREDIT_STANDARD_BANK:
    'Get personal finance options for this product through Standard Bank',
  PRODUCT_NOT_AVAILABLE: 'This item is not applicable to your home',
  SO_SORRY: 'So sorry',
  ADDED_TO_WAITING_LIST:
    'We will be in touch as soon as this item becomes available',
  EXPERT_WILL_BE_IN_TOUCH: 'One of our experts will be in touch!',
  PLEASE_FILL_WAITING_LIST:
    'Please fill in this form to confirm your interest in this item.',
  PLEASE_FILL_CALL_TO_BUY:
    ' Please fill in this form and one of our experts will call you back to finalise your purchase.',
  PROCEED: 'Proceed',
  PROFILE: 'Profile',
  ACCOUNT_AND_SETTINGS: 'Account and Settings',
  TOKEN_REQUEST_WAIT: 'Error sending email verification',
  ERROR_VERIFY_EMAIL_RETRY:
    'Please wait 1 minute before trying to send verification email again',
  PI_CONSENT_FORM_PREAMBLE:
    'I agree to have the owner of the property fill in the',
  PI_CONSENT_FORM_LINK: 'consent form',
  SOLAR_SLIDE_ONE_TITLE:
    'Enjoy a solar-powered home by a solar partner you can trust!',
  SOLAR_SLIDE_ONE_BODY_A:
    'Together with Standard Bank, LookSee has partnered with ',
  SOLAR_SLIDE_ONE_BODY_B:
    ' to offer alternative power solutions for your home, from portable backup all the way to full off-grid.',
  SOLAR_SLIDE_TWO_TITLE: 'What can the system power?',
  SOLAR_SLIDE_TWO_BODY: 'Looking to start small? Systems are fully upgradable.',
  SOLAR_SLIDE_THREE_TITLE: 'How does it work?',
  SOLAR_SLIDE_THREE_BODY:
    'We help you choose the right installation for your home through:',
  SOLAR_SLIDE_FOUR_TITLE: 'Ready to go solar?',
  SOLAR_SLIDE_FIVE_TITLE:
    'Choose a payment method that best suits your pocket:',
  SOLAR_SLIDE_THREE_POINT_ONE: 'Rightsizing the system for your home',
  SOLAR_SLIDE_THREE_POINT_TWO:
    'Identifying the right solution provider for your needs',
  SOLAR_SLIDE_THREE_POINT_THREE:
    'Through Standard Bank, provide finance options for your solar installation',
  SOLAR_SLIDE_TWO_POINT_ONE: 'Gates',
  SOLAR_SLIDE_TWO_POINT_TWO: 'Garage Doors',
  SOLAR_SLIDE_TWO_POINT_THREE: 'Pool Pump',
  SOLAR_SLIDE_TWO_POINT_FOUR: 'CCTV',
  SOLAR_SLIDE_TWO_POINT_FIVE: 'Laptops',
  SOLAR_SLIDE_TWO_POINT_SIX: 'Lights',
  SOLAR_SLIDE_TWO_POINT_SEVEN: 'TV',
  SOLAR_SLIDE_TWO_POINT_EIGHT: 'Fridge',
  SOLAR_SLIDE_TWO_POINT_NINE: 'Internet',
  SOLAR_SLIDE_FOUR_POINT_ONE: 'Sign up',
  SOLAR_SLIDE_FOUR_POINT_TWO: 'Establish system requirements',
  SOLAR_SLIDE_FOUR_POINT_THREE: 'Choose your payment option',
  SOLAR_SLIDE_FOUR_POINT_FOUR: 'Site survey',
  SOLAR_SLIDE_FOUR_POINT_FIVE: 'System design confirmation',
  SOLAR_SLIDE_FOUR_POINT_SIX: 'Installation',
  SOLAR_SLIDE_FOUR_POINT_SEVEN: 'Power on',
  SOLAR_SLIDE_FIVE_POINT_ONE: 'Pay cash',
  SOLAR_SLIDE_FIVE_POINT_TWO: 'Use your access bond',
  SOLAR_SLIDE_FIVE_POINT_THREE: 'Get financing through Standard Bank',
  SOLAR_BROCHURE_BUTTON_ONE: 'Call me back for financing options',
  SOLAR_BROCHURE_BUTTON_TWO: 'Call me back for solar solutions',
  POWER_PULSE: 'PowerPulse',
  YOUR_DETAILS: 'Your details',
  SOLAR_SOLUTIONS_TITLE: 'Solar solution enquiry',
  SOLAR_SOLUTIONS_DESCRIPTION:
    'Please register your details below and one of our Customer Care team members will be in touch with you.',
  SOLAR_SOLUTIONS_THANKS:
    'We’re processing your request and one of our Customer Care team members will be in touch with you within 2-3 business days.',
  ACCEPT_TERMS: 'I agree to the LookSee',
  THANK_YOU_ENQUIRY: 'Thank you for your enquiry!',
  ENTER_MANUALLY: 'Enter manually',
  TURN_YOUR_GEYSER: 'Turn your geyser into a smart geyser and save',
  REDUCE_ELECTRICITY_USAGE: 'Reduce household electricity usage ',
  RECEIVE_ALERTS: 'Receive in App alerts',
  LESS_GEYSER_DAMAGE: 'Less damage if your geyser bursts',
  GEYSER_CONTROLLER: 'What is a smart geyser controller?',
  FOR_36_MONTHS: 'for 36 months',
  MONTHLY_COST: 'R99',
  LAST_MONTHS_SAVINGS:
    '“My last month’s account was R3052 and I am so so happy to say that this month I only needed to pay R2361"',
  MELONIE_FOLEY: 'Melonie Foley, Germiston',
  FEATURE_SAVINGS:
    '“Apart from all the other features, I saved R1552 over 12 months“',
  SAS_DU_PLESSIS: 'Sas Du Plessis, Centurion',
  SEE_MONEY_SAVINGS:
    '“I can see the money I’m saving live on the app on a daily basis”',
  JULES_NAICKER: 'Jules Naicker, Kempton Park',
  SEE_HOW_MUCH: 'See how much you can save',
  CALCULATE_SAVINGS:
    'Calculate how much you can save with our Smart Geyser solution',
  HOW_MUCH_DO_YOU_PAY:
    'How much do you pay for electricity on average per month?',
  CONSIDER_WINTER: 'Please consider winter and summer months.',
  CALCULATE: 'Calculate ',
  ESTIMATED_SAVINGS:
    'Estimated saving. Average of a typical home using the device as recommended',
  SAVINGS_EXCLUDING: 'Savings excluding monthly fee',
  ENQUIRY_TYPE: 'Enquiry type',
  REGISTER: 'register',
  LOGIN: 'login',
};

export const SOLAR_YOUR_POWER = {
  YOUR_POWER: 'Your Usage Details',
  YOUR_POWER_HEADING: 'How much electricity do you use?',
  YOUR_POWER_SUBTITLE:
    'Please provide us with your home power details so we can calculate your home power analysis',
  NOT_SURE: 'Not sure',
  ELECTRICITY_SUPPLY: 'What type of electricity connection do you have?',
  SINGLE_PHASE: 'Single phase',
  THREE_PHASE: 'Three phase',
  MONTHLY_BILL: 'Your average monthly electricity bill',
  MONTHLY_BILL_TOOLTIP:
    'To get the best estimate, please provide us with an average of the last 12 months',
  HIGHEST_BILL: 'The highest electricity bill you can remember',
  AVERAGE_TARIFF: 'Your average tariff in R/kWh',
  AVERAGE_TARIFF_TOOLTIP:
    'From your total electricity bill: 1. Subtract the network charges 2. Divide by the total kWh used in the month(If you don’t know your average the system will default to R2.50/kWh)',
  ENERGY_SUPPLIER: 'Your energy supplier',
  ENERGY_SUPPLIER_TOOLTIP: 'eg: City Power',
  UPLOAD_BILL: 'Please upload your latest electricity bill.',
  UPLOAD_BILL_TOOLTIP:
    "If you don't have access to it now, or you are on prepaid, skip this step and our concierge will be in contact with you.",
  ADD_DOCUMENT: 'Add document',
  GET_YOUR_HPA: 'Get your home power analysis',
};

export const FORM_REQUIREMENTS = {
  NUMERIC: 'Please enter a number value',
  FULL_NAME_IS_REQUIRED: 'Full name is required',
  NAME_IS_REQUIRED: 'First name is required',
  SURNAME_IS_REQUIRED: 'Last name is required',
  QUERY_IS_REQUIRED: 'Query is required',
  CONSENT_IS_REQUIRED:
    'You must consent to the collection of your personal information',
  EMAIL_IS_REQUIRED: 'Email is required',
  PHONE_IS_REQUIRED: 'Mobile number is required',
  WHEN_CONTACT:
    'When would you like to be contacted to complete your transaction?',
  WHAT_KNOW: 'What would you like to know about this product?',
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  EMAIL_ADDRESS: 'Email address',
  PHONE_NUMBER: 'Mobile number',
  CONSENT:
    'I give consent to pass on my details to LookSee and 3rd parties which may use WhatsApp, phone call or email as a means of communication to deliver the service. You may contact us to opt out.',
  FULL_NAME: 'Full Name',
  REQUIRED: 'Required',
  CHOOSE_ONE: 'Please choose one',
  POSITIVE_NUMBER: 'Must be a positive number',
  PROVINCE:
    'Must be one of the following values: Western Cape, Eastern Cape, Northern Cape, North West, Free State, Gauteng, Limpopo, Mpumalanga, KwaZulu-Natal',
};

export const CALL_ME_BACK_TYPE = {
  GENERAL: 'GENERAL',
};

export const SOLAR_CALL_ME_BACK_TYPE = [
  'Financing',
  'Solar solution',
  'Backup solution',
  'Other',
];

export const BANK_HOLIDAYS = [
  '2021-12-16',
  '2021-12-25',
  '2021-12-26',
  '2021-12-27',
  '2022-01-01',
  '2022-03-21',
  '2022-04-15',
  '2022-04-18',
  '2022-04-27',
  '2022-05-01',
  '2022-05-02',
  '2022-06-16',
  '2022-08-09',
  '2022-09-24',
  '2022-12-16',
  '2022-12-25',
  '2022-12-26',
  '2023-01-01',
  '2023-01-02',
  '2023-03-21',
  '2023-04-07',
  '2023-04-10',
  '2023-04-27',
  '2023-05-01',
  '2023-06-16',
  '2023-08-09',
  '2023-09-24',
  '2023-09-25',
  '2023-12-16',
  '2023-12-25',
  '2023-12-26',
  '2024-01-01',
  '2024-03-21',
  '2024-03-29',
  '2024-04-01',
  '2024-04-27',
  '2024-05-01',
  '2024-06-16',
  '2024-06-17',
  '2024-08-09',
  '2024-09-24',
  '2024-12-16',
  '2024-12-25',
  '2024-12-26',
].map(date => new Date(date));

export const TAB_OPTIONS = [
  {value: STRINGS.BATTERY_SOLAR, label: 'Solar'},
  {value: STRINGS.BATTERY_BACKUP, label: 'Battery Backup'},
];
