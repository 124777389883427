import React from 'react';

import {Transition} from 'react-transition-group';
import {useRecoilValue} from 'recoil';

import atoms from '../../atoms';
import {Container} from './styles';

const SliderModal = () => {
  const sliderModal = useRecoilValue(atoms.sliderModal);

  return (
    <Transition unmountOnExit mountOnEnter in={sliderModal} timeout={200}>
      {state => <Container state={state}>{sliderModal}</Container>}
    </Transition>
  );
};

export default SliderModal;
