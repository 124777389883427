import React, {useEffect, useState} from 'react';

import {useRecoilState, useRecoilValue} from 'recoil';

import {Button} from '..';
import atoms, {STRINGS} from '../../atoms';
import {
  ButtonWrapper,
  SecondaryAction,
  Overlay,
  Box,
  Wrapper,
  Image,
  TextWrapper,
  Text1,
  Text2,
} from './styles';

const ErrorModal = () => {
  const theme = useRecoilValue(atoms.theme);
  const [errorModal, setErrorModal] = useRecoilState(atoms.errorModal);
  const [errorTitle, setErrorTitle] = useState('');
  const [errorBody, setErrorBody] = useState('');

  useEffect(() => {
    renderErrorTitle(errorModal.message);
  });

  const handlePrimary = () => {
    setErrorModal({
      message: '',
      visible: false,
    });
    errorModal.onPrimaryClick?.();
  };

  const handleSecondary = () => {
    setErrorModal({
      message: '',
      visible: false,
    });
    errorModal.onSecondaryClick?.();
  };

  const renderErrorTitle = message => {
    switch (message) {
      case STRINGS.INVALID_TOKEN:
        setErrorTitle(STRINGS.SESSION_HAS_EXPIRED);
        setErrorBody('Laborum Lorem ea magna ipsum.');
        break;
      case STRINGS.UNAUTHORIZED:
        setErrorTitle(STRINGS.EMAIL_DOES_NOT_EXIST);
        setErrorBody(
          'Cillum aliquip ad incididunt ad excepteur ullamco nulla exercitation.',
        );
        break;
      case STRINGS.TIMEOUT:
        setErrorTitle(STRINGS.CONNECTION_TIMED_OUT);
        setErrorBody(
          'Unfortunately, your connection has timed out. Please try again.',
        );
        break;
      case STRINGS.FETCH_ERROR:
        setErrorTitle(STRINGS.COULD_NOT_RETRIEVE_RESULTS);
        setErrorBody(
          'Unfortunately, we could not complete the search as requested. Please try again later.',
        );
        break;
      case STRINGS.VALUATION_ERROR:
        setErrorTitle(STRINGS.FAILED_TO_RETRIEVE_PROPERTY_VALUATION);
        setErrorBody(STRINGS.UNFORTUNATELY_WE_CANT_FIND);
        break;
      case STRINGS.HUB_ACCESS_ERROR:
        setErrorTitle(STRINGS.ADD_YOUR_PROPERTY_TO_ACCESS);
        setErrorBody(STRINGS.AS_SOON_AS_YOU_ADD_PROPERTY);
        break;
      case STRINGS.CANCELLATION_ERROR:
        setErrorTitle(STRINGS.CANCELLATION_ERROR);
        setErrorBody(STRINGS.SOMETHING_WENT_WRONG_CANCEL);
        break;
      case STRINGS.DISPUTE_ERROR:
        setErrorTitle(STRINGS.DISPUTE_ERROR);
        setErrorBody(STRINGS.SOMETHING_WENT_WRONG_DISPUTE);
        break;
      case STRINGS.CONFIRM_ERROR:
        setErrorTitle(STRINGS.CONFIRM_ERROR);
        setErrorBody(STRINGS.SOMETHING_WENT_WRONG_CONFIRM);
        break;
      case STRINGS.INVALID_FILETYPE:
        setErrorTitle(message);
        setErrorBody(STRINGS.FILETYPE_NOT_SUPPORTED);
        break;
      case STRINGS.SUBSCRIPTION_PAYMENT_ERROR:
        setErrorTitle(STRINGS.SOMETHING_WENT_WRONG_TITLE);
        setErrorBody(STRINGS.SUBSCRIPTION_PAYMENT_ERROR);
        break;
      case STRINGS.ERROR_VERIFY_EMAIL_RETRY:
        setErrorTitle(STRINGS.TOKEN_REQUEST_WAIT);
        setErrorBody(STRINGS.ERROR_VERIFY_EMAIL_RETRY);
        break;
      default:
        setErrorTitle(message);
        setErrorBody(STRINGS.SOMETHING_WENT_WRONG);
        break;
    }
  };

  const button = (primary, secondary) => {
    if (secondary) {
      return (
        <>
          <ButtonWrapper>
            <Button
              text={primary.text}
              onClick={primary.onClick}
              styles={{borderRadius: 4, width: '100%'}}
            />
          </ButtonWrapper>
          <ButtonWrapper onClick={secondary.onClick}>
            <SecondaryAction>{secondary.text}</SecondaryAction>
          </ButtonWrapper>
        </>
      );
    }

    return (
      <ButtonWrapper
        data-intent="navigational"
        data-scope="button"
        data-id="link_content"
        data-text={`${errorTitle} | ${primary.text} button click`}>
        <Button
          text={primary.text}
          onClick={primary.onClick}
          styles={{borderRadius: 4, width: '100%'}}
        />
      </ButtonWrapper>
    );
  };

  const renderButtons = ({message}) => {
    switch (message) {
      case STRINGS.INVALID_TOKEN:
        return button(
          {
            text: 'Invalid Action 1',
            onClick: handlePrimary,
          },
          {
            text: 'Invalid Action 2',
            onClick: handleSecondary,
          },
        );
      case STRINGS.USER_WITH_MATCHING_EMAIL:
        return button({
          text: 'Not found Action 1',
          onClick: handlePrimary,
        });
      case STRINGS.TOO_MANY_PROPERTY_REQUESTS_ERROR:
        return button({
          text: 'Try again later',
          onClick: handlePrimary,
        });
      case STRINGS.TIMEOUT:
      case STRINGS.FETCH_ERROR:
        return button({
          text: 'Continue',
          onClick: handlePrimary,
        });
      case STRINGS.HUB_ACCESS_ERROR:
      case STRINGS.SUBSCRIPTION_PAYMENT_ERROR:
        return button({
          text: STRINGS.GOT_IT,
          onClick: handlePrimary,
        });
      default:
        return button({
          text: STRINGS.CONTINUE,
          onClick: handlePrimary,
        });
    }
  };

  return errorModal.visible ? (
    <Overlay>
      <Box>
        <Wrapper>
          {errorModal.message === 'Timeout' ? (
            <Image src={theme.images.LostConnection} alt="Onboard image" />
          ) : (
            <Image src={theme.images.PropertyNotFound} alt="Onboard image" />
          )}
          <TextWrapper>
            <Text1>{errorTitle}</Text1>
            <Text2>{errorBody}</Text2>
          </TextWrapper>
          {renderButtons(errorModal)}
        </Wrapper>
      </Box>
    </Overlay>
  ) : null;
};

export default ErrorModal;
