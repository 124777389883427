import React from 'react';

import {Box, Card, CardContent, Grid} from '@material-ui/core';
import PropTypes from 'prop-types';
import {useTheme} from 'styled-components';

import {Tooltip} from '../../components';
import {Header5} from '../../components/newTypography';

function ContentCard({title, body, children}) {
  const theme = useTheme();

  return (
    <Box height="100%" width="100%" component={Card} textAlign="center">
      <Box component={CardContent} height="90%">
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          height="100%">
          <Box component={Grid} container mt="5%" mb="10%">
            <Grid item xs={body ? 11 : 12}>
              <Header5 inline>{title}</Header5>
            </Grid>
            <Grid item xs={body ? 1 : false}>
              {body && (
                <Tooltip
                  dataText={`your solar score analysis | ${title} tool tip click`}
                  dataIntent="informational"
                  dataScope="tool Tip"
                  dataId="link_content"
                  id={title}
                  body={body}
                  icon={theme.images.info}
                />
              )}
            </Grid>
          </Box>
          {children}
        </Box>
      </Box>
    </Box>
  );
}

ContentCard.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string,
  children: PropTypes.node,
};

export default ContentCard;
