import {Link as ReactRouterLink} from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components';

const Link = styled(ReactRouterLink)({
  textDecoration: 'none',
  color: ({theme}) => theme.colors.black,
  display: ({display}) => display || 'block',
});

export default Link;
