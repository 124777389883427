import React, {useCallback, useState} from 'react';

import {
  Box,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import {Search} from '@material-ui/icons';
import {Autocomplete} from '@material-ui/lab';
import {
  useConditionalEffect,
  useDebouncedCallback,
  useToggle,
} from '@react-hookz/web';
import PropTypes from 'prop-types';
import {useTheme} from 'styled-components';

import {Header2, Subtitle2} from '../../components/newTypography';
import LookSeeServices from '../../services/LookSeeServices';

export default function SearchWidget({
  title,
  subtitle,
  onSelect,
  address,
  children,
}) {
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState([]);
  const [open, toggleOpen] = useToggle(false);
  const loading = open && !options.length;

  const handleAutocompleteSelect = useCallback(
    (e, searchResult) => {
      if (searchResult) {
        setSearchTerm(searchResult.description);
        onSelect(searchResult);
      }
    },
    [onSelect],
  );

  useConditionalEffect(
    () => {
      setSearchTerm(address);
    },
    [address],
    [!!address],
  );

  const debouncedSearch = useDebouncedCallback(
    async query => {
      const searchQuery = query.length > 50 ? query.substring(0, 51) : query;
      const response = await LookSeeServices.propertySearch(
        searchQuery,
        'riskscape',
      );
      if (response.searchTerm === searchQuery) {
        setOptions(response.results);
      }
    },
    [],
    500,
  );

  useConditionalEffect(
    () => {
      debouncedSearch(searchTerm);
    },
    [searchTerm],
    [!!searchTerm, searchTerm.length > 4],
  );

  return (
    <Card>
      <Box padding="20px">
        <CardContent>
          <Box textAlign={{xs: 'center', sm: 'left'}}>
            <Subtitle2 style={{display: 'inline'}} color={theme.colors.grayOne}>
              {subtitle}
            </Subtitle2>
          </Box>
          <Box textAlign={{xs: 'center', sm: 'left'}}>
            <Header2
              style={{display: 'inline'}}
              color={theme.colors.blueMobileNav}>
              {title}
            </Header2>
          </Box>
          <Box>
            <Autocomplete
              freeSolo
              inputValue={searchTerm}
              open={open}
              onOpen={() => toggleOpen(true)}
              onClose={() => toggleOpen(false)}
              options={options}
              filterOptions={x => x}
              getOptionLabel={option => option.description}
              getOptionSelected={(option, value) =>
                option.address_id === value.address_id
              }
              loading={loading}
              onChange={handleAutocompleteSelect}
              onInputChange={(event, newInputValue) => {
                setSearchTerm(newInputValue);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Enter your home address"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: <Search />,
                    endAdornment: (
                      <>
                        {loading && (
                          <CircularProgress color="inherit" size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  InputLabelProps={{
                    style: {
                      marginLeft: searchTerm ? 'auto' : '25px',
                    },
                    shrink: !!searchTerm,
                  }}
                />
              )}
            />
          </Box>
        </CardContent>
        <CardActions>{children}</CardActions>
      </Box>
    </Card>
  );
}

SearchWidget.propTypes = {
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.node.isRequired,
  onSelect: PropTypes.func.isRequired,
  address: PropTypes.object,
  children: PropTypes.node,
};
