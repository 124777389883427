import React, {useState, useEffect} from 'react';

import {useLocation} from 'react-router-dom';
import {useRecoilValue, useRecoilState, useSetRecoilState} from 'recoil';
import {useTheme} from 'styled-components';

import atoms, {STRINGS} from '../../atoms';
import MobileMainMenu from '../MobileMainMenu';
import AccountsMenu from '../MobileMainMenu/AccountsMenu';
import MarketPlaceMenu from '../MobileMainMenu/MarketPlaceMenu';
import {
  Icon,
  Wrapper,
  InnerWrapper,
  Row,
  SignupBanner,
  BannerText,
} from './styles';

function MobileMenuContainer() {
  const theme = useTheme();

  const [selectedMenu, setSelectedMenu] = useState(null);
  const user = useRecoilValue(atoms.user);
  const loggedIn = !!user;
  const location = useLocation();
  const [open, setOpen] = useRecoilState(atoms.homeMenu);
  const setLoginOrSignup = useSetRecoilState(atoms.loginOrSignup);

  const resetMenu = () => {
    setSelectedMenu(null);
  };

  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <Wrapper open={open}>
      {!loggedIn && !selectedMenu && (
        <Row onClick={() => setLoginOrSignup(STRINGS.LOGIN)}>
          <SignupBanner>
            <BannerText>
              Register / Log in for full access to the LookSee platform
            </BannerText>
            <Icon small src={theme.images.chevronRightWhite} />
          </SignupBanner>
        </Row>
      )}
      <InnerWrapper>
        {!selectedMenu && <MobileMainMenu setSelectedMenu={setSelectedMenu} />}
        {selectedMenu === 'accountMenu' && <AccountsMenu onReset={resetMenu} />}
        {selectedMenu === 'marketPlaceMenu' && (
          <MarketPlaceMenu onReset={resetMenu} />
        )}
      </InnerWrapper>
    </Wrapper>
  );
}

MobileMenuContainer.propTypes = {};

export default MobileMenuContainer;
