import styled from 'styled-components';

export const Overlay = styled.div`
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  top: 0;
  height: 100vh;
  width: 100%;
  background: rgba(33, 33, 33, 0.2);
  z-index: 9999;
`;
export const Box = styled.div`
  box-sizing: border-box;
  background: ${props => props.theme.colors.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  margin: ${({theme}) => theme.spacing.normal};

  @media ${({theme}) => theme.device.tablet} {
    margin: 0;
  }
  @media ${({theme}) => theme.device.mobile} {
    margin: 0;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
`;
