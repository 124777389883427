import React from 'react';

import {Box, CardContent, Container, Grid} from '@material-ui/core';
import PropTypes from 'prop-types';
import {useTheme} from 'styled-components';

import CircularProgressBar from '../../components/CircularProgressBar';
import GradientSVG from '../../components/GradientSVG';
import {
  BodyMed,
  BodySm,
  BodyXsm,
  Header2,
  Subtitle1,
} from '../../components/newTypography';
import ContentCard from './ContentCard';
import {AnalysisImage, ListItem, StyledChip} from './styles';

const scoreAttributes = [
  {
    checkScore: score => score < 40,
    body:
      'Your property will not benefit from a solar installation because the amount of ' +
      'electricity that can be harnessed from the sun is low. This could be due to a lack of ' +
      'sunlight, your roof being at a poor angle to the sun, or the shape and slope of your roof not being optimal.',
    gradientColor: {
      startColor: '#FFC1AD',
      middleColor: '#F0FD9F',
      endColor: '#008805',
    },
    description: 'Unfeasible',
    chipBackgroundColor: '#FFC1AD',
    chipColor: '#E94600',
  },

  {
    checkScore: score => score < 50,
    body:
      'Your property could benefit from an entry-level solar ' +
      'installation, but the output from the system could potentially be ' +
      'limited.',
    gradientColor: {
      startColor: '#FFC1AD',
      middleColor: '#F0FD9F',
      endColor: '#9EFF8E',
    },
    description: 'Low',
    chipBackgroundColor: '#FFEBCE',
    chipColor: '#FF8A00',
  },
  {
    checkScore: score => score < 60,
    body:
      'Your property, like most residences in South Africa, could benefit ' +
      'from a solar installation due to a combination of roof area and ' +
      'its northern facing cardinal direction.',
    gradientColor: {startColor: '#FFEDAD', endColor: '#18A315'},
    description: 'Average',
    chipBackgroundColor: '#FFEDAD',
    chipColor: '#FFA200',
  },
  {
    checkScore: score => score < 70,
    body:
      'Your property offers above-average feasibility for a Solar ' +
      'installation and might be able to support an ‘off-the-grid’ ' +
      'system.',
    gradientColor: {startColor: '#F0FD9F', endColor: '#009906'},
    description: 'Good',
    chipBackgroundColor: '#E0FFC8',
    chipColor: '#22842C',
  },
  {
    checkScore: () => true,
    body:
      'Your property has the best Solar installation potential and could ' +
      'allow you to go ‘off-the-grid’ as well as sell-back to the grid ' +
      'opportunities, if local authorities permit.',
    description: 'Excellent',
    gradientColor: {startColor: '#F0FD9F', endColor: '#009906'},
    chipBackgroundColor: '#D1FFBC',
    chipColor: '#22842C',
  },
];

export default function Analysis({solarScore}) {
  const theme = useTheme();
  const {
    score,
    usable_roof_size,
    suggested_system: {kwh_per_day, kwh_per_year, kwh_per_month},
    sunny_days,
  } = solarScore;
  const scoreAtt = scoreAttributes.find(x => x.checkScore(score));

  const body = scoreAtt.body;
  const gradientColor = scoreAtt.gradientColor;

  const scoreAnalysis = [
    <ContentCard key="your_score" title="Your Solar Score" body={body}>
      <GradientSVG
        id="solarscore"
        startColor={gradientColor.startColor}
        endColor={gradientColor.endColor}
        middleColor={gradientColor?.middleColor}
        transformation="rotate(90)"
      />
      <CircularProgressBar
        value={score.toFixed()}
        circleRatio={0.6}
        strokeWidth={10}
        styles={{
          root: {rotate: '252deg', strokeLinecap: 'round'},
          path: {stroke: 'url(#solarscore)', strokeLinecap: 'round'},
          trail: {
            stroke: `${theme.colors.border}`,
          },
        }}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="58%"
          height="55%"
          borderRadius="50%"
          boxShadow={theme.gradient.boxShadow}
          marginTop="-15%">
          <CardContent>
            <Header2 lineHeight="0">{score.toFixed()}</Header2>
            <BodySm inline>Out of 100</BodySm>
          </CardContent>
        </Box>
      </CircularProgressBar>
      <Box width="100%" marginTop="-15%">
        <StyledChip
          chipColor={scoreAtt.chipColor}
          chipBackgroundColor={scoreAtt.chipBackgroundColor}
          label={scoreAtt.description}
        />
      </Box>
    </ContentCard>,
    <ContentCard
      key="roof"
      title="Usable roof area available for solar panels"
      body="The useable square metres available to install solar panels on your roof.">
      <AnalysisImage
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="200px"
        size="contain"
        image={theme.images.RoofArea}>
        <Header2 inline lineHeight="0" style={{margin: 0}}>
          {usable_roof_size}
        </Header2>
        <BodySm inline>&#13217;</BodySm>
      </AnalysisImage>
      <Box height="20%" />
    </ContentCard>,
    sunny_days && (
      <ContentCard
        key="sunny_days"
        title="Usable days of sunlight"
        body="Based on the last 5 years of historical weather data in your area, with a sunlight day being defined as less than 2mm of rain.">
        <AnalysisImage image={theme.images.Sun}>
          <Header2 inline lineHeight="0" style={{margin: '40px 0 0 0'}}>
            {sunny_days.per_month}
          </Header2>
          <BodySm inline>days per month</BodySm>
        </AnalysisImage>
        <Box display="flex" justifyContent="center">
          <ul>
            <ListItem color={theme.colors.sunlight}>
              <BodyMed
                inline
                fontWeight="700"
                color={theme.colors.blueMobileNav}>
                {sunny_days.per_year.toFixed()}{' '}
              </BodyMed>
              <BodyXsm inline color={theme.colors.blueMobileNav}>
                days a year
              </BodyXsm>
            </ListItem>
          </ul>
        </Box>
      </ContentCard>
    ),
    <ContentCard
      key="energy_generation"
      title="Energy generation based on the max system size installable on your home"
      body={
        'The energy generation potential is calculated using the potential energy production of the maximum system size that you could install ' +
        'on your roof, based on the number of panels, inverter size and battery storage. We assume 6 hours of sunlight per day including bell curve mathematics.'
      }>
      <AnalysisImage image={theme.images.Battery}>
        <Header2 lineHeight="0" style={{margin: '20px 0 0 0'}} inline>
          {kwh_per_day}
        </Header2>
        <BodySm inline>kwh/day</BodySm>
      </AnalysisImage>
      <Box display="flex" justifyContent="space-between">
        <ul>
          <ListItem color={theme.colors.darkPurple}>
            <BodyMed inline fontWeight="700" color={theme.colors.blueMobileNav}>
              {kwh_per_year}
            </BodyMed>
            <BodyXsm inline color={theme.colors.blueMobileNav}>
              <br />
              kwh/year
            </BodyXsm>
          </ListItem>
        </ul>
        <ul>
          <ListItem color={theme.colors.mediumPurple}>
            <BodyMed inline fontWeight="700" color={theme.colors.blueMobileNav}>
              {kwh_per_month}
            </BodyMed>
            <BodyXsm inline color={theme.colors.blueMobileNav}>
              <br /> kwh/month
            </BodyXsm>
          </ListItem>
        </ul>
      </Box>
    </ContentCard>,
  ].filter(Boolean);

  return (
    <Container>
      <Box mb="1%">
        <Header2 inline>Your Solar Score analysis</Header2>
      </Box>
      <Box sx={{marginBottom: {xs: '5%', md: '30px'}}}>
        <Subtitle1 inline color={theme.colors.grayOne}>
          Based off the cardinal direction, area, slope and horizontal
          irradiation of your roof
        </Subtitle1>
      </Box>
      <Grid container spacing={2}>
        {scoreAnalysis.map((content, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            {content}
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

Analysis.propTypes = {
  solarScore: PropTypes.shape({score: PropTypes.number}),
};
