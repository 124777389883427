import React, {Suspense, useCallback, useState} from 'react';

import {Box, Container} from '@material-ui/core';
import {useMountEffect, useToggle} from '@react-hookz/web';
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import {useTheme} from 'styled-components';

import {RoundedButton} from '../../components/Button';
import ErrorBoundary from '../../components/ErrorBoundary';
import MapPlugin from '../../components/MapPlugin';
import {Header3, BodySm} from '../../components/newTypography';
import useDetectMobile from '../../hooks/useDetectMobile';
import {logger} from '../../services/Logger';
import LookSeeServices from '../../services/LookSeeServices';
import {sendComponentAnalytics, sendPageAnalytics} from '../../utils/Analytics';
import Analysis from './Analysis';
import AnnualSavingsCalculator from './AnnualSavingsCalculator';
import ErrorModal from './ErrorModal';
import NextStepsFooter from './NextStepFooter';
import SearchWidget from './SearchWidget';

export default function SolarScoreAnalysisPage() {
  const theme = useTheme();
  const isMobile = useDetectMobile();
  const headerHeight = isMobile ? '200px' : '500px';
  const {propertyKey} = useParams();
  const [solarScore, setSolarScore] = useState();
  const [open, toggleOpen] = useToggle();
  const [selectedSearchResult, setSelectedSearchResult] = useState();
  const [address, setAddress] = useState();
  const {path} = useRouteMatch();
  const history = useHistory();

  const handleError = useCallback(e => {
    logger.error(e);
    sendComponentAnalytics(
      {platformMessage: 'solar score not available right now'},
      'platformMessage',
    );
    toggleOpen(true);
    setSolarScore(null);
  }, []);

  const getSolarScore = useCallback(
    async (key, subplaceId) => {
      try {
        const result = await LookSeeServices.getSolarScore(key, subplaceId);
        setSolarScore(result);
      } catch (e) {
        handleError(e);
      }
    },
    [handleError],
  );

  const fetchAddress = useCallback(
    async id => {
      try {
        const result = await LookSeeServices.getPropertyDetails(
          id,
          'riskscape',
          selectedSearchResult?.unit_number || '00000',
        );
        setAddress(result);
        return result.subplace_id;
      } catch (e) {
        handleError(e);
      }
    },
    [handleError, selectedSearchResult?.unit_number],
  );

  const refresh = useCallback(
    async key => {
      const subplaceId = await fetchAddress(key);
      await getSolarScore(key, subplaceId);
    },
    [fetchAddress, getSolarScore],
  );

  const onRecalculateClick = useCallback(() => {
    const newPropertyKey = selectedSearchResult?.property_key || propertyKey;
    history.push(
      generatePath(path, {
        propertyKey: newPropertyKey,
      }),
    );
    refresh(newPropertyKey);
  }, [refresh, history, path, selectedSearchResult?.property_key]);

  const onSearchResultSelect = searchResult => {
    sendComponentAnalytics({searchTerm: 'solar score'}, 'search_form');
    setSelectedSearchResult(searchResult);
  };

  useMountEffect(() => {
    sendPageAnalytics({
      pageCategory: 'Property Hub',
      pageName: 'Property Hub:Solar Score:Your solar score analysis',
      pageSubSection1: 'Property Hub:Solar Score:Your solar score analysis',
      pageSubSection2:
        'Property Hub:Solar Score:Annual Electricity production calculator',
    });
    refresh(propertyKey);
  });

  const title = (
    <span>
      Analysis{' '}
      <Box
        component="span"
        sx={{
          textDecoration: 'underline',
          textDecorationColor: theme.colors.secondary,
        }}>
        complete
      </Box>
    </span>
  );

  const widget = (
    <ErrorBoundary onError={handleError}>
      <SearchWidget
        title={title}
        subtitle="Ready for your results?"
        onSelect={onSearchResultSelect}
        address={address?.full_address}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%">
          <RoundedButton
            data-id="link_content"
            data-scope="button"
            data-intent="confirmational"
            data-text="analysis complete | recalculate button click"
            text="Recalculate"
            backgroundColor={theme.colors.blueMobileNav}
            textColor={theme.colors.white}
            onClick={onRecalculateClick}
          />
        </Box>
      </SearchWidget>
    </ErrorBoundary>
  );

  const map = (
    <Box height={headerHeight}>
      {address && <MapPlugin propertyKey={propertyKey} />}
    </Box>
  );
  let header = (
    <>
      <Box
        height={headerHeight}
        display="flex"
        position="absolute"
        width="100%">
        <Box marginLeft="5%" alignSelf="center" maxWidth="fit-content">
          {widget}
        </Box>
      </Box>
      {map}
    </>
  );

  if (isMobile) {
    header = (
      <>
        {map}
        <Box marginTop="5%">
          <Box marginX="5%">{widget}</Box>
        </Box>
      </>
    );
  }

  return (
    <>
      <Suspense
        fallback={<Box height={headerHeight} bgcolor={theme.colors.greyOne} />}>
        {header}
      </Suspense>
      {solarScore && (
        <Box marginY="5%">
          <Analysis solarScore={solarScore} />
          <Box mt="5%">
            <AnnualSavingsCalculator
              kWhPerYear={solarScore.suggested_system.kwh_per_year}
            />
          </Box>
        </Box>
      )}
      {!solarScore && (
        <Box
          my="5%"
          component={Header3}
          color={theme.colors.grayOne}
          textAlign="center">
          No score available for this property right now
        </Box>
      )}
      <Box mb="5%">
        <NextStepsFooter
          title={
            solarScore
              ? 'Ready for the next step?'
              : 'You can still get a free HPA!'
          }
        />
      </Box>
      <Box component={Container} mb="5%">
        <BodySm italic fontWeight={700}>
          Disclaimer
        </BodySm>
        <BodySm italic>
          The Solar Score analysis is a guide only and is intended as a starting
          point for you and our experts to determine the best solar solution for
          you.
          <br />
          The information used to determine the Solar Score analysis is subject
          to change at any point, and the Solar Score analysis is only an
          estimation of your possible savings.
          <br />
          Whilst we have made every effort to ensure accuracy, we cannot warrant
          the integrity and security of the Solar Score analysis, or that it is
          free of errors, corruption, viruses, interception or interference.
          <br />
          You hereby indemnify and hold Standard Bank harmless against any loss
          or damage you may suffer due to decisions or actions taken in reliance
          on the Solar Score analysis.
          <br />
        </BodySm>
      </Box>
      <ErrorModal open={open} onClose={toggleOpen} hasAddress />
    </>
  );
}
