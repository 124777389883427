import styled from 'styled-components';

export const Wrapper = styled.div`
  cursor: pointer;
  width: ${props => (props.showAll ? '45%' : '170px')};
  flex: 0 0 auto;
  @media ${props => props.theme.device.tablet} {
    width: ${props => (props.showAll ? '23%' : '170px')};
  }
`;

export const ImageWrapper = styled.div(
  ({theme}) => `
  background: ${theme.colors.white};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 205px;
  justify-content: center;
  padding: ${theme.spacing.medium};
  position: relative;
  width: 100%;
`,
);

export const TitleImage = styled.img`
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
`;
