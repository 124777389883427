export default {
  services: {
    baseUrl: process.env.REACT_APP_BASE_URL,
    applicationId: process.env.REACT_APP_APPLICATION_ID,
    localLogLevel: process.env.REACT_APP_LOG_LEVEL || 'debug',
    remoteLogLevel: process.env.REACT_APP_REMOTE_LOG_LEVEL || 'warn',
    // This should only be enabled in deployed instances, so that dev machines
    // don't pollute our logs and rack up unnecessary costs.
    remoteLogUrl: process.env.REACT_APP_REMOTE_LOG_URL,
    geocodeUrl: process.env.REACT_APP_GEOCODE_PLUGIN_URL,
    mapPluginUrl: process.env.REACT_APP_MAP_PLUGIN_URL,
  },
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    fetchTimeMillis: process.env.REACT_APP_FIREBASE_FETCH_TIME,
    minimumFetchIntervalMillis:
      process.env.REACT_APP_FIREBASE_MINIMUM_FETCH_INTERVAL,
  },
  maxFileSize: process.env.REACT_APP_MAX_FILE_SIZE || 8388608, // Default 8MB
  pingIdentity: {
    pingBaseUrl: process.env.REACT_APP_PING_BASE_URL,
    pingAudienceId: process.env.REACT_APP_PING_AUDIENCE_ID,
    pingLocalIdentityProfileId:
      process.env.REACT_APP_PING_LOCAL_IDENTITY_PROFILE_ID,
    pingRedirectUri: process.env.REACT_APP_PING_REDIRECT_URI,
  },
};
