import React from 'react';

import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import './index.css';
import {RecoilRoot} from 'recoil';

import App from './App';
import MarketplaceContextProvider from './provider/marketplace';
import reportWebVitals from './reportWebVitals';
import HttpServices from './services/HttpServices';
import {initializeLogger} from './services/Logger';
import PingIdentityService from './services/PingIdentityServices';

try {
  initializeLogger();
} catch (e) {
  // eslint-disable-next-line no-console
  console.error(e);
}

if (!window.dataLayer) {
  window.dataLayer = {};
}

if (!window.dataLayer.application) {
  window.dataLayer.application = {};
}

PingIdentityService.getCsrfToken().then(token => {
  HttpServices.setToken(token);
  ReactDOM.render(
    <RecoilRoot>
      <MarketplaceContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </MarketplaceContextProvider>
    </RecoilRoot>,
    document.getElementById('root'),
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
