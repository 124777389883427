import {atom, selector} from 'recoil';

import {logger} from '../services/Logger';
import LookSeeServices from '../services/LookSeeServices';

export default {
  solarPackages: selector({
    key: 'solarPackages',

    get: async () => {
      try {
        return await LookSeeServices.getSolarPackages();
      } catch (e) {
        logger.error('Error fetching solar packages', e);

        return [];
      }
    },
  }),
  solarLoanFinancing: atom({
    key: 'solarLoanFinancing',
    default: false,
  }),
};
