export const HOW_DOES_IT_WORK = 'How does it work?';
export const HOW_DOES_IT_WORK_HEADLINE =
  'We guide you through the entire process from usage analysis to installation';
export const HOW_DOES_IT_WORK_OPTIONS = {
  SOLUTION_HEADING: '1.Solution',
  SOLUTION_PARAGRAPH: 'Working out which system is suitable for your home.',
  PROVIDER_HEADING: '2.Provider',
  PROVIDER_PARAGRAPH:
    'Choosing the best installation partner based on your location and solution',
  FINANCING_HEADING: '3.Financing',
  FINANCING_PARAGRAPH:
    'Providing solar installation financing options through Standard Bank',
  SUPPORT_HEADING: '4.Support',
};
export const CHOOSE_PAYMENT_METHOD =
  'Choose a payment method that best suits your pocket:';
export const PAY_CASH = 'Pay cash';
export const USE_ACCESS_BOND = 'Use your access bond';
export const GET_FINANCING = 'Get financing through Standard Bank';
export const OUR_OFFERS = 'Our offers';
export const BATTERY_PACKAGE = 'Battery Package';
export const BATTERY_PACKAGE_PARAGRAPH =
  'An inverter and a battery back up connected to your home’s distribution board which is upgradable to add solar at a later stage. Can be used to power most of your home (lights, appliances, TV’s) during power cuts or load shedding.';
export const SOLAR_AND_BATTERY_PACKAGE = 'Solar and Battery Package';
export const SOLAR_AND_BATTERY_PACKAGE_PARAGRAPH =
  'Includes an inverter, battery back up and solar panels, used to generate your own power during the day, recharging your batteries to be used at night or during loadshedding. This reduces reliance on Eskom and reduces municipal electricity bills. The home is still connected to the Eskom grid.';

export const FOOTER_BANNER_TITLE =
  'Request a quote to get your custom-fit solar solution';

export const CUSTOMER_TESTIMONIAL =
  '“We save a lot of money. It’s been almost a month now, and using the app I can already see the savings on electricity that we have. We are happy with the installers that Standard Bank provided. They always kept us informed on what was needed and took note of what we wanted. We dont even know if there was loadshedding, we are always on!.”';
export const WHAT_CUSTOMERS_SAY = 'What our customers say';
export const CUSTOMER_NAME = '-The Holyoake family';
