import app from './app';
import {DEFAULT_USER_STATE, TIMEOUT_ERROR, STRINGS} from './app/constants';
import contentHub from './contentHub';
import creditInformation from './creditInformation';
import insights from './insights';
import marketplace from './marketplace';
import property from './property';
import solar from './solar';

export {DEFAULT_USER_STATE, TIMEOUT_ERROR, STRINGS};

export default {
  ...app,
  ...insights,
  ...marketplace,
  ...property,
  ...contentHub,
  ...solar,
  ...creditInformation,
};
