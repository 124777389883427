import React from 'react';

import {Link} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import {useTheme} from 'styled-components';

import atoms from '../../atoms';
import {productsServicesUrl} from '../../navigation/routes';
import {useFeaturesContext} from '../../provider/features';
import {getCurrentTabValue} from '../../utils';
import {Headline2} from '../typography';
import {MarketPlaceMenuContainer, StyledTab, StyledTabs} from './styles';

const createCategoryUrl = categoryCode => {
  return `${productsServicesUrl}/${encodeURIComponent(categoryCode)}`;
};

export default function SubNavbar() {
  const categories = useRecoilValue(atoms.marketplaceCategories).filter(
    c =>
      c.name.toLowerCase() !== 'home solar' &&
      c.name.toLowerCase() !== 'journeys',
  );
  const features = useFeaturesContext();
  const list = [
    features?.solar && {
      categoryCode: 'solar',
      name: 'Home Solar',
    },
    features?.solarLoan && {
      categoryCode: 'solarloan',
      name: 'Solar Loan',
    },
    features?.solarScore && {
      categoryCode: 'solarscore',
      name: 'Solar Score',
    },
  ];
  const tabs = [...list, ...categories];
  const theme = useTheme();
  const tabsValue = getCurrentTabValue(
    decodeURIComponent(location.pathname),
    2,
  );

  return (
    <MarketPlaceMenuContainer>
      <StyledTabs variant="scrollable" value={tabsValue}>
        {tabs?.map(c => (
          <StyledTab
            label={<Headline2 color={theme.colors.white}>{c?.name}</Headline2>}
            value={c?.categoryCode ?? c?.name}
            key={c?.name}
            component={Link}
            to={createCategoryUrl(c?.categoryCode)}
          />
        ))}
      </StyledTabs>
    </MarketPlaceMenuContainer>
  );
}
