import React from 'react';

import {Box} from '@material-ui/core';
import {useTheme} from 'styled-components';

import {Header1, Subtitle1} from '../newTypography';
import {BannerImage} from './styles';

export function FormHeading({gradient, imageUrl, heading, subtitle}) {
  const theme = useTheme();
  return (
    <BannerImage
      gradient={gradient}
      imageUrl={imageUrl}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      py="5vh"
      px="5vw">
      <Box>
        <Header1 inline color={theme.colors.white}>
          {heading}
        </Header1>
      </Box>
      <Box>
        <Subtitle1 inline color={theme.colors.white}>
          {subtitle}
        </Subtitle1>
      </Box>
    </BannerImage>
  );
}
