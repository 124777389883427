// eslint-disable-next-line no-restricted-imports
import styled, {css} from 'styled-components';

const MAX_VIEWPORT_WIDTH = 960;
const MIN_VIEWPORT_WIDTH = 600;
const ROOT = 16;

const convertPxToRem = (px, dec = 3) => Number((px / ROOT).toFixed(dec));
export const calcViewportWidth = (maxWidth, minWidth, dec = 2) => {
  return (
    100 *
    ((maxWidth - minWidth) / (MAX_VIEWPORT_WIDTH - MIN_VIEWPORT_WIDTH))
  ).toFixed(dec);
};
export const calcRem = (maxWidth, minWidth) => {
  return `${convertPxToRem(
    (MIN_VIEWPORT_WIDTH * maxWidth - MAX_VIEWPORT_WIDTH * minWidth) /
      (MIN_VIEWPORT_WIDTH - MAX_VIEWPORT_WIDTH),
  )}`;
};

export const calcPreferredValue = (maxWidth, minWidth) => {
  if (!minWidth) {
    throw new Error('Error: Minimum width must be provided');
  }
  const viewportWidth = calcViewportWidth(maxWidth, minWidth);
  const remWidth = calcRem(maxWidth, minWidth);
  return `${viewportWidth}vw + ${remWidth}rem`;
};

const BaseTypography = css`
  font-family: ${({font}) =>
    font ? `${font}, sans-serif` : `Inter, sans-serif`};
  font-style: ${({italic}) => (italic ? 'italic' : 'normal')};
  font-size: ${({fontSize}) => (fontSize ? fontSize : '')};
  font-weight: ${({fontWeight}) => (fontWeight ? fontWeight : '')};
  color: ${({color, theme}) => (color ? color : theme.colors.black)};
  ${({inline}) => (inline ? 'display: inline' : '')};
  ${({lineHeight}) => (lineHeight ? `line-height: ${lineHeight}` : '')};
  ${({m}) => (m ? `margin: ${m}` : '')};
`;

const BoldWeight = css`
  font-weight: 700;
`;

const MediumWeight = css`
  font-weight: 500;
`;
const RegularWeight = css`
  font-weight: 400;
`;
const LightWeight = css`
  font-weight: 300;
`;

const BaseHeader = css`
  ${BoldWeight}
  ${BaseTypography}
`;

const BaseMedSubtitle = css`
  ${MediumWeight}
  ${BaseTypography}
`;

const BaseRegSubtitle = css`
  ${RegularWeight}
  ${BaseTypography}
`;

const BaseRegBody = css`
  ${RegularWeight}
  ${BaseTypography}
`;

const BaseLightBody = css`
  ${LightWeight}
  ${BaseTypography}
`;

export const Header1 = styled.h1`
  font-size: clamp(1.5rem, ${calcPreferredValue(54, 24)}, 3.375rem);
  line-height: clamp(2rem, ${calcPreferredValue(75, 32)}, 4.688rem);
  ${BaseHeader}
`;

export const Header2 = styled.h2`
  font-size: clamp(1.25rem, ${calcPreferredValue(40, 20)}, 2.5rem);
  line-height: clamp(1.5rem, ${calcPreferredValue(48, 24)}, 3rem);
  ${BaseHeader}
`;

export const Header3 = styled.h3`
  font-size: clamp(0.875rem, ${calcPreferredValue(32, 14)}, 2rem);
  line-height: clamp(1.25rem, ${calcPreferredValue(40, 20)}, 2.5rem);
  ${BaseHeader}
`;
export const Header4 = styled.h4`
  font-size: clamp(1.125rem, ${calcPreferredValue(24, 18)}, 1.5rem);
  line-height: clamp(1.25rem, ${calcPreferredValue(28, 20)}, 1.75rem);
  ${BaseHeader}
`;
export const Header5 = styled.h5`
  font-size: 1.125rem;
  line-height: 1.25rem;
  ${BaseHeader}
`;

export const SubtitleMed1 = styled.h6`
  font-size: 1.5rem;
  line-height: 2rem;
  ${BaseMedSubtitle}
`;

export const SubtitleMed2 = styled.h6`
  font-size: 1.5rem;
  line-height: 2rem;
  ${BaseMedSubtitle}
`;

export const Subtitle1 = styled.h6`
  font-size: clamp(0.875rem, ${calcPreferredValue(24, 14)}, 1.5rem);
  line-height: clamp(1.25rem, ${calcPreferredValue(32, 20)}, 2rem);
  ${BaseRegSubtitle}
`;

export const Subtitle2 = styled.h6`
  font-size: clamp(0.875rem, ${calcPreferredValue(16, 14)}, 1rem);
  line-height: 1.25rem;
  ${BaseRegSubtitle}
`;

export const BodyExLg = styled.p`
  font-size: 1.75rem;
  line-height: 2.25rem;
  ${BaseRegBody}
`;

export const BodyLg = styled.p`
  font-size: 1.5rem;
  line-height: 2rem;
  ${BaseRegBody}
`;

export const BodyMed = styled.p`
  font-size: clamp(0.875rem, ${calcPreferredValue(18, 14)}, 1.125rem);
  line-height: clamp(1.25rem, ${calcPreferredValue(24, 20)}, 1.5rem);
  ${BaseRegBody}
`;

export const BodyMedLight = styled.p`
  font-size: clamp(0.875rem, ${calcPreferredValue(18, 14)}, 1.125rem);
  line-height: clamp(1.25rem, ${calcPreferredValue(24, 20)}, 1.5rem);
  ${BaseLightBody}
`;

export const BodyReg = styled.p`
  font-size: clamp(0.875rem, ${calcPreferredValue(16, 14)}, 1rem);
  line-height: 1.25rem;
  ${BaseRegBody}
`;

export const BodySm = styled.p`
  font-size: clamp(0.75rem, ${calcPreferredValue(14, 12)}, 0.875rem);
  line-height: clamp(1rem, ${calcPreferredValue(20, 16)}, 1.25rem);
  ${BaseRegBody}
`;

export const BodyXsm = styled.p`
  font-size: 0.75rem;
  line-height: 1rem;
  ${BaseRegBody}
`;

export const LinkReg = styled.a`
  font-size: 1rem;
  line-height: 1.25rem;
  color: ${({color, theme}) => (color ? color : theme.colors.primary)};
  ${RegularWeight}
  ${BaseTypography}
`;
