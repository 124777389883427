import React from 'react';

import {Box, Grid} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {useTheme} from 'styled-components';

import {RoundedButton} from '../../components/Button';
import {
  BodyLg,
  BodyReg,
  calcPreferredValue,
} from '../../components/newTypography';
import useDetectMobile from '../../hooks/useDetectMobile';
import {solarApply, solarLoanApply} from '../../navigation/routes';
import {CheckedCircle, StyledImg} from './styles';

export default function RequirementsSection() {
  const theme = useTheme();
  const isMobile = useDetectMobile();
  const history = useHistory();

  const requirements = [
    'The loan must be used for a system which includes solar panels',
    'You have a quotation from an accredited solar provider',
    'You don’t have a Government Energy Bounce Back Loan facility with another bank',
    'You have a monthly minimum income of R8000 and are permanently employed',
    'You’re over the age of 18',
    'You own the property or have the necessary permission from the owner, where the Solar System will be installed.',
  ];

  return (
    <Box component={Grid} container bgcolor={theme.colors.grayEleven}>
      <Box component={Grid} item xs={12} md={6} px="4%" pt="2%">
        <BodyLg
          color={theme.colors.tertiary}
          fontWeight="700"
          fontSize={`clamp(1.25rem, ${calcPreferredValue(40, 20)}, 2.5rem)`}>
          Requirements to get a Solar Loan
        </BodyLg>
        {requirements.map(text => (
          <Box
            key={text}
            display="flex"
            flexDirection="row"
            alignItems="center">
            <CheckedCircle />
            &nbsp;
            <BodyReg>{text}</BodyReg>
          </Box>
        ))}
        <Box
          Box
          display="flex"
          width="100%"
          marginY="5%"
          sx={{
            flexDirection: {xs: 'column', sm: 'row'},
            gridGap: {xs: '8px', sm: '40px'},
          }}>
          <Box item xs={12} sm={6}>
            <Box
              component={RoundedButton}
              width="100%"
              data-intent="navigational"
              data-scope="button"
              data-id="link_content"
              data-text="Requirements to get a Solar Loan | Get a financed solar system button click"
              text="Get a financed solar system"
              backgroundColor={theme.colors.secondary}
              textColor={theme.colors.tertiary}
              onClick={() => history.push(solarApply)}
            />
          </Box>
          <Box item xs={12} sm={6}>
            <Box
              component={RoundedButton}
              width="100%"
              data-intent="navigational"
              data-scope="button"
              data-id="link_content"
              data-text="Requirements to get a Solar Loan | Only apply for a Solar Loan button click"
              text="Only apply for a Solar Loan"
              backgroundColor={theme.colors.grayEleven}
              borderColor={theme.colors.tertiary}
              border="1px solid"
              textColor={theme.colors.tertiary}
              onClick={() => history.push(solarLoanApply)}
            />
          </Box>
        </Box>
      </Box>
      {!isMobile && (
        <Grid item sm={6}>
          <StyledImg
            src={theme.images.ManInstallingSolar}
            alt="man installing solar panel"
            width="100%"
            height="700px"
            object-fit="cover"
          />
        </Grid>
      )}
    </Box>
  );
}
