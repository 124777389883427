import styled from 'styled-components';

export const Wrapper = styled.div(
  ({theme}) => `
  color: ${theme.colors.grayEight};
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.small};
  align-items: center;
  justify-items: center;
  font: ${theme.typography.headline3};
  line-height: ${theme.spacing.normal};
  padding: 0 ${theme.spacing.xSmall};

  @media ${theme.device.laptop} {
    gap: ${theme.spacing.normal};
  }
`,
);

export const Item = styled.div(
  ({theme, noLink}) => `
    cursor: ${noLink ? 'auto' : 'pointer'};
    color: ${noLink && theme.colors.grayTwo};
  `,
);

export const Icon = styled.img(
  ({theme}) => `
    height: ${theme.spacing.small};
  `,
);
