import styled from 'styled-components';

export const Wrapper = styled.div`
  color: ${({theme}) => theme.colors.black1200};
  font-weight: 600;
  padding: ${({theme}) => `${theme.spacing.small} ${theme.spacing.large}`};
  display: flex;
  align-items: baseline;
  font: 600 14px Inter;
  height: 29px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${props => props.bgColor};
`;

export const ActionRequired = styled.a`
  cursor: pointer;
  text-decoration: underline;
  margin-left: 5px;
`;
