import {Box, Card as MuiCard} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {Link as ReactRouterLink} from 'react-router-dom';
import styled from 'styled-components';

import CollapseToCarousel from '../../components/CollapseToCarousel';
import {
  Headline1,
  Headline2,
  LargestBody,
  MainTitle,
  Title1,
} from '../../components/typography';

export const bannerContainerHeight = {xs: '300px', sm: '400px'};

export const StyledMainTitle = styled(MainTitle)`
  font-size: ${({fontSize}) => fontSize || '24px'};
  line-height: 30px;
  color: ${({theme, color}) => (color ? color : theme.colors.white)};
  max-width: 90%;
  font-weight: 700;

  @media ${({theme}) => theme.device.laptop} {
    font-size: 54px;
    line-height: 64px;
  }

  @media ${({theme}) => theme.device.mobileL} {
    max-width: 70%;
  }
`;

export const StyledBody = styled(Title1)`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-top: ${({bodyPadding}) => (bodyPadding ? bodyPadding : '30px')};
  max-width: 90%;
  color: ${({theme, color}) => (color ? color : theme.colors.white)};

  @media ${({theme}) => theme.device.mobileL} {
    max-width: 70%;
  }
  @media ${({theme}) => theme.device.laptop} {
    font-size: 24px;
    line-height: 40px;
  }
`;

export const IconText = styled(Headline2)`
  text-align: left;
`;

export const Icon = styled.img`
  padding: ${({padding}) => padding || '0 10px'};
  width: ${({width}) => width || '31px'};
  height: ${({height}) => height || '31px'};
`;

export const Link = styled(ReactRouterLink)`
  color: ${({theme, color}) => color || theme.colors.white};
`;

export const NumberIcon = styled(Title1)`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-weight: 600;
  font-size: 16px;
  background: ${({theme}) => theme.colors.secondary};
  border: 3px solid ${({theme}) => theme.colors.secondary};
  color: ${({theme}) => theme.colors.tertiary};
`;

export const HeaderBannerImage = styled(Box)`
  background: ${({theme}) => theme.gradient.blueDark},
    url(${({theme}) => theme.images.solarBrochureHeaderBannerImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const HeaderBannerMainTitle = styled(MainTitle)`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  @media ${({theme}) => theme.device.mobileL} {
    font-size: 40px;
    line-height: 60px;
  }
`;

export const StyledSubTitle = styled(Title1)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;

  @media ${({theme}) => theme.device.mobileL} {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
  }

  @media ${({theme}) => theme.device.tablet} {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }
`;

export const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  color: ${({theme}) => theme.colors.blueMobileNav};
`;

export const InfoBoxTitle = styled(MainTitle)`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  @media ${({theme}) => theme.device.mobileL} {
    font-size: 32px;
    line-height: 36px;
  }

  @media ${({theme}) => theme.device.tablet} {
    font-size: 40px;
    line-height: 48px;
  }
`;

export const InfoBoxSubTitle = styled(LargestBody)`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  @media ${({theme}) => theme.device.mobileL} {
    font-size: 20px;
    line-height: 28px;
  }

  @media ${({theme}) => theme.device.tablet} {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const RoundedIcon = styled(Icon)`
  height: 52px;
  background: ${({theme, color}) =>
    color ? color : theme.colors.blueMobileNav};
  border-radius: 100%;
`;

export const InfoBoxHeadline = styled(Headline1)`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: block;
  margin-top: 16px;
  width: 100%;
  text-align: center;

  @media ${({theme}) => theme.device.mobileL} {
    width: 68%;
    text-align: left;
    margin-left: 0;
    margin-right: 0;
  }

  @media ${({theme}) => theme.device.tablet} {
    font-weight: 700;
    font-size: 18px;
    width: 100%;
  }
`;

export const InfoBoxParagraph = styled(LargestBody)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-block-start: 4px;
  margin-block-end: 16px;
  display: block;
  width: 70%;
  margin-left: auto;
  margin-right: auto;

  @media ${({theme}) => theme.device.mobileL} {
    width: 68%;
    text-align: left;
    display: inline-block;
  }
  @media ${({theme}) => theme.device.tablet} {
    font-size: 18px;
    line-height: 24px;
    width: 100%;
    display: block;
  }
`;

export const PaymentMethodBoxTitle = styled(InfoBoxTitle)`
  width: 85%;
  @media ${({theme}) => theme.device.mobileL} {
    font-size: 32px;
    line-height: 36px;
    width: 100%;
    display: inline-block;
  }
`;

export const OurOffersTitle = styled(MainTitle)`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  display: block;

  @media ${({theme}) => theme.device.mobileL} {
    line-height: 28px;
  }

  @media ${({theme}) => theme.device.tablet} {
    font-size: 40px;
    line-height: 48px;
  }
`;

export const BatteryPackageTitle = styled(MainTitle)`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  display: block;
  margin-top: 16px;

  @media ${({theme}) => theme.device.mobileL} {
    font-size: 24px;
    line-height: 28px;
    margin-top: 16px;
  }

  @media ${({theme}) => theme.device.tablet} {
    margin-top: 48px;
  }
`;

export const BatteryPackageParagraph = styled(MainTitle)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 24px;
  margin-bottom: 32px;
  display: block;

  @media ${({theme}) => theme.device.mobileL} {
    font-size: 20px;
    line-height: 28px;
    margin-top: 32px;
  }

  @media ${({theme}) => theme.device.tablet} {
    font-size: 24px;
    line-height: 32px;
    margin-top: 16px;
  }
`;

export const FooterBannerTitle = styled(MainTitle)`
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;

  @media ${({theme}) => theme.device.mobileL} {
    font-size: 24px;
    line-height: 28px;
  }

  @media ${({theme}) => theme.device.laptop} {
    font-size: 40px;
    line-height: 48px;
  }
`;

export const TestimonialHeading = styled(MainTitle)`
  font-weight: 700;
  font-size: 20px;
  line-height: 17px;

  @media ${({theme}) => theme.device.laptop} {
    font-size: 40px;
    line-height: 48px;
  }
`;

export const SolarLoanBannerImage = styled(Box)`
  background: ${({theme}) => theme.gradient.solarLoanBannerGradient},
    url(${({theme}) => theme.images.solarLoanBannerImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const Carousel = styled(CollapseToCarousel)`
  .Carousel-fullHeightHoverWrapper-65 {
    height: 60%;
  }

  .Carousel-root-83 {
    justify-content: center;
    display: flex;
  }

  @media (max-width: ${({theme}) => theme.sizes.laptopWidth}px) {
    width: 60%;
    margin: 20px 5%;
  }

  @media ${({theme}) => theme.device.laptop} {
    display: grid;
    grid-template-columns: repeat(${({columns}) => columns}, 1fr);
    gap: 20px;
    padding: 20px 10%;
  }
`;

export const ProductImage = styled.img`
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
`;

export const ProductCardContainer = styled.div(
  ({theme}) => `
  background: ${theme.colors.white};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 205px;
  justify-content: center;
  padding: ${theme.spacing.medium};
  position: relative;
  width: 100%;
`,
);

export const StyledCard = styled(MuiCard)`
  &.MuiCard-root {
    background: ${({theme}) => theme.colors.midblue};
    max-width: 100%;
    height: 300px;
  }
`;
