import React, {useCallback, useRef} from 'react';

import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const Currency = React.forwardRef(
  (
    {
      value,
      currency = 'R',
      decimalValue = 2,
      thousandSeparator = ' ',
      suffix,
      onChange,
      name,
      ...props
    },
    ref,
  ) => {
    const prevValueRef = useRef(value);

    const onValueChange = useCallback(
      values => {
        onChange({target: {name, value: values.value}});
      },
      [name, onChange],
    );

    // Check if the value has changed and update the input value if necessary
    if (ref && ref.current && prevValueRef.current !== value) {
      ref.current.setInputValue(value);
      prevValueRef.current = value;
    }

    const displayProps = onChange ? {onValueChange} : {displayType: 'text'};

    return (
      <NumberFormat
        {...props}
        getInputRef={ref}
        value={value}
        prefix={currency}
        suffix={suffix}
        thousandSeparator={thousandSeparator}
        decimalScale={decimalValue}
        fixedDecimalScale
        {...displayProps}
      />
    );
  },
);

Currency.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currency: PropTypes.string,
  decimalValue: PropTypes.number,
  thousandSeparator: PropTypes.string,
  suffix: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
};

export default Currency;
