import {useAsync, useMediatedState, useMountEffect} from '@react-hookz/web';

import LookSeeServices from '../services/LookSeeServices';

const DEFAULT_ORDER_DESC = 'Created';
const ORDER_DESC_KEY = 'stateDescription';
const VALID_RULE_KEYS = [
  'canCancel',
  'canRaiseIssue',
  'canConfirmCompletion',
  'canGoToPayment',
  ORDER_DESC_KEY,
  'alertText',
  'canRetractCancellation',
  'canDownloadReport',
  'canDecline',
  'canAccept',
];

const DEFAULT_ORDER_RULES = {
  canCancel: false,
  canRaiseIssue: false,
  canConfirmCompletion: false,
  canGoToPayment: false,
  alertText: false,
  canRetractCancellation: false,
};
const DEFAULT_QUOTE_ORDER_RULES = {
  canRaiseIssue: false,
  canDownloadReport: false,
  canGoToPayment: false,
  alertText: false,
};
const DEFAULT_RFQ_RULES = {
  canDecline: false,
  canAccept: false,
};

export const parseOrderState = state => {
  const strings = state.toLowerCase().split('_');
  strings[0] = strings[0].slice(0, 1).toUpperCase() + strings[0].slice(1);
  return strings.join(' ');
};

const extractValidRules = rules => {
  const filteredRuleEntries = Object.entries(rules).filter(([key]) =>
    VALID_RULE_KEYS.includes(key),
  );
  return Object.fromEntries(filteredRuleEntries);
};

const parseRules = (
  orderState,
  rules,
  defaultRules,
  defaultDescription = DEFAULT_ORDER_DESC,
) => {
  const validRules = extractValidRules(rules);
  const description =
    validRules[ORDER_DESC_KEY][orderState] ?? defaultDescription;
  const nonDescriptionRules = Object.entries(validRules).filter(
    entry => entry[0] !== ORDER_DESC_KEY,
  );
  const parsedRules = Object.assign(
    {},
    ...nonDescriptionRules.map(([key, value]) => ({
      [key]: value.includes(orderState),
    })),
  );
  const state = orderState;
  return Object.assign({}, defaultRules, parsedRules, {
    [ORDER_DESC_KEY]: description,
    state,
  });
};

export default function useParsedOrders(orderType) {
  const [orderRulesState, orderRulesActions] = useAsync(() =>
    LookSeeServices.getMarketplaceRules('order'),
  );
  const [quoteOrderRulesState, quoteOrderRulesActions] = useAsync(() =>
    LookSeeServices.getMarketplaceRules('quoteOrder'),
  );
  const [rfqRulesState, rfqRulesActions] = useAsync(() =>
    LookSeeServices.getMarketplaceRules('rfq'),
  );

  useMountEffect(() => {
    if (orderType === 'rfq') {
      rfqRulesActions.execute();
    } else {
      orderRulesActions.execute();
      quoteOrderRulesActions.execute();
    }
  });

  const parseOrders = rawOrders =>
    rawOrders.map(ro => {
      const rules =
        ro.type === 'QUOTE'
          ? parseRules(
              ro.state,
              quoteOrderRulesState.result || {},
              DEFAULT_QUOTE_ORDER_RULES,
            )
          : parseRules(
              ro.state,
              orderRulesState.result || {},
              DEFAULT_ORDER_RULES,
            );
      return {...rules, ...ro};
    });

  const parseQuotes = rawQuotes =>
    rawQuotes.map(q => {
      const rules = parseRules(
        q.quoteState ?? q.rfqState,
        rfqRulesState.result || {},
        DEFAULT_RFQ_RULES,
        q.quoteState ? 'Quote accepted' : DEFAULT_ORDER_DESC,
      );
      return {...rules, ...q};
    });

  return useMediatedState([], orderType === 'rfq' ? parseQuotes : parseOrders);
}
