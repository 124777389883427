import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 20px;
  height: 20px;
  background: transparent;
  border: none;
  cursor: ${props => (props.hide ? 'auto' : 'pointer')};
  padding: 0;
  z-index: 10;
  margin-right: 28px;

  &:focus {
    outline: none;
  }

  &:active {
    outline: none;
  }

  @media ${props => props.theme.device.tablet} {
    right: ${({theme}) => theme.spacing.xLarge};
    top: 19px;
    margin-right: 48px;
  }

  @media ${props => props.theme.device.laptop} {
    top: 29px;
  }
`;

export const Div = styled.div`
  position: relative;
  height: 2px;
  background: ${props => props.theme.colors.white};
  min-width: 100%;
`;
