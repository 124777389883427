import React from 'react';

import {Box, Grid} from '@material-ui/core';
import {useConditionalEffect} from '@react-hookz/web';
import {useParams} from 'react-router-dom';
import {useRecoilValue} from 'recoil';

import atoms from '../../atoms';
import SubcategoryCard from '../../components/SubcategoryCard';
import {BodyReg, Header1} from '../../components/newTypography';
import {dataLayerVariables, sendPageAnalytics} from '../../utils/Analytics';

export default function Categories() {
  const {categoryCode} = useParams();
  const decodedCategoryCode = decodeURIComponent(categoryCode);
  const marketplaceCategories = useRecoilValue(atoms.marketplaceCategories);
  const category = marketplaceCategories.find(
    c => c.categoryCode === decodedCategoryCode,
  );
  const categoryName = category?.name;
  const categoryDescription = category?.description;
  const subcategories = category?.subcategories;
  useConditionalEffect(
    () => {
      sendPageAnalytics(
        dataLayerVariables({
          pageCategory: 'Products and services',
          pageName: `products and services:${categoryName}`,
          pageSubSection1: 'products and services',
          pageSubSection2: `products and services:${categoryName}`,
        }),
      );
    },
    [categoryName],
    [categoryName],
  );

  return (
    <Box px={3} py={6}>
      <Box>
        <Header1 inline fontSize="24px" lineHeight="28px">
          {categoryName}
        </Header1>
        <BodyReg>{categoryDescription}</BodyReg>
      </Box>
      <Grid spacing={2} container>
        {subcategories?.map((item, index) => (
          <Grid xs={6} sm={4} md={3} lg={2} item key={index}>
            <SubcategoryCard data={item} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
