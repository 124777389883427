import React from 'react';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import PropTypes from 'prop-types';
import {useTheme} from 'styled-components';

import {RoundedButton} from './Button';

const GenericErrorModal = ({open, onClose, onContinue, messageBody}) => {
  const theme = useTheme();
  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description">
      <DialogContent>
        <Box display="flex">
          <Box
            height={10}
            padding={0}
            component={IconButton}
            aria-label="close"
            onClick={onClose}
            marginLeft="auto">
            <CloseIcon />
          </Box>
        </Box>
        <Box component={DialogContentText} textAlign="center" px={3}>
          <Box
            component={ReportProblemOutlinedIcon}
            sx={{fontSize: 50, color: theme.colors.tertiary}}
          />
          {messageBody}
        </Box>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item container justifyContent="center">
            <RoundedButton
              text="Continue"
              backgroundColor={theme.colors.blueMobileNav}
              borderColor={theme.colors.blueMobileNav}
              textColor={theme.colors.white}
              onClick={onContinue}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

GenericErrorModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onContinue: PropTypes.func,
  messageBody: PropTypes.element,
};

export default GenericErrorModal;
