import React, {createContext, useContext} from 'react';

import {useSetRecoilState} from 'recoil';

import atoms from '../../atoms';
import useParsedOrders from '../../hooks/useParsedOrders';
import {logger} from '../../services/Logger';
import LookSeeServices from '../../services/LookSeeServices';

const MarketplaceContext = createContext();

export function useMarketplaceContext() {
  return useContext(MarketplaceContext);
}

const MarketplaceContextProvider = ({children}) => {
  const setErrorModal = useSetRecoilState(atoms.errorModal);
  const setMarketplaceSavedItems = useSetRecoilState(
    atoms.marketplaceSavedItems,
  );
  const setLoadingModal = useSetRecoilState(atoms.loadingModal);

  const [orders, setOrders] = useParsedOrders('orders');
  const [quotes, setQuotes] = useParsedOrders('rfq');

  const {getMarketplaceSavedItems, getOrder, getOrders, getQuotes, getItem} =
    LookSeeServices;

  const services = {
    getSaved: async () => {
      try {
        const res = await getMarketplaceSavedItems();
        setMarketplaceSavedItems(res);

        return res;
      } catch (error) {
        logger.error(error);
      }
    },
    getOrder: async orderId => {
      setLoadingModal(true);
      try {
        return await getOrder(orderId);
      } catch (error) {
        setErrorModal({message: error.message, visible: true});
      } finally {
        setLoadingModal(false);
      }
    },
    getOrders: async () => {
      setLoadingModal(true);
      try {
        const res = await getOrders();
        setOrders(res);
        return res;
      } catch (error) {
        setErrorModal({message: error.message, visible: true});
      } finally {
        setLoadingModal(false);
      }
    },
    getQuotes: async () => {
      setLoadingModal(true);
      try {
        const res = await getQuotes();
        setQuotes(res);
      } catch (error) {
        setErrorModal({message: error.message, visible: true});
      } finally {
        setLoadingModal(false);
      }
    },
    getItem: async (marketId, categoryId, itemId) => {
      try {
        return await getItem(marketId, categoryId, itemId);
      } catch (error) {
        logger.error(error);
      }
    },
  };

  const selectors = {
    orders: orders,
    quotes: quotes,
  };

  const context = {
    services: services,
    selectors,
  };

  return (
    <MarketplaceContext.Provider value={context}>
      {children}
    </MarketplaceContext.Provider>
  );
};

export default MarketplaceContextProvider;
