import React, {createRef, useState} from 'react';

import {Box} from '@material-ui/core';
import PropTypes from 'prop-types';
import Carousel from 'react-material-ui-carousel';
import {useTheme} from 'styled-components';

import {BodyReg, Header4, Header5, calcPreferredValue} from '../newTypography';
import {
  CarouselImage,
  ImageBanner,
  StyledImageList,
  StyledImageListItem,
} from './styles';

export default function PackageCarousel({imageUrls, percentage}) {
  const theme = useTheme();
  const [carouselImageIndex, setCarouselImageIndex] = useState(0);
  const imageListRef = imageUrls.map(() => createRef());

  const hasImageList = !!imageUrls.length;

  const onChangeHandler = index => {
    if (imageListRef[index].current) {
      imageListRef[index].current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });

      setCarouselImageIndex(index);
    }
  };

  return (
    <Box>
      <Box display="flex" sx={{flexDirection: {xs: 'column', sm: 'row'}}}>
        <Box sx={{order: {xs: 2, sm: 1}}}>
          {hasImageList && (
            <Box sx={{width: {sm: '30%', md: '100%'}}}>
              <StyledImageList gap={1}>
                {imageUrls.map((url, index) => (
                  <StyledImageListItem
                    onClick={() => setCarouselImageIndex(index)}
                    key={index}
                    ref={imageListRef[index]}>
                    <Box
                      border="2px solid"
                      borderColor={
                        carouselImageIndex === index ? 'green' : 'black'
                      }
                      component="img"
                      width="80%"
                      height="80%"
                      src={url}
                      loading="lazy"
                      alt={url}
                    />
                  </StyledImageListItem>
                ))}
              </StyledImageList>
            </Box>
          )}
        </Box>
        <Box sx={{order: {xs: 1, sm: 2}}}>
          <Carousel
            next={onChangeHandler}
            prev={onChangeHandler}
            index={carouselImageIndex}
            indicators={false}
            autoPlay={false}>
            {imageUrls.map((url, index) => (
              <Box key={index}>
                <CarouselImage src={url} loading="lazy" alt={url} />
                {percentage > 0 && (
                  <ImageBanner
                    display="flex"
                    justifyContent="center"
                    sx={{
                      width: {xs: '60%', sm: '70%', md: '50%'},
                      minWidth: '225px',
                      height: {xs: '64px', sm: '74px'},
                    }}>
                    <Box
                      width="90%"
                      display="flex"
                      justifyContent="space-around"
                      alignItems="center">
                      <Box>
                        <Header4
                          fontSize={`clamp(1.125rem, ${calcPreferredValue(
                            30,
                            18,
                          )}, 1.875em)`}
                          inline
                          color={theme.colors.tertiary}>
                          {percentage + '%'}
                        </Header4>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start">
                        <Box width="100px">
                          <BodyReg
                            fontSize={`clamp(0.625rem, ${calcPreferredValue(
                              12,
                              10,
                            )}, 0.75rem)`}
                            lineHeight={`clamp(1rem, ${calcPreferredValue(
                              20,
                              16,
                            )}, 1.25rem)`}
                            inline
                            color={theme.colors.white}>
                            of your estimated
                          </BodyReg>
                        </Box>
                        <Box width="112px">
                          <Header5
                            fontSize={`clamp(0.625rem, ${calcPreferredValue(
                              16,
                              10,
                            )}, 1rem)`}
                            lineHeight={`clamp(1rem, ${calcPreferredValue(
                              20,
                              16,
                            )}, 1.25rem)`}
                            inline
                            color={theme.colors.white}>
                            Power Needs
                          </Header5>
                        </Box>
                      </Box>
                    </Box>
                  </ImageBanner>
                )}
              </Box>
            ))}
          </Carousel>
        </Box>
      </Box>
    </Box>
  );
}

PackageCarousel.propTypes = {
  imageUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
  percentage: PropTypes.number,
};
