import React from 'react';

import PropTypes from 'prop-types';

import {StyledTooltip} from './styles';

export default function SliderLabel({children, value}) {
  return (
    <StyledTooltip
      valueLabelDisplay="auto"
      enterTouchDelay={0}
      placement="top"
      title={value}
      arrow>
      {children}
    </StyledTooltip>
  );
}

SliderLabel.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.string.isRequired,
};
