import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  overflow: scroll;
`;

export const MobileInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: ${({theme}) => theme.spacing.tiny};
`;

export const MobileBody = styled.div`
  display: flex;
  max-width: 100%;
  flex-direction: column;
  margin-bottom: ${({theme}) => theme.spacing.large};
  margin-left: ${({theme}) => theme.spacing.normal};
`;

export const MobileHeader = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  align-items: center;
  text-align: center;
  border-top: ${({theme}) => `1px solid ${theme.colors.border}`};
  font: 600 14px Inter;
  height: 60px;
  background: white;
  width: 100%;
`;

export const MobileBackButton = styled.img`
  margin-left: ${({theme}) => theme.spacing.medium};
`;

export const MobileMainBackButton = styled.img`
  margin-left: ${({theme}) => theme.spacing.medium};
`;

export const MobileHeadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: ${({theme}) => `1px solid ${theme.colors.grayFour}`};
  padding-bottom: ${({theme}) => theme.spacing.tiny};
  margin-left: -16px;
`;
