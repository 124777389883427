import React, {useState, useRef, useCallback} from 'react';

import {Box} from '@material-ui/core';
import PropTypes from 'prop-types';
import {useRecoilValue} from 'recoil';

import atoms, {STRINGS} from '../../atoms';
import {productsServicesUrl} from '../../navigation/routes';
import CarouselProductCard from '../CarouselProductCard';
import Link from '../Link';
import {BodyReg, Header1} from '../newTypography';
import ArrowButton from './ProgButton';
import {
  Wrapper,
  UpperRow,
  LowerRow,
  ShowWrapper,
  StyledCategoryCard,
} from './styles';

// Multipliers used for calculating new scroll position;
const LEFT = -1;
const RIGHT = 1;

const CarouselRow = ({
  data = [],
  title,
  description,
  productRow,
  showGrid,
  id,
  wrapperStyling,
  pageScrollCallBack,
  similarProduct,
}) => {
  const [showAll, setShowAll] = useState(showGrid);
  const theme = useRecoilValue(atoms.theme);
  const scrollRef = useRef();

  const scroll = useCallback(
    direction => () => {
      const currentScrollPosition = scrollRef.current.scrollLeft;
      const moveValue = 200;
      const left = currentScrollPosition + moveValue * direction;
      scrollRef.current.scroll({left, behavior: 'smooth'});
    },
    [],
  );

  const checkForDoubles = () => {
    let mutableData = [...data];
    let newData = [];
    data.forEach((item, idx) => {
      if (newData.includes(item.itemId)) {
        mutableData.splice(idx, 1);
      } else {
        newData.push(item.itemId);
      }
    });
    return mutableData;
  };

  const clickShowAll = () => {
    pageScrollCallBack?.();
    setShowAll(true);
  };

  return (
    <Wrapper {...{wrapperStyling}}>
      <UpperRow id="products-and-services-recommended-for-you" title={title}>
        {title && (
          <Box>
            <Header1 inline fontSize="24px" lineHeight="28px">
              {title}
            </Header1>
            <BodyReg>{description}</BodyReg>
          </Box>
        )}
        {!showAll && (
          <ShowWrapper>
            <Box
              component={Link}
              display="flex"
              justifyContent="center"
              to={`${productsServicesUrl}/${id}`}
              sx={{
                fontSize: 16,
                fontWeight: 500,
                mr: '12px',
              }}
              onClick={clickShowAll}>
              {STRINGS.SHOW_ALL}
            </Box>
            <ArrowButton onClick={scroll(LEFT)}>
              <img alt="Progress Left" src={theme.images.chevronLeft} />
            </ArrowButton>
            <ArrowButton onClick={scroll(RIGHT)}>
              <img alt="Progress Right" src={theme.images.chevronRight} />
            </ArrowButton>
          </ShowWrapper>
        )}
      </UpperRow>
      <LowerRow ref={scrollRef} showAll={showAll} onClick={pageScrollCallBack}>
        {!productRow &&
          data.map(item => (
            <StyledCategoryCard
              category={item.market}
              key={item.subCategoryId}
              scope={`products and services | ${title}`}
            />
          ))}
        {productRow &&
          checkForDoubles().map((item, index) => (
            <CarouselProductCard
              title={title}
              key={index}
              data={item}
              showAll={showAll}
              similarProduct={similarProduct}
            />
          ))}
      </LowerRow>
    </Wrapper>
  );
};

CarouselRow.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  description: PropTypes.string,
  wrapperStyling: PropTypes.shape({}),
  id: PropTypes.string,
  showGrid: PropTypes.bool,
  productRow: PropTypes.bool,
  pageScrollCallBack: PropTypes.func,
  similarProduct: PropTypes.bool,
};

export default CarouselRow;
