import React from 'react';

import {Box} from '@material-ui/core';
import {Helmet} from 'react-helmet';
import {useHistory} from 'react-router-dom';
import {useTheme} from 'styled-components';

import {RoundedButton} from '../../components/Button';
import {
  BodyExLg,
  BodyMed,
  calcPreferredValue,
} from '../../components/newTypography';
import {solarUrl} from '../../navigation/routes';
import BenefitsSection from './BenefitsSection';
import HeroBannerSection from './HeroBannerSection';
import MiddleBanner from './MiddleBanner';
import RequirementsSection from './RequirementsSection';
import SolarLoanArticles from './SolarLoanArticles';
import StepsSection from './StepsSection';
import {CenterBox} from './styles';

export default function MainContent() {
  const theme = useTheme();
  const history = useHistory();

  return (
    <div>
      <Helmet>
        <title>LookSee Solar Loan</title>
      </Helmet>
      <HeroBannerSection />
      <CenterBox
        bgcolor={theme.colors.grayEleven}
        height="4%"
        p="3%"
        textAlign="center">
        <BodyExLg
          color={theme.colors.tertiary}
          fontWeight="700"
          fontSize={`clamp(1.25rem, ${calcPreferredValue(40, 20)}, 2.5rem)`}>
          Benefits of a financed solar package from LookSee
        </BodyExLg>
        <BodyMed color={theme.colors.tertiary}>
          Quick and easy solar solutions from a bank you can trust
        </BodyMed>
      </CenterBox>
      <BenefitsSection />
      <MiddleBanner />
      <StepsSection />
      <RequirementsSection />
      <SolarLoanArticles />
      <Box p="5%">
        <Box
          component={RoundedButton}
          sx={{width: {xs: '100%', sm: '10%'}}}
          data-intent="navigational"
          data-scope="button"
          data-id="link_content"
          data-text="Apply for a Solar Loan | back button click"
          text="Back"
          onClick={() => history.push(solarUrl)}
          textColor={theme.colors.tertiary}
          borderColor={theme.colors.tertiary}
          border="1px solid"
          backgroundColor={theme.colors.white}
        />
      </Box>
    </div>
  );
}
