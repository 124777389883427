import React from 'react';

import {Helmet} from 'react-helmet';
import {Redirect, Route, Switch, useRouteMatch} from 'react-router-dom';

import OrderSummaryItem from '../../components/OrderSummary';
import QuoteSummaryItem from '../../components/QuoteSummaryItem';
import SubNavbar from '../../components/SubNavbar';
import {solarApply} from '../../navigation/routes';
import {PrivateRoute} from '../../navigation/utils';
import {useFeaturesContext} from '../../provider/features';
import Categories from '../Categories';
import ProductDetails from '../ProductDetails';
import Products from '../Products';
import Solar from '../Solar';
import SolarLoan from '../SolarLoan';
import SolarScore from '../SolarScore';
import {Content} from './styles';

export default function ProductsAndServices() {
  const {path} = useRouteMatch();
  const features = useFeaturesContext();
  const activeApplication = location.pathname === solarApply;
  const redirect = () => {
    return (
      <Redirect
        to={{
          pathname: `${path}/solar`,
          from: location,
        }}
      />
    );
  };

  return (
    <>
      {!activeApplication && <SubNavbar />}
      <Content>
        <Helmet>
          <title>LookSee Products and Services</title>
        </Helmet>
        <Switch>
          <PrivateRoute path={`${path}/purchases`} exact>
            <OrderSummaryItem />
          </PrivateRoute>
          <PrivateRoute path={`${path}/quotes`} exact>
            <QuoteSummaryItem />
          </PrivateRoute>
          {features?.solar && (
            <Route path={`${path}/solar`}>
              <Solar />
            </Route>
          )}
          {features?.solarScore && (
            <Route path={`${path}/solarscore`}>
              <SolarScore />
            </Route>
          )}
          {features?.solarLoan && (
            <Route path={`${path}/solarloan`}>
              <SolarLoan />
            </Route>
          )}
          <Route path={`${path}/:categoryCode/:subcategoryCode/:itemCode`}>
            <ProductDetails />
          </Route>
          <Route path={`${path}/:categoryCode/:subcategoryCode`}>
            <Products />
          </Route>
          <Route path={`${path}/:categoryCode`}>
            <Categories />
          </Route>
          <Route path={path} exact>
            {redirect()}
          </Route>
        </Switch>
      </Content>
    </>
  );
}
