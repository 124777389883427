// eslint-disable-next-line no-restricted-imports
import log from 'loglevel';
import prefixer from 'loglevel-plugin-prefix';
import remote from 'loglevel-plugin-remote';
import {v4 as uuidv4} from 'uuid';

import config from '../config';

function setupLoggingPrefix() {
  prefixer.reg(log);
  prefixer.apply(log, {
    timestampFormatter: timestamp => timestamp.toISOString(),
    nameFormatter: name => name || 'root',
    levelFormatter: level => level.toUpperCase(),
    template: '[%t] (%n) %l:',
  });
}

function setupRemoteLogging() {
  const sessionId = uuidv4();
  const formatRemoteLogs = data => ({
    ...data,
    url: window.location.pathname,
    level: data.level.label,
    sessionId,
  });

  remote.apply(log, {
    url: config.services.remoteLogUrl,
    level: config.services.remoteLogLevel,
    method: 'POST',
    format: formatRemoteLogs,
    stacktrace: {
      depth: 5,
    },
  });
}

function setupEventHandlers() {
  window.addEventListener('error', e => {
    log.error(e.error);
  });
  window.addEventListener('unhandledrejection', e => {
    log.error(e.reason);
  });
}

export function initializeLogger() {
  log.setDefaultLevel(config.services.localLogLevel);
  setupLoggingPrefix();
  if (config.services.remoteLogUrl) {
    setupRemoteLogging();
  }
  setupEventHandlers();
}

export {log as logger};
