import React, {useEffect, useState} from 'react';

import {Box, Grid} from '@material-ui/core';
import {useConditionalEffect} from '@react-hookz/web';
import {Helmet} from 'react-helmet';
import Carousel from 'react-material-ui-carousel';
import {useParams} from 'react-router-dom';

import {CarouselRow} from '../../components';
import MarketplaceBreadcrumbs from '../../components/MarketplaceBreadcrumbs';
import ProductDetailsHeader from '../../components/ProductDetailsHeader';
import ProductFaqs from '../../components/ProductFaqs';
import ProductFeatures from '../../components/ProductFeatures';
import {logger} from '../../services/Logger';
import LookSeeServices from '../../services/LookSeeServices';
import {
  dataLayerVariables,
  sendComponentAnalytics,
} from '../../utils/Analytics';
import {
  AccordianContainer,
  BreadCrumbWrapper,
  CarouselContainer,
  ProductInfo,
  PageStart,
  CarouselImage,
  gridItem,
  gridStyle,
  carouselRowStyle,
} from './styles';

function ProductDetails() {
  const pageStart = React.createRef();
  const {categoryCode, subcategoryCode, itemCode} = useParams();
  const decodedSubcategoryCode = decodeURIComponent(subcategoryCode);
  const decodedItemCode = decodeURIComponent(itemCode);
  const [productInfo, setProductInfo] = useState(null);
  const [subcategoryProducts, setSubcategoryProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useConditionalEffect(
    () => {
      const categoryName = productInfo?.breadCrumb[0].name;
      const subcategoryName = productInfo?.breadCrumb[1].name;

      sendComponentAnalytics(
        {
          ...dataLayerVariables({
            pageCategory: 'products and services',
            pageName: `products and services:${categoryName}:${subcategoryName}:${productInfo?.name}`,
            pageSubSection1: 'products and services',
            pageSubSection2: `products and services:${categoryName}`,
            pageSubSection3: `products and services:${categoryName}:${subcategoryName}`,
            pageSubSection4: `products and services:${categoryName}:${subcategoryName}:${productInfo?.name}`,
          }),
          ecommerce: {
            currencyCode: 'ZAR',
            product: [
              {
                category: categoryName,
                family: subcategoryName,
                sku: productInfo.itemCode,
                productName: productInfo.name,
                price: productInfo?.amount ?? productInfo?.monthlyAmount,
              },
            ],
          },
        },
        'productView',
      );
    },
    [productInfo],
    [productInfo],
  );

  useEffect(() => {
    async function fetchProductInfo() {
      try {
        const result = await LookSeeServices.getProduct(
          decodedSubcategoryCode,
          decodedItemCode,
        );
        setProductInfo(result);

        if (result) {
          const products = await LookSeeServices.getSubcategory(
            result.subCategoryId,
          );
          let filteredProducts = products.items.filter(
            product => product.itemCode !== decodedItemCode,
          );
          setSubcategoryProducts(filteredProducts);
        }
      } catch (error) {
        logger.error(error);
      } finally {
        setLoading(false);
      }
    }

    fetchProductInfo();
  }, [decodedSubcategoryCode, decodedItemCode]);

  const [carouselImageIndex] = useState(0);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {productInfo && (
        <Helmet>
          <title>{productInfo.name}</title>
          <meta name="og:title" content={productInfo.name} />
          <meta name="description" content={productInfo.description} />
          <meta name="og:description" content={productInfo.description} />
          {/*<meta name="keywords" content={keywords} />*/}
          <meta name="og:image" content={productInfo.imageUrl[0].name} />
          <meta name="og:url" content={location.href} />
        </Helmet>
      )}
      <PageStart ref={pageStart} />
      {productInfo && (
        <BreadCrumbWrapper>
          <MarketplaceBreadcrumbs
            productName={productInfo.name}
            categoryName={productInfo.breadCrumb[0].name}
            subcategoryName={productInfo.breadCrumb[1].name}
            categoryCode={productInfo.breadCrumb[0].categoryCode}
            subcategoryCode={productInfo.breadCrumb[1].categoryCode}
          />
        </BreadCrumbWrapper>
      )}
      <Box marginY={6}>
        {productInfo && (
          <Grid container style={gridStyle}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6} style={gridItem}>
                  <CarouselContainer>
                    <Carousel index={carouselImageIndex}>
                      {productInfo.imageUrl.map(image => (
                        <CarouselImage
                          key={image.index}
                          src={image.name}
                          loading="lazy"
                          alt={image.name}
                        />
                      ))}
                    </Carousel>
                  </CarouselContainer>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ProductInfo>
                    <ProductDetailsHeader
                      loading={loading}
                      productInformation={productInfo}
                    />
                  </ProductInfo>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <AccordianContainer>
                {productInfo.feature && (
                  <ProductFeatures features={productInfo.feature} />
                )}
                {productInfo.faqs && <ProductFaqs faqs={productInfo.faqs} />}
              </AccordianContainer>
            </Grid>
          </Grid>
        )}
      </Box>
      {subcategoryProducts.length > 0 && (
        <CarouselRow
          pageScrollCallBack={() =>
            pageStart.current.scrollIntoView({behavior: 'smooth'})
          }
          wrapperStyling={carouselRowStyle}
          title="Similar Products"
          data={subcategoryProducts}
          productRow
          showAll
          id={`${categoryCode}/${subcategoryCode}`}
          similarProduct
        />
      )}
    </>
  );
}

export default ProductDetails;
