import React, {useCallback, useRef} from 'react';

import {
  Box,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {Formik, Field, Form} from 'formik';
import PropTypes from 'prop-types';
import {useRecoilValue} from 'recoil';
import {useTheme} from 'styled-components';
import * as Yup from 'yup';

import atoms, {STRINGS} from '../../atoms';
import {
  FORM_REQUIREMENTS,
  SOLAR_CALL_ME_BACK_TYPE,
} from '../../atoms/app/constants';
import {RoundedButton} from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import FormikTextField from '../../components/FormikTextField';
import {Headline2} from '../../components/typography';
import {termsUrl} from '../../navigation/routes';
import LookSeeServices from '../../services/LookSeeServices';
import {sendComponentAnalytics} from '../../utils/Analytics';
import {phoneValidator} from '../../validations/phone';
import {Link, StyledBody, StyledMainTitle} from './styles';

const callMeBackValidationSchema = Yup.object().shape({
  firstName: Yup.string().required(FORM_REQUIREMENTS.FIRST_NAME),
  lastName: Yup.string().required(FORM_REQUIREMENTS.LAST_NAME),
  phoneNumber: phoneValidator,
  email: Yup.string().required(FORM_REQUIREMENTS.EMAIL_ADDRESS),
  type: Yup.string().required(FORM_REQUIREMENTS.CHOOSE_ONE),
  consent: Yup.bool().oneOf([true], FORM_REQUIREMENTS.CONSENT_IS_REQUIRED),
  terms: Yup.bool().oneOf([true], STRINGS.READ_THE_TERMS_AND_ACCEPT_CONDITIONS),
});

const inputRow = [
  {
    name: 'firstName',
    label: FORM_REQUIREMENTS.FIRST_NAME,
  },
  {
    name: 'lastName',
    label: FORM_REQUIREMENTS.LAST_NAME,
  },
];

const inputs = [
  {
    name: 'email',
    label: FORM_REQUIREMENTS.EMAIL_ADDRESS,
  },
  {
    name: 'phoneNumber',
    label: FORM_REQUIREMENTS.PHONE_NUMBER,
  },
];

const enquiryForm = {
  formName: STRINGS.SOLAR_SOLUTIONS_TITLE.toLowerCase(),
  title: STRINGS.SOLAR_SOLUTIONS_TITLE,
  description: STRINGS.SOLAR_SOLUTIONS_DESCRIPTION,
};

function SolarEnquiryModal({open = false, onClose}) {
  const theme = useTheme();
  const user = useRecoilValue(atoms.user);
  const formStartedRef = useRef(false);

  const onValueChange = () => {
    if (!formStartedRef.current) {
      sendComponentAnalytics(
        {formStatus: '', formName: enquiryForm.formName},
        'globalFormStart',
      );
      formStartedRef.current = true;
    }
  };

  const initialValues = {
    firstName: user?.first_name || '',
    lastName: user?.last_name || '',
    phoneNumber: user?.phone || '',
    email: user?.email || '',
    type: '',
    terms: false,
    consent: false,
  };

  const submit = useCallback(
    async values => {
      sendComponentAnalytics(
        {
          formStatus: 'complete',
          formisSubmitted: true,
          formName: enquiryForm.formName,
        },
        'globalFormComplete',
      );

      await LookSeeServices.callMeBack(values);
      onClose();
    },
    [onClose],
  );

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description">
      <DialogContent>
        <Box display="flex">
          <Box
            height={10}
            padding={0}
            component={IconButton}
            aria-label="close"
            onClick={onClose}
            marginLeft="auto">
            <CloseIcon />
          </Box>
        </Box>
        <Box>
          <StyledMainTitle fontSize="32px" color={theme.colors.blueMobileNav}>
            {enquiryForm.title}
          </StyledMainTitle>
          <Box pt={1}>
            <StyledBody color={theme.colors.black}>
              {enquiryForm.description}
            </StyledBody>
          </Box>
        </Box>
        <Box pt={2}>
          <Formik
            validationSchema={callMeBackValidationSchema}
            initialValues={initialValues}
            onSubmit={submit}>
            {({isSubmitting, values, isValid, dirty}) => (
              <Form
                aria-label="form"
                data-frmcontainer={enquiryForm.formName}
                id={enquiryForm.formName}
                name={enquiryForm.formName}
                onChange={onValueChange}>
                <Grid spacing={2} container>
                  {inputRow.map((item, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                      <Field
                        component={FormikTextField}
                        label={item.label}
                        key={index}
                        name={item.name}
                      />
                    </Grid>
                  ))}
                  {inputs.map((item, index) => (
                    <Grid item xs={12} key={index}>
                      <Field
                        component={FormikTextField}
                        label={item.label}
                        name={item.name}
                      />
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="type-select-label">
                        {STRINGS.ENQUIRY_TYPE}
                      </InputLabel>
                      <Field
                        as={Select}
                        labelId="type-select-label"
                        name="type"
                        value={values.type}
                        label={STRINGS.ENQUIRY_TYPE}>
                        {SOLAR_CALL_ME_BACK_TYPE.map(value => (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={Checkbox}
                      name="consent"
                      text={
                        <Headline2>
                          I give my consent to pass on my details to LookSee and
                          3rd parties that will be necessary in order to deliver
                          the service.
                        </Headline2>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={Checkbox}
                      name="terms"
                      text={
                        <Headline2>
                          {STRINGS.ACCEPT_TERMS}{' '}
                          <Link
                            data-id="link_content"
                            data-scope="link"
                            data-intent="informational"
                            data-text={`${STRINGS.SOLAR_SOLUTIONS_TITLE} | ${STRINGS.TERMS_AND_CONDITIONS} link click`}
                            to={termsUrl}
                            color={theme.colors.primary}>
                            {STRINGS.TERMS_AND_CONDITIONS}
                          </Link>
                        </Headline2>
                      }
                    />
                  </Grid>
                </Grid>
                <Box
                  display="flex"
                  sx={{justifyContent: {xs: 'center', sm: 'flex-end'}}}
                  py={1}
                  width="100%">
                  <Box px={1}>
                    <RoundedButton
                      onClick={onClose}
                      data-text={`${STRINGS.SOLAR_SOLUTIONS_TITLE} | ${STRINGS.BACK} button click`}
                      data-id="link_content"
                      data-scope="button"
                      data-intent="navigational"
                      backgroundColor={theme.colors.white}
                      borderColor={theme.colors.blueMobileNav}
                      text={STRINGS.BACK}
                      textColor={theme.colors.blueMobileNav}
                    />
                  </Box>
                  <Box px={1}>
                    <RoundedButton
                      data-text={`${STRINGS.SOLAR_SOLUTIONS_TITLE} | ${STRINGS.SUBMIT} button click`}
                      data-id="link_content"
                      data-scope="button"
                      data-intent="confirmational"
                      text={STRINGS.SUBMIT}
                      type="submit"
                      backgroundColor={theme.colors.blueMobileNav}
                      textColor={theme.colors.white}
                      disabled={!isValid || !dirty || isSubmitting}
                      disabledColor={theme.colors.white}
                      disabledBGColor={theme.colors.grayFour}
                    />
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

SolarEnquiryModal.propTypes = {
  open: PropTypes.bool,
  submit: PropTypes.func,
};

export default SolarEnquiryModal;
