import styled from 'styled-components';

export const Wrapper = styled.div`
  cursor: pointer;
`;

export const Title = styled.h1`
  font-size: 18px;
  font-weight: 400;
  text-align: center;
`;

export const BodyText = styled.p`
  font: ${({theme}) => theme.typography.body};
  color: ${({theme}) => theme.colors.grayEight};
  margin: 0px;
  margin-top: ${({theme}) => theme.spacing.normal};
  margin-bottom: ${({theme}) => theme.spacing.normal};
  text-align: center;
`;

export const TitleImage = styled.img`
  width: 100%;
  box-sizing: border-box;
  border-radius: ${({theme}) => theme.spacing.xSmall};
  object-fit: contain;
  display: flex;
  align-items: center;

  @media ${props => props.theme.device.mobileL} {
    height: 205px;
  }
`;
