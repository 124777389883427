import getPkce from 'oauth-pkce';
import {v4 as uuidv4} from 'uuid';

import config from '../config/index';
import HttpServices from './HttpServices';

const PING_BASE_URL = config.pingIdentity.pingBaseUrl;
const PING_AUD_ID = config.pingIdentity.pingAudienceId;
const PING_LOCAL_IDENTITY_PROFILE_ID =
  config.pingIdentity.pingLocalIdentityProfileId;

const PingIdentityService = {
  getLocationOrigin() {
    let locationOrigin = window.location.origin;
    // This is done so that if you are running Web in develop then you can still access the page since
    // http://localhost:3000 is not trusted because it does not use SSL and https://localhost:3000 is trusted
    if (locationOrigin.startsWith('http:')) {
      locationOrigin = `https:${locationOrigin.slice(5)}`;
    }

    return locationOrigin;
  },
  setLoginState({code_verifier, state, nonce}) {
    sessionStorage.setItem('nonce', nonce);
    sessionStorage.setItem('state', state);
    sessionStorage.setItem('verifier', code_verifier);
  },
  getChallenge() {
    return new Promise(resolve => {
      getPkce(43, (error, {verifier, challenge}) => {
        if (error) {
          throw error;
        }
        resolve({verifier, challenge});
      });
    });
  },
  async redirectToLogin(isRegister) {
    const {verifier: code_verifier, challenge: code_challenge} =
      await this.getChallenge();
    const base = `${PING_BASE_URL}/as/authorization.oauth2`;
    const redirect = config.pingIdentity.pingRedirectUri;
    let loginAuthPath;
    const nonce = uuidv4();
    const state = uuidv4();
    this.setLoginState({code_verifier, state, nonce});

    loginAuthPath = `${base}?${
      isRegister ? 'target=register&' : ''
    }client_id=${PING_AUD_ID}&response_type=code&scope=openid email profile&redirect_uri=${redirect}&code_challenge=${code_challenge}&code_challenge_method=S256&nonce=${nonce}&state=${state}`;

    window.location.assign(loginAuthPath);
  },
  async login({code, state}) {
    const savedState = sessionStorage.getItem('state');
    sessionStorage.removeItem('state');
    if (state !== savedState) {
      throw new Error('Invalid state');
    }
    const nonce = sessionStorage.getItem('nonce');
    sessionStorage.removeItem('nonce');

    const verifier = sessionStorage.getItem('verifier');
    sessionStorage.removeItem('verifier');
    return HttpServices.post('/user/login', {
      code,
      codeVerifier: verifier,
      nonce: nonce,
    });
  },
  async getCsrfToken() {
    const result = await HttpServices.get('/token/csrf');
    return result.token;
  },
  async logout() {
    await HttpServices.post('/user/logout');
    window.location.assign(
      `${PING_BASE_URL}/idp/startSLO.ping?TargetResource=${this.getLocationOrigin()}`,
    );
  },
  getManageAccountPath() {
    return `${PING_BASE_URL}/pf/idprofile.ping?LocalIdentityProfileID=${PING_LOCAL_IDENTITY_PROFILE_ID}&userType=general`;
  },
};

export default PingIdentityService;
