import React from 'react';

import {Box, Dialog, DialogContent} from '@material-ui/core';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {useTheme} from 'styled-components';

import useDetectMobile from '../../hooks/useDetectMobile';
import {RoundedButton} from '../Button';
import {Body, MainTitle, Title1} from '../typography';

export default function ThankYou({open, productName}) {
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useDetectMobile();
  const TitleComponent = isMobile ? Title1 : MainTitle;

  return (
    <Box component={Dialog} open={open}>
      <DialogContent>
        <Box my="5%" textAlign="center" alignItems="center">
          <img alt="send-cloud" src={theme.images.SendCloud} />
          <Box>
            <TitleComponent color={theme.colors.tertiary}>
              Thank you
            </TitleComponent>
          </Box>
          <Box my="5%">
            <Body color={theme.colors.grayOne}>
              A Customer Care Consultant will be in touch with you soon.
            </Body>
          </Box>
          <Box
            my="6%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center">
            <RoundedButton
              width="90%"
              data-id="link_content"
              data-text={`products and services | ${productName} query | Thank you | close button click`}
              data-scope="button"
              data-intent="navigational"
              backgroundColor={theme.colors.blueMobileNav}
              textColor={theme.colors.white}
              text="Close"
              onClick={() => history.push('/')}
            />
          </Box>
        </Box>
      </DialogContent>
    </Box>
  );
}

ThankYou.propTypes = {
  open: PropTypes.bool.isRequired,
};
