import React from 'react';

import {Box} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Alert from '@material-ui/lab/Alert';
import {useTheme} from 'styled-components';

function EnsureDetailsAreCorrectAlert() {
  const theme = useTheme();

  return (
    <Box bgcolor={theme.colors.lightGrey} borderRadius="4px 13px 13px 4px">
      <Alert
        variant="outlined"
        icon={<InfoOutlinedIcon style={{color: theme.colors.lightGrey}} />}
        style={{
          borderRadius: '4px 12px 12px 4px',
          border: `1px solid ${theme.colors.paleGrey}`,
          background: theme.colors.paleGrey25,
          marginLeft: '7px',
        }}>
        Please ensure these details are correct. Once you click Next, we&apos;ll
        do a credit check based on the details you&apos;ve given us
      </Alert>
    </Box>
  );
}

export default EnsureDetailsAreCorrectAlert;
