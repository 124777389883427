import {Component} from 'react';

import PropTypes from 'prop-types';

import {logger} from '../services/Logger';

export default class ErrorBoundary extends Component {
  static propTypes = {
    onError: PropTypes.func,
    fallback: PropTypes.node,
    children: PropTypes.node.isRequired,
  };

  constructor({fallback, children, onError}) {
    super({fallback, children, onError});
    this.state = {hasError: false};
  }

  static getDerivedStateFromError() {
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    logger.error(error, errorInfo);
    const {onError} = this.props;
    onError?.();
  }

  render() {
    const {hasError} = this.state;
    const {fallback, children} = this.props;
    if (hasError) {
      return fallback ?? null;
    }

    return children;
  }
}
