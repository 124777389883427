import AddMember from './images/AddMember.svg';
import AddNewProperty from './images/AddNewProperty.svg';
import Basket from './images/Basket.svg';
import CallOutService from './images/CallOutService.svg';
import CheckCircleIcon from './images/CheckCircleIcon.svg';
import CheckboxOn from './images/CheckboxOn.svg';
import ConfirmID from './images/ConfirmID.svg';
import Congratulations from './images/Congratulations.svg';
import Contact from './images/Contact.svg';
import Cooling from './images/Cooling.svg';
import Crime from './images/Crime.svg';
import EmptyBasket from './images/EmptyBasket.svg';
import ErrorCircleIcon from './images/ErrorCircleIcon.svg';
import FUEAccount from './images/FUE-account.svg';
import FUEAddService from './images/FUE-add-service.svg';
import FUECreatePost from './images/FUE-create-post.svg';
import FUEEventCard from './images/FUE-event-card.svg';
import FUEInfoIcon from './images/FUE-info-icon.svg';
import FUESettings from './images/FUE-settings.svg';
import Gardening from './images/Gardening.svg';
import GeneralError from './images/GeneralError.svg';
import GroupCategories from './images/GroupCategories.svg';
import GroupCategoriesMobile from './images/GroupCategoriesMobile.svg';
import GroupSteps from './images/GroupSteps.svg';
import IllustrationHeader from './images/IllustrationHeader.svg';
import LogIn from './images/LogIn.svg';
import LostConnection from './images/LostConnection.svg';
import MarketplaceTutorial from './images/MarketplaceTutorial.svg';
import MiniGetUpdates from './images/MiniGetUpdates.svg';
import MiniGetUpdates2 from './images/MiniGetUpdates2.svg';
import MiniHomeAndUtilities from './images/MiniHomeAndUtilities.svg';
import MiniHomeAndUtilities2 from './images/MiniHomeAndUtilities2.svg';
import MiniInsight from './images/MiniInsight.svg';
import MiniInviteNewMember from './images/MiniInviteNewMember.svg';
import MiniProduct from './images/MiniProduct.svg';
import MiniPropertyValue from './images/MiniPropertyValue.svg';
import MiniPropertyValue2 from './images/MiniPropertyValue2.svg';
import MiniSecurity from './images/MiniSecurity.svg';
import MiniService from './images/MiniService.svg';
import NoCommunity from './images/NoCommunity.svg';
import NoEvents from './images/NoEvents.svg';
import NoMapReturned from './images/NoMapReturned.svg';
import NoMarketplaceProducts from './images/NoMarketplaceProducts.svg';
import NoResults from './images/NoResults.svg';
import Power from './images/Power.svg';
import PropertyNotFound from './images/PropertyNotFound.svg';
import RecurringPaymentSuccess from './images/RecurringPaymentSuccess.svg';
import ResetPassword from './images/ResetPassword.svg';
import SectionIndicator1 from './images/SectionIndicator1.svg';
import SectionIndicator2 from './images/SectionIndicator2.svg';
import SectionIndicator3 from './images/SectionIndicator3.svg';
import service from './images/Service.svg';
import SignUp from './images/SignUp.svg';
import SmallIllustrationHeader from './images/SmallIllustrationHeader.svg';
import StepOne from './images/StepOne.svg';
import StepThree from './images/StepThree.svg';
import StepTwo from './images/StepTwo.svg';
import Success from './images/Success.svg';
import Survey from './images/Survey.svg';
import trustShield from './images/TrustShield.svg';
import book from './images/Tutorial.svg';
import Unlock from './images/Unlock.svg';
import UnlockWhite from './images/UnlockWhite.svg';
import Update from './images/Update.svg';
import Water from './images/Water.svg';
import WeveSentYouAnEmail from './images/WeveSentYouAnEmail.svg';
import accountKeyIcon from './images/account-key-icon.svg';
import accountLogOutIcon from './images/account-log-out-icon.svg';
import activityIconMobile from './images/activity-icon-mobile.svg';
import activityIconSelected from './images/activity-icon-selected.svg';
import activityIcon from './images/activity-icon.svg';
import addBlack from './images/add_black.svg';
import addCommunityPool from './images/add_community_pool.svg';
import adviceInspiration from './images/advice-and-inspiration.png';
import appStore from './images/app-store.svg';
import arrowDown from './images/arrow-down.svg';
import arrowDropdown from './images/arrow-dropdown.svg';
import arrowLeft from './images/arrow-left.svg';
import arrowRight from './images/arrow-right.svg';
import attachIcon from './images/attach-icon.svg';
import basketIconDark from './images/basket-icon-dark.svg';
import basketIconSelected from './images/basket-icon-selected.svg';
import basketIcon from './images/basket-icon.svg';
import blackChevronLeft from './images/black-chevron-left.svg';
import buildingServicesSubCat from './images/building-services-sub-cat.svg';
import burgularGuardSubcat from './images/burgular_guard_subcat.svg';
import calendarIcon from './images/calendar-icon.svg';
import callOutIcon from './images/call-out-icon.svg';
import callOutIconSelected from './images/call-out-selected-icon.svg';
import cctvIcon from './images/cctv-icon.svg';
import cellPhone from './images/cellPhone.svg';
import checkAllIcon from './images/check-all-icon.svg';
import checkCircle from './images/check-circle.svg';
import checkIcon from './images/check-icon.svg';
import checkAllIconWhite from './images/checkAlliconWhite.svg';
import checkboxOff from './images/checkbox-off.svg';
import checkboxOn from './images/checkbox-on.svg';
import checkedRadioIcon from './images/checked-radio-icon.svg';
import checkmark from './images/checkmark.svg';
import chevronRightBreadcrumbs from './images/chevron-right-breadcrumbs.svg';
import chevronRightWhite from './images/chevron-right-white.svg';
import chevronRightWithBubble from './images/chevron-right-with-bubble.svg';
import chevronLeft from './images/chevron_left.svg';
import chevronRight from './images/chevron_right.svg';
import circleAddIconGreyMobile from './images/circle-add-icon-grey-mobile.svg';
import circleAddIconGrey from './images/circle-add-icon-grey.svg';
import circleAddIcon from './images/circle-add-icon.svg';
import claim from './images/claim.svg';
import clipboardIcon from './images/clipboard-icon.png';
import closeIconBlack from './images/close-icon-black.svg';
import closeIconCircle from './images/close-icon-circle.svg';
import closeIconWhite from './images/close-icon-white.svg';
import closeIcon from './images/close-icon.svg';
import communityEventNotificationIcon from './images/community-event-notification-icon.svg';
import communityMembersIconMobile from './images/community-members-icon-mobile.svg';
import communityMembersIconSelected from './images/community-members-icon-selected.svg';
import communityMembersIcon from './images/community-members-icon.svg';
import communityMyPostsIconMobile from './images/community-my-posts-icon-mobile.svg';
import communityMyPostsIconSelected from './images/community-my-posts-icon-selected.svg';
import communityMyPostsIcon from './images/community-my-posts-icon.svg';
import communityPollNotificationIcon from './images/community-poll-notification-icon.svg';
import communityPoolNotificationIcon from './images/community-pool-notification-icon.svg';
import communityPostNotificationIcon from './images/community-post-notification-icon.svg';
import contactUsImage from './images/contact-us-form-header.png';
import coolingIcon from './images/coolingIcon.svg';
import crimeIcon from './images/crime-icon.svg';
import CrimeEventIcon from './images/crime_event_icon.svg';
import drawingIllustratedIcon from './images/drawing-illustrated-icon.svg';
import editIcon from './images/edit-icon.svg';
import ElecEventIcon from './images/elec_event_icon.svg';
import electricAlert from './images/electric-alert.svg';
import electricityServicesSubCat from './images/electricity-services-sub-cat.svg';
import ellipsesIcon from './images/ellipses.svg';
import Empower from './images/empower.png';
import emptyBasket from './images/empty-basket.svg';
import emptyIcon from './images/empty-icon.svg';
import emptyHeart from './images/empty_heart.svg';
import emptySearch from './images/empty_search.svg';
import energyIconSelected from './images/energy-icon-selected.svg';
import energyIcon from './images/energy-icon.svg';
import errorIcon from './images/error-icon.svg';
import estValue from './images/est_value.svg';
import eventBlack from './images/event-black.svg';
import eventDropdownArrow from './images/event-dropdown-arrow.svg';
import eventIcon from './images/event.svg';
import exitIcon from './images/exit-icon.svg';
import filterIcon from './images/filter-icon.svg';
import fireIcon from './images/fireIcon.svg';
import fridgeIcon from './images/fridge-icon.svg';
import garageIcon from './images/garage-door-icon.svg';
import gateIcon from './images/gate-icon.svg';
import generatorSubcat from './images/generator_subcat.svg';
import geyserIcon from './images/geyserIcon.svg';
import geyserSubcat from './images/geyser_subcat.svg';
import globalBackIcon from './images/global-back-icon.svg';
import globalImageIcon from './images/global-image-icon.svg';
import goingIconBlack from './images/going-icon-black.svg';
import goingIconGray from './images/going-icon-gray.svg';
import grayX from './images/grayX.svg';
import grayChevronLeft from './images/gray_chevron_left.svg';
import grayDot from './images/gray_dot.svg';
import grayEllipses from './images/gray_ellipses.svg';
import hash from './images/hash.svg';
import homeFrame from './images/home-frame.svg';
import homeIcon from './images/home-icon.svg';
import homeImg1 from './images/home-img1.svg';
import homeImg2 from './images/home-img2.svg';
import homeImg3 from './images/home-img3.svg';
import HomeManagementServices from './images/home-management-services.svg';
import HomeUtility from './images/homeandutilities.svg';
import homeIconSelected from './images/house-icon.svg';
import batteryIcon from './images/hpa/battery-icon.svg';
import inverterIcon from './images/hpa/inverter-icon.svg';
import solarBrochureContentImageOne from './images/hpa/solar-brochure-content-image-one.png';
import solarBrochureContentImageTwo from './images/hpa/solar-brochure-content-image-two.png';
import solarBrochureFooterBannerImage from './images/hpa/solar-brochure-footer-banner.png';
import solarBrochureHeaderBannerImage from './images/hpa/solar-brochure-landing-page-header.png';
import solarBrochureTileImageOne from './images/hpa/solar-brochure-one.png';
import solarBrochureTileImageThree from './images/hpa/solar-brochure-three.png';
import solarBrochureTileImageTwo from './images/hpa/solar-brochure-two.png';
import solarLoanBannerImage from './images/hpa/solar-loan-house.png';
import solarPowerIcon from './images/hpa/solar-power-icon.svg';
import HpaTestimonial from './images/hpa/testimonial.png';
import SendCloud from './images/hpa/thankyou-send-cloud.png';
import accountBalanceIcon from './images/icons/account_balance.svg';
import bolt from './images/icons/bolt.svg';
import financing from './images/icons/financing.svg';
import handShakeIcon from './images/icons/handshake.svg';
import filledLightBulb from './images/icons/light-bulb.svg';
import output from './images/icons/output.svg';
import savings from './images/icons/savings.svg';
import settingsSuggestIcon from './images/icons/settings_suggest.svg';
import supportAgentIcon from './images/icons/support_agent.svg';
import systemSize from './images/icons/system_size.svg';
import imageIcon from './images/image-icon.svg';
import infoGray from './images/info_g.svg';
import info from './images/info_i.svg';
import infoLG from './images/info_l_g.svg';
import insight from './images/insight.svg';
import keyIcon from './images/key-icon.svg';
import knowledgeHub from './images/knowledge-hub-icon.svg';
import landingHeroBannerDecoration from './images/landing-page-decoration.svg';
import landingHeroBannerDesktop from './images/landing-page-desktop-image.png';
import landingHeroBannerMobile from './images/landing-page-mobile-image.png';
import laptopIcon from './images/laptop-icon.svg';
import lastPrice from './images/last_price.svg';
import lastSold from './images/last_sold.svg';
import lightBulbIcon from './images/light-bulb-icon.svg';
import lightBulb from './images/light_bulb.svg';
import lightningIcon from './images/lightningIcon.svg';
import loadSheddingIcon from './images/load-shedding-icon.svg';
import locationIconDisabled from './images/location-icon-disabled.svg';
import locationIconSelected from './images/location-icon-selected.svg';
import locationIcon from './images/location-icon.svg';
import LockClosedIcon from './images/lock-closed-icon.svg';
import logOutIcon from './images/logout-icon.svg';
import lookSeeLogo from './images/look-see-logo.svg';
import magnifyingGlassIcon from './images/magnifying-glass-icon.svg';
import managePropertiesIcon from './images/manage-properties-icon.svg';
import mapPoint from './images/map_point.svg';
import feedPower from './images/market_power_alert.svg';
import feedWater from './images/market_water_alert.svg';
import marketplaceIcon from './images/marketplace-icon.svg';
import marketplaceNotificationIcon from './images/marketplace-notification-icon.svg';
import MiniConsumables from './images/marketplace/MiniConsumables.svg';
import MiniProductBundle from './images/marketplace/MiniProduct.svg';
import MiniServiceBundle from './images/marketplace/MiniServices.svg';
import quotesIcon from './images/marketplace/QuotesIcon.svg';
import quotesIconSelected from './images/marketplace/QuotesIconSelected.svg';
import homeMgtServicesIcon from './images/marketplace/home-mgt-services-icon.svg';
import mobileNavIcon from './images/marketplace/mobile-nav-icon.svg';
import purchasesIconSelected from './images/marketplace/purchases-icon-selected.svg';
import purchasesIcon from './images/marketplace/purchases-icon.svg';
import recentlyViewedIconSelected from './images/marketplace/recently-viewed-icon-selected.svg';
import recentlyViewedIcon from './images/marketplace/recently-viewed-icon.svg';
import recommendedIconSelected from './images/marketplace/recommended-icon-selected.svg';
import recommendedIcon from './images/marketplace/recommended-icon.svg';
import savedIconSelected from './images/marketplace/saved-icon-selected.svg';
import savedIcon from './images/marketplace/saved-icon.svg';
import maybeIconBlack from './images/maybe-icon-black.svg';
import maybeIconGray from './images/maybe-icon-gray.svg';
import messageBubbleIconMobile from './images/message-bubble-icon-mobile.svg';
import messageBubbleIconSelected from './images/message-bubble-icon-selected.svg';
import messageBubbleIcon from './images/message-bubble-icon.svg';
import messageIcon from './images/message_icon.svg';
import messagesIcon from './images/messages-icon.svg';
import mobileManagePropertiesIcon from './images/mobile-manage-properties.svg';
import mobileNavBarAccountIcon from './images/mobile-nav-bar-account-icon.svg';
import mobileNavBarMessagesIcon from './images/mobile-nav-bar-messages-icon.svg';
import mobileNavBarNotificationsIcon from './images/mobile-nav-bar-notifications-icon.svg';
import mobileNavBarSettingsIcon from './images/mobile-nav-bar-settings-icon.svg';
import mobileSingupCheck from './images/mobile_signup_check.svg';
import munic from './images/munic.svg';
import navBarAccountIcon from './images/nav-bar-account-icon.svg';
import navBarNotificationsIcon from './images/nav-bar-notifications-icon.svg';
import navBarSettingsIcon from './images/nav-bar-settings-icon.svg';
import navCommunityIconSelected from './images/nav-community-icon-selected.svg';
import navCommunityIcon from './images/nav-community-icon.svg';
import navHomeManagementIconSelected from './images/nav-home-management-selected.svg';
import navHomeManagementIcon from './images/nav-home-management.svg';
import navInsightsIconSelected from './images/nav-insights-icon-selected.svg';
import navInsightsIcon from './images/nav-insights-icon.svg';
import navMarketplaceIconSelected from './images/nav-marketplace-icon-selected.svg';
import navMarketplaceIcon from './images/nav-marketplace-icon.svg';
import navMyFeedIconSelected from './images/nav-my-feed-icon-selected.svg';
import navMyFeedIcon from './images/nav-my-feed-icon.svg';
import navPropertyIconSelected from './images/nav-property-icon-selected.svg';
import navPropertyIcon from './images/nav-property-icon.svg';
import navTogglePropertyIcon from './images/nav-toggle-property-icon.svg';
import navBlogIcon from './images/nav-vat-icon.svg';
import networkError from './images/network-error.svg';
import stepCircle from './images/next-steps-circle.svg';
import nextSteps from './images/next-steps-icon.svg';
import notGoingIconBlack from './images/not-going-icon-black.svg';
import notGoingIconGray from './images/not-going-icon-gray.svg';
import notificationPreferencesIcon from './images/notification-preferences-icon.svg';
import mobileSettingsNotificationsPreferencesIcon from './images/notification-preferences-mobile.svg';
import notificationsIconSelected from './images/notifications-icon-selected.svg';
import notificationsIcon from './images/notifications-icon.svg';
import onboardPassword from './images/onboard-password.svg';
import onboardSignup from './images/onboard-signup.svg';
import one from './images/one.svg';
import paperScrollIcon from './images/paper-scroll-icon.svg';
import pencilIcon from './images/pencil-icon.svg';
import phone from './images/phone.svg';
import pipeIcon from './images/pipeIcon.svg';
import placeholderIcon from './images/placeholder-icon.svg';
import placeholder from './images/placeholder.svg';
import placeholderTwo from './images/placeholder_two.svg';
import plannedOutagesIcon from './images/planned-outages-icon.svg';
import playStore from './images/play-store.svg';
import plumbingServicesSubCat from './images/plumbing-services-sub-cat.svg';
import plus from './images/plus.svg';
import pollArrow from './images/poll-arrow.svg';
import pollBlack from './images/poll-black.svg';
import pollDateSelect from './images/poll-date-select.svg';
import poll from './images/poll.svg';
import pollCheck from './images/poll_check.svg';
import poolBlack from './images/pool-black.svg';
import poolPumpIcon from './images/pool-pump-icon.svg';
import pool from './images/pool.svg';
import popupArrow from './images/popupArrow.svg';
import powerOnIcon from './images/power-on-button.svg';
import powerCat from './images/power_cat.svg';
import prepaidMeterSubcat from './images/prepaid_meter_subcat.svg';
import productDropdownArrow from './images/product_dropdown_arrow.svg';
import profileIconSelected from './images/profile-icon-selected.svg';
import profileIcon from './images/profile-icon.svg';
import propAge from './images/prop_age.svg';
import propertyValuation from './images/property-valuation.png';
import smartGeyserOne from './images/property/smart_geyser_one.png';
import smartGeyserThree from './images/property/smart_geyser_three.png';
import smartGeyserTwo from './images/property/smart_geyser_two.png';
import solarBrochureImageFive from './images/property/solar-brochure-slider-five.png';
import solarBrochureImageOne from './images/property/solar-brochure-slider-one.png';
import solarBrochureImageThree from './images/property/solar-brochure-slider-three.png';
import purpleLine from './images/purple_line.svg';
import rateIcon from './images/rate-icon.svg';
import bulb from './images/rebrand_bulb.svg';
import receiptIconSelected from './images/receipt-icon-selected.svg';
import receiptIcon from './images/receipt-icon.svg';
import redCross from './images/red-cross.svg';
import removeIcon from './images/remove-icon.svg';
import propertyInspectionBanner from './images/rfq/property_inspection_rfq_banner.png';
import saveHeart from './images/save_heart.svg';
import saveHeartHover from './images/save_heart_hover.svg';
import searchIconOpen from './images/search-icon-open.svg';
import searchIconSelected from './images/search-icon-selected.svg';
import searchIcon from './images/search-icon.svg';
import searchIconGray from './images/searchicon_gray.svg';
import Secure from './images/secure.png';
import securityIconSelected from './images/security-icon-selected.svg';
import securityIcon from './images/security-icon.svg';
import securityCamSubcat from './images/security_cam_subcat.svg';
import securityCat from './images/security_cat.svg';
import securityGateSubcat from './images/security_gate_subcat.svg';
import selectSearch from './images/select_search.svg';
import serviceIcon from './images/service_icon.svg';
import servicesImage from './images/services_image.svg';
import settingsFeedbackIcon from './images/settings-feedback-icon.svg';
import settingsNotificationsPreferencesIcon from './images/settings-notifications-preferences-icon.svg';
import settingsTermsAndConditionsIcon from './images/settings-terms-and-conditions-icon.svg';
import shoppingIconCartMobile from './images/shopping-cart-mobile.svg';
import shoppingIconCart from './images/shopping-cart.svg';
import shoppingIconSelected from './images/shopping-icon-selected.svg';
import shoppingIcon from './images/shopping-icon.svg';
import signUpSolarIcon from './images/sign-up-solar-icon.svg';
import signupCheck from './images/signup_check.svg';
import signupFoot from './images/signup_footer.svg';
import signupWidget from './images/signup_widget.svg';
import solarHeroImage from './images/solar-hero-image.png';
import solarLoanYourDetails from './images/solar-loan-your-details.png';
import TopBanner from './images/solar-loan/banner.png';
import card from './images/solar-loan/card.svg';
import checkedCircle from './images/solar-loan/check-circle.svg';
import concierge from './images/solar-loan/concierge.svg';
import StepsBanner from './images/solar-loan/how-it-works.png';
import interestRate from './images/solar-loan/interest-rates.svg';
import ManInstallingSolar from './images/solar-loan/man-installing-solar.png';
import MiddleBannerImage from './images/solar-loan/middle-banner.png';
import oneEllipse from './images/solar-loan/one.svg';
import paymentTerms from './images/solar-loan/payment-terms.svg';
import provider from './images/solar-loan/provider.svg';
import solarLoanQualify from './images/solar-loan/solar-loan-qualify.svg';
import systemFinancing from './images/solar-loan/system-financing.svg';
import threeEllipse from './images/solar-loan/three.svg';
import twoEllipse from './images/solar-loan/two.svg';
import Battery from './images/solar-score/battery.png';
import cardBackgroundOrange from './images/solar-score/card-background-orange.png';
import SolarScoreHeroBannerMobile from './images/solar-score/hero-banner-mobile.png';
import SolarScoreHeroBannerTablet from './images/solar-score/hero-banner-tablet.png';
import SolarScoreHeroBanner from './images/solar-score/hero-banner.png';
import nextStepFooter from './images/solar-score/next-steps-footer.png';
import RoofArea from './images/solar-score/roof_area.png';
import Sun from './images/solar-score/sun.png';
import wrongLocation from './images/solar-score/wrong_location.svg';
import solarScoreMapTargetAreas from './images/solarScore/map-target-areas.png';
import solarScoreHouse1 from './images/solarScore/solar-score-house-1.png';
import solarScoreHouse2 from './images/solarScore/solar-score-house-2.png';
import solarScoreHouse3 from './images/solarScore/solar-score-house-3.png';
import spannerIcon from './images/spanner-icon.svg';
import starEmptyIcon from './images/star-empty-icon.svg';
import starFilledIcon from './images/star-filled-icon.svg';
import status from './images/status.svg';
import stormIcon from './images/stormIcon.svg';
import systemDesignIcon from './images/system-design-icon.svg';
import three from './images/three.svg';
import toggleArrowDownIcon from './images/toggle-arrow-down-icon.svg';
import trashBinIcon from './images/trash-bin-icon.svg';
import trophy from './images/trophy.svg';
import Trust from './images/trust.png';
import tvIcon from './images/tv-icon.svg';
import two from './images/two.svg';
import uncheckedRadioIcon from './images/unchecked-radio-icon.svg';
import unplannedOutagesIcon from './images/unplanned-outages-icon.svg';
import usersIconSelected from './images/users-icon-selected.svg';
import usersIcon from './images/users-icon.svg';
import valueRange from './images/value_range.svg';
import videoIcon from './images/video-icon.svg';
import waterAlert from './images/water-alert.svg';
import waterIconSelected from './images/water-icon-selected.svg';
import waterIcon from './images/water-icon.svg';
import waterCat from './images/water_cat.svg';
import waterDrop from './images/water_drop.svg';
import WaterEventIcon from './images/water_event_icon.svg';
import waterTankSubcat from './images/water_tanks_subcat.svg';
import wentWrong from './images/went-wrong.svg';
import wifiIcon from './images/wifi-icon.svg';
import xIcon from './images/x-icon.svg';

const mobileLWidth = 425;
const tabletWidth = 768;
const laptopWidth = 1024;
const modalZIndex = 1001;

const device = {
  mobileS: `(min-width: 320px)`,
  mobileM: `(min-width: 375px)`,
  mobileL: `(min-width: 425px)`,
  tablet: `(min-width: ${tabletWidth}px)`,
  laptop: `(min-width: 1024px)`,
  laptopL: `(min-width: 1440px)`,
  desktop: `(min-width: 2560px)`,
  desktopL: `(min-width: 2560px)`,
};

const COLORS = {
  newPrimary: '#0051FF',
  white: '#FFFFFF',
  black: '#212121',
  pink: '#C6437D',
  primary: '#47A7DB',
  secondary: '#F4AD44',
  tertiary: '#0F213E',
  background: '#E4E9FF',
  homeBackground: '#EFF1FD',
  overlayGeneral: 'rgba(33, 33, 33, 0.2)',
  overlayTutorial: 'rgba(33, 33, 33, 0.5)',
  border: '#EEEEEE',
  grayOne: '#676767',
  grayTwo: '#BDBDBD',
  grayThree: '#B5B5B5',
  grayFour: '#E0E0E0',
  grayFive: '#999999',
  graySix: '#E5E5E5',
  graySeven: '#595959',
  grayEight: '#737373',
  grayNine: '#BFBFBF',
  grayTen: '#A9A9A9',
  grayEleven: '#F6F6F6',
  offBlack: '#333333',
  brandBlue1: '#F6FBFD',
  brandBlue2: '#E5EAFF',
  brandBlue4: '#F0FAFF',
  blue1000: '#0F213E',
  blue900: '#084363',
  blue800: '#1A5F85',
  blue700: '#2F88B8',
  blue500: '#79C3EC',
  blue400: '#83CAF1',
  blue300: '#A6D9F5',
  blue200: '#D6F0FF',
  blue100: '#E9F7FF',
  blueMobileNav: '#263C5F',
  success: '#459E4E',
  error: '#D11C34',
  warning: '#EF7137',
  pink200: '#ffefea',
  pink500: '#C6437D',
  green1000: '#0C5614',
  green900: '#166A1F',
  green800: '#257E2E',
  green700: '#328E3C',
  green600: '#459E4E',
  green500: '#58B461',
  green400: '#85DC8E',
  green300: '#A9E4AF',
  green200: '#CAF9CF',
  green100: '#EDFFEF',
  red1000: '#870606',
  red900: '#9C0A0A',
  red800: '#A91515',
  red700: '#BB2929',
  red600: '#C63838',
  red500: '#D11C34',
  red400: '#EB6060',
  red300: '#F67E7E',
  red200: '#FF9696',
  red100: '#FFC3C3',
  yellow100: '#FFFDED',
  yellow200: '#F0C98E',
  yellow300: '#F0C98E',
  yellow400: '#FFF2D0',
  orange200: '#EF7137',
  orange100: '#FFDD9A',
  black1200: '#000000',
  black1100: '#20252F',
  black1000: '#666F81',
  black950: '#20252F',
  black900: '#768093',
  black800: '#8D95A5',
  black700: '#A9AFBB',
  black600: '#C6CAD2',
  black500: '#D5DBE2',
  black400: '#E1E5EA',
  black200: '#F3F5F7',
  black100: '#F5F5F5',
  black50: '#FAFAFA',
  landingPageHeader: '#F5F1EE',
  palladium: '#B0B0B0',
  magnolia: '#EFE9FF',
  lightBlue: '#B5E4FF',
  lightYellow: '#FFDFA1',
  lightGreen: '#C7EFCB',
  lightPurple: '#E4C4F3',
  lightPurpleBackground: '#9DC2FF26',
  lightPink: '#E19AD6',
  orange: '#FF9900',
  chineseSilver: '#CCCCCC',
  ghostWhite: '#F9F8FF',
  cardBackground: '#E6E7E8',
  bannerBlue: '#C9DEEA',
  blueBackground: '#3174AA',
  sliderRail: '#003893',
  browserBackground: '#F4F8FD',
  mediumPurple: '#C093D6',
  darkPurple: '#263C5F',
  sunlight: '#FBAC7F',
  bannerGreen: '#D1F5D6',
  shadedBlue: '#446aa8',
  whiteTransparent: '#fffffff5',
  blendedBlue: '#020064',
  darkGray: '#02070D',
  brightRed: '#E60000',
  hrColor: '#DEDEDE',
  primaryBlue: '#0062E1',
  darkGreyText: '#02070D',
  blueAccent: '#50BEFF',
  lightGrey: '#5C6C80',
  paleGrey: '#CED3D9',
  paleGrey25: '#F4F5F7',
  midblue: '#0033A1',
};

const GRADIENT = {
  blue: `linear-gradient(180deg, #6F85FB 0%, #4E62CD 100%), #6F85FB`,
  blueTwo: `linear-gradient(-97.14deg, #51abdb 9.29%, rgba(241, 245, 248, 0.609375) 128%, rgba(26, 65, 86, 0) 68.84%)`,
  blueDark: `linear-gradient( 112.32deg, rgba(26, 57, 105, 0.62) 19.03%, rgba(0, 0, 0, 0) 69.53%)`,
  blueDarkTwo: `linear-gradient(342.84deg, rgba(0, 0, 0, 0) 9.98%, rgba(15, 33, 62, 0.88) 80.22%)`,
  green: `linear-gradient(180deg, #00BD90 0%, #00AE85 100%)`,
  red: `linear-gradient(180deg, #EB4B60 0%, #D83E52 100%)`,
  orange: `linear-gradient(180deg, #FF8D24 0%, #FF8724 100%)`,
  boxShadow: `0px 0px 4px 3px rgba(0, 0, 0, 0.03)`,
  tooltipShadow: `0px 1px 6px 0px #00000038`,
  yourDetailsBanner:
    'linear-gradient(90deg, #333742C2 40.66%, transparent 76.31%)',
  greenBanner: 'linear-gradient(90deg, #2C3312C2 25%, transparent 100%)',
  yellowBanner: 'linear-gradient(80deg, #4B4124C2 25%, transparent 67%)',
  hpaBanner: `linear-gradient(91.05deg, #DD5F8C4D 47.22%, #F4AD444D 77.6%), linear-gradient(90.42deg, ${COLORS.blueMobileNav} 10.36%, #FBB946 86.96%)`,
  lightBlue: `linear-gradient(90.28deg, #D9E2F1 5.19%, rgba(255, 255, 255, 0) 99.95%), #D9E2F1`,
  sliderThumbBoxShadow: '0px 1px 6px rgba(0, 0, 0, 0.25);',
  sliderThumbFocusBoxShadow: '0px 1px 6px rgba(0, 0, 0, 0.3);',
  lightPurpleBackgroundGradient: `linear-gradient(90deg, ${COLORS.lightPurpleBackground} 2%, rgba(157, 194, 255, 0.15) 30%, rgba(255, 255, 255, 0) 72.6%)`,
  contactUsGradient:
    'linear-gradient(91.12deg, rgba(38, 39, 43, 0), rgba(15, 33, 62, 0) 100.96%)',
  blackWhiteGradient: `linear-gradient(89.4deg, rgba(38, 39, 43, 0.76) 3.65%, rgba(15, 33, 62, 0) 98.78%)`,
  lightBlueGradient: `linear-gradient(89.58deg, rgba(53, 89, 126, 0.76) 19.33%, rgba(0, 52, 135, 0) 75.8%)`,
  solarLoanBannerGradient: `linear-gradient(92.79deg,rgba(26, 35, 52, 0.5) 4.03%,rgba(18, 30, 51, 0) 81.05%),linear-gradient(0deg, rgba(217, 217, 217, 0.1), rgba(217, 217, 217, 0.1))`,
};

const BORDER = {
  default: '1px solid #EEEEEE',
  grayOne: '1px solid #676767',
  shadow: '0px 4px 4px rgba(0, 0, 0, 0.03)',
};

const SPACING = {
  tiny: '4px',
  xSmall: '8px',
  small: '12px',
  normal: '16px',
  medium: '20px',
  large: '24px',
  xLarge: '32px',
  giant: '48px',
  xGiant: '64px',
  huge: '96px',
  xHuge: '128px',
  enormous: '192px',
  xEnormous: '256px',
};

const CAROUSEL_CARD_SIZING = {
  mobileSAll: '90px',
  mobileMAll: '110px',
  mobileS: '100%',
  mobileM: '100%',
};

const TYPOGRAPHY = {
  mainTitle: `600 45px Inter`,
  title1: `600 24px Inter`,
  title2: `600 20px Inter`,
  title3: `600 32px Inter`,
  title4: `400 24px Inter`,
  headline1: `600 18px Inter`,
  headline2: `500 16px Inter`,
  headline3: `500 12px Inter`,
  bodyLargest: `400 20px Inter`,
  bodyLarge: `400 16px Inter`,
  body: `400 14px Inter`,
  description: `400 12px Inter`,
  footerBold: `500 14px Inter`,
  footnote: `font: 400 12px Inter; letter-spacing: 0.01em`,
  caption1: `font: 400 12px Inter; letter-spacing: 0.02em`,
  buttonLabel: `font: 600 12px Inter; letter-spacing: 0.15em`,
  smallButtonLabel: `font: 600 12px Inter; letter-spacing: 0.05em`,
  fieldEntry: `font: 400 13px Inter; letter-spacing: 0.01em`,
  mobileSmallBold: `500 10px Inter`,
  mobileSmall: `400 10px Inter`,
};

const FONT_WEIGHT = {
  normal: 'normal',
  bold: 'bold',
};

export default {
  sizes: {
    tabletWidth: tabletWidth,
    laptopWidth: laptopWidth,
    modalZIndex: modalZIndex,
    mobileLWidth: mobileLWidth,
  },
  border: BORDER,
  typography: TYPOGRAPHY,
  spacing: SPACING,
  colors: COLORS,
  gradient: GRADIENT,
  carouselCard: CAROUSEL_CARD_SIZING,
  device: device,
  fontWeight: FONT_WEIGHT,
  images: {
    cardBackgroundOrange,
    chevronRightWhite,
    navBlogIcon,
    bulb,
    lastPrice,
    lastSold,
    valueRange,
    estValue,
    hash,
    propAge,
    munic,
    info,
    infoGray,
    logOutIcon,
    signupCheck,
    signupFoot,
    signupWidget,
    homeIconSelected,
    homeFrame,
    homeImg1,
    homeImg2,
    homeImg3,
    insight,
    claim,
    mobileSingupCheck,
    one,
    two,
    three,
    purpleLine,
    mapPoint,
    onboardSignup,
    onboardPassword,
    errorIcon,
    checkIcon,
    emptyIcon,
    emptySearch,
    selectSearch,
    homeIcon,
    searchIcon,
    searchIconSelected,
    notificationsIcon,
    profileIcon,
    locationIcon,
    shoppingIcon,
    receiptIcon,
    usersIcon,
    arrowLeft,
    arrowRight,
    notificationsIconSelected,
    locationIconSelected,
    shoppingIconSelected,
    shoppingIconCart,
    shoppingIconCartMobile,
    receiptIconSelected,
    usersIconSelected,
    circleAddIcon,
    circleAddIconGrey,
    lightBulb,
    waterDrop,
    ellipsesIcon,
    grayDot,
    checkmark,
    starFilledIcon,
    starEmptyIcon,
    placeholderIcon,
    placeholder,
    purchasesIcon,
    recentlyViewedIcon,
    savedIcon,
    energyIcon,
    energyIconSelected,
    waterIcon,
    waterIconSelected,
    profileIconSelected,
    purchasesIconSelected,
    recentlyViewedIconSelected,
    savedIconSelected,
    closeIcon,
    waterAlert,
    electricAlert,
    chevronLeft,
    chevronRight,
    chevronRightWithBubble,
    saveHeart,
    xIcon,
    filterIcon,
    activityIcon,
    activityIconSelected,
    messageBubbleIcon,
    messageBubbleIconSelected,
    crimeIcon,
    rateIcon,
    loadSheddingIcon,
    editIcon,
    marketplaceIcon,
    attachIcon,
    calendarIcon,
    poll,
    pool,
    status,
    eventIcon,
    arrowDropdown,
    placeholderTwo,
    accountKeyIcon,
    accountLogOutIcon,
    settingsFeedbackIcon,
    settingsNotificationsPreferencesIcon,
    settingsTermsAndConditionsIcon,
    mobileSettingsNotificationsPreferencesIcon,
    navInsightsIcon,
    navInsightsIconSelected,
    navMarketplaceIcon,
    navMarketplaceIconSelected,
    navMyFeedIcon,
    navMyFeedIconSelected,
    navPropertyIcon,
    navPropertyIconSelected,
    navTogglePropertyIcon,
    navCommunityIcon,
    navCommunityIconSelected,
    navBarAccountIcon,
    communityMembersIcon,
    communityMembersIconSelected,
    communityMyPostsIcon,
    communityMyPostsIconSelected,
    navBarNotificationsIcon,
    navBarSettingsIcon,
    mobileNavBarNotificationsIcon,
    mobileNavBarAccountIcon,
    mobileNavBarSettingsIcon,
    mobileNavBarMessagesIcon,
    toggleArrowDownIcon,
    imageIcon,
    grayChevronLeft,
    blackChevronLeft,
    locationIconDisabled,
    videoIcon,
    globalImageIcon,
    removeIcon,
    exitIcon,
    pollCheck,
    pencilIcon,
    trashBinIcon,
    infoLG,
    grayX,
    plannedOutagesIcon,
    unplannedOutagesIcon,
    eventDropdownArrow,
    pollBlack,
    eventBlack,
    poolBlack,
    keyIcon,
    serviceIcon,
    addCommunityPool,
    emptyHeart,
    productDropdownArrow,
    basketIconSelected,
    arrowDown,
    emptyBasket,
    basketIcon,
    goingIconGray,
    maybeIconGray,
    notGoingIconGray,
    goingIconBlack,
    maybeIconBlack,
    notGoingIconBlack,
    managePropertiesIcon,
    mobileManagePropertiesIcon,
    checkedRadioIcon,
    uncheckedRadioIcon,
    closeIconCircle,
    securityIcon,
    securityIconSelected,
    checkboxOn,
    globalBackIcon,
    lookSeeLogo,
    recommendedIcon,
    recommendedIconSelected,
    trophy,
    networkError,
    redCross,
    book,
    appStore,
    playStore,
    securityCat,
    powerCat,
    waterCat,
    waterTankSubcat,
    securityGateSubcat,
    securityCamSubcat,
    prepaidMeterSubcat,
    generatorSubcat,
    geyserSubcat,
    burgularGuardSubcat,
    phone,
    servicesImage,
    service,
    feedWater,
    feedPower,
    callOutIcon,
    callOutIconSelected,
    plumbingServicesSubCat,
    buildingServicesSubCat,
    electricityServicesSubCat,
    closeIconBlack,
    addBlack,
    popupArrow,
    mobileNavIcon,
    circleAddIconGreyMobile,
    messageBubbleIconMobile,
    activityIconMobile,
    communityMembersIconMobile,
    communityMyPostsIconMobile,
    saveHeartHover,
    searchIconOpen,
    // rebrand images
    IllustrationHeader,
    SmallIllustrationHeader,
    NoMapReturned,
    PropertyNotFound,
    LogIn,
    WeveSentYouAnEmail,
    SignUp,
    Survey,
    ResetPassword,
    Congratulations,
    MarketplaceTutorial,
    NoEvents,
    NoMarketplaceProducts,
    LostConnection,
    ConfirmID,
    NoCommunity,
    AddMember,
    Unlock,
    AddNewProperty,
    EmptyBasket,
    Contact,
    Success,
    Service: service,
    Water,
    Crime,
    Power,
    CallOutService,
    GeneralError,
    NoResults,
    MiniPropertyValue,
    MiniGetUpdates,
    MiniHomeAndUtilities,
    MiniService,
    MiniInsight,
    MiniProduct,
    MiniSecurity,
    MiniInviteNewMember,
    MiniPropertyValue2,
    MiniGetUpdates2,
    MiniHomeAndUtilities2,
    CrimeEventIcon,
    WaterEventIcon,
    ElecEventIcon,
    SectionIndicator1,
    SectionIndicator2,
    SectionIndicator3,
    CheckCircleIcon,
    ErrorCircleIcon,
    CheckboxOn,
    UnlockWhite,
    GroupCategories,
    GroupCategoriesMobile,
    GroupSteps,
    StepOne,
    StepTwo,
    StepThree,
    Basket,
    chevronRightBreadcrumbs,
    closeIconWhite,
    FUECreatePost,
    FUEEventCard,
    communityPostNotificationIcon,
    communityPoolNotificationIcon,
    communityPollNotificationIcon,
    communityEventNotificationIcon,
    marketplaceNotificationIcon,
    notificationPreferencesIcon,
    checkAllIcon,
    checkboxOff,
    FUEAddService,
    FUEInfoIcon,
    FUEAccount,
    FUESettings,
    messagesIcon,
    Cooling,
    coolingIcon,
    messageIcon,
    searchIconGray,
    grayEllipses,
    wentWrong,
    basketIconDark,
    homeMgtServicesIcon,
    pollArrow,
    pollDateSelect,
    quotesIcon,
    quotesIconSelected,
    HomeManagementServices,
    fireIcon,
    lightningIcon,
    stormIcon,
    geyserIcon,
    pipeIcon,
    navHomeManagementIcon,
    navHomeManagementIconSelected,
    RecurringPaymentSuccess,
    HomeUtility,
    Update,
    plus,
    Gardening,
    MiniConsumables,
    MiniServiceBundle,
    MiniProductBundle,
    checkCircle,
    checkAllIconWhite,
    cellPhone,
    adviceInspiration,
    Trust,
    Empower,
    Secure,
    trustShield,
    solarBrochureImageOne,
    knowledgeHub,
    solarBrochureImageThree,
    solarBrochureImageFive,
    signUpSolarIcon,
    drawingIllustratedIcon,
    paperScrollIcon,
    magnifyingGlassIcon,
    systemDesignIcon,
    cctvIcon,
    garageIcon,
    gateIcon,
    wifiIcon,
    fridgeIcon,
    tvIcon,
    laptopIcon,
    poolPumpIcon,
    lightBulbIcon,
    spannerIcon,
    powerOnIcon,
    propertyValuation,
    smartGeyserOne,
    smartGeyserTwo,
    smartGeyserThree,
    accountBalanceIcon,
    handShakeIcon,
    supportAgentIcon,
    settingsSuggestIcon,
    solarBrochureHeaderBannerImage,
    solarLoanBannerImage,
    solarBrochureContentImageOne,
    solarBrochureContentImageTwo,
    solarBrochureTileImageOne,
    solarBrochureTileImageThree,
    solarBrochureTileImageTwo,
    solarBrochureFooterBannerImage,
    systemSize,
    financing,
    filledLightBulb,
    savings,
    bolt,
    output,
    SendCloud,
    HpaTestimonial,
    solarScoreMapTargetAreas,
    solarScoreHouse1,
    solarScoreHouse2,
    solarScoreHouse3,
    wrongLocation,
    SolarScoreHeroBanner,
    nextStepFooter,
    SolarScoreHeroBannerTablet,
    SolarScoreHeroBannerMobile,
    Sun,
    RoofArea,
    Battery,
    contactUsImage,
    propertyInspectionBanner,
    inverterIcon,
    batteryIcon,
    solarPowerIcon,
    card,
    provider,
    concierge,
    ManInstallingSolar,
    MiddleBannerImage,
    StepsBanner,
    oneEllipse,
    twoEllipse,
    threeEllipse,
    solarLoanYourDetails,
    TopBanner,
    systemFinancing,
    paymentTerms,
    interestRate,
    solarHeroImage,
    clipboardIcon,
    solarLoanQualify,
    checkedCircle,
    // New landing page assets
    landingHeroBannerDesktop,
    landingHeroBannerMobile,
    landingHeroBannerDecoration,
    LockClosedIcon,
    nextSteps,
    stepCircle,
  },
};
