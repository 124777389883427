import {Box, ImageList, ImageListItem} from '@material-ui/core';
import styled from 'styled-components';

export const CarouselImage = styled.img`
  min-height: 270px;
  max-height: 420px;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

export const StyledImageList = styled(ImageList)`
  &.MuiImageList-root {
    max-width: 100px;
    min-width: 100px;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-end;
    overflow-y: hidden;
    @media (max-width: 599px) {
      max-width: 250px;
      flex-direction: row;
    }
  }
`;
export const StyledImageListItem = styled(ImageListItem)`
  &.MuiImageListItem-root {
    width: 70% !important;
    height: 60px !important;

    @media (max-width: 599px) {
      width: 30% !important;
      height: 50px !important;
    }
  }
`;

export const ImageBanner = styled(Box)`
  position: absolute;
  bottom: 4px;
  right: 0;
  background: ${({theme}) => theme.colors.primary};
  border-radius: 40px 0px 0px 40px;
`;
