import React from 'react';

import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
} from '@material-ui/core';
import {useTheme} from 'styled-components';

import {BodyLg, BodyMed} from '../../components/newTypography';
import useDetectMobile from '../../hooks/useDetectMobile';
import {useFeaturesContext} from '../../provider/features';
import {StyledArticleContainer, subHeadingFontSize} from './styles';

export default function SolarLoanArticles() {
  const theme = useTheme();
  const features = useFeaturesContext();
  const isMobile = useDetectMobile();
  const {articles} = features.solarLoanConfig;
  return (
    articles.length > 0 && (
      <Box px="5%" py="3%">
        <BodyLg
          color={theme.colors.tertiary}
          fontWeight="700"
          fontSize={subHeadingFontSize}>
          Learn more about solar
        </BodyLg>
        <StyledArticleContainer
          sx={{
            gridGap: {xs: '32px', sm: '40px', md: '48px'},
            justifyContent: {xs: 'flex-start'},
            alignItems: 'center',
            overflowX: isMobile ? 'auto' : 'hidden',
          }}
          display="flex">
          {articles.map(({title, image, content, url}) => (
            <Box key={title}>
              <Box
                component={Card}
                variant="outlined"
                sx={{
                  width: {xs: '280px', sm: '400px', md: '480px'},
                  height: '100%',
                }}>
                <Box
                  minHeight="190px"
                  component={CardMedia}
                  image={image}
                  alt={title}
                />
                <CardActionArea href={url} target="_blank">
                  <Box
                    component={CardContent}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center">
                    <Box height="60px" alignItems="center" display="flex">
                      <BodyMed
                        color={theme.colors.tertiary}
                        fontWeight={700}
                        lineHeight={0}>
                        {title}
                      </BodyMed>
                    </Box>
                    <Box
                      overflow="hidden"
                      display="flex"
                      flexDirection="column">
                      <Box sx={{height: {md: '165px', lg: '140px'}}}>
                        <BodyMed m={0} fontSize="15px">
                          {content}
                        </BodyMed>
                      </Box>
                      <BodyMed
                        fontWeight={600}
                        fontSize="14px"
                        inline
                        color={theme.colors.primary}>
                        Read more
                      </BodyMed>
                    </Box>
                  </Box>
                </CardActionArea>
              </Box>
            </Box>
          ))}
        </StyledArticleContainer>
      </Box>
    )
  );
}
