import React, {useState} from 'react';

import {Wrapper} from './styles';

const ProgButton = ({children, onClick, disabled}) => {
  const [hover, setHover] = useState(false);

  return (
    <Wrapper
      disabled={disabled}
      onClick={onClick}
      hover={hover}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}>
      {children}
    </Wrapper>
  );
};

export default ProgButton;
