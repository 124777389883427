import {STRINGS} from '../../atoms';
import {
  manageCookiesUrl,
  productsServicesUrl,
  signupUrl,
} from '../../navigation/routes';
import {logger} from '../../services/Logger';

export const sendPageAnalytics = (arg, application) => {
  window.dataLayer = {
    ...window.dataLayer,
    ...(application && {
      application: {
        ...window.dataLayer.application,
        ...application,
      },
    }),
    ...arg,
  };
  window._satellite?.track('globalVirtualPageView');
};

export const dataLayerVariables = ({
  pageName = '',
  pageSubSection1 = '',
  pageSubSection2 = '',
  pageSubSection3 = '',
  pageSubSection4 = '',
  pageCategory = 'Personal',
}) => {
  return {
    pageCategory,
    pageName,
    pageSubSection1,
    pageSubSection2,
    pageSubSection3,
    pageSubSection4,
  };
};

export const sendPageAnalyticsByPath = path => {
  try {
    switch (path) {
      case '/':
        window.dataLayer = {
          ...window.dataLayer,
          ...dataLayerVariables({
            pageName: 'Home',
            pageSubSection1: 'Home',
          }),
        };
        break;
      case '/login':
        window.dataLayer = {
          ...window.dataLayer,
          ...dataLayerVariables({
            pageName: 'login',
            pageSubSection1: 'login',
          }),
        };
        break;
      case '/forgot-password':
        window.dataLayer = {
          ...window.dataLayer,
          ...dataLayerVariables({
            pageName: 'Forgot Password',
            pageSubSection1: 'Forgot Password',
          }),
        };
        break;
      case signupUrl:
        window.dataLayer = {
          ...window.dataLayer,
          ...dataLayerVariables({
            pageName: 'Signup',
            pageSubSection1: 'Signup',
          }),
        };
        break;
      case '/reset-password':
        window.dataLayer = {
          ...window.dataLayer,
          ...dataLayerVariables({
            pageName: 'Password reset',
            pageSubSection1: 'Password reset',
          }),
        };
        break;
      case '/contact-us':
        window.dataLayer = {
          ...window.dataLayer,
          ...dataLayerVariables({
            pageName: 'Contact Us',
            pageSubSection1: 'Contact Us',
          }),
        };
        break;
      case '/privacy-policy':
        window.dataLayer = {
          ...window.dataLayer,
          ...dataLayerVariables({
            pageName: 'Privacy Policy',
            pageSubSection1: 'Privacy Policy:page 1',
          }),
        };
        break;
      case '/terms-and-conditions':
        window.dataLayer = {
          ...window.dataLayer,
          ...dataLayerVariables({
            pageName: 'Terms and conditions:page 1',
            pageSubSection1: 'Terms and conditions',
            pageSubSection2: 'Terms and conditions:page 1',
          }),
        };
        break;
      case `${productsServicesUrl}/purchases`:
        window.dataLayer = {
          ...window.dataLayer,
          ...dataLayerVariables({
            pageCategory: 'Products and services',
            pageName: 'Products and services:Orders',
            pageSubSection1: 'Products and services',
            pageSubSection2: 'Products and services:Orders',
          }),
        };
        break;
      case `${productsServicesUrl}/messages`:
        window.dataLayer = {
          ...window.dataLayer,
          ...dataLayerVariables({
            pageCategory: 'Products and services',
            pageName: 'Messages',
            pageSubSection1: 'Messages',
          }),
        };
        break;
      case '/products-services/solar':
        window.dataLayer = {
          ...window.dataLayer,
          ...dataLayerVariables({
            pageCategory: 'Products and services',
            pageName: 'Products and services:Solar',
            pageSubSection1: 'Products and services:Solar',
          }),
        };
        break;
      case '/products-services/solar/financing':
        window.dataLayer = {
          ...window.dataLayer,
          ...dataLayerVariables({
            pageCategory: 'Products and services',
            pageName: 'Products and services:Solar:Financing',
            pageSubSection1: 'Products and services:Solar:Financing',
          }),
        };
        break;
      case '/products-services/solar/solutions':
        window.dataLayer = {
          ...window.dataLayer,
          ...dataLayerVariables({
            pageCategory: 'Products and services',
            pageName: 'Products and services:Solar:Solutions',
            pageSubSection1: 'Products and services:Solar:Solutions',
          }),
        };
        break;
      case '/products-services/solar/apply':
        window.dataLayer = {
          ...window.dataLayer,
          ...dataLayerVariables({
            pageCategory: 'Products and services',
            pageName: 'Products and services:Solar:Apply:Your Usage Details',
            pageSubSection1:
              'Products and services:Solar:Apply:Your Usage Details',
          }),
        };
        break;
      case '/products-services/solarloan':
        window.dataLayer = {
          ...window.dataLayer,
          ...dataLayerVariables({
            pageCategory: 'Products and services',
            pageName: 'Products and services:Solar loan:apply for a solar loan',
            pageSubSection1:
              'Products and services:Solar loan:apply for a solar loan',
          }),
        };
        break;
      case '/products-services/solarloan/apply':
        window.dataLayer = {
          ...window.dataLayer,
          ...dataLayerVariables({
            pageCategory: 'Products and services',
            pageName:
              'Products and services:Solar loan:Apply:Provide your details to see if you qualify for a Solar Loan',
            pageSubSection1:
              'Products and services:Solar loan:Apply:Provide your details to see if you qualify for a Solar Loan',
          }),
        };
        break;
      case manageCookiesUrl:
        window.dataLayer = {
          ...window.dataLayer,
          ...dataLayerVariables({
            pageName: STRINGS.MANAGE_COOKIES,
            pageSubSection1: STRINGS.MANAGE_COOKIES,
          }),
        };
        break;
      default:
        return;
    }

    window._satellite?.track('globalVirtualPageView');
  } catch (e) {
    logger.error(e);
  }
};

export const sendComponentAnalytics = (data, track) => {
  try {
    window.dataLayer = {
      ...window.dataLayer,
      ...data,
    };
    if (track) {
      window._satellite?.track(track);
    }
  } catch (e) {
    logger.error(e);
  }
};

export const getFormName = form => {
  return form.replace(/_/g, ' ');
};

export const updateApplicationData = data => {
  window.dataLayer.application = {
    ...window.dataLayer.application,
    ...data,
  };
};

export const satelliteTrackCode = arg => {
  try {
    window._satellite?.track(arg);
  } catch (err) {
    logger.error(err);
  }
};
