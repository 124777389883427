import React from 'react';

import {Route, Switch, useRouteMatch} from 'react-router-dom';

import CongratulationsYouQualify from '../../components/CongratulationsYouQualify';
import NextStepsQualify from '../../components/NextStepsQualify';
import YouDoNotQualifySolarLoan from '../../components/YouDoNotQualifySolarLoan';
import MainContent from './MainContent';
import YourDetails from './YourDetails';

const nextSteps = [
  {
    title: "We'll be in touch",
    body: 'Our team will contact you within the next 48 business hours',
  },
  {
    title: 'Prepare your documents',
    body: 'ID, proof of residence, 3 months bank statements, payslips, solar systems invoice',
  },
  {
    title: 'Finalise your application',
    body: 'A consultant will assist you with the details of your application',
  },
  {
    title: 'Accept your agreement',
    body: 'Your loan will get paid directly into your account',
  },
];
export default function SolarLoan() {
  const {path} = useRouteMatch();
  return (
    <Switch>
      <Route path={path} exact>
        <MainContent />
      </Route>
      <Route path={`${path}/apply`} exact>
        <YourDetails />
      </Route>
      <Route path={`${path}/you-do-not-qualify`}>
        <YouDoNotQualifySolarLoan pageName="products and services:Solar Loan:Apply:You do not qualify for a Solar Loan at this time" />
      </Route>
      <Route path={`${path}/congratulations`} exact>
        <CongratulationsYouQualify pageName="products and services:solar loan:apply:congratulations" />
      </Route>
      <Route path={`${path}/next-steps`} exact>
        <NextStepsQualify
          pageName="products and services:Solar Loan:Qualify:Next Steps"
          nextSteps={nextSteps}
          subHeading="We're excited to assist you on your solar journey!"
        />
      </Route>
    </Switch>
  );
}
