import styled from 'styled-components';

export const Wrapper = styled.button`
  width: ${({width}) => width || 'auto'};
  height: 48px;
  background: ${({backgroundColor}) => backgroundColor};
  border: ${({borderColor, borderWidth}) =>
    borderColor
      ? `${borderWidth ? borderWidth : '1px'} solid ${borderColor}`
      : 'none'};
  outline: none;
  color: ${({textColor, theme}) => textColor || theme.colors.white};
  border-radius: 4px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  transition-duration: 0.1s;
  cursor: ${({disabled}) => (disabled ? 'auto' : 'pointer')};
  svg,
  img {
    ${({text, endIcon}) => {
      if (endIcon) {
        return `margin-left:${text ? '10px' : '0'}`;
      }
      return `margin-right:${text ? '10px' : '0'}`;
    }};
    align-self: center;
  }
  &:hover {
    background: ${({hoverColor, backgroundColor}) =>
      hoverColor || `${backgroundColor}CC`};
    color: ${({hoverTextColor}) => hoverTextColor};
  }
  &:active {
    background: ${({activeColor, backgroundColor}) =>
      activeColor || `${backgroundColor}50`};
  }

  &:disabled {
    background: ${({disabledBGColor, theme}) =>
      disabledBGColor || theme.colors.border};
    color: ${({disabledColor, theme}) =>
      disabledColor || theme.colors.grayFive};
  }

  ${props => props.styles || null};
`;
