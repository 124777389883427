import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  gap: 20px;
  width: 100%;

  @media ${props => props.theme.device.laptop} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const Question = styled.div`
  padding-bottom: 15px;
`;
