import React, {lazy, Suspense} from 'react';

import {Route, Switch, Redirect, useLocation} from 'react-router-dom';

import {
  NavBar,
  ErrorModal,
  InteractiveModal,
  SliderModal,
  LoadingModal,
  VerificationModal,
} from '../components';
import Footer from '../components/Footer';
import PingRedirectNotificationModal from '../components/PingRedirectNotificationModal';
import TermsOfUseModal from '../components/TermsOfUseModal';
import {useFeaturesContext} from '../provider/features';
import {allRoutes, productsServicesUrl} from './routes';
import {PageContent} from './styles';
import {PrivateRoute, QueryRoute} from './utils';

const Home = lazy(() => import('../pages/Home'));

const getRoutes = (features, routes) => {
  return routes.filter(({feature}) => features[feature] ?? true);
};
const smallElon = '/marketplace/water/geysers_water/SolarGeyserSmall';
const mediumElon =
  '/marketplace/water/geysers_water/SolarGeyserConversionMedium';
const RedirectComponent = ({path}) => {
  switch (path) {
    case smallElon:
      return (
        <Redirect
          from={path}
          to={`${productsServicesUrl}/waterheating/solargeyser/electrictosolargeyserconversion–small–fullyinstalled`}
        />
      );
    case mediumElon:
      return (
        <Redirect
          from={path}
          to={`${productsServicesUrl}/waterheating/solargeyser/electrictosolargeyserconversion–medium–fullyinstalled`}
        />
      );
    case '/property/solar/apply':
      return <Redirect from={path} to={`${productsServicesUrl}/solar/apply`} />;
    default:
      return (
        <Route path="*" exact>
          <Redirect to={{pathname: '/'}} />
        </Route>
      );
  }
};

const Navigation = () => {
  const features = useFeaturesContext();
  const routes = getRoutes(features, allRoutes);
  const location = useLocation();
  const redirects = ['basket/checkout', 'basket', 'inspection', 'home-care'];

  return (
    <>
      <NavBar />
      <PageContent>
        <Suspense fallback={<div>Loading...</div>}>
          {features && (
            <Switch>
              <QueryRoute path="/" exact>
                <Home />
              </QueryRoute>
              {redirects.map(path => {
                return (
                  <Redirect key={path} from={`/marketplace/${path}`} to="/" />
                );
              })}
              {routes.map(
                ({path, Page, requireClaims, isPrivate, exact, props = {}}) => {
                  let RouteComponent = isPrivate ? PrivateRoute : Route;
                  return (
                    <RouteComponent
                      key={path}
                      path={path}
                      exact={exact}
                      requireClaims={requireClaims}>
                      <Page {...props} />
                    </RouteComponent>
                  );
                },
              )}
              <RedirectComponent path={location.pathname} />
            </Switch>
          )}
        </Suspense>
        {/* Modals */}
        <ErrorModal />
        <InteractiveModal />
        <SliderModal />
        <LoadingModal />
        <VerificationModal />
        <PingRedirectNotificationModal />
        <TermsOfUseModal />
      </PageContent>
      <Footer />
    </>
  );
};

export default Navigation;
