// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components';

const DefaultText = styled.span(({theme, color, font = 'Inter'}) => ({
  fontFamily: `${font}, sans-serif`,
  fontStyle: 'normal',
  color: color || theme.colors.black,
}));

const MainTitle = styled(DefaultText)({
  fontWeight: 600,
  fontSize: '48px',
  lineHeight: '72px',
});

const Title1 = styled(DefaultText)({
  fontWeight: 'bold',
  fontSize: '24px',
  lineHeight: '32px',
});

const Title2 = styled(DefaultText)({
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '28px',
});

const Headline1 = styled(DefaultText)({
  fontWeight: 600,
  fontSize: '18px',
  lineHeight: '24px',
});

const Headline2 = styled(DefaultText)({
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '24px',
});

const Headline3 = styled(DefaultText)({
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '20px',
});

const LargestBody = styled(DefaultText)({
  fontWeight: 400,
  fontSize: '20px',
  lineHeight: '28px',
});

const LargeBody = styled(DefaultText)({
  fontWeight: 'normal',
  fontSize: '17px',
  lineHeight: '24px',
});

const Body = styled(DefaultText)({
  fontWeight: 'normal',
  fontSize: '14px',
  lineHeight: '24px',
});

const SmallBody = styled(DefaultText)({
  fontWeight: 'normal',
  fontSize: '14px',
  lineHeight: '20px',
});

const Footnote = styled(DefaultText)({
  fontWeight: 'normal',
  fontSize: '12px',
  lineHeight: '20px',
});

const Caption1 = styled(DefaultText)({
  fontWeight: 'normal',
  fontSize: '12px',
  lineHeight: '16px',
});

const Caption2 = styled(DefaultText)({
  fontWeight: 'normal',
  fontSize: '10px',
  lineHeight: '12px',
});

const ButtonLabel = styled(DefaultText)({
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '16px',
  textAlign: 'center',
  textTransform: 'uppercase',
  letterSpacing: 0.015,
});

const SmallButtonLabel = styled(DefaultText)({
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '16px',
  textAlign: 'center',
  textTransform: 'uppercase',
  letterSpacing: 0.05,
});

const FieldEntry = styled(DefaultText)({
  fontWeight: 'normal',
  fontSize: '15px',
  lineHeight: '20px',
  letterSpacing: 0.01,
});

const Label = styled.label({
  fontWeight: '600',
  fontSize: '12px',
  lineHeight: '20px',
});

const BoldText = styled(DefaultText)({
  fontSize: '16px',
  fontWeight: 600,
});

const InsightsTitle = styled(DefaultText)({
  fontWeight: 500,
  fontSize: '24px',
  lineHeight: '29px',
});

//TODO: Remove once the components are used throughout the app
// eslint-disable-next-line import/no-unused-modules
export {
  MainTitle,
  Title1,
  Title2,
  Headline1,
  Headline2,
  Headline3,
  LargestBody,
  LargeBody,
  Body,
  SmallBody,
  Footnote,
  Caption1,
  Caption2,
  ButtonLabel,
  SmallButtonLabel,
  FieldEntry,
  InsightsTitle,
  Label,
  BoldText,
};
