import React from 'react';

import PropTypes from 'prop-types';

function GradientSVG({
  id,
  startColor,
  endColor,
  middleColor,
  transformation,
  startOffset = '0%',
  endOffset = '100%',
}) {
  return (
    <svg height={0}>
      <defs>
        <linearGradient id={id} gradientTransform={transformation}>
          <stop offset={startOffset} stopColor={startColor} />
          {middleColor && <stop offset={endOffset} stopColor={middleColor} />}
          <stop offset={endOffset} stopColor={endColor} />
        </linearGradient>
      </defs>
    </svg>
  );
}

GradientSVG.propTypes = {
  id: PropTypes.string,
  startColor: PropTypes.string,
  endColor: PropTypes.string,
  middleColor: PropTypes.string,
  transformation: PropTypes.string,
  startOffset: PropTypes.string,
  endOffset: PropTypes.string,
};

export default GradientSVG;
