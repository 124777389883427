import React, {useState, useCallback} from 'react';

import {Box} from '@material-ui/core';
import {useMountEffect, useToggle} from '@react-hookz/web';
import {Helmet} from 'react-helmet';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {useTheme} from 'styled-components';

import {RoundedButton} from '../../components/Button';
import ErrorBoundary from '../../components/ErrorBoundary';
import useDetectMobile from '../../hooks/useDetectMobile';
import {logger} from '../../services/Logger';
import {sendComponentAnalytics, sendPageAnalytics} from '../../utils/Analytics';
import ErrorModal from './ErrorModal';
import {FaqSection} from './FaqSection';
import SearchWidget from './SearchWidget';
import SolarScoreLandingBody from './SolarScoreLandingBody';
import {CapitalizedLink, HeroImage} from './styles';

export default function SolarScoreLandingPage() {
  const isMobile = useDetectMobile();
  const theme = useTheme();
  const headerHeight = isMobile ? '200px' : '500px';
  const [open, toggleOpen] = useToggle(false);
  const history = useHistory();
  const {path} = useRouteMatch();

  const [selectedSearchResult, setSelectedSearchResult] = useState();
  const propertyKey = selectedSearchResult?.property_key;
  const isValidSearchResult =
    selectedSearchResult?.category === 'StreetAddress' &&
    selectedSearchResult?.sub_category !== 'SchemeUnit' &&
    !selectedSearchResult?.property_has_sectional_title_units;

  useMountEffect(() => {
    sendPageAnalytics({
      pageCategory: 'Property Hub',
      pageName: 'Property Hub:Solar Score',
      pageSubSection1: 'Property Hub:Solar Score',
      pageSubSection2:
        "Property Hub:Solar Score:how does looksee's solar score work",
    });
  });

  const handleError = useCallback(e => {
    logger.error(e);
    sendComponentAnalytics(
      {platformMessage: 'solar score not available right now'},
      'platformMessage',
    );
    toggleOpen(true);
  }, []);

  const onSearchResultSelect = useCallback(searchResult => {
    sendComponentAnalytics({searchTerm: 'solar score'}, 'search_form');
    setSelectedSearchResult(searchResult);
  }, []);

  const onGetSolarScoreClick = useCallback(() => {
    if (isValidSearchResult) {
      history.push(`${path}/${propertyKey}`);
    } else {
      handleError(new Error('Invalid search result selected'));
    }
  }, [propertyKey, handleError, history, isValidSearchResult, path]);

  const title = (
    <span>
      Calculate your{' '}
      <Box
        component="span"
        sx={{
          textDecoration: 'underline',
          textDecorationColor: theme.colors.secondary,
        }}>
        Solar Score
      </Box>
    </span>
  );

  const widget = (
    <ErrorBoundary onError={handleError}>
      <SearchWidget
        title={title}
        subtitle="Interested in going solar?"
        onSelect={onSearchResultSelect}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%">
          <RoundedButton
            data-id="link_content"
            data-scope="button"
            data-intent="navigational"
            data-text="calculate your solar score | get my solar score button click"
            text="Get my Solar Score"
            backgroundColor={theme.colors.blueMobileNav}
            textColor={theme.colors.white}
            onClick={onGetSolarScoreClick}
            disabled={!selectedSearchResult}
          />
          <Box textAlign="center" marginTop="20px">
            <CapitalizedLink
              to="#coverage"
              scroll={el =>
                el.scrollIntoView({behavior: 'smooth', block: 'center'})
              }
              data-id="link_content"
              data-intent="navigational"
              data-scope="link"
              data-text="calculate your solar score | check coverage in my area link click">
              Check coverage in my area
            </CapitalizedLink>
          </Box>
        </Box>
      </SearchWidget>
    </ErrorBoundary>
  );

  let header = (
    <HeroImage paddingLeft="5%" height={headerHeight} display="flex">
      <Box alignSelf="center" maxWidth="fit-content">
        {widget}
      </Box>
    </HeroImage>
  );

  if (isMobile) {
    header = (
      <>
        <HeroImage height={headerHeight} />
        <Box paddingX="5%" marginTop="-35px">
          {widget}
        </Box>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>LookSee Solar Score</title>
        <meta
          name="description"
          content="Use our solar calculator to discover your roof’s generation potential and how much you could save on your electricity bill."
        />
      </Helmet>
      {header}
      <SolarScoreLandingBody />
      <FaqSection />
      <ErrorModal
        open={open}
        onClose={toggleOpen}
        hasAddress={!!selectedSearchResult}
      />
    </>
  );
}
