import React from 'react';

import moment from 'moment';
import {useSetRecoilState} from 'recoil';
import {useTheme} from 'styled-components';

import atoms, {STRINGS} from '../../atoms';
import {getImage} from '../../utils/formatting';
import Currency from '../Currency';
import {InfoTitle, InfoTextBold, InfoText, LightText} from './moreinfo.styles';
import {
  Wrapper,
  MobileInfoWrapper,
  QuantWrapper,
  MobileBody,
  MobileHeader,
  MobileBackButton,
  MobileHeadWrapper,
} from './orderdetailmobile.styles';
import {
  HeaderWrap,
  Heading,
  FlexColumn,
  TextBold,
  Text,
  OrderInfo,
  HeaderTotal,
  OrderNumber,
  ItemName,
  MobileDelivDate,
  MobileItemInfo,
  MobileItemDetails,
  MobileImageWrap,
  ProductImage,
} from './styles';

const OrderDetailMobile = ({order}) => {
  const theme = useTheme();
  const setSliderModal = useSetRecoilState(atoms.sliderModal);

  const getAppointment = slot => {
    if (slot['after']) {
      return `After ${moment(slot['after'])
        .utc()
        .format('h:mm a, MMMM Do YYYY')}`;
    } else {
      return `Before ${moment(slot['before'])
        .utc()
        .format('DD/MM/YYYY HH:mm')}`;
    }
  };

  return (
    <Wrapper>
      <MobileHeader>
        <MobileBackButton
          src={theme.images.chevronLeft}
          onClick={() => setSliderModal(null)}
        />
        {STRINGS.ORDER_DETAILS}
      </MobileHeader>
      <HeaderWrap>
        <Heading alert={order.alertText}>{order.stateDescription}</Heading>
        <OrderInfo>
          <HeaderTotal>Total: R{order.paymentAmount}</HeaderTotal>
          <FlexColumn>
            <TextBold>
              Order placed:
              <Text>{moment(order.createdAt).format('LL')}</Text>
            </TextBold>
          </FlexColumn>
          <OrderNumber>
            Order no:<Text>{order.orderId}</Text>
          </OrderNumber>
        </OrderInfo>
      </HeaderWrap>
      {order.orderLineItems &&
        order.orderLineItems.map(item => {
          const {deliveryDetails} = item;
          const {address} = deliveryDetails;
          return (
            <MobileBody key={item.lineItemId}>
              <MobileHeadWrapper>
                <MobileImageWrap>
                  <ProductImage src={getImage(item.item)} />
                </MobileImageWrap>
                <FlexColumn>
                  <ItemName>{item.item.attributes.name}</ItemName>
                  <MobileItemInfo>
                    {order.orderLineItems.length > 1 && (
                      <MobileItemDetails>
                        <Currency value={item.item.price.amount} />
                      </MobileItemDetails>
                    )}
                    <MobileDelivDate>
                      {getAppointment(item.deliveryDetails.slot)}
                    </MobileDelivDate>
                  </MobileItemInfo>
                </FlexColumn>
              </MobileHeadWrapper>
              <MobileInfoWrapper>
                <InfoTitle>
                  <InfoTextBold>{`${STRINGS.PROVIDER}:`}</InfoTextBold>
                  <InfoText>{item.item.attributes.brand}</InfoText>
                </InfoTitle>
                <LightText>{item.item.attributes.brand}</LightText>
              </MobileInfoWrapper>
              <MobileInfoWrapper>
                <InfoTitle>
                  <InfoTextBold>{`${STRINGS.DISPATCH}:`}</InfoTextBold>
                  <InfoText>{`${address.number} ${address.street}`}</InfoText>
                </InfoTitle>
                <LightText>{address.suburb}</LightText>
                <LightText>{address.city}</LightText>
                <LightText>{address.postalCode}</LightText>
              </MobileInfoWrapper>
              <QuantWrapper>
                <InfoTitle>
                  <InfoTextBold>{`${STRINGS.QUANTITY}:`}</InfoTextBold>
                  <InfoText>{item.quantity}</InfoText>
                </InfoTitle>
              </QuantWrapper>
            </MobileBody>
          );
        })}
    </Wrapper>
  );
};

export default OrderDetailMobile;
