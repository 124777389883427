import React, {useCallback} from 'react';

import {AccordionSummary, Box, Card, Grid} from '@material-ui/core';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import {useTheme} from 'styled-components';

import {RoundedButton} from '../../components/Button';
import Currency from '../../components/Currency';
import PackageCarousel from '../../components/PackageCarousel';
import {
  BodyExLg,
  BodyMed,
  BodySm,
  BodyXsm,
  Header3,
} from '../../components/newTypography';
import useDetectMobile from '../../hooks/useDetectMobile';
import {calculateMonthlyPayment} from '../../utils';
import {MobilePackageCard, PackageCard, WarrantyBanner} from './styles';

export default function PackageWidget({
  solarPackage,
  calculationsConfig,
  onContinueClick = null,
  selected = false,
  financeScreen = false,
  buttonDisabled = false,
  packageType,
}) {
  const {
    description,
    name,
    price,
    inverterCapacity,
    batterySize,
    numPanels,
    images,
  } = solarPackage;
  const theme = useTheme();
  const isMobile = useDetectMobile({detectTablet: false});
  const averageTariff = calculationsConfig.tariffRate;
  const usage = calculationsConfig.monthlyAverageBill / averageTariff;
  const monthlyOutput = solarPackage.monthlyOutput || 0;

  const systemPowerOffset = Math.round((monthlyOutput / usage) * 100);
  const monthlySavingsAmount = (monthlyOutput * averageTariff).toFixed(2);
  let estMonthlyPrice = -calculateMonthlyPayment(
    calculationsConfig.interestRate / 12,
    calculationsConfig.loanTermMonths,
    price,
  );

  if (packageType === 'batteryBackup') {
    estMonthlyPrice = -calculateMonthlyPayment(
      calculationsConfig.interestRateBattery / 12,
      calculationsConfig.loanTermMonthsBattery,
      price,
    );
  }

  const isRealSavingsAmount =
    monthlySavingsAmount > calculationsConfig.monthlyAverageBill &&
    monthlyOutput > 0;
  const displayedSavingsAmount = isRealSavingsAmount
    ? calculationsConfig.monthlyAverageBill
    : monthlySavingsAmount;

  const onPackageSelect = useCallback(
    event => {
      onContinueClick({
        ...solarPackage,
        ...{
          systemPowerOffset,
          monthlySavingsAmount: Number(displayedSavingsAmount),
          estMonthlyPrice: Number(estMonthlyPrice.toFixed(2)),
          estCashPrice: price,
        },
      });
      event.stopPropagation();
    },
    [onContinueClick, solarPackage],
  );

  const continueButton = (
    <RoundedButton
      data-text={`View range of solutions | Your solution | ${name} | Continue button click`}
      data-id="link_content"
      data-scope="button"
      data-intent="confirmational"
      text="Continue"
      aria-label="Continue"
      onClick={onPackageSelect}
      backgroundColor={theme.colors.blueMobileNav}
      textColor={theme.colors.white}
      disabledColor={theme.colors.white}
      disabledBGColor={theme.colors.grayFour}
      disabled={buttonDisabled}
    />
  );

  const headerComponent = (
    <Grid container>
      <Box
        component={Grid}
        pb={2}
        item
        xs={12}
        sm
        container
        justifyContent="space-between">
        <Box>
          <Header3 inline>{name}</Header3>
        </Box>
        <Box
          component={WarrantyBanner}
          px={3}
          py={1}
          variant="outlined"
          sx={{display: {xs: 'none', md: 'block'}}}>
          <BodySm fontWeight="700" m="0">
            Fully warrantied components
          </BodySm>
        </Box>
      </Box>
      <Grid item container xs={12}>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12}>
            <BodyMed inline>From</BodyMed>
          </Grid>
          <Grid item xs={12}>
            <BodyExLg inline fontWeight={700}>
              <Currency decimalValue={0} value={estMonthlyPrice} />
            </BodyExLg>
            <BodyXsm inline> p/m</BodyXsm>
          </Grid>
          {displayedSavingsAmount > 0 && (
            <Grid item xs={11}>
              <BodyXsm inline>
                <Currency decimalValue={0} value={displayedSavingsAmount} /> in
                monthly savings
              </BodyXsm>
            </Grid>
          )}
        </Grid>
      </Grid>
      {selected && isMobile && !financeScreen && (
        <Box component={Grid} item xs={12} paddingY="10px">
          {continueButton}
        </Box>
      )}
    </Grid>
  );

  const body = (
    <>
      <Box py={2}>
        <ReactMarkdown>{description}</ReactMarkdown>
      </Box>
      <Box py={2}>
        <BodyXsm inline>In this solution:</BodyXsm>
        <Box component={Grid} item xs={12} container spacing={3}>
          {numPanels && (
            <Grid item xs={12} sm={4}>
              <Box
                component={Card}
                variant="outlined"
                display="flex"
                alignItems="center">
                <Box px={2} component="img" src={theme.images.solarPowerIcon} />
                <BodySm inline>{numPanels} x panels</BodySm>
              </Box>
            </Grid>
          )}
          <Grid item xs={12} sm={4}>
            <Box
              component={Card}
              variant="outlined"
              display="flex"
              alignItems="center">
              <Box px={2} component="img" src={theme.images.batteryIcon} />
              <BodySm inline>{batterySize}kWh battery</BodySm>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box
              component={Card}
              variant="outlined"
              display="flex"
              alignItems="center">
              <Box px={2} component="img" src={theme.images.inverterIcon} />
              <BodySm inline>{inverterCapacity}kW inverter</BodySm>
            </Box>
          </Grid>
        </Box>
      </Box>
    </>
  );

  return (
    <Box bgcolor={theme.colors.white}>
      {isMobile ? (
        <MobilePackageCard gradient={selected}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="solution-content"
            id="solution-accordion-header">
            {headerComponent}
          </AccordionSummary>
          <Box
            component={AccordionDetails}
            display="flex"
            flexDirection="column">
            <PackageCarousel
              imageUrls={[images]}
              percentage={systemPowerOffset}
            />
            {body}
            {onContinueClick && !financeScreen && <div>{continueButton}</div>}
          </Box>
        </MobilePackageCard>
      ) : (
        <Box
          component={PackageCard}
          px={3}
          py={5}
          display="flex"
          sx={{flexDirection: {xs: 'column', md: 'row'}}}
          gradient={selected}>
          <Box paddingRight="30px" sx={{width: {md: '50%'}}}>
            <PackageCarousel
              imageUrls={[images]}
              percentage={systemPowerOffset}
            />
          </Box>
          <Box sx={{width: {md: '50%'}}}>
            {headerComponent}
            {body}
            {onContinueClick && !financeScreen && <div>{continueButton}</div>}
          </Box>
        </Box>
      )}
    </Box>
  );
}

PackageWidget.propTypes = {
  solarPackage: PropTypes.object.isRequired,
  calculationsConfig: PropTypes.object.isRequired,
  onContinueClick: PropTypes.func,
  selected: PropTypes.bool,
  financeScreen: PropTypes.bool,
  buttonDisabled: PropTypes.bool,
  packageType: PropTypes.string,
};
