import React, {useCallback, useState} from 'react';

import {Box, Card, CardContent, Container, Grid} from '@material-ui/core';
import PropTypes from 'prop-types';
import {useTheme} from 'styled-components';

import {Tooltip} from '../../components';
import Currency from '../../components/Currency';
import SliderLabel from '../../components/SliderLabel/SliderLabel';
import {
  BodySm,
  Header1,
  Header2,
  Subtitle1,
  Subtitle2,
} from '../../components/newTypography';
import {useFeaturesContext} from '../../provider/features';
import {AnnualSavingsSlider, GradientCard} from './styles';

const sliderValueText = value => `${value} ${value > 1 ? 'years' : 'year'}`;
const defaultValue = 1;

export default function AnnualSavingsCalculator({kWhPerYear = 0}) {
  const theme = useTheme();
  const features = useFeaturesContext();
  const {
    solarHPAConfig: {defaultKiloWattRate},
  } = features;
  const defaultRate = parseFloat(defaultKiloWattRate).toFixed(2);
  const [years, setYears] = useState(defaultValue);
  const savings = kWhPerYear * defaultKiloWattRate * years;

  const handleChange = useCallback(
    (event, yearsValue) => {
      setYears(yearsValue);
    },
    [kWhPerYear],
  );

  return (
    <Container>
      <Box mb={1}>
        <Header2 inline>Annual Electricity production calculator</Header2>
      </Box>
      <Box mb={3}>
        <Subtitle1 inline color={theme.colors.grayOne}>
          See an estimated Rand value of the solar energy produced, if you
          installed the maximum possible system size.
        </Subtitle1>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{height: {xs: '180px', md: '100%'}}}
            width="100%"
            component={Card}
            display="flex"
            flexDirection="column">
            <Box p={2} display="flex" justifyContent="space-between">
              <ul>
                <li>
                  <Subtitle2
                    style={{marginTop: 0}}
                    inline
                    color={theme.colors.tertiary}>
                    Estimate based on results of solar score analysis
                  </Subtitle2>
                </li>
              </ul>
              <Box display="flex" alignItems="center">
                <Tooltip
                  id="solar-score"
                  dataIntent="informational"
                  dataScope="tool tip"
                  dataId="link_content"
                  dataText="your solar score analysis | estimate based on results of solar score analysis tool tip click"
                  body={`Rand value is calculated as the potential energy produced on your roof. Tariff assumed at R${defaultRate}/kWh.`}
                  icon={theme.images.info}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              width="95%"
              height="20%"
              sx={{mt: {xs: 1, sm: 5}}}>
              <Box mx="3%">
                <Subtitle2 inline color={theme.colors.tertiary}>
                  1
                </Subtitle2>
              </Box>
              <Box width="100%">
                <AnnualSavingsSlider
                  data-intent="informational"
                  data-scope="solar score calculator"
                  data-id="link_content"
                  data-text="your solar score analysis | estimate based on results of solar score analysis  | calculator click"
                  valueLabelFormat={sliderValueText}
                  onChange={handleChange}
                  ValueLabelComponent={SliderLabel}
                  step={1}
                  min={1}
                  max={25}
                  defaultValue={defaultValue}
                  valueLabelDisplay="on"
                />
              </Box>
              <Box mx="3%">
                <Subtitle2 inline color={theme.colors.tertiary}>
                  25
                </Subtitle2>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item container xs={12} md={6} alignItems="stretch">
          <Box
            sx={{height: {xs: '180px', md: '100%'}}}
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            component={GradientCard}>
            <CardContent>
              <Header1 inline color={theme.colors.white}>
                <Currency value={savings} decimalValue={0} />
              </Header1>
              <Box>
                <BodySm fontWeight="500" inline>
                  Estimated Rand value for your roof over{' '}
                  {sliderValueText(years)}
                </BodySm>
              </Box>
            </CardContent>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

AnnualSavingsCalculator.propTypes = {
  kWhPerYear: PropTypes.number,
};
