import React from 'react';

import {Redirect, Route, useLocation} from 'react-router-dom';
import {useRecoilState, useRecoilValue} from 'recoil';

import atoms from '../atoms';
import PingIdentityServices from '../services/PingIdentityServices';
import {productsServicesUrl} from './routes';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const QueryRoute = ({children, ...rest}) => {
  let query = useQuery();

  const _type = query.get('type');
  const _ref = query.get('ref');
  const _method = query.get('method');
  const _status = query.get('status');

  return (
    <Route
      {...rest}
      render={({location}) =>
        _type && _ref && _method && _status ? (
          <Redirect
            to={{
              pathname: `/${_type}/${_ref}/${_method}/${_status}`,
              state: {from: location},
            }}
          />
        ) : (
          children
        )
      }
    />
  );
};

export const PrivateRoute = ({children, requireClaims = false, ...rest}) => {
  const user = useRecoilValue(atoms.user);
  const [logout, setLogout] = useRecoilState(atoms.logout);
  const claimedPropertyList = useRecoilValue(atoms.claimedPropertyList);
  const hasClaims = claimedPropertyList.length > 0;
  const valid = user && (!requireClaims || hasClaims);

  if (logout) {
    setLogout(false);
    return (
      <Route
        {...rest}
        render={({location}) => (
          <Redirect
            to={{
              pathname: `${productsServicesUrl}`,
              state: {from: location},
            }}
          />
        )}
      />
    );
  }
  if (!user && !valid) {
    PingIdentityServices.redirectToLogin();
  } else {
    return (
      <Route
        {...rest}
        render={({location}) =>
          valid ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: `${productsServicesUrl}`,
                state: {from: location},
              }}
            />
          )
        }
      />
    );
  }
};
