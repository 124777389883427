import styled from 'styled-components';

import {Headline2} from '../../typography';

export const MenuTitle = styled(Headline2)`
  display: flex;
  align-items: center;
  padding: ${({theme: {spacing}}) => spacing.normal};
`;

export const PaddingItem = styled.div`
  padding: ${({theme: {spacing}}) => spacing.normal};
`;
