import React from 'react';

import {Box, Link} from '@material-ui/core';
import {useToggle} from '@react-hookz/web';
import {useTheme} from 'styled-components';

import {BodyMed} from '../newTypography';

function TermsAndConditionsAndMore() {
  const theme = useTheme();

  const [showMoreOrLess, setshowMoreOrLess] = useToggle();

  const termsAndConditions = {
    firstSection: (
      <BodyMed color={theme.colors.tertiary} inline>
        We understand that your personal information is important to you. By
        using our products, you acknowledge that your personal information{' '}
      </BodyMed>
    ),
    secondSection: (
      <BodyMed color={theme.colors.tertiary} inline>
        will be processed by us and third parties(if necessary) according to our{' '}
        <a
          href="https://www.standardbank.co.za/southafrica/personal/about-us/legal/privacy-statement"
          target="_blank"
          rel="noreferrer">
          Privacy Statement
        </a>
        , which is in line with all applicable laws on protecting and processing
        personal information. It is your responsibility to read and understand
        the contents of the Privacy Statement which is available on our website
        at{' '}
        <a
          href="https://www.standardbank.co.za/southafrica/personal/about-us/legal/privacy-statement"
          target="_blank"
          rel="noreferrer">
          www.standardbank.co.za/privacy
        </a>{' '}
        or you can ask us to email it to you.
      </BodyMed>
    ),
    thirdSection: (
      <BodyMed color={theme.colors.tertiary}>
        We will maintain the confidentiality of your personal information and we
        will implement security safeguards to secure your personal information
        as set out in the Privacy Statement.
      </BodyMed>
    ),
    forthSection: (
      <BodyMed color={theme.colors.tertiary} inline>
        Our Privacy Statement includes what personal information is; what
        information we process; how we process your information; where we
        collect your information; who we share your information with; your
        rights as a data subject and the complaints contact details of both
        Standard Bank and the Information Regulator
      </BodyMed>
    ),
  };

  return (
    <Box>
      <BodyMed fontWeight="600" color={theme.colors.tertiary}>
        Terms and conditions:
      </BodyMed>
      <Box textOverflow="ellipsis" display="inline">
        {termsAndConditions.firstSection}
        {showMoreOrLess && [
          termsAndConditions.secondSection,
          termsAndConditions.thirdSection,
          termsAndConditions.forthSection,
        ]}
      </Box>
      <Link
        component="button"
        type="button"
        onClick={setshowMoreOrLess}
        color={theme.colors.newPrimary}
        style={{padding: '0px'}}>
        <BodyMed inline>... </BodyMed>
        <BodyMed inline color={theme.colors.newPrimary}>
          {showMoreOrLess ? 'See less' : 'See more'}
        </BodyMed>
      </Link>
    </Box>
  );
}

export default TermsAndConditionsAndMore;
