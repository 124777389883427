import styled from 'styled-components';

export const Overlay = styled.div`
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  top: 0;
  height: 100vh;
  width: 100%;
  background: rgba(33, 33, 33, 0.2);
  z-index: 9999;
  padding: 0px 10px;
  @media ${props => props.theme.device.tablet} {
    padding: 0;
  }
`;
export const Box = styled.div`
  box-sizing: border-box;
  background: ${props => props.theme.colors.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  padding: 20px 10px;
  @media ${props => props.theme.device.tablet} {
    padding: 30px 50px;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
`;
export const Text1 = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: ${props => props.theme.colors.black};
  margin: 0;
`;
export const Text2 = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;

  @media ${props => props.theme.device.tablet} {
    font-size: 17px;
  }
`;
export const SecondaryAction = styled.p`
  box-sizing: border-box;
  color: ${props => props.theme.colors.primary};
  line-height: 24px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;

  &:hover {
    cursor: pointer;
  }

  &:active {
    opacity: 0.7;
  }

  @media ${props => props.theme.device.tablet} {
    font-size: 18px;
  }
`;
export const Image = styled.img`
  height: 105px;
  width: 100px;

  @media ${props => props.theme.device.mobileM} {
    height: 155px;
    width: 150px;
  }
  @media ${props => props.theme.device.tablet} {
    height: 190px;
    width: 185px;
  }
  @media ${props => props.theme.device.laptop} {
    height: 290px;
    width: 315px;
  }
`;
export const TextWrapper = styled.div`
  box-sizing: border-box;
  text-align: center;
  padding: 10px;

  @media ${props => props.theme.device.tablet} {
    padding: 10px 0px;
    width: 520px;
  }
`;
export const ButtonWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 10px;

  @media ${props => props.theme.device.tablet} {
    width: 370px;
  }
`;
