import React from 'react';

import PropTypes from 'prop-types';
import {useTheme} from 'styled-components';

import ProductAccordion from '../ProductAccordion';
import {LargeBody, SmallBody} from '../typography';
import {Container, Question} from './styles';

function ProductFaqs({faqs}) {
  const theme = useTheme();

  return (
    <ProductAccordion title={faqs.heading}>
      <LargeBody color={theme.colors.grayEight}>{faqs.description}</LargeBody>
      <Container>
        {faqs.keyValuePairs?.map(({key, value}, i) => (
          <div key={i}>
            <Question>
              <LargeBody color={theme.colors.primary}>{key}</LargeBody>
            </Question>
            {value && (
              <SmallBody color={theme.colors.grayEight}>{value}</SmallBody>
            )}
          </div>
        ))}
      </Container>
    </ProductAccordion>
  );
}

ProductFaqs.propTypes = {
  faqs: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    keyValuePairs: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
  }),
};

export default ProductFaqs;
