import styled from 'styled-components';

export const FeatureItem = styled.div`
  text-align: right;
`;

export const Container = styled.div`
  display: grid;
  width: 100%;
  padding: 3px;
  grid-template-columns: 1fr 1fr;
  border-bottom: ${props => `1px solid ${props.theme.colors.grayFour}`};
  @media ${props => props.theme.device.mobileL} {
    grid-template-columns: 1fr 1fr;
  }
`;
