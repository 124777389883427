import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-top: 10px;

  @media ${props => props.theme.device.mobile} {
    overflow-x: hidden;
  }
`;

export const Title = styled.div`
  font: ${({theme}) => theme.typography.title2};
  margin-left: ${({theme}) => theme.spacing.small};
  @media ${props => props.theme.device.tablet} {
    margin-left: ${({theme}) => theme.spacing.xSmall};
  }
`;

export const ProductImage = styled.img`
  width: 60px;
  margin-right: 18px;
  margin-left: -8px;
  @media ${props => props.theme.device.tablet} {
    width: 100px;
    margin-right: ${({theme}) => theme.spacing.small};
    object-fit: contain;
    margin-left: ${({theme}) => theme.spacing.small};
  }
  @media ${props => props.theme.device.laptop} {
    margin-right: 0px;
  }
`;

export const Text = styled.span`
  font: ${({theme}) => theme.typography.description};
  margin-left: 5px;
`;

export const TextBold = styled.span`
  font: ${({theme}) => theme.typography.headline3};
  margin: 2px 0px;
  color: ${({theme}) => theme.colors.grayFive};
`;

export const Heading = styled.h4`
  padding-top: ${({theme}) => theme.spacing.tiny};
  margin: 0;
  color: ${({theme, alert}) =>
    alert ? theme.colors.red800 : theme.colors.black};
  font-size: 16px;
  font-weight: 600;
  margin-bottom: ${({theme}) => theme.spacing.xSmall};
`;

export const OrderNumber = styled.h4`
  font: ${({theme}) => theme.typography.headline3};
  padding: 0px;
  margin: 0px;
  margin-top: ${({theme}) => theme.spacing.xSmall};
  color: ${({theme}) => theme.colors.grayFive};
  @media ${props => props.theme.device.tablet} {
    text-align: end;
    margin-top: 0px;
  }
`;

export const BorderSummaryItemWrap = styled.div(
  ({theme}) => `
    width: 100%;
    background-color: ${theme.colors.white};
    border-bottom: ${theme.border.default};

    @media ${theme.device.tablet} {
      border-bottom: none;
      margin: ${theme.spacing.normal} 0px;
      border-radius: ${theme.spacing.xSmall};
    }
  `,
);

export const HeaderWrap = styled.div`
  padding: 14px 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: ${({theme}) => theme.colors.border};

  @media ${props => props.theme.device.tablet} {
    flex-direction: row;
    justify-content: space-between;
    padding: 14px 24px;
    background: white;
  }
`;

export const OrderInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({theme}) => theme.spacing.tiny};
`;

export const CardBody = styled.div`
  display: grid;
  @media ${props => props.theme.device.laptop} {
    grid-template-columns: 65% 35%;
  }
`;

export const HeaderTotal = styled.div`
  color: ${({theme}) => theme.colors.grayEight};
  font: ${({theme}) => theme.typography.headline2};
  display: flex;
  justify-content: flex-start;
  margin-bottom: ${({theme}) => theme.spacing.xSmall};
  @media ${props => props.theme.device.tablet} {
    justify-content: flex-end;
    font: ${({theme}) => theme.typography.title2};
  }
`;

export const ImageWrap = styled.div`
  display: none;
  justify-content: center;
  align-items: flex-start;
  @media ${props => props.theme.device.tablet} {
    display: flex;
  }
  @media ${props => props.theme.device.laptop} {
    margin-right: ${({theme}) => theme.spacing.normal};
  }
`;

export const MobileImageWrap = styled.div`
  margin: 0px;
  margin-left: ${({theme}) => theme.spacing.normal};
  @media ${props => props.theme.device.tablet} {
    display: none;
  }
`;

export const DetailsWrapper = styled.div(
  ({theme, item}) => `
    display: flex;
    flex-direction: column;
    border-bottom: ${
      item.length > 1 ? `1px solid ${theme.colors.border}` : 'none'
    };
    margin-bottom: ${theme.spacing.xSmall};
    padding-top: ${theme.spacing.small};
    background: ${theme.colors.white};
    &:last-child {
      border-bottom: none;
    }
    @media ${theme.device.tablet} {
      flex-direction: row;
      padding-top: none;
    }
  `,
);

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ItemName = styled.div`
  font: ${({theme}) => theme.typography.footerBold};
  color: ${({theme}) => theme.colors.primary};
  margin-bottom: 8px;
  @media ${props => props.theme.device.tablet} {
    font: 500 18px Inter;
    width: 83%;
    margin-bottom: 0px;
  }
  @media ${props => props.theme.device.laptop} {
    width: 80%;
  }
  @media ${props => props.theme.device.laptopL} {
    width: 66%;
  }
`;

export const ItemDetails = styled.div`
  font: 500 14px Inter;
  margin-bottom: ${({theme}) => theme.spacing.small};
  display: none;
  @media ${props => props.theme.device.tablet} {
    display: block;
    font: 500 18px Inter;
  }
`;

export const ItemPrice = styled.div`
  font: ${({theme}) => theme.typography.footerBold};
  margin-bottom: ${({theme}) => theme.spacing.xSmall};
  @media ${props => props.theme.device.tablet} {
    font: 500 18px Inter;
    margin-bottom: ${({theme}) => theme.spacing.small};
  }
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const QuantWrapper = styled.div`
  width: 100%;
  display: none;
  margin-bottom: ${({theme}) => theme.spacing.xSmall};

  @media ${props => props.theme.device.tablet} {
    display: block;
  }
`;

export const Attributes = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 18px;
  @media ${props => props.theme.device.tablet} {
    margin: 0px;
    width: 100%;
  }
`;

export const MobileDelivDate = styled.div`
  display: flex;
  margin-bottom: ${({theme}) => theme.spacing.xSmall};
  font: ${({theme}) => theme.typography.footerBold};
  color: ${({theme}) => theme.colors.grayEight};
`;

export const MobileItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  @media ${props => props.theme.device.tablet} {
    display: none;
  }
`;

export const MobileItemDetails = styled.div`
  color: ${({theme}) => theme.colors.black};
  font: ${({theme}) => theme.typography.footerBold};
  margin-bottom: ${({theme}) => theme.spacing.xSmall};
`;

export const EmptyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 60px);
  place-content: center;
`;

export const EmptyText = styled.div`
  font: ${({theme}) => theme.typography.headline1};
  color: ${({theme}) => theme.colors.grayFive};
  text-align: center;
  @media ${({theme}) => theme.device.mobileL} {
    font: ${({theme}) => theme.typography.title1};
  }
`;

export const TitleWrapper = styled.div(
  ({theme}) => `
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    @media ${theme.device.mobileL} {
      flex-direction: row;
      padding: 8px 20px 8px 0;
      border-bottom: ${theme.border.default};
    }
  `,
);
