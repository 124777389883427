import React from 'react';

import {Tooltip} from '@material-ui/core';
import styled from 'styled-components';

export const StyledTooltip = styled(({className, ...props}) => (
  <Tooltip {...props} classes={{popper: className}} />
))(({theme}) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
    boxShadow: theme.gradient.tooltipShadow,
    fontSize: '14px',
    '& .MuiTooltip-arrow': {
      color: theme.colors.white,
    },
    '& .MuiTooltip-tooltipArrow': {boxShadow: theme.gradient.tooltipShadow},
  },
}));
