import React from 'react';

import {Box} from '@material-ui/core';
import {useTheme} from 'styled-components';

import {BodyReg, BodyXsm} from '../newTypography';

export default function BulletedItem({title, body, number}) {
  const {images, colors} = useTheme();

  return (
    <Box>
      <Box display="inline-flex" alignItems="start" paddingY="3%">
        <Box position="relative" sx={{top: {xs: '2px', md: '4px'}}}>
          <Box>
            <img src={images.stepCircle} alt="step circle" />
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            position="relative"
            top="-34px">
            <BodyXsm color={colors.newPrimary}>{number}</BodyXsm>
          </Box>
        </Box>
        <Box
          paddingLeft="5px"
          display="flex"
          flexDirection="column"
          sx={{minWidth: {xs: '60%', sm: '100%'}}}>
          <BodyReg fontWeight={700} color={colors.darkGreyText} m="1%">
            {title}
          </BodyReg>
          <BodyReg color={colors.darkGreyText} m="1%">
            {body}
          </BodyReg>
        </Box>
      </Box>
    </Box>
  );
}
